// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { List, Input, Typography, Modal } from 'antd';
import CoverModal from 'components/cover-modal';
import ReactDragListView from 'react-drag-listview';
import AntIcon from 'components/ant-icon';
import IconPicker from 'components/icon-picker';

// Interfaces
import { ITopic, IArticle } from 'views/support/HelpArticles';

// Icons
import { HolderOutlined, DownOutlined, RightOutlined, SettingOutlined } from '@ant-design/icons';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Styles
import 'components/help-article/HelpArticle.scss';

interface Props {
  client_id: number;
  topics: ITopic[];
  onOk: () => void;
  onClose: () => void;
};

const API: Api = new Api();

export default function TopicModal({ client_id, topics, onOk, onClose }: Props) {

  const [manipulatedTopics, setManipulatedTopics] = React.useState<ITopic[]>(topics);
  const [collapsed, setCollapsed] = React.useState<number[]>([]);
  const [placeholderTopic, setPlaceholderTopic] = React.useState<ITopic | null>(null);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const renderEditTopicDialog = () => {

    if (!placeholderTopic) return <></>;

    return (
      <Modal
        visible
        centered
        title={ 'Edit Topic' }
        okButtonProps={{
          disabled: !placeholderTopic?.title || !placeholderTopic?.description,
        }}
        onOk={ () => {
          setManipulatedTopics(manipulatedTopics.map((_topic: ITopic) => {
            if (_topic.id === placeholderTopic.id) {
              return {
                ...placeholderTopic
              };
            }
            return _topic;
          }));
          setPlaceholderTopic(null);
        } }
        onCancel={ () => setPlaceholderTopic(null) }
      >
        <div>
          <div>
            <Input
              status={ !placeholderTopic?.title ? 'error' : undefined }
              className="w-100p"
              placeholder={ 'Topic *' }
              value={ placeholderTopic?.title }
              maxLength={ 20 }
              showCount
              onChange={ (event: React.BaseSyntheticEvent) => {
                setPlaceholderTopic({
                  ...placeholderTopic,
                  title: event.target.value
                });
              } }
            />
          </div>
          <div className="mT-10">
            <Input.TextArea
              status={ !placeholderTopic?.description ? 'error' : undefined }
              autoComplete="none"
              placeholder={ 'Description *' }
              autoSize={{ minRows: 2 }}
              value={ placeholderTopic?.description }
              maxLength={ 250 }
              showCount
              onChange={ (event: React.BaseSyntheticEvent) => {
                setPlaceholderTopic({
                  ...placeholderTopic,
                  description: event.target.value
                });
              } }
            />
          </div>
          <div className="mT-30">
            <IconPicker
              selectedIcon={ placeholderTopic?.icon }
              onSelect={ (icon: string) => {
                setPlaceholderTopic({
                  ...placeholderTopic,
                  icon: icon
                });
              } }
            />
          </div>
        </div>
      </Modal>
    );
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (type: 'TOPIC' | 'ARTICLE', fromIndex: number, toIndex: number, index: number) => {

    if (toIndex < 0) return;

    switch (type) {
      case 'TOPIC':
        return setManipulatedTopics(reorder(manipulatedTopics, fromIndex, toIndex));
      case 'ARTICLE':
        const __topic = manipulatedTopics.find((__, _index: number) => _index === index);

        if (!!__topic?.articles) {
          const articles = reorder(__topic.articles, fromIndex, toIndex);
          const _topics = JSON.parse(JSON.stringify(manipulatedTopics));
          _topics[index].articles = articles;

          return setManipulatedTopics(_topics);
        }
        return;
      default:
        return new Error('Invalid target');
    }
  };

  return (
    <CoverModal
      cover
      middleContent={ 'Edit Topics' }
      isLoading={ isEditing }
      onClose={ () => onClose() }
      actions={ [
        {
          label: 'Save',
          type: 'primary',
          isDisabled: false,
          onClick: async () => {
            try {

              setIsEditing(true);

              await API.put(`client/${client_id}/support/topic`, {
                data: manipulatedTopics
              });

              Notification('success', `Topics edited`);

              onOk();
            } catch (error) {
              console.error('Error: ', error);
              Notification('error', `Failed to edit topics`);
            }
          },
        }
      ] }
    >
      <div className="d-f jc-c ai-c h-100p" style={{ backgroundColor: '#FFF' }}>
        <div className="d-f fxd-c h-100p mT-50" style={{ width: '80%', maxWidth: 750 }}>
          <ReactDragListView
            nodeSelector=".ant-list.Help-Article-Draggable-list"
            lineClassName="Help-Article-Draggable-list-line"
            onDragEnd={(fromIndex: number, toIndex: number) => onDragEnd('TOPIC', fromIndex, toIndex, 0) }
          >
            { manipulatedTopics.map((manipulatedTopic: ITopic, index: number) => (
              <List
                key={ `${manipulatedTopic.id}-${index}` }
                className="Help-Article-Draggable-list"
                header={
                  <div className="d-f ai-c w-100p">
                    <span className='d-if'>
                      <HolderOutlined className="Help-Article-Draggable-list-icon" />
                    </span>
                    <span className='d-if'>
                      { collapsed.includes(manipulatedTopic.id) ? (
                        <span className="cur-p" onClick={ () => setCollapsed(collapsed.filter((_collapseId: number) => _collapseId !== manipulatedTopic.id)) }>
                          <RightOutlined />
                        </span>
                      ) : (
                        <span className="cur-p" onClick={ () => setCollapsed(collapsed.concat(manipulatedTopic.id)) }>
                          <DownOutlined />
                        </span>
                      ) }
                    </span>
                    <span className="d-if jc-sb w-100p mL-10">
                      <span className="d-if ai-c">
                        <span className="mL-5">
                          <AntIcon type={ manipulatedTopic.icon } />
                        </span>
                        <span className="mL-5">
                          <Typography.Text strong>{ manipulatedTopic.title }</Typography.Text>
                        </span>
                      </span>
                      <span className="d-if">
                        <span className="cur-p mL-5" onClick={ () => setPlaceholderTopic(manipulatedTopic) }>
                          <SettingOutlined />
                        </span>
                      </span>
                    </span>
                  </div>
                }
              >
                { !collapsed.includes(manipulatedTopic.id) ? (
                  <ReactDragListView
                    nodeSelector=".ant-list-item.Help-Article-Draggable-list-item"
                    lineClassName="Help-Article-Draggable-list-line"
                    onDragEnd={(fromIndex: number, toIndex: number) => onDragEnd('ARTICLE', fromIndex, toIndex, index) }
                  >
                    { manipulatedTopic.articles?.map((article: IArticle, index) => (
                      <List.Item key={ index } className="Help-Article-Draggable-list-item pL-10">
                        <HolderOutlined className="Help-Article-Draggable-list-icon pR-10" />
                        <List.Item.Meta title={ article.title } />
                      </List.Item>
                    )) }
                  </ReactDragListView>
                ) : (
                  <span />
                ) }
              </List>
            ))}
          </ReactDragListView>
          { !!placeholderTopic && renderEditTopicDialog() }
        </div>
      </div>
    </CoverModal>
  );
};
