// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Containers
import MaintenancePlanContainer from 'containers/workplace-service/maintenance-plan/MaintenancePlanContainer';
import AssetCategoryContainer from './maintenance-plan/AssetCategoryContainer';
import AssetTypeContainer from './maintenance-plan/AssetTypeContainer';

// Views
import MaintenancePlans from 'views/workplace-service/MaintenancePlans';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
};

class MaintenancePlansContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/workplace-services/maintenance-plan', '/workplace-services/maintenance-plan/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'category_asset_maintenance_plan_view_list') }>
                <MaintenancePlans />
              </RestrictionWrapper>
              <Route
                exact
                path='/workplace-services/maintenance-plan/create'
                render={ () => (
                  <RestrictionWrapper restricted={ !hasPermission(permissions, 'category_asset_maintenance_plan_create') }>
                    <ListViewCreateModal
                      bundle={ 'category' }
                      type={ 'asset-maintenance-plan' }
                      redirectUrl={ '/workplace-services/maintenance-plan' }
                    />
                  </RestrictionWrapper>
                ) }
              />
            </>
          );
        } } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id" component={ AssetTypeContainer } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id" component={ AssetCategoryContainer } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id" component={ MaintenancePlanContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};


// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(MaintenancePlansContainer);
