// Libs
import React, { BaseSyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';
import { Task } from 'gantt-task-react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Button, DatePicker, Input, Select, Tooltip, Badge, Modal, Table } from 'antd';
import { hasPermission } from 'components/restriction';
import CommentsDialog from 'components/form/field/activity_management/CommentsDialog';
import ActivityUploadDialog from 'components/form/field/activity_management/ActivityUploadDialog';
import GanttView from 'components/form/field/activity_management/GanttView';
import BadgeComponent, { BadgeType, getBadgeType } from 'components/badge';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import RearrangeModal from 'components/rearrange-modal';

// Icons
import Icon, { PlusOutlined, QuestionCircleOutlined, RollbackOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Utils
import { findFirst, flattenSet, modifyNestedSetItem, removeNestedSetItem } from 'utils/utils';
import { calculateWorkdays } from 'utils/calculateWorkdays';
import { DaysOfWeek } from 'utils/activityManagement';

// Services
import { getFormatedDate, getUserSetting } from 'services/settings';
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { ActivityRecord, Alignment, Field, FieldValues } from './ActivityManagement.interfaces';
import { Feature, Competence, Milestone } from 'views/admin/templates/Templates.interfaces';
import { RecordFormEntity } from 'types/entities';
import { Comment } from 'components/comment/Comment.interface';
import { WorkflowCondition, WorkflowStage, WorkflowTransition } from 'components/workflow/Workflow.interface';
import {
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';

// Styles
import './ActivityManagement.scss';

const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD 00:00:00';
const DATE_FORMAT = getUserSetting('date_format');
const { TextArea } = Input;
const { Option } = Select;

// maximum number of days to continue activity
const MAX_DURATION = 365 * 30;

const getBlankState = (field: Field): Partial<ActivityRecord> => {
  return {
    key: uuidv4(),
    activity_id: '',
    activity_title: '',
    comment_count: 0,
    evidence_count: 0,
    document_count: 0,
    activity_resource: [],
    activity_milestone: [],
    activity_competency: [],
    activity_description: '',
    activity_url: null,

    activity_workflow_stage: field?.default_workflow_stage || null,

    activity_planned_start_date: null,
    activity_planned_duration: null,
    activity_planned_completion_date: null,

    activity_forecast_start_date: null,
    activity_forecast_duration: null,
    activity_forecast_completion_date: null,

    activity_actual_start_date: null,
    activity_actual_duration: null,
    activity_actual_completion_date: null,

    activity_required: false,
    activity_task_required: false,
    activity_not_applicable: false,
    activity_comment: true, // comments enabled for custom activity by default
    activity_evidence: true, // evidence enabled for custom activity by default
    children: [],
  };
};

const moveStartDateToMonday = (startDate: moment.Moment): moment.Moment => {
  const date = startDate.clone();
  const dayOfWeek = date.day();
  const isWeekend = dayOfWeek === DaysOfWeek.SUNDAY || dayOfWeek === DaysOfWeek.SATURDAY;
  // if the start date falls on a weekend, the date is moved to Monday
  return isWeekend ? date.add({ days: dayOfWeek === DaysOfWeek.SUNDAY ? 1 : 2 }) : date;
};

interface Props {
  clientId: number;
  originalValues: any;
  record: RecordFormEntity;
  field: Field;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onValueChange(state: any, callback: () => void): void;
  canEditActualCompletionDate: boolean;
};

interface State {
  uploadDialogInfo: { visible: boolean, activityId: number, key: string, bundle: string, type: string, fieldReference: string, folders?: number[] } | null;
  commentsDialogInfo: { visible: boolean, activityId: number, key: string, bundle: string, type: string } | null;
  showFilter: boolean;
  filterMilestones: any;
  showOverdue: boolean;
  showNextSevenDays: boolean;
  showNextTwentyEightDays: boolean;
  showGanttView: boolean;
  descriptionDialogInfo: { visible: boolean, activityKey: string, activities: any[] };
  urlDialogInfo: { visible: boolean, activityKey: string, activities: any[] };
  tmpValue: any;
  isExporting: boolean;
  shouldUpdateRows: string[];
  showRearrangeModal: boolean;
  key: string; // this will be used to force a hard rerender
};

const API: Api = new Api();

class ActivityManagementField extends React.Component<Props, State> {

  moveFormRef: any = React.createRef();
  scrollToBottomIndicator: boolean = false;

  state: State = {
    key: uuidv4(),
    uploadDialogInfo: null,
    commentsDialogInfo: null,
    showFilter: false,
    filterMilestones: [],
    showOverdue: false,
    showNextSevenDays: false,
    showNextTwentyEightDays: false,
    showGanttView: false,
    descriptionDialogInfo: { visible: false, activityKey: '', activities: [] },
    urlDialogInfo: { visible: false, activityKey: '', activities: [] },
    tmpValue: '',
    isExporting: false,
    shouldUpdateRows: [],
    showRearrangeModal: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, config, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field, config, originalValues);
    }

    // Scroll to bottom when a new item was added
    if (this.scrollToBottomIndicator) {
      this.scrollToBottom();
    }
  };

  scrollToBottom = () => {
    const table = document.querySelector('.ActivityManagementField div.ant-table-body') as HTMLElement;
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
    this.scrollToBottomIndicator = false;
  };

  export = async () => {
    try {
      await API.download(`client/${this.props.clientId}/activity/project-activity/${this.props.record.id}/export`, `pacs_project_activity_export_${moment().format('YYYY-MM-DD')}.xlsx`);
    } catch (error) {
      Notification('error', 'Export Failed');
    } finally {
      this.setState({
        isExporting: false
      });
    }
  };

  validate = (field: Field, config: FormFieldConfig, originalValues: any[]): void => {
    this.generateModifiedState(field, config, originalValues);
    this.generateErrorState(field, config);
  };

  generateModifiedState = (field: Field, config: FormFieldConfig, originalValues: any[]) => {
    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${field.id}`;

    if (!_.isEqual(originalValues, field.values)) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: 0,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      this.props.setFieldModifiedMessage(key, message);
    } else {
      this.props.setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (field: Field, config: FormFieldConfig) => {
    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${field.id}`;
    const errors: string[] = [];

    if (_.isArray(field?.values?.activities)) {
      flattenSet(field.values.activities).forEach((activity: ActivityRecord) => {
        if (!activity.activity_title) {
          errors.push(`${activity?.key || activity?.id}`);
        }
      });
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: errors
        },
        errors: errors
      };

      this.props.setFieldErrorMessage(key, message);
    } else {
      this.props.setFieldErrorMessage(key);
    }
  };

  hasError = (rowKey: string | number): boolean => {
    const { field, config, fieldErrorMessages } = this.props;
    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${field.id}`;

    return _.has(fieldErrorMessages, [key, 'errors']) && fieldErrorMessages[key]['errors'].includes(rowKey);
  };

  isDuplicatedActivityID = (activities: ActivityRecord[] = [], activityId: ActivityRecord['activity_id']): boolean => {
    if (!!activityId) {
      return activities?.filter((record: ActivityRecord) => record.activity_id === activityId).length >= 2;
    }

    return false;
  };

  filterColumns = (columns: any[], features: Feature[]): any[] => {
    return columns
      .filter((column: any) => !this.isHiddenFeature(column.dataIndex, features, column?.dependencyFeatures || []))
      .map((column: any) => {
        return {
          ...column,
          shouldCellUpdate: (record: any, prevRecord: any) => {

            // Determine if we should force update the row
            if (this.state.shouldUpdateRows.includes(record.key)) {
              this.setState({
                shouldUpdateRows: this.state.shouldUpdateRows.filter((rowKey: string | number) => rowKey !== record.key)
              });
              return true;
            }

            return !_.isEqual(record, prevRecord);
          },
          children: !_.isEmpty(column?.children) ? this.filterColumns(column.children, features) : undefined
        };
      });
  };

  isHiddenFeature = (reference: string, features: Feature[], dependencyFeatures: string[] = []): boolean => {

    if (!_.isEmpty(dependencyFeatures)) {
      return !features
        .filter((feature: Feature) => dependencyFeatures.includes(feature.reference))
        .every((feature: Feature) => !!feature.enabled);
    }

    if (features.find((feature: Feature) => feature.reference === reference)) {
      return !features.find((feature: Feature) => feature.reference === reference)?.enabled;
    }

    return false;
  };

  doesDateExceed = (date?: any, days?: number): boolean => {
    if (!!date && !!days) {
      const now = moment();
      const futureDate = moment().add(days, 'day');
      return futureDate.format(SYSTEM_DATE_FORMAT) >= date && now.format(SYSTEM_DATE_FORMAT) <= date;
    }

    return false;
  };

  getTasks = (activities: ActivityRecord[]): Task[] => {

    const tasks: Task[] = [];

    // Get Tasks
    activities.forEach((activity: ActivityRecord) => {
      // Loop planned and actual
      for (let n = 0; n < 2; n++) {

        const start = (n === 0 ? activity.activity_planned_completion_date : activity.activity_actual_completion_date);
        const end = (n === 0 ? activity.activity_planned_completion_date : activity.activity_actual_completion_date);
        const colour = (n === 0 ? '#9ccddd' : '#2f639f');

        if (start && end) {
          tasks.push(
            {
              id: `task_${activity.key}_${n}`,
              name: activity.activity_title + (n === 0 ? ' (P)' : ' (A)'),
              start: moment(start).toDate(),
              end: moment(end).toDate(),
              type: 'task',
              progress: 100,
              isDisabled: true,
              styles: { progressColor: colour, progressSelectedColor: colour },
            }
          );
        }
      }
    });

    return tasks;
  };

  isRowOverdue = (date?: any): boolean => {
    if (!!date) {
      return moment(date).isSameOrBefore(moment());
    }

    return false;
  };

  filterCheck = (rowOptions?: any, filterOptions?: any): boolean => {
    let check = false;
    for (let i = 0; i < rowOptions.length; i++) {
      check = filterOptions.includes(rowOptions[i]);
      if (!!check) {
        break;
      }
    }
    return check;
  };

  handleChange = (values: FieldValues, callback?: () => void): void => {
    this.props.onValueChange(values, callback ? callback : () => {});
  };

  modifyActivities = (identifier: string, values: ActivityRecord[], newValue: any, key: string): ActivityRecord[] => {
    const currentActivity = findFirst({ children: values }, 'children', { key: identifier });

    if (currentActivity) {
      return modifyNestedSetItem(identifier, _.set(currentActivity, [key], newValue), values);
    }

    return values;
  };

  // generate dummy state for hidden features
  generateMockState = (activity: Partial<ActivityRecord>, features: Feature[]): Partial<ActivityRecord> => {
    const copyActivity = _.cloneDeep(activity);

    if (this.isHiddenFeature('planned_commencement', features)) {
      // if the start date falls on a weekend, the date is moved to Monday for calculation
      const rangeStart = moveStartDateToMonday(moment());
      const duration = 0;
      const plannedCompletionDate = calculateWorkdays({ rangeStart: rangeStart, daysToAdd: duration });

      _.set(copyActivity, 'activity_planned_start_date', moment().format(SYSTEM_DATE_FORMAT));
      _.set(copyActivity, 'activity_planned_duration', duration);
      _.set(copyActivity, 'activity_planned_completion_date', plannedCompletionDate.format(SYSTEM_DATE_FORMAT));
    }

    return copyActivity;
  };

  renderColumnTitle = (title: string, tooltip: string = '', required: boolean = false): JSX.Element => {
    return (
      <div className="d-f">
        { tooltip &&
          <div>
            <Tooltip
              placement="top"
              title={ tooltip }
            >
              <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
            </Tooltip>
          </div>
        }
        <div className="whs-nw ov-h tov-e mL-5">
          <Tooltip
            placement="top"
            title={ title }
          >
            <span>{ title }</span>
          </Tooltip>
        </div>
        { required && <span className="text-required mL-2 lh-1 fsz-md va-t">*</span> }
      </div>
    );
  };

  renderRearrangeModal = (field: Field, sortedActivities: any[]) => {

    const replaceTitle = (item: any) => {
      return {
        ...item,
        'title': item.activity_title,
        'children': !_.isEmpty(item.children) ? item.children.map((child: any) => replaceTitle(child)) : null,
      };
    };

    const treeData = sortedActivities.map((activity: ActivityRecord) => replaceTitle(activity));

    return (
      <RearrangeModal
        isNestable
        treeData={ treeData }
        isLoading={ false }
        onOk={ (manipulatedTreeData: any) => {
          this.handleChange({
            ...field.values,
            activities: manipulatedTreeData,
          }, () => {
            this.setState({
              showRearrangeModal: false,
              key: uuidv4()
            });
          });
        } }
        onClose={ () => this.setState({ showRearrangeModal: false }) }
      />
    );
  };

  renderUploadDialog = (): JSX.Element => {
    const { clientId, field } = this.props;
    const { bundle, type, activityId, key, fieldReference, folders } = this.state.uploadDialogInfo || {};
    const isDocumentField: boolean = fieldReference === 'document_count'  ? true : false;

    const entityBundle = bundle?.replaceAll('_', '-');
    const entityType = type?.replaceAll('_', '-');

    const baseEndpoint = `client/${clientId}/${entityBundle}/${entityType}/${activityId}`;

    return (
      <ActivityUploadDialog
        getDocumentsEndpoint={ `${baseEndpoint}/folders/document` }
        uploadDocumentsEndpoint={ `${baseEndpoint}/folders/document` }
        downloadDocumentsEndpoint={ `${baseEndpoint}/document/download` }
        title={ isDocumentField ? 'Document' : 'Evidence' }
        uploadDialogTitle={ isDocumentField ? 'Document Upload' : 'Evidence Upload' }
        folderIds={ folders }
        onSave={ (count: number) => {
          if (!key) return;
          if (!fieldReference) return;

          const newValues = {
            ...field.values,
            activities: this.modifyActivities(key, _.cloneDeep(field.values.activities), count, fieldReference),
          };

          this.handleChange(newValues);
        } }
        onClose={ () => this.setState({ uploadDialogInfo: null }) }
      />
    );
  };

  renderCommentsDialog = (): JSX.Element => {
    const { clientId, field } = this.props;
    const { bundle, type, activityId, key } = this.state.commentsDialogInfo || {};
    const { activities = [] } = field.values;

    const entityBundle = bundle?.replaceAll('_', '-');
    const entityType = type?.replaceAll('_', '-');
    const activity = flattenSet(activities).find((_activity: any) => _activity.id === activityId || _activity?.key === activityId);
    const endpoint = `client/${clientId}/${entityBundle}/${entityType}/${activityId}/comment`;
    const canCreateComment = activity && !!activity.can_create_comments ? true : false;

    return (
      <CommentsDialog
        endpoint={ endpoint }
        canCreateComment={ canCreateComment }
        onSave={ (comments: Comment[]) => {
          if (!key) return;
          const newValues = {
            ...field.values,
            activities: this.modifyActivities(key, _.cloneDeep(field.values.activities), flattenSet(comments).length, 'comment_count'),
          };
          this.handleChange(newValues);
        } }
        onCancel={ () => this.setState({ commentsDialogInfo: null }) }
      />
    );
  };

  renderGanttView = (record:RecordFormEntity, tasks: Task[]): JSX.Element => {
    return (
      <GanttView
        title={ record.title }
        tasks={ tasks }
        onCancel={ () => this.setState({ showGanttView: false }) }
      />
    );
  };

  renderDescriptionDialog = (canBypassAccessChecks: boolean) => {
    const { field } = this.props;
    const { tmpValue } = this.state;
    const { activityKey, activities } = this.state.descriptionDialogInfo || {};

    const activity = field.values?.activities?.find((record: ActivityRecord) => record.key === activityKey);
    const isLocked = this.isLocked(activity, 'activity_description', canBypassAccessChecks);

    return (
      <Modal
        visible
        centered
        title={ 'Description' }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isLocked,
          title: isLocked ? 'The description is uneditable' : undefined
        }}
        onOk={ () => {
          this.handleChange({
            ...field.values,
            activities: this.modifyActivities(activityKey, _.cloneDeep(activities), _.cloneDeep(tmpValue), 'activity_description'),
          });
          this.setState({
            descriptionDialogInfo: { visible: false, activityKey: '', activities: [] },
            tmpValue: '',
          });
        } }
        onCancel={ () => this.setState({ descriptionDialogInfo: { visible: false, activityKey: '', activities: [] }, tmpValue: '' }) }
      >
        { isLocked ? (
          <div>
            <p>{ tmpValue }</p>
          </div>
        ) : (
          <TextArea
            autoSize={{ minRows: 4 }}
            disabled={ isLocked }
            autoFocus
            onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
            onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: event.target.value }) }
            defaultValue={ tmpValue }
          />
        ) }
      </Modal>
    );
  };

  renderUrlDialog = (canBypassAccessChecks: boolean) => {
    const { field } = this.props;
    const { tmpValue } = this.state;
    const { activityKey, activities } = this.state.urlDialogInfo || {};

    const activity = field.values?.activities?.find((record: ActivityRecord) => record.key === activityKey);
    const isLocked = this.isLocked(activity, 'activity_url', canBypassAccessChecks) || this.isLocked(activity, 'activity_url_label', canBypassAccessChecks);

    return (
      <Modal
        visible
        centered
        title={ 'URL' }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isLocked,
          title: isLocked ? 'The URL is uneditable' : undefined
        }}
        onOk={ () => {

          let updatedActivities = _.cloneDeep(activities);

          if (!tmpValue?.url) {
            updatedActivities = this.modifyActivities(activityKey, updatedActivities, null, 'activity_url_label');
            updatedActivities = this.modifyActivities(activityKey, updatedActivities, null, 'activity_url');
          } else {
            updatedActivities = this.modifyActivities(activityKey, updatedActivities, tmpValue?.label, 'activity_url_label');
            updatedActivities = this.modifyActivities(activityKey, updatedActivities, tmpValue.url, 'activity_url');
          }

          this.handleChange({
            ...field.values,
            activities: updatedActivities,
          });

          this.setState({
            urlDialogInfo: { visible: false, activityKey: '', activities: [] },
            tmpValue: '',
          });
        } }
        onCancel={ () => this.setState({ urlDialogInfo: { visible: false, activityKey: '', activities: [] }, tmpValue: '' }) }
      >
        { isLocked ? (
          <div>
            <p>{ tmpValue }</p>
          </div>
        ) : (
          <div>
            <div>
              <label>Label</label>
              <Input
                disabled={ isLocked }
                autoFocus
                onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
                onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: { ...tmpValue, label: event.target.value } }) }
                defaultValue={ tmpValue?.label }
              />
            </div>
            <div className="mT-10">
              <label>URL<span className="text-required fsz-md lh-1 va-t">*</span></label>
              <Input
                disabled={ isLocked }
                onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: { ...tmpValue, url: event.target.value } }) }
                defaultValue={ tmpValue?.url }
              />
            </div>
          </div>
        ) }
      </Modal>
    );
  };

  // Permissions check & Only non-fixed templates
  canDeleteActivity = (): boolean => {
    const { record, field } = this.props;
    return hasPermission(record.permissions, `activity_${_.snakeCase(field.entity_type)}_activity_archive`) && field.template?.fixed === 0;
  };

  isLocked = (row: ActivityRecord, featureReference: string, canBypassAccessChecks: boolean): boolean => {
    return !canBypassAccessChecks && (this.isReadOnly(row, featureReference) || this.props.isDisabled || row?.activity_workflow_context === 'RESOLVED');
  };

  isReadOnly = (row: ActivityRecord, featureReference: string): boolean => {
    const originalActivity = _.has(this.props?.originalValues, 'activities') && !_.isEmpty(this.props.originalValues.activities) && this.props?.originalValues.activities?.find((activity: ActivityRecord) => activity?.key === row?.key || activity?.id === row?.id);

    const isActualField = ['activity_actual_start_date', 'activity_actual_duration', 'activity_actual_completion_date'].includes(featureReference);
    const isCompleted = !!originalActivity && !!originalActivity?.activity_completion;
    const isLockedField = !!originalActivity && (isCompleted || !!originalActivity?.activity_not_applicable);

    if (!isCompleted && ['activity_evidence', 'activity_document', 'activity_comment', 'activity_required', 'activity_completion'].includes(featureReference)) {
      return false;
    }

    if (isActualField && !isLockedField) {
      return false;
    }

    if (isLockedField) {
      return true;
    }

    if (!isActualField) {
      return true;
    }

    return false;
  };

  dateComparison = (startDate: moment.Moment, completionDate: moment.Moment) => {

    if (!moment(startDate).isValid() || !moment(completionDate).isValid()) {
      return 0;
    }

    return Math.abs(startDate.startOf('day').diff(completionDate.startOf('day'), 'days'));
  };

  onDateChange = (field: Field, row: ActivityRecord, dateValue: any, featureReference: string) => {
    const date = !!dateValue ? dateValue.format(SYSTEM_DATE_FORMAT) : null;
    this.onChange(field, row, date, featureReference);
  };

  setCompletionDate = (field: Field, row: ActivityRecord, tempState: any, value: moment.Moment | null, featureReference: string) => {
    const newValues = {
      ...field.values,
      activities: this.modifyActivities(row.key, tempState, !!value ? value.format('YYYY-MM-DD HH:mm:ss') : null, featureReference),
    };
    this.handleChange(newValues);
  };

  onChange = (field: Field, row: ActivityRecord, value: any, featureReference: string) => {
    const { activities = [] } = field.values;

    const newValues = {
      ...field.values,
      activities: this.modifyActivities(row.key, _.cloneDeep(activities), value, featureReference),
    };
    this.handleChange(newValues);
  };

  renderFilters = (field: Field, features: Feature[]): React.ReactNode | boolean => {

    const viewOptions: { title: string, value: string }[] = [];
    let showMilestones: boolean = false;

    if (!this.isHiddenFeature('milestone_type', features)) {
      showMilestones = true;
    }

    if (!this.isHiddenFeature('activity_actual_completion_date', features)) {
      viewOptions.push({ title: 'Overdue', value: 'showOverdue' });
    }

    if (!this.isHiddenFeature('activity_planned_completion_date', features)) {
      viewOptions.push({ title: 'Next 7 Days', value: 'showNextSevenDays' });
      viewOptions.push({ title: 'Next 28 Days', value: 'showNextTwentyEightDays' });
    }

    // Quit early
    if (_.isEmpty(viewOptions) && !showMilestones) {
      return false;
    }

    return (
      <div
          className='mT-15'
          onClick={ (event: any) => {
            event.preventDefault();
          } }
        >
          { !_.isEmpty(viewOptions) &&
            <div className="d-ib">
              <Select
                allowClear
                style={{ width: 200 }}
                key={ 'viewOptionsFilter' }
                placeholder={ 'View Options' }
                dropdownMatchSelectWidth={ false }
                onChange={ (value: string) => {
                  this.setState({
                    showOverdue: (value === 'showOverdue'),
                    showNextSevenDays: (value === 'showNextSevenDays'),
                    showNextTwentyEightDays: (value === 'showNextTwentyEightDays')
                  });
                } }
              >
                { viewOptions.map((option: { title: string, value: string }, index: number) =>
                  <Option key={ index } value={ option.value }>{ option.title }</Option>
                ) }
              </Select>
            </div>
          }
          { showMilestones &&
            <div className="d-ib mL-10">
              <Select
                allowClear
                style={{ width: 200 }}
                key={'milestoneFilter'}
                mode={ 'multiple' }
                placeholder={ 'Milestone' }
                dropdownMatchSelectWidth={ false }
                onChange={ (value: any) => {
                  this.setState({
                    filterMilestones: value,
                  });
                } }
              >
                { field.milestones?.map((milestone: Milestone) => (
                  <Select.Option key={ milestone.id } value={ milestone.id }>{ milestone.title }</Select.Option>
                )) }
              </Select>
            </div>
          }
        </div>
    );
  };

  renderTable = (field: Field, features: Feature[], tasks: Task[], errors: any, canBypassAccessChecks: boolean): JSX.Element => {
    const { record, isDisabled } = this.props;
    const {
      commentsDialogInfo,
      uploadDialogInfo,
      filterMilestones,
      showOverdue,
      showNextSevenDays,
      showNextTwentyEightDays,
      showGanttView,
      descriptionDialogInfo,
      urlDialogInfo,
      showRearrangeModal,
    } = this.state;

    const { activities = [] } = field.values;
    const canDeleteActivity = this.canDeleteActivity();

    const columns = [
      {
        key: 'activity_title',
        dataIndex: 'activity_title',
        width: 300,
        fixed: 'left',
        title: this.renderColumnTitle('Title', 'This title will be used to name the created activity', true),
        render: (title: ActivityRecord['activity_title'], row: ActivityRecord) => {
          const isLocked = this.isLocked(row, 'activity_title', canBypassAccessChecks);
          const tooltip = row?.activity_tooltip;

          const onChange = _.debounce((value: string) => {
            this.handleChange({
              ...field.values,
              activities: this.modifyActivities(row.key, _.cloneDeep(activities), value, 'activity_title'),
            });
          }, 300);

          return (
            <div className="d-f">
              <Tooltip
                title={!!row?.activity_task_required ? 'This activity is required' : 'This activity is optional'}
                placement="top"
              >
                <ExclamationCircleOutlined
                  className={ classNames('mR-10 mT-5 fsz-md', {
                    'text-warning': !!row?.activity_task_required,
                    'color-grey-500': !row?.activity_task_required,
                  }) }
                />
              </Tooltip>
              { isLocked ? (
                <span className="wob-n whs-bs">{ title }</span>
              ) : (
                <Input
                  className="Field"
                  onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                  defaultValue={ title }
                />
              ) }
              { tooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ tooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
          );
        }
      },
      {
        key: 'activity_id',
        dataIndex: 'activity_id',
        dependencyFeatures: ['activity_id'],
        width: 100,
        title: this.renderColumnTitle('ID', 'This unique ID helps users identify the activity' ),
        render: (activityId: ActivityRecord['activity_id'], row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_id', canBypassAccessChecks);

          if (isLocked) {
            return (
              <div className="d-f">
                { activityId }
              </div>
            );
          }

          const onChange = _.debounce((value: string) => {
            this.handleChange({
              ...field.values,
              activities: this.modifyActivities(row.key, _.cloneDeep(activities), value, 'activity_id'),
            });
          }, 300);

          return (
            <div className="d-f">
              <Input
                className={ "Field" }
                onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                value={ activityId || undefined }
              />
            </div>
          );
        }
      },
      {
        key: 'activity_planned_completion_date_status',
        dataIndex: 'activity_planned_completion_date_status',
        width: 120,
        title: this.renderColumnTitle('Status', 'Status'),
        dependencyFeatures: ['planned_dates_start', 'planned_dates_completion'],
        render: (rowStatusData: ActivityRecord['activity_planned_completion_date_status'], row: ActivityRecord) => {
          const plannedCompletionDate = row?.activity_planned_completion_date;

          if (!plannedCompletionDate || !moment(plannedCompletionDate).isValid()) {
            return (
              <span> - </span>
            );
          }

          let badgeColor = BadgeType.Default;
          const badgeText = ( _.has(rowStatusData, 'text') ? rowStatusData.text : '' );
          if (_.has(rowStatusData, 'color')) {
            switch (_.toUpper(rowStatusData.color)) {
              case 'GREEN':
                badgeColor = BadgeType.Success;
              break;
              case 'AMBER':
                badgeColor = BadgeType.Warning;
              break;
              case 'RED':
                badgeColor = BadgeType.Danger;
              break;
            }
          }

          return (
            <BadgeComponent
              type={ badgeColor }
              text={ badgeText }
            />
          );
        }
      },
      {
        key: 'activity_description',
        dataIndex: 'activity_description',
        dependencyFeatures: ['description'],
        title: this.renderColumnTitle('Description', 'Allow users to write a useful description'),
        width: 300,
        render: (description: ActivityRecord['activity_description'], row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_description', canBypassAccessChecks);

          if (isLocked) {
            return (
              <span>{ description || '-' }</span>
            );
          }

          return (
            <Input
              className="Field"
              onClick={ () => {
                this.setState({
                  descriptionDialogInfo: {
                    visible: true,
                    activityKey: row.key,
                    activities: activities
                  },
                  tmpValue: description || ''
                });
              } }
              value={ description || '-' }
            />
          );
        },
      },
      {
        key: 'activity_url',
        dataIndex: 'activity_url',
        dependencyFeatures: ['supporting_url'],
        title: this.renderColumnTitle('Supporting URL', 'Allows inclusion of a URL to direct the user to a page to complete the task. Supports smart tags'),
        width: 180,
        render: (__: any, row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_url', canBypassAccessChecks);
          const renderLink = (label: string | null, url: string) => {
            return (
              <a
                rel="noopener noreferrer"
                className='d-ib whs-nw ov-h tov-e primaryColor'
                style={{ width: 'calc(100%)' }}
                target="_blank"
                href={ url }
              >
                { label || url }
              </a>
            );
          };

          if (isLocked) {
            return (
              <span style={{ width: '90%' }}>{ row?.activity_url ? renderLink(row?.activity_url_label, row.activity_url) : '-' }</span>
            );
          }

          if (!row.activity_url) {
            return (
              <Input
                className="Field"
                onClick={ () => {
                  this.setState({
                    urlDialogInfo: {
                      visible: true,
                      activityKey: row.key,
                      activities: activities
                    },
                    tmpValue: ''
                  });
                } }
                defaultValue={ '-' }
              />
            );
          }

          return (
            <div className='d-f jc-sb'>
              <span style={{ width: '90%' }}>
                { renderLink(row?.activity_url_label, row.activity_url) }
              </span>
              <span>
                <EditOutlined
                  className="link fsz-sm"
                  onClick={ () => {
                    this.setState({
                      urlDialogInfo: {
                        visible: true,
                        activityKey: row.key,
                        activities: activities
                      },
                      tmpValue: {
                        label: row?.activity_url_label,
                        url: row.activity_url,
                      }
                    });
                  } }
                />
              </span>
            </div>
          );
        },
      },
      {
        key: 'activity_milestone',
        dataIndex: 'activity_milestone',
        dependencyFeatures: ['milestone_type'],
        width: 200,
        ellipsis: true,
        title: this.renderColumnTitle('Milestone'),
        render: (activityMilestone: ActivityRecord['activity_milestone'], row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_milestone', canBypassAccessChecks);

          return (
            <Select
              showSearch
              mode={ 'multiple' }
              allowClear={ !isLocked }
              open={ (isLocked && _.isEmpty(activityMilestone)) ? false : undefined }
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ activityMilestone?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'ant-select-disabled': isLocked
              }) }
              dropdownClassName={ classNames('ActivityManagementFieldSelect', {
                'ant-select-disabled': isLocked
              }) }
              filterOption={(input: any, option: any) => {
                return !!field.milestones?.find((record: Milestone) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ !_.isEmpty(activityMilestone) ? activityMilestone : undefined }
              onChange={ (recordIds: Array<string | number>) => {
                if (!isLocked) {
                  this.onChange(field, row, recordIds, 'activity_milestone');
                }
              } }
            >
              { field.milestones?.map( (milestone: Milestone) => (
                <Select.Option key={ milestone.id } value={ milestone.id }>{ milestone.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'activity_resource',
        dataIndex: 'activity_resource',
        dependencyFeatures: ['resource_allocation'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle('Resource', 'Resource'),
        render: (activityResource: ActivityRecord['activity_resource'], row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_resource', canBypassAccessChecks);

          return (
            <Select
              showSearch
              mode={ 'multiple' }
              allowClear={ !isLocked }
              open={ (isLocked && _.isEmpty(activityResource)) ? false : undefined }
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ activityResource?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ 'Everyone' }
              className={ classNames('Select-Field', {
                'ant-select-disabled': isLocked
              }) }
              dropdownClassName={ classNames('ActivityManagementFieldSelect', {
                'ant-select-disabled': isLocked
              }) }
              filterOption={(input: any, option: any) => {
                return !!field.resource?.find((record) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ activityResource }
              onChange={ (recordIds: Array<string | number>) => {
                if (!isLocked) {
                  this.onChange(field, row, recordIds, 'activity_resource');
                }
              } }
            >
              { field.resource?.map((resource) => (
                <Select.Option key={ resource.id } value={ resource.id }>{ resource.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'planned',
        title: 'Planned',
        onHeaderCell: () => {
          return { className: 'bg-tinted-cyan' };
        },
        children: [
          {
            key: 'activity_planned_start_date',
            dataIndex: 'activity_planned_start_date',
            dependencyFeatures: ['planned_dates_start'],
            width: 150,
            title: this.renderColumnTitle('Start', 'Planned Start Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            onCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            render: (plannedStartDate: ActivityRecord['activity_planned_start_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_planned_start_date', canBypassAccessChecks);
              const datetime = plannedStartDate && moment(plannedStartDate).isValid() ? getFormatedDate(plannedStartDate) : '-';

              if (isLocked) {
                return (
                  <span>{ datetime }</span>
                );
              }

              if (!!field?.config?.locked_dates?.planned) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.planned }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ plannedStartDate && moment(plannedStartDate).isValid() ? moment(plannedStartDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  className={ "Field" }
                  format={ DATE_FORMAT }
                  value={ plannedStartDate && moment(plannedStartDate).isValid() ? moment(plannedStartDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {
                    if (dateString) {
                      this.onDateChange(field, row, dateString, 'activity_planned_start_date');
                    } else {

                      let newActivities = _.cloneDeep(activities);
                      newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_planned_start_date');
                      newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_planned_duration');
                      newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_planned_completion_date');

                      this.handleChange({
                        ...field.values,
                        activities: newActivities,
                      });
                    }
                  } }
                />
              );
            }
          },
          {
            key: 'activity_planned_duration',
            dataIndex: 'activity_planned_duration',
            dependencyFeatures: ['planned_dates_start', 'planned_dates_completion'],
            width: 120,
            title: this.renderColumnTitle('Duration', 'The number of days the activity should last'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            onCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            render: (__: any, row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_planned_duration', canBypassAccessChecks);
              const diff = this.dateComparison(moment(row.activity_planned_start_date), moment(row.activity_planned_completion_date));

              if (isLocked) {
                return (
                  <span>
                    { row?.activity_planned_start_date && row?.activity_planned_completion_date ? `${diff} ${ diff === 1 ? 'day' : 'days' }` : '-' }
                  </span>
                );
              }

              if (!!field?.config?.locked_dates?.planned) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.planned }
                  >
                    <span>
                      <NumberFormat
                        className={ "Field pR-20 ta-r" }
                        disabled
                        decimalScale={ 0 }
                        customInput={ Input }
                        allowNegative={ false }
                        value={ diff }
                      />
                    </span>
                  </Tooltip>
                );
              }

              const onChange = _.debounce((value: string) => {
                const intValue = parseInt(value);
                const tempState = this.modifyActivities(row.key, _.cloneDeep(activities), intValue, 'activity_planned_duration');
                const dateString = moment(row.activity_planned_start_date).add(intValue, 'days');
                this.setCompletionDate(field, row, tempState, dateString, 'activity_planned_completion_date');
              }, 300);

              return (
                <NumberFormat
                  className={ "Field pR-20 ta-r" }
                  disabled={ !row?.activity_planned_start_date }
                  decimalScale={ 0 }
                  // User limit, value cannot be set greater than MAX_DURATION
                  isAllowed={ ({ floatValue = 0 }) => floatValue <= MAX_DURATION }
                  customInput={ Input }
                  allowNegative={ false }
                  value={ diff }
                  onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                />
              );
            }
          },
          {
            key: 'activity_planned_completion_date',
            dataIndex: 'activity_planned_completion_date',
            dependencyFeatures: ['planned_dates_completion'],
            width: 150,
            ellipsis: true,
            title: this.renderColumnTitle('Completion', 'Planned Completion Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            onCell: () => {
              return { className: 'bg-tinted-cyan' };
            },
            render: (plannedCompletionDate: ActivityRecord['activity_planned_completion_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_planned_completion_date', canBypassAccessChecks);
              const datetime = plannedCompletionDate && moment(plannedCompletionDate).isValid() ? getFormatedDate(plannedCompletionDate) : '-';

              if (isLocked) {
                return (
                  <span> { datetime } </span>
                );
              }

              if (!!field?.config?.locked_dates?.planned) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.planned }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ plannedCompletionDate && moment(plannedCompletionDate).isValid() ? moment(plannedCompletionDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  format={ DATE_FORMAT }
                  className={ "Field" }
                  disabled={ !this.isHiddenFeature('planned_dates_start', features) && !row?.activity_planned_start_date }
                  disabledDate={ (current: moment.Moment) => {
                    return !this.isHiddenFeature('planned_dates_start', features) && !!row?.activity_planned_start_date && moment(current).isBefore(row.activity_planned_start_date);
                  } }
                  value={ plannedCompletionDate && moment(plannedCompletionDate).isValid() ? moment(plannedCompletionDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {
                    this.onDateChange(field, row, dateString, 'activity_planned_completion_date');
                  } }
                />
              );
            }
          },
        ]
      },
      {
        key: 'forecast',
        title: 'Forecast',
        onHeaderCell: () => {
          return { className: 'bg-tinted-yellow' };
        },
        children: [
          {
            key: 'activity_forecast_start_date',
            dataIndex: 'activity_forecast_start_date',
            dependencyFeatures: ['forecast_dates_start'],
            width: 150,
            title: this.renderColumnTitle('Start', 'Forecast Start Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            onCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            render: (forecastStartDate: ActivityRecord['activity_forecast_start_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_forecast_start_date', canBypassAccessChecks);
              const datetime = forecastStartDate && moment(forecastStartDate).isValid() ? getFormatedDate(forecastStartDate) : '-';

              if (isLocked) {
                return (
                  <span> { datetime } </span>
                );
              }

              if (!!field?.config?.locked_dates?.forecast) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.forecast }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ forecastStartDate && moment(forecastStartDate).isValid() ? moment(forecastStartDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  format={ DATE_FORMAT }
                  className={ "Field" }
                  value={ forecastStartDate && moment(forecastStartDate).isValid() ? moment(forecastStartDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {

                    let newActivities = _.cloneDeep(activities);
                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), dateString, 'activity_forecast_start_date');
                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_forecast_duration');
                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_forecast_completion_date');

                    this.handleChange({
                      ...field.values,
                      activities: newActivities,
                    });

                  } }
                />
              );
            }
          },
          {
            key: 'activity_forecast_duration',
            dataIndex: 'activity_forecast_duration',
            dependencyFeatures: ['forecast_dates_start', 'forecast_dates_completion'],
            width: 120,
            title: this.renderColumnTitle('Duration', 'The number of days the activity should last'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            onCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            render: (__: any, row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_forecast_duration', canBypassAccessChecks);
              const diff = this.dateComparison(moment(row.activity_forecast_start_date), moment(row.activity_forecast_completion_date));

              if (isLocked) {
                return (
                  <span>
                    { row?.activity_forecast_start_date && row?.activity_forecast_completion_date ? `${diff} ${ diff === 1 ? 'day' : 'days' }` : '-' }
                  </span>
                );
              }

              if (!!field?.config?.locked_dates?.forecast) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.forecast }
                  >
                    <span>
                      <NumberFormat
                        className={ "Field pR-20 ta-r" }
                        disabled
                        decimalScale={ 0 }
                        customInput={ Input }
                        allowNegative={ false }
                        value={ diff }
                      />
                    </span>
                  </Tooltip>
                );
              }

              const onChange = _.debounce((value: string) => {
                const intValue = parseInt(value);
                const tempState = this.modifyActivities(row.key, _.cloneDeep(activities), intValue, 'activity_forecast_duration');
                const dateString = moment(row.activity_forecast_start_date).add(intValue, 'days');
                this.setCompletionDate(field, row, tempState, dateString, 'activity_forecast_completion_date');
              }, 300);

              return (
                <NumberFormat
                  className={ "Field pR-20 ta-r" }
                  disabled={ !row?.activity_forecast_start_date }
                  decimalScale={ 0 }
                  // User limit, value cannot be set greater than MAX_DURATION
                  isAllowed={ ({ floatValue = 0 }) => floatValue <= MAX_DURATION }
                  customInput={ Input }
                  allowNegative={ false }
                  value={ diff }
                  onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                />
              );
            }
          },
          {
            key: 'activity_forecast_completion_date',
            dataIndex: 'activity_forecast_completion_date',
            dependencyFeatures: ['forecast_dates_completion'],
            width: 150,
            ellipsis: true,
            title: this.renderColumnTitle('Completion', 'Forecast Completion Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            onCell: () => {
              return { className: 'bg-tinted-yellow' };
            },
            render: (forecastCompletionDate: ActivityRecord['activity_forecast_completion_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_forecast_completion_date', canBypassAccessChecks);
              const datetime = forecastCompletionDate && moment(forecastCompletionDate).isValid() ? getFormatedDate(forecastCompletionDate) : '-';

              if (isLocked) {
                return (
                  <span> { datetime } </span>
                );
              }

              if (!!field?.config?.locked_dates?.forecast) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.forecast }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ forecastCompletionDate && moment(forecastCompletionDate).isValid() ? moment(forecastCompletionDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  format={ DATE_FORMAT }
                  className={ "Field" }
                  disabled={ !this.isHiddenFeature('forecast_dates_start', features) && !row?.activity_forecast_start_date }
                  disabledDate={ (current: moment.Moment) => {
                    return !this.isHiddenFeature('forecast_dates_start', features) && !!row?.activity_forecast_start_date && moment(current).isSameOrBefore(row.activity_forecast_start_date);
                  } }
                  value={ forecastCompletionDate && moment(forecastCompletionDate).isValid() ? moment(forecastCompletionDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {
                    this.onDateChange(field, row, dateString, 'activity_forecast_completion_date');
                  } }
                />
              );
            }
          },
        ]
      },
      {
        key: 'actual',
        title: 'Actual',
        onHeaderCell: () => {
          return { className: 'bg-tinted-green' };
        },
        children: [
          {
            key: 'activity_actual_start_date',
            dataIndex: 'activity_actual_start_date',
            dependencyFeatures: ['actual_dates_start'],
            width: 150,
            title: this.renderColumnTitle('Start', 'Actual Start Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-green' };
            },
            onCell: () => {
              return { className: 'bg-tinted-green' };
            },
            render: (actualStartDate: ActivityRecord['activity_actual_start_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_actual_start_date', canBypassAccessChecks);
              const datetime = actualStartDate && moment(actualStartDate).isValid() ? getFormatedDate(actualStartDate) : '-';

              if (isLocked) {
                return (
                  <span> { datetime } </span>
                );
              }

              if (!!field?.config?.locked_dates?.actual) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.actual }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ actualStartDate && moment(actualStartDate).isValid() ? moment(actualStartDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  format={ DATE_FORMAT }
                  className={ "Field" }
                  disabledDate={ (current: moment.Moment) => {
                    if (!canBypassAccessChecks && current.isAfter(moment())) {
                      return true;
                    }
                    return false;
                  } }
                  value={ actualStartDate && moment(actualStartDate).isValid() ? moment(actualStartDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {
                    let newActivities = _.cloneDeep(activities);

                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), dateString, 'activity_actual_start_date');
                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_actual_duration');
                    newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), null, 'activity_actual_completion_date');

                    this.handleChange({
                      ...field.values,
                      activities: newActivities,
                    });
                  } }
                />
              );
            }
          },
          {
            key: 'activity_actual_duration',
            dataIndex: 'activity_actual_duration',
            dependencyFeatures: ['actual_dates_start', 'actual_dates_completion'],
            width: 120,
            title: this.renderColumnTitle('Duration', 'The number of days the activity should last'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-green' };
            },
            onCell: () => {
              return { className: 'bg-tinted-green' };
            },
            render: (__: any, row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_actual_duration', canBypassAccessChecks);
              const diff = this.dateComparison(moment(row.activity_actual_start_date), moment(row.activity_actual_completion_date));

              if (isLocked) {
                return (
                  <span>
                    { row?.activity_actual_start_date && row?.activity_actual_completion_date ? `${diff} ${ diff === 1 ? 'day' : 'days' }` : '-' }
                  </span>
                );
              }

              if (!!field?.config?.locked_dates?.actual) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.actual }
                  >
                    <span>
                      <NumberFormat
                        className={ "Field pR-20 ta-r" }
                        disabled
                        decimalScale={ 0 }
                        customInput={ Input }
                        allowNegative={ false }
                        value={ diff }
                      />
                    </span>
                  </Tooltip>
                );
              }

              const onChange = _.debounce((value: string) => {
                let intValue = parseInt(value);

                const maxAllowedDays = moment().diff(row?.activity_actual_start_date, 'days');
                if (intValue > maxAllowedDays) {
                  intValue = maxAllowedDays;
                }

                const tempState = this.modifyActivities(row.key, _.cloneDeep(activities), intValue, 'activity_actual_duration');
                const dateString = moment(row.activity_actual_start_date).add(intValue, 'days');
                this.setCompletionDate(field, row, tempState, dateString, 'activity_actual_completion_date');
              }, 300);

              return (
                <NumberFormat
                  className={ "Field pR-20 ta-r" }
                  disabled={ !row?.activity_actual_start_date }
                  decimalScale={ 0 }
                  // User limit, value cannot be set greater than MAX_DURATION
                  isAllowed={ ({ floatValue = 0 }) => floatValue <= MAX_DURATION }
                  customInput={ Input }
                  allowNegative={ false }
                  value={ diff }
                  onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                />
              );
            }
          },
          {
            key: 'activity_actual_completion_date',
            dataIndex: 'activity_actual_completion_date',
            dependencyFeatures: ['actual_dates_completion'],
            width: 150,
            title: this.renderColumnTitle('Completion', 'Actual Completion Date'),
            onHeaderCell: () => {
              return { className: 'bg-tinted-green' };
            },
            onCell: () => {
              return { className: 'bg-tinted-green' };
            },
            render: (actualCompletionDate: ActivityRecord['activity_actual_completion_date'], row: ActivityRecord) => {

              const isLocked = this.isLocked(row, 'activity_actual_completion_date', canBypassAccessChecks);
              const datetime = (actualCompletionDate && moment(actualCompletionDate).isValid()) ? getFormatedDate(actualCompletionDate) : '-';

              if (isLocked) {
                return (
                  <span> { datetime } </span>
                );
              }

              if (!!field?.config?.locked_dates?.actual) {
                return (
                  <Tooltip
                    placement="top"
                    title={ field?.config?.locked_dates?.actual }
                  >
                    <DatePicker
                      disabled
                      className="Field"
                      format={ DATE_FORMAT }
                      value={ actualCompletionDate && moment(actualCompletionDate).isValid() ? moment(actualCompletionDate) : undefined }
                    />
                  </Tooltip>
                );
              }

              return (
                <DatePicker
                  format={ DATE_FORMAT }
                  className={ "Field" }
                  disabled={ !this.isHiddenFeature('actual_dates_start', features) && !row?.activity_actual_start_date }
                  disabledDate={ (current: moment.Moment) => {
                    if (!canBypassAccessChecks && current.isSameOrAfter(moment())) {
                      return true;
                    }
                    return !this.isHiddenFeature('actual_dates_start', features) && !!row?.activity_actual_start_date && moment(current).isSameOrBefore(row.activity_actual_start_date);
                  } }
                  value={ actualCompletionDate && moment(actualCompletionDate).isValid() ? moment(actualCompletionDate) : undefined }
                  onChange={ (dateString: moment.Moment | null) => {
                    this.onDateChange(field, row, dateString, 'activity_actual_completion_date');
                  } }
                />
              );
            }
          },
        ]
      },
      {
        key: 'activity_evidence',
        dataIndex: 'activity_evidence',
        dependencyFeatures: ['evidence'],
        width: 120,
        ellipsis: true,
        title: this.renderColumnTitle('Evidence', 'Requires the user to upload a file as evidence of activity completion'),
        render: (evidence: ActivityRecord['activity_evidence'], row: ActivityRecord) => {

          // the button is disabled if the activity is not saved or the evidence is not enabled
          const isLocked: boolean = this.isLocked(row, 'activity_evidence', canBypassAccessChecks) || !_.has(row, 'id');
          let tooltip = "Evidence upload has been disabled as the activity is completed or NA'ed";

          if (!evidence) {
            tooltip = 'Evidence upload has been disabled for unsaved activities';
          } else if (!_.has(row, 'id')) {
            tooltip = 'Evidence is not required for this row';
          }

          if (!row?.evidence_count) {
            tooltip = 'Evidence field is required';
          }

          const evidenceBtn = (
            <Badge
              count={ row?.evidence_count }
              style={{ backgroundColor: !row?.evidence_count && evidence ? '#ff4d4f' : '#b9c2d0' }}
              offset={[2, 5]}
              showZero
            >
              <span className={ classNames({ 'DisabledViewButton': !!isLocked }) }>
                <Button
                  disabled={ isLocked }
                  onClick={ () => {
                    const { id, entity_bundle, entity_type } = row;
                    if (id && entity_bundle && entity_type) {
                      this.setState({
                        uploadDialogInfo: {
                          key: row.key,
                          visible: true,
                          activityId: id,
                          bundle: entity_bundle,
                          type: entity_type,
                          fieldReference: 'evidence_count',
                          folders: field.activity_evidence_folders
                        }
                      });
                    }
                  } }
                >
                  Evidence
                </Button>
              </span>
            </Badge>
          );

          if (isLocked || (!row?.evidence_count && evidence)) {
            return (
              <Tooltip
                placement="top"
                title={ tooltip }
              >
                { evidenceBtn }
              </Tooltip>
            );
          }

          return evidenceBtn;
        }
      },
      {
        key: 'activity_document',
        dataIndex: 'activity_document',
        dependencyFeatures: ['document'],
        width: 120,
        ellipsis: true,
        title: this.renderColumnTitle('Documents', 'The user can upload a file to the row'),
        render: (document: ActivityRecord['activity_document'], row: ActivityRecord) => {

          const isLocked: boolean = this.isLocked(row, 'activity_document', canBypassAccessChecks) || !_.has(row, 'id') || !document;
          let tooltip = "Document upload has been disabled as the activity is completed or NA'ed";

          if (!document) {
            tooltip = 'Document upload has been disabled for unsaved activities';
          } else if (!_.has(row, 'id')) {
            tooltip = 'Document is not required for this row';
          }

          const btn = (
            <Badge
              count={ row.document_count }
              style={{ backgroundColor: !!row.document_count ? '#52c41a' : '#b9c2d0' }}
              offset={[2, 5]}
              showZero
            >
              <span className={ classNames({ 'DisabledViewButton': !!isLocked }) }>
                <Button
                  disabled={ isLocked }
                  onClick={ () => {
                    const { id, entity_bundle, entity_type } = row;
                    if (id && entity_bundle && entity_type) {
                      this.setState({
                        uploadDialogInfo: {
                          key: row.key,
                          visible: true,
                          activityId: id,
                          bundle: entity_bundle,
                          type: entity_type,
                          fieldReference: 'document_count',
                          folders: field.activity_document_folders
                        }
                      });
                    }
                  } }
                >
                  Document
                </Button>
              </span>
            </Badge>
          );

          if (isLocked) {
            return (
              <Tooltip
                placement="top"
                title={ tooltip }
              >
                { btn }
              </Tooltip>
            );
          }

          return btn;
        }
      },
      {
        key: 'activity_competency',
        dataIndex: 'activity_competency',
        dependencyFeatures: ['competence_required'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle('Competence Required', 'Skills required to complete the activity from a predefined list'),
        render: (activityCompetency: ActivityRecord['activity_competency'], row: ActivityRecord) => {

          const isLocked = this.isLocked(row, 'activity_competency', canBypassAccessChecks);

          return (
            <Select
              showSearch
              mode={ 'multiple' }
              allowClear={ !isLocked }
              className={ classNames('Select-Field', {
                'ant-select-disabled': isLocked
              }) }
              dropdownClassName={ classNames('ActivityManagementFieldSelect', {
                'ant-select-disabled': isLocked
              }) }
              open={ (isLocked && _.isEmpty(activityCompetency)) ? false : undefined }
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ activityCompetency?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ '-' }
              filterOption={(input: any, option: any) => {
                return !!field.competencies?.find((record: Competence) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ !_.isEmpty(activityCompetency) ? activityCompetency : undefined }
              onChange={ (recordIds: Array<string | number>) => {
                if (!isLocked) {
                  this.onChange(field, row, recordIds, 'activity_competency');
                }
              } }
            >
              { field.competencies?.map( (competence: Competence) => (
                <Select.Option key={ competence.id } value={ competence.id }>{ competence.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'activity_comment',
        dataIndex: 'activity_comment',
        dependencyFeatures: ['comments'],
        width: 130,
        ellipsis: true,
        title: this.renderColumnTitle('Comments', 'Allows the user to post comments against an activity'),
        render: (comments: ActivityRecord['activity_comment'], row: ActivityRecord) => {
          // the button is disabled if the activity is not saved
          const isLocked: boolean = this.isLocked(row, 'activity_comment', canBypassAccessChecks) || !_.has(row, 'id');

          let tooltip = null;
          if (!_.has(row, 'id')) {
            tooltip = 'Comments have been disabled for unsaved activities';
          } else if (!_.has(row, 'id')) {
            tooltip = 'Comments are not required for this row';
          }

          const commentBtn = (
            <Badge
              count={ row.comment_count }
              style={{ backgroundColor: !!row.comment_count ? '#52c41a' : '#b9c2d0' }}
              offset={[2, 5]}
              showZero
            >
              <span className={ classNames({ 'DisabledViewButton': !!isLocked }) }>
                <Button
                  disabled={ isLocked }
                  onClick={ () => {
                    const { id, entity_bundle, entity_type } = row;
                    if (id && entity_bundle && entity_type) {
                      this.setState({
                        commentsDialogInfo: {
                          key: row.key,
                          visible: true,
                          activityId: id,
                          bundle: entity_bundle,
                          type: entity_type,
                        }
                      });
                    }
                  } }
                >
                  Comments
                </Button>
              </span>
            </Badge>
          );

          if (isLocked) {
            return (
              <Tooltip
                placement="top"
                title={ tooltip }
              >
                { commentBtn }
              </Tooltip>
            );
          }

          return commentBtn;
        }
      },
      {
        key: 'activity_stage',
        dataIndex: 'activity_stage',
        width: 100,
        title: this.renderColumnTitle('Stage'),
        render: (__: any, row: ActivityRecord) => {

          const originalRow = findFirst({ children: this.props.originalValues.activities }, 'children', { id: row.id });
          const isModified = !_.isEqual(originalRow?.activity_workflow_stage, row?.activity_workflow_stage) && !_.isEqual(row?.activity_workflow_stage, field.default_workflow_stage);

          if (!!row?.activity_workflow_stage) {
            return (
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                { isModified &&
                  <div
                    style={{
                      position: 'absolute',
                      width: 16,
                      height: 16,
                      zIndex: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      top: -8,
                      right: -8,
                      backgroundColor: '#efb636',
                      borderRadius: 10,
                      border: '1px solid #f2c45e'
                    }}
                  >
                    <RollbackOutlined
                      className='cur-p'
                      style={{ color: 'white', fontSize: 10 }}
                      onClick={ () => {
                        if (!row?.id) {
                          if (!!field?.default_workflow_stage) {
                            this.onChange(field, row, field.default_workflow_stage, 'activity_workflow_stage');
                          }
                        } else {
                          if (originalRow && !!originalRow?.activity_workflow_stage) {
                            this.onChange(field, row, originalRow.activity_workflow_stage, 'activity_workflow_stage');
                          }
                        }
                      } }
                    />
                  </div>
                }
                <div>
                  <BadgeComponent
                    fullWidth
                    type={ getBadgeType(row.activity_workflow_stage.context) }
                    text={ row.activity_workflow_stage.title }
                  />
                </div>
              </div>
            );
          }

          return <>-</>;
        }
      },
      {
        key: 'actions',
        title: () => {
          if (!!isDisabled) {
            return (
              <Button
                style={{
                  marginRight: 10,
                  padding: '4px 7px',
                  width: '32px',
                }}
                disabled
              >
                <PlusOutlined />
              </Button>
            );
          }

          if (!!field.template?.fixed) {
            return (
              <Tooltip
                placement="topRight"
                title={ `This ${field?.entity_label} is fixed. Custom activities cannot be created` }
                visible
              >
                <Button
                  style={{
                    marginRight: 10,
                    padding: '4px 7px',
                    width: '32px',
                  }}
                  disabled
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
            );
          }

          if (!field?.can_create) {
            return <></>;
          }

          return (
            <Button
              style={{
                marginRight: 10,
                padding: '4px 7px',
                width: '32px',
              }}
              onClick={ () => {
                this.scrollToBottomIndicator = true;
                this.handleChange({
                  ...field.values,
                  activities: _.concat(_.cloneDeep(activities), this.generateMockState(getBlankState(field), features)),
                });
              } }
              disabled={ !!field.template?.fixed }
            >
              <PlusOutlined />
            </Button>
          );
        },
        render: (__: any, row: ActivityRecord) => {
          // activity open button disabled if field has unsaved changes
          const originalRow = findFirst({ children: this.props.originalValues.activities }, 'children', { id: row.id });
          const isModified = !_.isEqual(originalRow?.activity_workflow_stage, row?.activity_workflow_stage) && !_.isEqual(row?.activity_workflow_stage, field.default_workflow_stage);

          const actions: DropdownAction[] = [
            {
              node: '',
              onClick: () => {}
            }
          ];

          if (!field?.template?.fixed && !!field?.can_create) {
            actions.push(
              {
                node: 'Create Child Activity',
                group: 'Action',
                onClick: () => {
                  const newRow = this.generateMockState(getBlankState(field), features);
                  this.handleChange({
                    ...field.values,
                    activities: modifyNestedSetItem(row.key, { ...row, children: _.isEmpty(row?.children) ? [newRow] : _.concat(row.children, newRow) }, _.cloneDeep(activities))
                  });
                }
              }
            );
          }

          if (!row.id || (canDeleteActivity && !row.activity_required)) {
            actions.push(
              {
                node: 'Remove',
                group: 'Action',
                isDangerous: true,
                onClick: () => {
                  this.handleChange({
                    ...field.values,
                    activities: removeNestedSetItem(row.key, _.cloneDeep(activities)),
                  });
                }
              }
            );
          }

          if (row?.activity_workflow_stage && !_.isEmpty(row?.activity_workflow_stage?.transitions) && !isModified) {
            row?.activity_workflow_stage.transitions.forEach((transition: WorkflowTransition) => {
              let disabled: string[] = !row?.id ? ['You need to save to unlock transitions for this activity.'] : [];

              if (_.isEmpty(disabled)) {
                disabled = transition.conditions
                    .filter((condition: WorkflowCondition) => !condition.pass)
                    .map((condition: WorkflowCondition) => condition.description);
              }

              actions.push(
                {
                  node: transition.title,
                  group: 'Workflow',
                  onClick: () => {

                    let newActivities = _.cloneDeep(activities);

                    const targetStage = !!field?.target_stages && field.target_stages.find((stage: WorkflowStage) => stage.id === transition.target_stage_id);
                    if (targetStage) {

                      // Set completion date if not set
                      if (targetStage?.context === 'RESOLVED') {

                        if (!row?.activity_actual_start_date) {
                          newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), moment().format(SYSTEM_DATE_FORMAT), 'activity_actual_start_date');
                        }

                        if (!row?.activity_actual_completion_date) {
                          newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), moment().format(SYSTEM_DATE_FORMAT), 'activity_actual_completion_date');
                        }
                      }

                      newActivities = this.modifyActivities(row.key, _.cloneDeep(newActivities), {
                        context: targetStage.context,
                        title: targetStage.title,
                        transition_id: transition.id,
                        transitions: targetStage.transitions,
                      }, 'activity_workflow_stage');

                      this.handleChange({
                        ...field.values,
                        activities: newActivities,
                      });
                    }
                  },
                  disabled: !_.isEmpty(disabled) ? disabled : false,
                }
              );
            });
          }

          if (!_.isEmpty(actions)) {
            return (
              <div className="mR-10">
                <Dropdown
                  actions={ actions }
                />
              </div>
            );
          }

          return <></>;
        },
        align: Alignment.right,
        fixed: 'right' as 'right',
        width: 90,
      }
    ];

    let sortedActivities = _.cloneDeep(activities);

    if (showNextSevenDays) {
      sortedActivities = sortedActivities.filter( (activity: ActivityRecord) => this.doesDateExceed( activity.activity_planned_completion_date, 7));
    }

    if (showNextTwentyEightDays) {
      sortedActivities = sortedActivities.filter( (activity: ActivityRecord) => this.doesDateExceed( activity.activity_planned_completion_date, 28));
    }

    if (showOverdue) {
      sortedActivities = sortedActivities.filter( (activity: ActivityRecord) => !moment(activity.activity_actual_completion_date).isValid() && this.isRowOverdue( activity.activity_planned_completion_date));
    }

    if (filterMilestones && filterMilestones.length >= 1) {
      sortedActivities = sortedActivities.filter( (activity: ActivityRecord) =>  !_.isEmpty(activity.activity_milestone) && this.filterCheck( activity.activity_milestone, filterMilestones) );
    }

    // if the feature is not enabled in the template config, the column associated with that feature is hidden
    let filteredColumns = this.filterColumns(columns, features);

    // filter out column headers where all features are disabled
    filteredColumns = filteredColumns.filter((column: any) => !(_.isArray(column.children) && _.isEmpty(column.children)));

    const scrollX = flattenSet(filteredColumns).reduce((acc: any, curr: any) => acc += curr?.width || 150, 0);

    return (
      <div className="d-f">
        <Table
          className={ 'ActivityManagementField' }
          key={ this.state.key }
          sticky
          size={ 'small' }
          columns={ filteredColumns }
          showSorterTooltip={ false }
          dataSource={ sortedActivities }
          pagination={ false }
          rowClassName={ (row: any) => {
            return !_.isEmpty(errors) && errors.includes(row?.key) ? 'error' : '';
          } }
          expandable={{
            expandedRowKeys: flattenSet(activities).map((activity: ActivityRecord) => activity.key),
            expandIcon: () => <></>,
            indentSize: 30,
          }}
          scroll={{
            x: scrollX,
            y: 500
          }}
        />
        { !!uploadDialogInfo?.visible && this.renderUploadDialog() }
        { !!commentsDialogInfo?.visible && this.renderCommentsDialog() }
        { showRearrangeModal && this.renderRearrangeModal(field, sortedActivities) }
        { showGanttView && this.renderGanttView(record, tasks) }
        { descriptionDialogInfo?.visible && this.renderDescriptionDialog(canBypassAccessChecks) }
        { urlDialogInfo?.visible && this.renderUrlDialog(canBypassAccessChecks) }
      </div>
    );
  };

  render = () => {
    const { record, field, config, fieldErrorMessages, fieldModifiedMessages } = this.props;
    const { showFilter } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${field.id}`;
    const isModified = _.has(fieldModifiedMessages, key);
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];

    const features = field.template?.features || [];
    const canBypassAccessChecks = hasPermission(record.permissions, 'bypass_access_checks');
    const filters = this.renderFilters(field, features);

    const activities = field?.values?.activities;
    const tasks: Task[] = this.getTasks(activities);

    const rightActions: DropdownAction[] = [];

    if (!_.isEmpty(tasks)) {
      rightActions.push(
        {
          disabled: _.isEmpty(field?.values?.activities),
          node: 'View Gantt',
          onClick: () => this.setState({ showGanttView: true })
        }
      );
    }

    rightActions.push(
      {
        disabled: _.isEmpty(field?.values?.activities) || isModified,
        isLoading: this.state.isExporting,
        node: 'Export',
        onClick: () => {
          this.setState({
            isExporting: true
          }, () => {
            this.export();
          } );
        }
      },
      {
        disabled: _.isEmpty(field?.values?.activities),
        node: 'Change Order',
        onClick: () => this.setState({ showRearrangeModal: true })
      }
    );

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        border
        isModified={ isModified }
        errors={ errors }
        hideErrorInfo
        col={ config.fieldColSpan }
        label={ (
          <div>
            <div className="mT-5">
              <span>{ field.label }</span>
              { !!filters &&
                <span
                  className={ classNames('mL-15', {
                    'link': true,
                    'active': showFilter
                  }) }
                  onClick={ (event: any) => {
                    this.setState({
                      showFilter: !showFilter
                    });
                    event.preventDefault();
                  } }
                >
                  <Icon component={ FilterIcon } />
                  <span>Filter</span>
                </span>
              }
            </div>
            { showFilter &&
              filters
            }
          </div>
        ) }
        required={ field.config.required }
        description={ !!field.description && field.description }
        rightActions={ [
          {
            node: (
              <Dropdown actions={ rightActions } />
            )
          }
        ] }
      >
        { this.renderTable(field, features, tasks, errors, canBypassAccessChecks) }
      </FieldWrapper>
    );
  };
}

export default ActivityManagementField;
