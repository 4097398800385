// Libs
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Button, Checkbox, Input, Popconfirm, Select, Table, Tooltip, InputNumber } from 'antd';

// Icons
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import { CompetencyOption, FrequencyType, MaintenanceTask, Modified } from './MaintenanceSchedule.interface';
import { Option } from 'components/form/field/list/List.interface';

// Utils
import { isBlank } from 'utils/utils';

// Styles
import './MaintenanceSchedule.scss';

interface Props {
  originalValues: any;
  field: FormField;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onValueChange(state: any): void;
};

interface State {};

const getBlankState = (): Partial<MaintenanceTask> => {
  const uniqueKey: string = uuidv4();
  return {
    key: uniqueKey,
    task: '',
    description: '',
    notes: '',
    criticality_id: undefined,
    frequency_type: undefined,
    frequency_value: undefined,
    competency_id: undefined,
    required: 0,
    evidence: 0,
    completion: 0,
    benchmark_time_in_minutes: 0,
  };
};

const DAY_FREQUENCY_OPTIONS = [1, 2, 3, 4, 5, 6];
const WEEK_FREQUENCY_OPTIONS = [1, 2, 3, 4];
const MONTH_FREQUENCY_OPTIONS = [1, 2, 3, 4, 6, 12, 18, 24, 48];
const YEAR_FREQUENCY_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class MaintenanceSchedule extends React.Component<Props, State> {

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values as any[], originalValues);
    }
  };

  debouncedHandleChange = _.debounce((values: MaintenanceTask[]) => {
    this.props.onValueChange(values);
  }, 200);

  getErrors = (values: MaintenanceTask[]): Modified => {
    const errors: Modified = {};

    const _validate = (value: MaintenanceTask) => {
      const keys: Array<keyof MaintenanceTask> = ['task', 'criticality_id'];
      return keys.filter(key => {
        const verifiableValue = value[key];

        if (key === 'task' && this.isDuplicatedTask(value[key])) {
          return true;
        }

        if (typeof verifiableValue === 'string') {
          return isBlank(verifiableValue.trim());
        }

        return isBlank(verifiableValue);
      });
    };

    values.forEach((value: MaintenanceTask) => {
      const fieldKeys = _validate(value);
      if (!_.isEmpty(fieldKeys)) {
        errors[value.key] = fieldKeys;
      }
    });

    return errors;
  };

  getModified = (values: MaintenanceTask[], originalValues: MaintenanceTask[]): Modified => {
    const modified: Modified = {};

    if (!_.isEqual(values, originalValues)) {
      const fieldKeys: Array<keyof MaintenanceTask> = ['task', 'frequency_type', 'frequency_value', 'description', 'notes', 'required', 'evidence', 'completion', 'criticality_id', 'competency_id', 'benchmark_time_in_minutes'];

      const newStatekeys: string[] = values.map((value: MaintenanceTask) => value.key);
      if (originalValues.some((_value: MaintenanceTask) => !newStatekeys.includes(_value.key))) {
        modified['removed'] = null;
      }

      values.forEach((newValue: MaintenanceTask) => {
        if (originalValues.some(_value => _value.key === newValue.key)) {
          const oldValue = originalValues.find(_oldValue => _oldValue.key === newValue.key);
          if (!_.isEqual(newValue, oldValue)) {
            (Object.keys(newValue) as Array<keyof MaintenanceTask>)
              .filter(key => fieldKeys.includes(key))
              .forEach(key => {
                if (!oldValue) {
                  modified[newValue.key] = fieldKeys;
                } else if (!_.isEqual(newValue[key], oldValue[key])) {
                  if (!!modified[newValue.key] && !modified[newValue.key].includes(key)) {
                    modified[newValue.key] = modified[newValue.key].concat(key);
                  } else {
                    modified[newValue.key] = [key];
                  }
                }
              });
          }
        } else {
          modified[newValue.key] = fieldKeys;
        }
      });
    }

    return modified;
  };

  // The method returns the field key, which is used to store modified messages and error messages.
  getFieldKey = (): string => {
    const { field, config } = this.props;
    return `${field.id}_${config.fieldIndex || 0}_${field.type}`;
  };

  validate = (values: MaintenanceTask[], originalValues: MaintenanceTask[]): void => {
    const { field, config, setFieldErrorMessage, setFieldModifiedMessage } = this.props;
    const fieldKey = this.getFieldKey();
    const errors = this.getErrors(values);
    const modified = this.getModified(values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(fieldKey, errorMessage);
    setFieldModifiedMessage(fieldKey, modifiedMessage);
  };

  hasError = (fieldErrorMessages: any, fieldKey: string, rowKey: MaintenanceTask['key'], key: keyof MaintenanceTask): boolean => {
    return (
      _.has(fieldErrorMessages, [fieldKey, 'errors', rowKey]) &&
      fieldErrorMessages[fieldKey]['errors'][rowKey].includes(key)
    );
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string, rowKey: MaintenanceTask['key'], key: keyof MaintenanceTask): boolean => {
    return (
      _.has(fieldModifiedMessages, [fieldKey, 'modified', rowKey]) &&
      fieldModifiedMessages[fieldKey]['modified'][rowKey].includes(key)
    );
  };

  isDuplicatedTask = (task: MaintenanceTask['task']) => {
    if (isBlank(task.trim())) return false;

    const tasks: any[] = this.props.field.values;

    return tasks?.filter((row: MaintenanceTask) => row.task === task).length > 1;
  };

  handleChange = (values: MaintenanceTask[]): void => {
    this.debouncedHandleChange(values);
  };

  delete = (identifier: string, values: MaintenanceTask[]): MaintenanceTask[] => {
    return values.filter((value: MaintenanceTask) => value.key !== identifier);
  };

  modifyValues = (identifier: MaintenanceTask['key'], values: MaintenanceTask[], newValue: any, key: keyof MaintenanceTask): MaintenanceTask[] => {
    return values.map((value: MaintenanceTask) => {
      if (value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
      };
    });
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { required && <span className="text-required mL-2 fsz-md va-t">*</span> }
        { !_.isEmpty(tooltip) && (
          <Tooltip className="mL-5 pT-1" placement="top" title={ tooltip }>
            <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
          </Tooltip>
        ) }
      </div>
    );
  };

  renderBenchmarkTime = (row: MaintenanceTask) => {
    const { field } = this.props;
    const { isDisabled } = this.props;
    const benchmarkTime = row.benchmark_time_in_minutes || 0; // Single time value in minutes

    return (
      <InputNumber
        min={ 0 }
        value={ benchmarkTime || undefined }
        className="Field pR-3"
        style={{ width: '100%' }}
        placeholder={ 'Minutes' }
        disabled={ isDisabled }
        prefix={ <ClockCircleOutlined /> }
        onChange={ (value: number | null) => {
          const newValue = value || 0;
          this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values as unknown as MaintenanceTask[]), newValue, 'benchmark_time_in_minutes'));
        }}
      />
    );
  };

  renderTable = (field: any): JSX.Element => {
    const { isDisabled, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { frequencies: frequencyTypes, values, criticality, competencies } = field;
    const fieldKey = this.getFieldKey();

    const getFrequencies = (frequencyType: FrequencyType): Array<number> => {
      switch (frequencyType) {
        case 'day':
          return DAY_FREQUENCY_OPTIONS;
        case 'month':
          return MONTH_FREQUENCY_OPTIONS;
        case 'week':
          return WEEK_FREQUENCY_OPTIONS;
        case 'year':
          return YEAR_FREQUENCY_OPTIONS;
        default:
          return [];
      }
    };

    const columns: any[] = [
      {
        key: 'task',
        dataIndex: 'task',
        title: this.renderColumnTitle(['Task'], '', true),
        render: (task: MaintenanceTask['task'], row: MaintenanceTask) => {
          const hasErrors: boolean = this.hasError(fieldErrorMessages, fieldKey, row.key, 'task');
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'task');

          const isDuplicatedTask = this.isDuplicatedTask(task);

          const setTask = (value: string) => {
            this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'task'));
          };
          const debouncedSetTask = _.debounce(setTask, 200);

          return (
            <div className="d-f">
              <Input
                className={ classNames('Field', {
                  'Field--has-error border-danger': hasErrors,
                  'Field--has-warning border-warning': isModified && !hasErrors,
                }) }
                disabled={ isDisabled }
                onChange={ e => debouncedSetTask(e.target.value) }
                defaultValue={ task }
              />
              { !!isDuplicatedTask &&
                <Tooltip
                  overlayClassName="text-white"
                  placement="topRight"
                  title={ 'Task must be unique' }
                >
                  <WarningIcon className="text-danger mL-5 mT-5" height={ 20 } width={ 20 } />
                </Tooltip>
              }
            </div>
          );
        },
        width: 220,
        ellipsis: true,
      },
      {
        key: 'criticality_id',
        dataIndex: 'criticality_id',
        title: this.renderColumnTitle(['Criticality'], '', true),
        render: (criticalityValue: MaintenanceTask['criticality_id'], row: MaintenanceTask) => {
          const hasErrors: boolean = this.hasError(fieldErrorMessages, fieldKey, row.key, 'criticality_id');
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'criticality_id');

          return (
            <div className="d-f">
              <Select
                dropdownMatchSelectWidth={ false }
                disabled={ isDisabled || _.isEmpty(criticality) }
                style={{ width: '100%' }}
                placeholder={ '-' }
                className={ classNames('Select-Field', {
                  'Select-Field--has-error border-danger': !!hasErrors,
                  'Select-Field--has-warning border-warning': isModified && !hasErrors,
                }) }
                value={ criticalityValue }
                onChange={ (value: number) => {
                  this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'criticality_id'));
                } }
              >
                { criticality.map((criticalityOption: Option) => (
                  <Select.Option key={criticalityOption.id} value={criticalityOption.id}>{criticalityOption.option}</Select.Option>
                )) }
              </Select>
            </div>
          );
        },
        width: 100,
        ellipsis: true,
      },
      {
        key: 'frequency_type',
        dataIndex: 'frequency_type',
        title: this.renderColumnTitle(['Frequency Type'], '', false),
        render: (frequencyType: FrequencyType, row: MaintenanceTask) => {
          const hasErrors: boolean = this.hasError(fieldErrorMessages, fieldKey, row.key, 'frequency_type');
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'frequency_type');

          return (
            <Select
              allowClear
              dropdownMatchSelectWidth={ false }
              disabled={ isDisabled }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              value={ frequencyType }
              onChange={ (value: FrequencyType) => {
                const tempState = this.modifyValues(row.key, _.cloneDeep(values), null, 'frequency_value');
                this.handleChange(this.modifyValues(row.key, tempState, value, 'frequency_type'));
              } }
            >
              { frequencyTypes?.map( (frequencyType: FrequencyType) => (
                <Select.Option key={ frequencyType } value={ frequencyType }>{ _.capitalize(frequencyType) }</Select.Option>
              )) }
            </Select>
          );
        },
        width: 130,
        ellipsis: true,
      },
      {
        key: 'frequency_value',
        dataIndex: 'frequency_value',
        title: this.renderColumnTitle(['Frequency'], '', false),
        render: (frequencyValue: MaintenanceTask['frequency_value'], row: MaintenanceTask) => {
          const hasErrors: boolean = this.hasError(fieldErrorMessages, fieldKey, row.key, 'frequency_value');
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'frequency_value');
          const frequencies: Array<number> = getFrequencies(row.frequency_type);

          return (
            <Select
              allowClear
              dropdownMatchSelectWidth={ false }
              disabled={ isDisabled || _.isEmpty(frequencies) }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              value={ frequencyValue }
              onChange={ (value: number) => {
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'frequency_value'));
              } }
            >
              { frequencies.map((frequency) => (
                <Select.Option key={ frequency } value={ frequency }>{ frequency }</Select.Option>
              )) }
            </Select>
          );
        },
        width: 90,
        ellipsis: true,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        render: (description: MaintenanceTask['description'], row: MaintenanceTask) => {
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'description');

          const setDescription = (value: string) => {
            this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'description'));
          };
          const debouncedSetDescription = _.debounce(setDescription, 200);

          return (
            <Input
              className={ classNames('Field', {
                'Field--has-warning border-warning': !!isModified,
              }) }
              disabled={ !!isDisabled }
              onChange={ (e) => debouncedSetDescription(e.target.value) }
              defaultValue={ description }
            />
          );
        },
        width: 180,
        ellipsis: true,
      },
      {
        key: 'benchmark_time',
        dataIndex: 'benchmark_time_in_minutes',
        title: this.renderColumnTitle(['Benchmark Time'], '', false), // Updated column title
        render: (__: string, row: MaintenanceTask) => this.renderBenchmarkTime(row),
        width: 130,
        ellipsis: true,
      },
      {
        key: 'notes',
        dataIndex: 'notes',
        title: 'Notes',
        render: (notes: MaintenanceTask['notes'], row: MaintenanceTask) => {
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'notes');

          const setNotes = (value: string) => {
            this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'notes'));
          };
          const debouncedSetNotes = _.debounce(setNotes, 200);

          return (
            <Input
              className={ classNames('Field', {
                'Field--has-warning border-warning': !!isModified,
              }) }
              disabled={ isDisabled }
              onChange={ (e) => debouncedSetNotes(e.target.value) }
              defaultValue={ notes }
            />
          );
        },
        width: 180,
        ellipsis: true,
      },
      {
        key: 'competency_id',
        dataIndex: 'competency_id',
        title: this.renderColumnTitle(['Competency'], '', false),
        render: (competencyValue: MaintenanceTask['competency_id'], row: MaintenanceTask) => {
          const hasErrors: boolean = this.hasError(fieldErrorMessages, fieldKey, row.key, 'competency_id');
          const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, row.key, 'competency_id');

          return (
            <Select
              allowClear
              dropdownMatchSelectWidth={ false }
              disabled={ isDisabled || _.isEmpty(competencies) }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              value={ competencyValue }
              onChange={ (value: number) => {
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'competency_id'));
              } }
            >
              { competencies.map((competency: CompetencyOption) => (
                <Select.Option key={competency.id} value={competency.id}>{competency.title}</Select.Option>
              )) }
            </Select>
          );
        },
        width: 120,
        ellipsis: true,
      },
      {
        key: 'required',
        dataIndex: 'required',
        title: 'Required',
        render: (required: MaintenanceTask['required'], row: MaintenanceTask) => {
          return (
            <div className='d-f jc-c'>
              <Checkbox
                disabled={ isDisabled }
                onChange={ (e) => {
                  this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), Number(e.target.checked), 'required'));
                } }
                checked={ !!required }
              />
            </div>
          );
        },
        width: 90,
        ellipsis: true,
      },
      {
        key: 'evidence',
        dataIndex: 'evidence',
        title: 'Evidence',
        render: (evidence: MaintenanceTask['evidence'], row: MaintenanceTask) => {
          return (
            <div className='d-f jc-c'>
              <Checkbox
                disabled={ isDisabled }
                onChange={ (e) => {
                  this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), Number(e.target.checked), 'evidence'));
                } }
                checked={ !!evidence }
              />
            </div>
          );
        },
        width: 90,
        ellipsis: true,
      }
    ];

    if (!isDisabled) {
      columns.push(
        {
          key: 'actions',
          dataIndex: '',
          title: (
            <Button
              className="ActionButton"
              onClick={ () => this.handleChange(_.concat(values, getBlankState() as MaintenanceTask)) }
            >
              <PlusOutlined />
            </Button>
          ),
          render: (row: MaintenanceTask) => {
            return (
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={{
                  danger: true,
                }}
                placement="topRight"
                onConfirm={ () => {
                  const updatedTasks = this.delete(row.key, values);
                  this.handleChange(updatedTasks);
                } }
              >
                <DeleteOutlined className="link" style={{ fontSize: 18 }} />
              </Popconfirm>
            );
          },
          align: 'center' as 'center',
          fixed: 'right' as 'right',
          width: 50,
        }
      );
    }

    const scrollX = columns.reduce((acc, curr) => acc += curr.width, 0);

    return (
      <Table
        size={ 'small' }
        className={ 'MaintenanceScheduleField' }
        rowKey={ 'key' }
        sticky
        bordered
        pagination={ false }
        scroll={{
          x: scrollX,
        }}
        columns={ columns }
        dataSource={ values }
      />
    );
  };

  render = () => {
    const { field, config } = this.props;

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        label={ field.label }
        col={ config.fieldColSpan }
        required={ field.config.required }
        description={ field.description }
        border
      >
        { this.renderTable(field) }
      </FieldWrapper>
    );
  };
}

export default MaintenanceSchedule;