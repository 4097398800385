// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import history from 'utils/history';

// Utils
import { instanceOfFormErrorResponse, instanceOfRecordFormEntity } from 'utils/guards';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RenderCloneModal, ImportProps } from 'components/form/form-wrapper/FormWrapper.interface';

// Actions
import {
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import FormWrapper from 'components/form/form-wrapper';
import { hasPermission } from 'components/restriction';
import { setSecondarySidebarRoutes } from 'store/UI/ActionCreators';
import { Input, Modal } from 'antd';

// Views
import NoAccess from 'views/NoAccess';

const API: Api = new Api();
const { TextArea } = Input;

interface ProcessedTransition {
  recordId: number;
  workflowId: number;
  transitionId: number;
  callback: () => void;
  comment?: string;
  title: string;
  requires_comment_description: string;
};

interface Props extends RouteComponentProps {
  id: number;
  type: string;
  entity: string;
  record?: RecordFormEntity;
  customEndpoint?: string;
  client_id?: number;
  userPermissions?: any;
  secondarySidebarRoutes: any[];
  setRecord?: (record: RecordFormEntity) => void;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  isReady?: () => void;
  onSaveHandle?: () => void;
  onTransitionHandle?: () => void;
  onExportPdf?: (recordId: number, callback: () => void) => void;
  onExportXls?: (recordId: number, callback: () => void) => void;
  getRecord?: (silent?: boolean) => void;
  pure?: boolean;
  verboseTabs?: boolean;
  shouldSendPath?: boolean;
  floatingControls?: boolean | undefined;
  renderCloneDialog?: RenderCloneModal;
  importProps?: ImportProps;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
  showTransitionConfirmDialog: boolean;
  processedTransition: ProcessedTransition | null;
  permissionDenied: boolean;
};

class RecordView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: _.cloneDeep(this.props.record) || null,
    isFetching: false,
    showTransitionConfirmDialog: false,
    permissionDenied: false,
    processedTransition: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchRecord();
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.record, this.state.record) && this.state.record && this.props.setRecord && !this.props.pure) {
      this.props.setRecord(this.state.record);
    }

    if (prevProps.id !== this.props.id) {
      await this.fetchRecord();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchRecord = async (forceLoad = false) => {
    const { id, client_id, type, entity, pure, customEndpoint, record, isReady } = this.props;

    if (!client_id || !id || (!!record && !forceLoad)) return;

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => {
        if (!pure) {
          this.props.setBreadcrumbsLoading(true);
        }
        resolve(null);
      }));

      let record: any = null;
      if (customEndpoint) {
        record = await API.get(`client/${client_id}/${customEndpoint}`);
      } else {
        record = await API.get(`client/${client_id}/${entity}/${_.kebabCase(type)}/${id}`);
      }

      this.mounted && this.setState({
        record: record,
      }, () => {

        if (!pure) {
          if (_.has(record, 'breadcrumbs')) {
            this.props.setBreadcrumbs(record.breadcrumbs, false);
          }

          if (this.props.setRecord) {
            this.props.setRecord(record);
          }
        }

        isReady && isReady();
      });

    } catch (response: any) {
      if (_.has(response, 'error.response.status') && response.error.response.status === 403) {
        this.setState({
          permissionDenied: true
        });
      } else {
        console.error('Error: ', response);
      }
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  onSave = async (record: RecordFormEntity, callback: (response: any) => void) => {
    const { id, client_id, type, entity } = this.props;

    try {

      if (instanceOfRecordFormEntity(record)) {
        const updatedRecord = await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${id}`, { data: JSON.stringify(record) });
        if (instanceOfFormErrorResponse(updatedRecord)) {
          callback(updatedRecord);
        } else {
          this.setState({
            record: updatedRecord
          }, () => {
            Notification('success', 'The record has been updated', 'Record Saved');
            callback(updatedRecord);
          });
        }

      } else {
        Promise.reject({
          callee: 'recordInterfaceGuard',
          error: new Error('Location is not an instance of RecordFormEntity')
        });
      }
    } catch (error: any) {
      switch (_.has(error, 'callee') && error.callee) {
        case 'recordInterfaceGuard':
          Notification('error', '', 'Unknown Error saving record');
          break;

        default:
          Notification('error', '', 'Unknown Error saving record');
          break;
      }
    } finally {
      this.props.onSaveHandle?.();
    }
  };

  onArchive = async (record_id: number, callback: () => void) => {
    const { client_id, type, entity, shouldSendPath } = this.props;
    try {
      const payload: any = {
        archiving_tag: 'ARCHIVED'
      };

      if (!!shouldSendPath) {
        payload.data = JSON.stringify({ path: this.props.record?.path });
      }

      const record = await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record_id}/archive`, payload);
      this.setState({
        record: record
      }, () => {
        Notification('success', '', 'Record successfully archived');
        callback();
      });
    } catch (error) {
      Notification('error', '', 'Failed to archive record');
      console.error('Error: ', error);
    }
  };

  onHide = async (record_id: number, callback: () => void) => {
    const { client_id, type, entity, shouldSendPath } = this.props;
    try {
      const payload: any = {
        archiving_tag: 'HIDDEN'
      };

      if (!!shouldSendPath) {
        payload.data = JSON.stringify({ path: this.props.record?.path });
      }

      const record = await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record_id}/archive`, payload);
      this.setState({
        record: record
      }, () => {
        Notification('success', '', 'Record successfully hidden');
        callback();
      });
    } catch (error) {
      Notification('error', '', 'Failed to hidden record');
      console.error('Error: ', error);
    }
  };

  makeTransition = async (params: ProcessedTransition) => {
    const { client_id, type, entity, onTransitionHandle } = this.props;
    const { recordId, workflowId, transitionId, comment, callback } = params;
    try {
      const body = comment ? { data: { message: comment } } : undefined;
      const record = await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${recordId}/workflow/${workflowId}/transition/${transitionId}`, body);

      this.mounted && this.setState({
        record: record,
      }, () => {
        Notification('success', 'Transition Complete', 'Your record has moved to a new stage');
      });

    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Failed to push transition', 'Failed');
    } finally {
      callback();
      onTransitionHandle && onTransitionHandle();
    }
  };

  onTransition = (record_id: number, workflow_id: number, transition_id: number, requires_comment: boolean, callback: () => void) => {
    const { record } = this.state;

    const stage = record?.workflow.stages.find(stage => stage.transitions.some(transition => transition.id === transition_id));
    const transition = stage?.transitions.find(transition => transition.id === transition_id);

    if (transition) {
      const transitionParams = {
        recordId: record_id,
        workflowId: workflow_id,
        transitionId: transition_id,
        callback: callback,
        title: transition.title,
        requires_comment_description: transition.requires_comment_description,
      };

      if (requires_comment) {
        this.setState({
          showTransitionConfirmDialog: true,
          processedTransition: transitionParams,
        });
      } else {
        this.makeTransition(transitionParams);
      }
    }
  };

  onVersioning = async (record_id: number) => {
    const { client_id, type, entity } = this.props;
    try {

      const record = await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record_id}/new-version`);
      Notification('success', 'Version Created', 'A new version of your record has been created');
      window.location.href = record.path;

    } catch (error: any) {
      console.error('Error: ', error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to create version', 'Failed');
    }
  };

  onSpecificationRollOut = async (record_id: number, callback: () => void) => {
    const { client_id, type, entity } = this.props;
    try {

      await API.put(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record_id}/specification-roll-out`);
      Notification('success', 'The Specification has been Rolled Out', 'Rolled Out');

    } catch (error: any) {
      console.log(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to Roll Out Specification', 'Failed');
    } finally {
      callback();
    }
  };

  onClone = async (recordId: number, options: string[], callback: () => void, title?: string) => {
    const { client_id, type, entity } = this.props;
    try {

      const record = await API.post(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${recordId}/clone`, {
        data: !!title ? { with: options, title: title } : { with: options }
      });
      Notification('success', 'The Record has been cloned', 'Cloned');
      window.location.href = record.path;

    } catch (error: any) {
      console.log(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to clone Record', 'Failed');
    } finally {
      callback();
    }
  };

  onExportPdf = async (recordId: number, callback: () => void) => {
    const { client_id, entity } = this.props;
    const type = _.kebabCase(this.props.type);
    try {
      await API.download(`client/${client_id}/${entity}/${type}/${recordId}/export/pdf`, `pacs_export_${_.snakeCase(this.props.type)}_${moment().format('YYYY-MM-DD')}.pdf`);
    } catch (error) {
      Notification('error', 'Export Pdf Failed');
    } finally {
      callback();
    }
  };

  onExportXls = async (recordId: number, callback: () => void) => {
    const { client_id, entity } = this.props;
    const type = _.kebabCase(this.props.type);
    try {
      await API.download(`client/${client_id}/${entity}/${type}/${recordId}/export`, `pacs_export_${_.snakeCase(this.props.type)}_${moment().format('YYYY-MM-DD')}.xlsx`);
    } catch (error) {
      Notification('error', 'Export Xlsx Failed');
    } finally {
      callback();
    }
  };

  renderTransitionConfirmDialog = () => {
    const { processedTransition } = this.state;
    const isEmptyComment = _.isEmpty(processedTransition?.comment?.trim());

    const cancel = () => {
      processedTransition?.callback();
      this.setState({ showTransitionConfirmDialog: false, processedTransition: null });
    };
    const confirm = () => {
      if (processedTransition && !isEmptyComment) {
        this.makeTransition(processedTransition);
        this.setState({ showTransitionConfirmDialog: false, processedTransition: null });
      }
    };

    const setComment = (comment: string) => {
      this.setState({
        processedTransition: {
          ...processedTransition as any,
          comment: comment
        }
      });
    };
    const debouncedSetComment = _.debounce(setComment, 200);

    return (
      <Modal
        visible
        centered
        title={ processedTransition?.title }
        onCancel={ cancel }
        cancelButtonProps={{
          disabled: isEmptyComment,
        }}
        onOk={ confirm }
        okButtonProps={{
          disabled: isEmptyComment
        }}
      >
        { processedTransition?.requires_comment_description && (
          <p className="mB-5">{ processedTransition?.requires_comment_description }</p>
        ) }
        <TextArea
          rows={ 4 }
          placeholder='Please enter a comment'
          onChange={ e => debouncedSetComment(e.target.value) }
        />
      </Modal>
    );
  };

  render = () => {
    const { userPermissions, client_id, location: { hash, pathname }, renderCloneDialog, getRecord, floatingControls, verboseTabs, importProps } = this.props;
    const { isFetching, record, showTransitionConfirmDialog, permissionDenied } = this.state;
    const type = _.snakeCase(this.props.type);
    const entity = _.snakeCase(this.props.entity);

    const canView = hasPermission(userPermissions, `${entity}_${type}_view`) || hasPermission(record, `permissions.${entity}_${type}_view`);
    const canCreate = !!record?.form_config?.can_create;
    const canEdit = record?.form_config?.can_edit;

    if (permissionDenied) {
      return <NoAccess />;
    }

    return (
      <BlockingSpinner isLoading={ isFetching } style={{ justifyContent: 'center', width: '100%', height: '70vh' }}>
        { record && client_id && (
          <FormWrapper
            clientId={ client_id }
            record={ record }
            hash={ hash }
            pathname={ pathname }
            verboseTabs={ verboseTabs }
            canCreate={ canCreate }
            canEdit={ canEdit }
            canView={ canView }
            canVersion={ _.has(record, 'form_config') && !!record.form_config.can_version }
            canArchive={ _.has(record, 'form_config') && !!record.form_config.can_archive }
            canHide={ _.has(record, 'form_config') && !!record.form_config.can_hide }
            isLockedTitle={ _.has(record, 'form_config') && !!record.form_config.is_locked_title }
            canRollOutSpecification={ _.has(record, 'form_config') && !!record.form_config.can_roll_out_specification }
            canClone={ _.has(record, 'form_config') && !!record.form_config.can_clone }
            canImport={ _.has(record, 'form_config') && !!record.form_config.can_import }
            importProps={ { ...importProps, callback: () => { this.fetchRecord(true); } } as ImportProps }
            canExportPdf={ _.has(record, 'form_config') && !!record.form_config.can_export_pdf }
            canExportXls={ _.has(record, 'form_config') && !!record.form_config.can_export_xls && hasPermission(record, `permissions.${entity}_${type}_export_xls`) }
            onSave={ this.onSave }
            onArchive={ this.onArchive }
            onHide={ this.onHide }
            onTransition={ this.onTransition }
            onVersioning={ this.onVersioning }
            onSpecificationRollOut={ this.onSpecificationRollOut }
            onClone={ this.onClone }
            onExportPdf={ this.props.onExportPdf || this.onExportPdf }
            onExportXls={ this.props.onExportXls || this.onExportXls }
            renderCloneDialog={ !!renderCloneDialog ? renderCloneDialog : undefined }
            getRecord={ getRecord }
            floatingControls={ floatingControls }
          />
        ) }
        { showTransitionConfirmDialog && this.renderTransitionConfirmDialog() }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    userPermissions: store.UserState.user.permissions,
    secondarySidebarRoutes: store.UIState.secondarySidebarRoutes,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (routes: any[]) => dispatch(setSecondarySidebarRoutes(routes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecordView));
