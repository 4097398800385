// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Table, Typography } from 'antd';

// Utilities
import { getFormatedNumber } from 'services/settings';

// Interfaces
import {
  ITableFieldValue,
  ITableField,
  IColumnType,
  IColumn,
} from 'components/form/field/table/Table.interfaces';

const { Text } = Typography;

interface Props {
  field: ITableField;
  columns: any[];
  rows: readonly ITableFieldValue[];
};

export default function Summary({
  field,
  columns,
}: Props) {
  return (
    <Table.Summary.Row>
      { columns.map((column: { dataIndex: string }, index: number) => {

        const configColumns = field?.config?.columns || [];
        const configColumn = configColumns.find((_column: IColumn) => _column.reference === column.dataIndex);

        switch (configColumn?.type) {
          case IColumnType.Currency:
          case IColumnType.Number:

            let value: number | string | null = null;

            return (
              <Table.Summary.Cell key={ index } index={ index }>
                <div className="ta-r fw-600">
                  <Text>
                    { `${getFormatedNumber(`${value}`)}` }
                  </Text>
                </div>
              </Table.Summary.Cell>
            );
          default:
            return <Table.Summary.Cell key={ index } index={ index } />;
        }
      } ) }
    </Table.Summary.Row>
  );
};
