// Libs
import React from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Table from 'components/form/field/table/Table';

// Utilities
import { isBlank, isNumeric, nestedSet, validEmail, validPhone } from 'utils/utils';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import {
  ITableFieldValue,
  ITableField,
  ErrorState,
  ModifiedState,
  IColumnType,
  IColumn,
} from 'components/form/field/table/Table.interfaces';
import { RecordFormEntity } from 'types/entities';

// Styles
import './Table.scss';

interface Props {
  record: RecordFormEntity;
  field: ITableField;
  originalValues: any;
  errors?: Record<string, string[]>;
  numberFormat: any;
  clientId: number;
  entity: string;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onFieldChange(field: FormField): void;
};

export default function TableField(props: Props) {
  const {
    field,
    originalValues,
    numberFormat,
    config,
    isDisabled,
    border,
    fieldErrorMessages,
    fieldModifiedMessages,
    setFieldModifiedMessage,
    setFieldErrorMessage,
    onFieldChange,
  } = props;

  React.useEffect(() => {
    validate(field.values);
  }, [field.values]);

  const validate = (values: ITableFieldValue[]): void => {
    const columns = field?.config?.columns || [];
    const errors: ErrorState = getErrors(columns, values);
    const modified: ModifiedState = getModified(values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(`${field.id}`, errorMessage);
    setFieldModifiedMessage(`${field.id}`, modifiedMessage);
  };

  const getModified = (values: ITableFieldValue[], originalValues: ITableFieldValue[]): ModifiedState => {
    const modified: ModifiedState = {};

    const compareObjects = (o1: any, o2: any) => {
      return Object.keys(o2)
        .reduce((diff, key) => {
          if (_.isEqual(o1[key], o2[key])) return diff;
          return {
            ...diff,
            [key]: o2[key]
          };
        }, {});
    };

    const oldValues = nestedSet(originalValues);
    const newValues = nestedSet(values);

    // Check if values have changed
    if (!_.isEqual(oldValues, newValues)) {

      // Something's been removed
      const newStateKeys: string[] = newValues.map((value: ITableFieldValue) => value.key);
      if (oldValues.some((_value: ITableFieldValue) => !newStateKeys.includes(_value.key))) {
        modified['removed'] = null;
      }

      newValues.forEach((row: any) => {
        const originalRow: any = oldValues.find((originalRow: ITableFieldValue) => originalRow.key === row.key);
        const rowKeys = Object.keys(row);

        if (originalRow) {
          const diff: any = compareObjects(originalRow, row);
          if (!_.isEmpty(diff)) {
            modified[row.key] = Object.keys(diff);
          }
        } else {
          // New row
          modified[row.key] = rowKeys;
        }
      });
    }

    return modified;
  };

  const getErrors = (columns: IColumn[] = [], values: ITableFieldValue[]): ErrorState => {
    const availibleColumns = columns.filter((column: IColumn) => !column?.config?.hidden && !column?.config?.locked);
    const errors: ErrorState = {};

    values.forEach((row: any) => {

      const rowErrors = Object.keys(row)
        .filter((key: string) => {
          return availibleColumns
            .find((column: IColumn) => {

              // Return false = No error

              const isValidPhoneNumber = (phoneObj: { dial_code: string | null, number: string | null }) => {
                if (!phoneObj || !phoneObj?.dial_code || !phoneObj?.number ) {
                  return false;
                }
                return validPhone(`+${phoneObj?.dial_code} ${phoneObj?.number}`);
              };

              // Required column
              if (!!column?.config?.required && column.reference === key) {

                // No value found, instant error
                if (!row[key]) {
                  return false;
                }

                switch (column.type) {
                  case IColumnType.Currency:
                  case IColumnType.Number:
                    return !isNumeric(parseFloat(row[key]));
                  case IColumnType.Text:
                    return !isBlank(row[key]);
                  case IColumnType.Phone:
                    return !isValidPhoneNumber(row[key]);
                  case IColumnType.Email:
                    return !validEmail(row[key]);
                  case IColumnType.Select:
                  case IColumnType.Category:
                    return !_.isEmpty(row[key]);
                }
              }

              // Phone Column
              if (column.type === IColumnType.Phone && _.has(row, column?.reference) && column.reference === key) {
                const phoneObj: { dial_code: string | null, number: string | null } = row[key];

                if (!phoneObj?.dial_code && !phoneObj?.number) {
                  return false;
                }

                return !phoneObj?.dial_code || !phoneObj?.number;
              }

              // Email Column
              if (column.type === IColumnType.Email && _.has(row, column?.reference) && column.reference === key) {
                return !!row[key] && !validEmail(row[key]);
              }

              return false;
            });
        });

        if (!_.isEmpty(rowErrors)) {
          errors[row.key] = rowErrors;
        }
    });

    return errors;
  };

  return (
    <FieldWrapper
      id={ `${config.tabID}|${config.groupID}|${field.id}` }
      col={ config.fieldColSpan }
      label={ field.label }
      description={ !!field.description && field.description }
      required={ field.config.required }
      border
    >
      <Table
        field={ field }
        numberFormat={ numberFormat }
        isDisabled={ !!isDisabled }
        fieldErrorMessages={ fieldErrorMessages }
        fieldModifiedMessages={ fieldModifiedMessages }
        setFieldModifiedMessage={ setFieldModifiedMessage }
        setFieldErrorMessage={ setFieldErrorMessage }
        handleChange={ (values: ITableFieldValue[] ) => {
          onFieldChange({
            ...field,
            values: _.cloneDeep(values)
          });
        } }
      />
    </FieldWrapper>
  );
};
