// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Popconfirm, Modal, Form, Input, Button } from 'antd';
import Jumbotron from 'components/jumbotron';
import BasicList from "components/basic-list";

// Interfaces
import { Breadcrumb } from 'store/UI/State.interface';
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, StarOutlined } from '@ant-design/icons';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';
import { IDashboard } from 'components/dashboard/Dashboard.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { receiveMe } from 'store/User/ActionCreators';

const API: Api = new Api();

interface Props {
  client_id: number,
  permissions: any;
  routes: any;
  isPrimarySidebarCollapsed: boolean;
  setUser: (user: UserEntity) => void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  dashboards: IDashboard[];
  activeDashboard: IDashboard | null;
  showDialog: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  loadButtonClass: string;
};

class Dashboards extends React.Component<Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();

  state: State = {
    dashboards: [],
    activeDashboard: null,
    loadButtonClass: '',
    showDialog: false,
    isLoading: false,
    isDeleting: false,
  };

  componentDidMount = () => {
    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Dashboards', path: '/dashboards' }
    ], false);

    this.fetchDashboards();
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetchDashboards = async () => {

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const dashboards = await API.get(`client/${this.props.client_id}/insight/dashboard`);

      this.mounted && this.setState({
        dashboards: dashboards,
        loadButtonClass: _.isEmpty(dashboards) ? 'u-animationPulse' : '',
      });

    } catch (error) {
      Notification('error', 'Failed to load dashboards', 'Failed');
    } finally {
      this.mounted && this.setState({
        isLoading: false
      }, () => {
        setTimeout(() => {
          this.setState({
            loadButtonClass: ''
          });
        }, 1000);
      });
    }
  };

  setFavourite = async (dashboardId: number, status: boolean) => {
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      await API.put(`client/${this.props.client_id}/insight/dashboard/${dashboardId}/favourite/${status ? 1 : 0}`);
      const dashboards = await API.get(`client/${this.props.client_id}/insight/dashboard`);

      this.mounted && this.setState({
        dashboards: dashboards
      }, async () => {
        const user = await API.get(`user`);
        this.props.setUser(user);
      });

    } catch (error) {
      Notification('error', 'Failed to set favourite', 'Failed');
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  handleDelete = async (dashboardId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isDeleting: true }, () => resolve(null)));

      await API.delete(`client/${this.props.client_id}/insight/dashboard/${dashboardId}`);
      const dashboards = await API.get(`client/${this.props.client_id}/insight/dashboard`);

      this.mounted && this.setState({
        dashboards: dashboards
      });

    } catch (error) {
      Notification('error', 'Failed to delete dashboard', 'Failed');
    } finally {
      this.mounted && this.setState({
        isDeleting: false
      });
    }
  };

  renderDialog = (dashboard: IDashboard | null) => {
    return (
      <Modal
        visible
        centered
        title={ `${!!dashboard ? 'Edit' : 'Create'} Dashboard` }
        onOk={ () => this.mounted && this.setState({
            isLoading: false
          }, () => {
            this.formRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  if (!!dashboard?.id) {
                    await API.put(`client/${this.props.client_id}/insight/dashboard/${dashboard.id}`, {
                      data: {
                        title: values.title,
                      }
                    });
                  } else {
                    await API.post(`client/${this.props.client_id}/insight/dashboard`, {
                      data: {
                        title: values.title,
                      }
                    });
                  }

                  const dashboards = await API.get(`client/${this.props.client_id}/insight/dashboard`);

                  this.mounted && this.setState({
                    dashboards: dashboards
                  });
                } catch (error) {
                  Notification('error', '', 'Failed');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false,
                    showDialog: false,
                    activeDashboard: null
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ !!dashboard ? 'Save' : 'Create' }
        onCancel={ () => this.mounted && this.setState({
          showDialog: false,
          activeDashboard: null,
        }) }
        okButtonProps={{
          loading: this.state.isLoading,
          disabled: this.state.isLoading
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          initialValues={{
            'title': dashboard?.title
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderListView = (dashboards: IDashboard[]) => {
    return (
      <BasicList
        rawData
        isLoading={ this.state.isLoading || this.state.isDeleting }
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            filterable: true,
            render: (__: any, dashboard: IDashboard) => {
              return (
                <Link className='primaryColor' to={ `/dashboards/${dashboard.id}` }>
                  { dashboard.title }
                </Link>
              );
            },
          },
          {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            render: (__: any, dashboard: IDashboard) => {
              const canManage = true;
              return (
                <>
                  <StarOutlined
                    className={ classNames('fsz-def star', {
                      'text-ant-disabled': !canManage,
                      'cur-na': !canManage,
                      'active': !!dashboard.is_favourite,
                    }) }
                    onClick={ () => this.setFavourite(dashboard.id, !dashboard.is_favourite) }
                  />
                  <EditOutlined
                    className={ classNames('fsz-def link mL-10', {
                      'text-ant-disabled': !canManage,
                      'cur-na': !canManage,
                    }) }
                    onClick={ () => canManage && this.setState({
                      showDialog: true,
                      activeDashboard: dashboard
                    }) }
                  />
                  { canManage ? (
                    <Popconfirm
                      title={ 'Are you sure?' }
                      icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                      okButtonProps={{
                        danger: true
                      }}
                      onConfirm={ () => {
                        canManage && this.handleDelete(dashboard.id);
                      }}
                    >
                      <DeleteOutlined
                        className={ classNames('fsz-def link mL-10', {
                          'text-ant-disabled': !canManage,
                          'cur-na': !canManage,
                        }) }
                      />
                    </Popconfirm>
                  ) : (
                    <DeleteOutlined className={ "text-ant-disabled fsz-def cur-na mL-10" } />
                  ) }
                </>
              );
            },
            width: 120,
            sorter: false,
            ellipsis: true,
            filterable: false,
            align: 'center'
          },
        ] }
        items={ dashboards.map((dashboard: any, index: number) => {
          return {
            'key': index,
            ...dashboard,
          };
        } ) }
      />
    );
  };

  render = () => {
    const { dashboards, loadButtonClass, showDialog } = this.state;

    return (
      <>
        <Jumbotron
          title={ 'Dashboards' }
          tabs={ [
            {
              label: 'List View',
              node: this.renderListView(dashboards),
            }
          ] }
          content={ <div className="mT-20"></div> }
          rightActions={ [
            {
              node: (
                <Button
                  className={ loadButtonClass || '' }
                  onClick={ () => this.setState({ showDialog: true }) }
                >
                  Create Dashboard
                </Button>
              )
            }
          ] }
        />
        { showDialog && this.renderDialog(this.state.activeDashboard) }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    isPrimarySidebarCollapsed: store.UIState.isPrimarySidebarCollapsed,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setUser: (user: UserEntity) => dispatch(receiveMe(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
