// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Route, Switch, Redirect, Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { IntlProvider } from 'react-intl';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Interfaces
import AppState from 'store/AppState.interface';
import ClientState from 'store/Client/State.interface';
import { IQR } from 'store/UI/State.interface';

// Store
import { setPrimarySidebarCollapsed, setSecondarySidebarCollapsed } from 'store/UI/ActionCreators';
import { ping } from 'store/UI/Actions';

// Containers
import CustomersContainer from 'containers/CustomersContainer';
import PortfolioContainer from 'containers/PortfolioContainer';
import WorkspaceContainer from 'containers/WorkspaceContainer';
import ProjectContainer from 'containers/ProjectContainer';
import WorkplaceServiceContainer from 'containers/WorkplaceServiceContainer';
import FinanceContainer from 'containers/FinanceContainer';
import SupplierContainer from 'containers/SupplierContainer';
import EHSContainer from 'containers/EHSContainer';
import UserContainer from 'containers/UserContainer';
import AdminContainer from 'containers/AdminContainer';
import SupportContainer from 'containers/SupportContainer';
import UserRequestContainer from 'containers/UserRequestContainer';
import InsightsContainer from 'containers/InsightsContainer';
import DashboardsContainer from 'containers/DashboardsContainer';

// Views
import Login from 'views/auth/Login';
import RequestToken from 'views/auth/RequestToken';
import ResetPassword from 'views/auth/ResetPassword';
import QRRedirect from 'views/auth/QRRedirect';

// Components
import Sidebar from 'components/sidebar';
import Header from 'components/header';
import BlockingSpinner from 'components/blocking-spinner';
import TermsModal from 'components/terms-modal';
import EnvironmentBanner from 'components/environment-banner/EnvironmentBanner';
import { ConfigProvider, Modal, Typography } from 'antd';

// Services
import { getUserSetting, getInternalLocale, getAntLocale } from 'services/settings';
import { logout } from 'services/auth';
import { redirect } from 'services/store';
import { Poll } from 'services/poll';

// Views
import Home from 'views/Home';
import Notifications from 'views/Notifications';
import Help from 'views/Help';
import NotFound from 'views/NotFound';
import AcceptInvitation from 'views/auth/AcceptInvitation';
import MultiFactorAuthentication from 'views/auth/MultiFactorAuthentication';

// Styles
import 'assets/styles/_layout.scss';
import 'assets/styles/_auth.scss';

const { Link } = Typography;

// Define available props
interface Props extends RouteComponentProps {
  client: ClientState,
  isAuthenticated: boolean;
  isFetchingUser: boolean;
  isPrimarySidebarCollapsed: boolean;
  isSecondarySidebarCollapsed: boolean;
  setPrimarySidebarCollapsed(value: boolean): void;
  setSecondarySidebarCollapsed(value: boolean): void;
  isBlocked: boolean;
  routes: any;
  secondarySidebarRoutes: any;
  hasSessionExpired: boolean;
  appSha: string | undefined;
  pingClientId: number | undefined;
  QR: IQR;
  redirect(path: string): void;
  ping(): void;
  logout(hasSessionExpired: boolean): void;
};

interface State {
  showPrivacyPolicy: boolean;
  showTermsConditions: boolean;
};

class AppLayout extends React.Component<Props, State> {

  state: State = {
    showPrivacyPolicy: false,
    showTermsConditions: false,
  };

  pingPolling: Poll = new Poll(this.props.ping, (2 * 60) * 1000); // Poll every 2 minute

  componentDidMount = () => {
    if (!isEmpty(this.props.secondarySidebarRoutes)) {
      this.props.isSecondarySidebarCollapsed && this.props.setSecondarySidebarCollapsed(false);
    }

    if (!this.pingPolling.pollingStatus) {
      this.pingPolling.start();
    }
  };

  componentDidUpdate = (prevProps: Props) => {

    // Collapse sidebar logic
    if (prevProps.secondarySidebarRoutes !== this.props.secondarySidebarRoutes) {
      if (!isEmpty(this.props.secondarySidebarRoutes)) {
        this.props.isSecondarySidebarCollapsed && this.props.setSecondarySidebarCollapsed(false);
      } else {
        if (this.props.isPrimarySidebarCollapsed && !this.props.location.pathname.includes('help-articles')) {
          this.props.setPrimarySidebarCollapsed(false);
        }
      }
    }

    // Logged out
    if (prevProps.isAuthenticated === true && this.props.isAuthenticated === false) {
      this.pingPolling.terminate();
    }

    // Redirect logic
    if (!!this.props.QR && !!this.props.isAuthenticated && !!this.props.client) {
      this.props.redirect(this.props.QR.value);
    }
  };

  componentWillUnmount = () => {
    this.pingPolling.terminate();
  };

  renderSessionExpiredModal = () => {
    return (
      <Modal
        visible
        centered
        title="Session Expired"
        onOk={ () => this.props.logout(false) }
        onCancel={ () => this.props.logout(false) }
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)' }}
        cancelButtonProps={{
          className: 'd-n'
        }}
      >
        <p className="mB-10">Your session has expired, this can be due to inactivity or logging in on another browser.</p>
        <p>To continue here on this browser, you will need to login again.</p>
      </Modal>
    );
  };

  renderRefreshModal = (mismatch: boolean = false) => {
    return (
      <Modal
        visible
        centered
        closable={ false }
        title="Refresh Needed"
        onOk={ () => window.location.reload() }
        okText="Refresh"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)' }}
        cancelButtonProps={{
          className: 'd-n'
        }}
      >
        { mismatch ? (
          <p>It looks like you have logged into a different PACS environment in another tab/window. Please refresh this page to move to the current active environment.</p>
        ) : (
          <p>It looks like you're not using the latest version of PACS. Please refresh this browser window to update to the latest version.</p>
        ) }
      </Modal>
    );
  };

  render = () => {
    const {
      client,
      isAuthenticated,
      isPrimarySidebarCollapsed,
      isSecondarySidebarCollapsed,
      setPrimarySidebarCollapsed,
      setSecondarySidebarCollapsed,
      history,
      location,
      match,
      routes,
      isBlocked,
      secondarySidebarRoutes,
      hasSessionExpired,
      pingClientId,
      appSha,
    } = this.props;
    const { showPrivacyPolicy, showTermsConditions } = this.state;

    const router = {
      history,
      location,
      match
    };

    const locale = getUserSetting('locale');
    const internalTranslations = getInternalLocale(locale);
    const antTranslations = getAntLocale(locale);
    const currentYear = moment().year();
    const showEnvironmentBanner = process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'production';
    const isDifferentSha = !!appSha && !!process.env?.REACT_APP_VERCEL_GIT_COMMIT_SHA && (appSha !== process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA);
    const isDifferentActiveClient = !!client.client_id && !!pingClientId && (client.client_id !== pingClientId);

    if (isBlocked) {
      return (
        <div style={{ width: '100vw', height: '100vh', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <BlockingSpinner isLoading />
        </div>
      );
    }

    if (!isAuthenticated || hasSessionExpired) {
      return (
        <>
          <div className={
            classNames('Auth-layout', {
              'EnvironmentBanner--show': showEnvironmentBanner
            }) }
          >
            <aside className="Auth-aside">
              <div className="Auth-aside-container">
                <div className="Auth-aside-header">
                  <RouterLink to='/'>
                    <img className="Auth-aside-logo" src="/pacs-logo.png" alt="PACS" />
                  </RouterLink>
                </div>
                <div className="Auth-aside-body">
                  <Switch>

                    <Redirect exact from="/" to="/login" />

                    <Route exact path="/login" component={ Login } />
                    <Route exact path="/multi-factor-authentication" component={ MultiFactorAuthentication } />
                    <Route exact path="/request-token" component={ RequestToken } />
                    <Route exact path="/reset-password/:email/:token" component={ ResetPassword } />
                    <Route exact path="/accept-invitation/:email/:token/:client_id/:show_password_form?" component={ AcceptInvitation } />

                    <Route
                      exact
                      path="/qr/:hash"
                      render={ (props) => {
                        return (
                          <>
                            <Login />
                            <QRRedirect
                              QR={ this.props.QR }
                              hash={ props.match.params?.hash }
                              isAuthenticated={ isAuthenticated }
                            />
                          </>
                        );
                      } }
                    />

                    <Redirect to="/login" />

                  </Switch>
                </div>
                <div className="Auth-aside-footer">
                  <div className="Auth-aside-footer-wrapper">
                    <p><span>© Copyright </span><a rel="noopener noreferrer" target="_blank" href="https://www.pacs.org.uk">PACS</a><span>{ ` - ${currentYear}` }</span></p>
                    <p>
                      <Link onClick={ () => this.setState({ showTermsConditions: true }) }>{ 'Terms and Conditions' }</Link>
                      <span className="defaultColor"> & </span>
                      <Link onClick={ () => this.setState({ showPrivacyPolicy: true }) }>{ 'Privacy Policy' }</Link>
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <main className="Auth-main" style={{ backgroundImage: 'url(/login_background.jpg)' }}></main>
          </div>
          { showPrivacyPolicy && <TermsModal type={ 'privacy' } onClose={ () => this.setState({ showPrivacyPolicy: false }) } /> }
          { showTermsConditions && <TermsModal type={ 'terms' } onClose={ () => this.setState({ showTermsConditions: false }) } /> }
          { showEnvironmentBanner && <EnvironmentBanner environment={ process.env.REACT_APP_ENVIRONMENT } /> }
          { hasSessionExpired && this.renderSessionExpiredModal() }
          { isDifferentSha && this.renderRefreshModal() }
        </>
      );
    }

    return (
      <IntlProvider locale={ locale } messages={ internalTranslations }>
        <ConfigProvider locale={ antTranslations }>
          <div
            className={ classNames('Layout', {
              'Layout-secondary-menu-visible': !isEmpty(secondarySidebarRoutes),
              'EnvironmentBanner--show': showEnvironmentBanner
            }) }
          >
            <aside className='Layout-menu' id='Layout-menu'>
              <Sidebar
                client={ client }
                routes={ routes }
                router={ router }
                isPrimarySidebarCollapsed={ isPrimarySidebarCollapsed }
                isSecondarySidebarCollapsed={ isSecondarySidebarCollapsed }
                togglePrimarySidebar={ () => setPrimarySidebarCollapsed(!isPrimarySidebarCollapsed) }
                toggleSecondarySidebar={ () => setSecondarySidebarCollapsed(!isSecondarySidebarCollapsed) }
                secondarySidebarRoutes={ secondarySidebarRoutes }
              />
            </aside>
            <main className='Layout-container'>
              <Header
                router={ router }
              />
              <div className='Layout-content'>
                <Switch>
                  <Redirect exact from="/login" to="/" />

                  <Route exact path="/" component={ Home } />

                  <Route exact path="/notifications" component={ Notifications } />
                  <Route exact path="/notifications/:notification_id" component={ Notifications } />
                  <Route exact path="/help" component={ Help } />

                  <Route exact path="/user-requests" component={ UserRequestContainer } />
                  <Route path="/user-requests/:record_id" component={ UserRequestContainer } />

                  <Route path="/admin" component={ AdminContainer } />
                  <Route path="/dashboards" component={ DashboardsContainer } />
                  <Route path="/insights" component={ InsightsContainer } />
                  <Route path="/reports" component={ InsightsContainer } />
                  <Route path="/support" component={ SupportContainer } />
                  <Route path="/user" component={ UserContainer } />
                  <Route path="/customers" component={ CustomersContainer } />
                  <Route path="/portfolio" component={ PortfolioContainer } />
                  <Route path="/workspaces" component={ WorkspaceContainer } />
                  <Route path="/projects" component={ ProjectContainer } />
                  <Route path="/workplace-services" component={ WorkplaceServiceContainer } />
                  <Route path="/finance" component={ FinanceContainer } />
                  <Route path="/suppliers" component={ SupplierContainer } />
                  <Route path="/ehs" component={ EHSContainer } />

                  <Route
                    exact
                    path="/qr/:hash"
                    render={ (props) => {
                      return (
                        <QRRedirect
                          QR={ this.props.QR }
                          hash={ props.match.params?.hash }
                          isAuthenticated={ isAuthenticated }
                        />
                      );
                    } }
                  />

                  <Route render={ () => <NotFound /> } />
                </Switch>
              </div>
            </main>
          </div>
          { showEnvironmentBanner && <EnvironmentBanner environment={ process.env.REACT_APP_ENVIRONMENT } /> }
          { (isDifferentSha || isDifferentActiveClient) && this.renderRefreshModal(isDifferentActiveClient) }
        </ConfigProvider>
      </IntlProvider>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client: store.ClientState,
    hasSessionExpired: store.UserState.hasSessionExpired,
    isAuthenticated: store.UserState.isAuthenticated,
    isFetchingUser: store.UserState.isFetching,
    isPrimarySidebarCollapsed: store.UIState.isPrimarySidebarCollapsed,
    isSecondarySidebarCollapsed: store.UIState.isSecondarySidebarCollapsed,
    isBlocked: store.UIState.isBlocked,
    appSha: store.UIState.ping?.app_sha,
    pingClientId: store.UIState.ping?.client_id,
    QR: store.UIState.QR,
    routes: store.ClientState.routes,
    secondarySidebarRoutes: store.UIState.secondarySidebarRoutes,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: (hasSessionExpired: boolean) => logout(hasSessionExpired),
    ping: () => dispatch(ping()),
    setPrimarySidebarCollapsed: (value: boolean) => dispatch(setPrimarySidebarCollapsed(value)),
    setSecondarySidebarCollapsed: (value: boolean) => dispatch(setSecondarySidebarCollapsed(value)),
    redirect: (path: string) => redirect(path),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppLayout));