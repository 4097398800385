// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Containers
import AssetContainer from 'containers/workplace-service/asset/AssetContainer';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Views
import Assets from 'views/workplace-service/Assets';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
};

class AssetsContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/workplace-services/assets', '/workplace-services/assets/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_asset_view_list') }>
                <Assets />
              </RestrictionWrapper>
              <Route
                exact
                path='/workplace-services/assets/create'
                render={ () => (
                  <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_asset_create') }>
                    <ListViewCreateModal
                      bundle={ 'record' }
                      type={ 'asset' }
                      redirectUrl={ '/workplace-services/assets' }
                    />
                  </RestrictionWrapper>
                ) }
              />
            </>
          );
        } } />

        <Route path="/workplace-services/assets/:asset_id" component={ AssetContainer } />

        <Route render={() => <NotFound />} />

      </Switch>
    );
  };
};


// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(AssetsContainer);
