// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _, { isEmpty, isEqual } from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Modal, TreeSelect, Tooltip, Divider, Tree } from 'antd';

// Icons
import { PartitionOutlined, ArrowRightOutlined, EditOutlined } from '@ant-design/icons';

// Interfaces
import {
  Category as CategoryProp,
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';

interface Props {
  config: FormFieldConfig;
  field: FormField;
  state: Record<string, string | number>[];
  originalState: FormValues[];
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showPickerModal: boolean;
};

interface CategoryList {
  key: number;
  value: number;
  title: string;
  description?: string;
  children?: CategoryList[];
};

const { SHOW_PARENT } = TreeSelect;

class CategoryField extends Component<Props, State> {

  state: State = {
    showPickerModal: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    const pastValue = originalState && originalState.map((value: any) => value.target_id).sort((a: number, b: number) => a - b);
    const newValue = state && state.map((value: any) => value.target_id).sort((a: number, b: number) => a - b);

    this.generateModifiedState(pastValue, newValue, 'category', shouldClear);
    this.generateErrorState(newValue, 'category', shouldClear);
  };

  generateModifiedState = (rawPastValues: any, rawNewValues: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const pastValues = this.canSelectMultiple() ? rawPastValues : rawPastValues[0];
    const newValues = this.canSelectMultiple() ? rawNewValues : rawNewValues[0];

    if (!isEqual(pastValues, newValues) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: 0,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: string[] = [];

    if (field.config.required && isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: errors
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  generateCategoriesList = (category: CategoryProp): CategoryList => {
    const { field } = this.props;
    return {
      key: category.id,
      value: category.id,
      title: category.title,
      description: category.description,
      children: field.categories && field.categories
        .filter((_category: CategoryProp) => category.id === _category.parent_id)
        .map((_category: CategoryProp) => this.generateCategoriesList(_category))
    };
  };

  getChildren = (categoryID: number, categories?: CategoryProp[]): number[] => {
    const childrenCategories = categories && categories.filter(category => category.parent_id === categoryID);
    let children: number[] = [];

    if (childrenCategories && !isEmpty(childrenCategories)) {
      childrenCategories.forEach((childCategory: CategoryProp) => {
        children.push(...this.getChildren(childCategory.id, categories));
      });

      return children;
    }

    return [categoryID];
  };

  // Category fields support 1 or infinite values
  // Currently not intending to support value counts
  // Any value that is not a "1" will be treated as "0"
  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  renderPickerModal = () => {
    const { field, config, state, onChange } = this.props;

    const values: any = state ? state.map(value => value.target_id) : [];
    const categories = field.categories && field.categories
      .filter((category: CategoryProp) => !category.parent_id) // Filter out ground floor first
      .map((category: CategoryProp) => this.generateCategoriesList(category));

    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ '' }
        onCancel={ () => this.setState({ showPickerModal: false }) }
        okText={ 'Ok' }
        onOk={ () => this.setState({ showPickerModal: false }) }
        style={{ minWidth: 500 }}
      >
        <Tree
          className="ov-s"
          style={{ height: 400 }}
          checkable
          defaultExpandAll
          checkedKeys={ this.canSelectMultiple() ? values : values[0] }
          onCheck={ (changedValues: any) => {
            const newValues: Array<{ target_id: number }> = changedValues.map((id: number) => {
              return {
                target_id: id
              };
            });
            onChange(field, newValues, config);
          } }
          treeData={ categories }
        />
      </Modal>
    );
  };

  renderPicker = () => {
    const { field, config, border, isDisabled, state, fieldErrorMessages, fieldModifiedMessages } = this.props;
    const { showPickerModal } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_category`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const values: any[] = state ? state.map(value => value.target_id) : [];
    const categories = field.categories && field.categories
      .filter((category: CategoryProp) => !category.parent_id)
      .map((category: CategoryProp) => this.generateCategoriesList(category));

    const getRegionPath = (category_id: number): string[] => {
      const collector: any = [];

      const traverse = (category_id: number): any => {
        field.categories && field.categories.forEach((category: any) => {
          if (category.id === category_id) {
            collector.push(category.title);
            if (category.parent_id) {
              traverse(category.parent_id);
            }
          }
        });
      };

      traverse(category_id);

      return collector.reverse();
    };

    const getCategoryTrees = (categories: any[], category_ids: number[]) => {
      let collector: any = [];

      categories.forEach((category: any) => {

        let _collector: any = null;

        category_ids.forEach((category_id: number) => {
          if (category.value === category_id) {
            _collector = getRegionPath(category_id);
          }
        });

        if (_collector) {
          collector.push(_collector);
        } else if (_.has(category, 'children') && !_.isEmpty(category.children)) {
          const child = getCategoryTrees(category.children, category_ids);
          if (!_.isEmpty(child)) {
            collector = collector.concat(child);
          }
        }
      });

      return collector;
    };

    const categoryTrees = getCategoryTrees(categories || [], values);

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          errors={ errors || [] }
          isModified={ isModified }
          border={ border }
          description={ !!field.description && field.description }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={ !isDisabled ? [
            {
              node: (
                <EditOutlined
                  className="link fsz-md"
                  onClick={ () => this.setState({ showPickerModal: true }) }
                />
              )
            }
          ] : [] }
        >
          <div
            className={ classNames('w-100', {
              'mY-5': _.isEmpty(values),
            } ) }
          >
            { !_.isEmpty(values) ? (
              categoryTrees.map((trees: any, index: number) => (
                <div key={ index }>
                  <div key={ index }>
                    { trees.map((tree: any, _index: number) =>
                      trees.length === _index + 1 ?
                        (
                          <span key={ _index }>{ tree }</span>
                        ) : (
                          <span key={ _index }><span>{ tree }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                        )
                    ) }
                  </div>
                  { values.length !== index + 1 && <Divider className="mY-5" /> }
                </div>
              ) )
            ) : (
              <span>-</span>
            ) }
          </div>
        </FieldWrapper>
        { showPickerModal && this.renderPickerModal() }
      </>
    );
  };

  renderSelect = () => {
    const { field, config, border, state, fieldErrorMessages, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_category`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const values = state ? state.map(value => value.target_id) : [];
    const categories = field.categories && field.categories
      .filter((category: CategoryProp) => !category.parent_id) // Filter out ground floor first
      .map((category: CategoryProp) => this.generateCategoriesList(category));

    if (!!isDisabled) {
      const categories: CategoryProp[] = field.categories ? field.categories : [];

      if (!isEmpty(categories)) {
        const label = values.map((rawValue: any) => {
          const categoryObj = categories.find(category => category.id === Number(rawValue));
          return categoryObj ? categoryObj.title : '';
        });

        return (
          <FieldWrapper
            id={ `${config.tabID}|${config.groupID}|${field.id}` }
            col={ config.fieldColSpan }
            label={ field.label }
            required={ field.config.required }
            border={ border }
            versionChanged={ !!field.config.version_changed }
            description={ !!field.description && field.description }
          >
            <span className="d-b pY-5">{ !_.isEmpty(label) ? label.join(', ') : '-' }</span>
          </FieldWrapper>
        );
      }
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        errors={ errors || [] }
        border={ border }
        description={ !!field.description && field.description }
        versionChanged={ !!field.config.version_changed }
        leftActions={ !!field.config.refresh_on_change ? [
          {
            node: (
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Changing the value in the field will make updates in the wider form' }
              >
                <PartitionOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            )
          }
        ] : [] }
      >
        <div className="w-100p">
          <TreeSelect
            id={ field.label } // Resolves a duplication warning
            style={{
              width: '100%',
            }}
            className={ classNames('Select-Field', {
              'Select-Field--has-warning': isModified && _.isEmpty(errors)
            }) }
            showSearch
            allowClear
            treeCheckable={ this.canSelectMultiple() }
            treeData={ categories }
            value={ this.canSelectMultiple() ? values : values[0] }
            maxTagCount={ 3 }
            multiple={ this.canSelectMultiple() }
            showCheckedStrategy={ SHOW_PARENT }
            filterTreeNode={ (input: string, option: any) => {
              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }

              return false;
            } }
            disabled={ isDisabled }
            onChange={ (changedValues: any) => {
              if (!changedValues) {
                changedValues = [];
              }

              // If single value, convert to array for consistent handling
              if(!Array.isArray(changedValues)) {
                changedValues = [changedValues];
              }

              const newValues: Array<{ target_id: number }> = changedValues.map((id: number) => {
                return {
                  target_id: id
                };
              });

              onChange(field, newValues, config);
            } }
            placeholder={ field.label }
          />
        </div>
      </FieldWrapper>
    );
  };

  render = () => {
    if (_.has(this.props.field, 'config.format') && this.props.field.config.format === 'picker') {
      return this.renderPicker();
    } else {
      return this.renderSelect();
    }
  };
};

export default CategoryField;
