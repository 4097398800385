// Store
import { Reducer } from 'redux';

import UIActions from './Actions.type';
import UIActionTypes from './ActionTypes.enum';
import UIState from './State.interface';

const initialUIState: UIState = {
  isBlocked: true,
  initialisationError: null,
  isInitialised: false,
  isFetching: false,
  isPrimarySidebarCollapsed: false,
  isSecondarySidebarCollapsed: true,
  breadcrumbsLoading: false,
  breadcrumbs: [],
  secondarySidebarRoutes: [],
  ping: {},
  QR: null,
};

const UIReducer: Reducer<UIState, UIActions> = (
  state = initialUIState,
  action
) => {
  switch (action.type) {
    case UIActionTypes.APP_INITIALISATION_START: {
      return {
        ...state,
        isBlocked: true,
        initialisationError: null,
      };
    }
    case UIActionTypes.APP_INITIALISATION_FAILED: {
      return {
        ...state,
        isBlocked: false,
        initialisationError: action.initialisationError,
      };
    }
    case UIActionTypes.APP_INITIALISATION_SUCCESS: {
      return {
        ...state,
        isBlocked: false,
        isInitialised: true,
      };
    }
    case UIActionTypes.SET_PRIMARY_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isPrimarySidebarCollapsed: action.value,
        ...(!action.value && { isSecondarySidebarCollapsed: true }),
      };
    }
    case UIActionTypes.SET_SECONDARY_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSecondarySidebarCollapsed: action.value,
        ...(!action.value && { isPrimarySidebarCollapsed: true }),
      };
    }
    case UIActionTypes.SET_BREADCRUMBS_LOADING: {
      return {
        ...state,
        breadcrumbsLoading: action.value,
      };
    }
    case UIActionTypes.SET_BREADCRUMBS: {
      return {
        ...state,
        breadcrumbs: action.value,
      };
    }
    case UIActionTypes.SET_SECONDARY_SIDEBAR_ROUTES: {
      return {
        ...state,
        secondarySidebarRoutes: action.value,
      };
    }
    case UIActionTypes.SET_IS_BLOCKED: {
      return {
        ...state,
        isBlocked: action.value,
      };
    }
    case UIActionTypes.SET_PING: {
      return {
        ...state,
        ping: action.value,
      };
    }
    case UIActionTypes.SET_QR: {
      return {
        ...state,
        QR: action.value,
      };
    }
    default:
      return state;
  }
};

export default UIReducer;
