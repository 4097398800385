// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Views
import Locations from 'views/portfolio/Locations';
import LocationRecord from 'views/portfolio/location/LocationRecord';
import LocationDocuments from 'views/portfolio/location/LocationDocuments';
import LocationAudit from 'views/portfolio/location/LocationAudit';
import LocationResources from 'views/portfolio/location/LocationResources';
import LocationWorkflow from 'views/portfolio/location/LocationWorkflow';
import LocationComments from 'views/portfolio/location/LocationComments';
import DashboardView from 'views/common/DashboardView';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: any;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class LocationContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    record: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;

    // Get record
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.record_id !== this.props.match.params.record_id) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      if (!silent) {
        // Set default breadcrumbs
        this.props.setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'Portfolio', path: '/portfolio' },
          { title: 'Locations', path: '/portfolio/locations' },
        ], false);

        // Set default routes
        this.props.setSecondarySidebarRoutes([]);
      }

      if (this.props.match.params.record_id && this.props.match.params.record_id !== 'create' && this.props.client_id) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.getLocation(this.props.client_id, this.props.match.params.record_id);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Location', path: 'record', isDisabled: !hasPermission(permissions, 'record_location_view') },
        { title: 'Dashboards', path: `/portfolio/locations/${record.id}/dashboards`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_dashboard_view') },
        { title: 'Documents', path: `/portfolio/locations/${record.id}/documents`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: `/portfolio/locations/${record.id}/comments`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: `/portfolio/locations/${record.id}/resources`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Workflow', path: `/portfolio/locations/${record.id}/workflow`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_workflow_view') },
        { title: 'Audit', path: `/portfolio/locations/${record.id}/audit`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_location_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Route exact path={ ['/portfolio/locations', '/portfolio/locations/create' ] } render={ () => {
            return (
              <>
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_view_list') }>
                  <Locations />
                </RestrictionWrapper>
                <Route
                  exact
                  path='/portfolio/locations/create'
                  render={ () => (
                    <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_create') }>
                      <ListViewCreateModal
                        bundle={ 'record' }
                        type={ 'location' }
                        redirectUrl={ '/portfolio/locations' }
                      />
                    </RestrictionWrapper>
                  ) }
                />
              </>
            );
          } } />

          <Redirect exact from="/portfolio/locations/:record_id" to="/portfolio/locations/:record_id/record" />

          { record &&
            <Route exact path="/portfolio/locations/:record_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_view') }>
                  <LocationRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/locations/:record_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_document_view') }>
                  <LocationDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/locations/:record_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_audit_view') }>
                  <LocationAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/locations/:record_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_resource_view') }>
                  <LocationResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/locations/:record_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_workflow_view') }>
                  <LocationWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/locations/:record_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_comment_view') }>
                  <LocationComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route path="/portfolio/locations/:location_id/dashboards" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_location_dashboard_view') }>
                  <Route
                    exact
                    path="/portfolio/locations/:location_id/dashboards"
                    render={ () => {
                      return (
                        <DashboardView record={ record } />
                      );
                    } }
                  />
                  <Route
                    exact
                    path="/portfolio/locations/:location_id/dashboards/:dashboard_id"
                    render={ (props: RouteComponentProps | any) => {
                      return (
                        <DashboardView record={ record } dashboardId={ props.match?.params?.dashboard_id } />
                      );
                    } }
                  />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
