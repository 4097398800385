// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  FormValues,
} from 'components/form/form-wrapper';
import { IEnergyUnit } from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  entity: string;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
  originalValues: FormValues[];
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): void;
};

export default function EnergyUnitField(props: Props) {
  const {
    field,
    config,
    isDisabled,
    fieldErrorMessages,
    fieldModifiedMessages,
    originalValues,
    onChange,
    setFieldModifiedMessage,
    setFieldErrorMessage
  } = props;

  const getFieldKey = (props: Props): string => {
    return `${props.field.id}_${props.config.fieldIndex || 0}_${props.field.type}`;
  };

  const id = field.id;
  const cardinality = config.fieldIndex || 0;
  const fieldKey = getFieldKey(props);
  const hasErrors = _.has(fieldErrorMessages, fieldKey);
  const isModified = _.has(fieldModifiedMessages, fieldKey);
  const isRequired = !!field?.config?.required;
  const energyUnits: IEnergyUnit[] = field?.units || [];
  const values = field.values.map((value: any) => value?.unit);

  React.useEffect(() => {
    validate(field.values, originalValues);
  }, [field.values]);

  const validate = (fieldValues: FormValues[], originalValues: FormValues[]): void => {

    const generateModifiedState = () => {

      if (!_.isEqual(fieldValues, originalValues)) {

        const message: FormFieldInfoBoxModifiedMessage = {
          id: id,
          cardinality: cardinality,
          group: config.groupID,
          tab: config.tabID,
          order: config.elementIndex,
          content: {
            label: field.label,
            content: [],
          },
          modified: {}
        };

        setFieldModifiedMessage(fieldKey, message);
      } else {
        setFieldModifiedMessage(fieldKey);
      }
    };

    const generateErrorState = () => {

      const errors: string[] = [];

      if (field.config.required && _.isEmpty(values)) {
        errors.push('Cannot be empty');
      }

      if (!_.isEmpty(errors)) {
        const message = {
          id: id,
          cardinality: cardinality,
          group: config.groupID,
          tab: config.tabID,
          order: config.elementIndex,
          content: {
            label: field.label,
            content: []
          },
          errors: errors
        };

        setFieldErrorMessage(fieldKey, message);
      } else {
        setFieldErrorMessage(fieldKey);
      }
    };

    generateModifiedState();
    generateErrorState();
  };

  return (
    <FieldWrapper
      border
      hideErrorInfo
      id={ `${config.tabID}|${config.groupID}|${field.id}` }
      col={ config.fieldColSpan }
      label={ field.label }
      description={ field.description }
      required={ isRequired }
      errors={ hasErrors ? ['Cannot be empty'] : [] }
    >
      <Select
        mode='multiple'
        maxTagCount={ 'responsive' }
        allowClear
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasErrors,
          'Select-Field--has-warning border-warning': isModified && !hasErrors,
        }) }
        onChange={ (_energyUnits: string[]) => {
          onChange(field, _energyUnits.map((_energyUnit: string) => {
            return {
              unit: _energyUnit
            };
          }), config);
        } }
        placeholder={ field.columns.unit.label }
        value={ values || undefined }
        disabled={ isDisabled }
      >
        { energyUnits.map((energyUnit: IEnergyUnit) => {
          return (
            <Select.Option
              key={ `${field.id}-list-area-option-${energyUnit.unit}` }
              value={ energyUnit.unit }
            >
              { energyUnit.unit }
            </Select.Option>
          );
        }) }
      </Select>
    </FieldWrapper>
  );
};
