// Libs
import React, { useState, useEffect } from 'react';
import store from 'store/Store';
import _ from 'lodash';

// Actions
import { setQR } from 'store/UI/ActionCreators';

// Components
import OverlaySpinner from "components/overlay-spinner";

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import history from 'utils/history';

// Interfaces
import { IQR } from 'store/UI/State.interface';

interface Props {
  QR: IQR | undefined;
  hash: string | undefined;
  isAuthenticated: boolean;
};

const API: Api = new Api();

export default function QRRedirect(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.hash && !props.QR) {
      fetchQR(props.hash);
    }
  }, [props.hash]);

  const fetchQR = async (hash: string) => {
    try {

      setIsLoading(true);

      const QR: IQR = await API.get(`/qr/${hash}`);

      if (!!QR) {
        store.dispatch(setQR(QR));
      }

    } catch (error) {
      Notification('error', 'Failed to fetch QR', 'Failed');
      history.push(`/`);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? <OverlaySpinner /> : <></>;
};
