// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl, IntlShape } from 'react-intl';
import _ from 'lodash';

// Components
import Input from 'components/input';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import TermsModal from 'components/terms-modal';
import Flag from 'components/flag';
import {
  Form,
  Row,
  Col,
  Typography,
  Tooltip,
  Button,
  Select,
  Switch,
  Modal,
  Result,
  Input as AntInput
} from 'antd';

// Views
import FirstLoginModal from 'views/user/FirstLoginModal';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { logout } from 'services/auth';
import {
  getLocaleName,
  getSetting,
  getClientSetting,
  AVAILABLE_LOCALES,
  AVAILABLE_DATE_FORMATS,
  AVAILABLE_NUMBER_FORMATS,
  FIELD_DESCRIPTIONS,
} from 'services/settings';
import { validatePasswordStrength, validPhone } from 'utils/utils';

// Icons
import { ReactComponent as AvatarIcon } from 'assets/svg/avatar.svg';

// Interfaces
import { UserEntity, PhoneObject } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Actions
import { setUserSetting, receiveMe } from 'store/User/ActionCreators';
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();
const { Title, Text, Link } = Typography;
const { Option } = Select;
const fallbackPhoneObject: PhoneObject = {
  country_code: 'GB',
  phone_number: '',
  dial_code: '44'
};

const messages = defineMessages({
  settings: {
    id: 'general.settings',
    defaultMessage: 'Settings',
    description: '',
  },
  default: {
    id: 'general.default',
    defaultMessage: 'Default',
    description: '',
  },
  notification: {
    id: 'general.notification',
    defaultMessage: 'Notification',
    description: '',
  },
  first_name: {
    id: 'settings.first_name',
    defaultMessage: 'First name',
    description: '',
  },
  last_name: {
    id: 'settings.last_name',
    defaultMessage: 'Last name',
    description: '',
  },
  email: {
    id: 'settings.email',
    defaultMessage: 'Email',
    description: '',
  },
  new_password: {
    id: 'settings.new_password',
    defaultMessage: 'New password',
    description: '',
  },
  current_password: {
    id: 'settings.current_password',
    defaultMessage: 'Current password',
    description: '',
  },
  save_changes: {
    id: 'settings.save_changes',
    defaultMessage: 'Save changes',
    description: '',
  },
  date_format: {
    id: 'settings.date_format',
    defaultMessage: 'Date Format',
    description: '',
  },
  system_language: {
    id: 'settings.system_language',
    defaultMessage: 'System Language',
    description: '',
  },
  currency: {
    id: 'settings.currency',
    defaultMessage: 'Currency',
    description: '',
  },
  number_format: {
    id: 'settings.number_format',
    defaultMessage: 'Number Format',
    description: '',
  },
  landing_page: {
    id: 'settings.landing_page',
    defaultMessage: 'Landing Page',
    description: 'The default page user is redirected to when log in',
  },
  measurement: {
    id: 'settings.measurement',
    defaultMessage: 'Measurement',
    description: '',
  },
  results_per_page: {
    id: 'settings.results_per_page',
    defaultMessage: 'Results Per Page',
    description: '',
  },
  tooltip_disabled: {
    id: 'settings.tooltip_disabled',
    defaultMessage: "You're not allowed to change this",
    description: '',
  },
  setting_changed_success: {
    id: 'settings.setting_changed_success',
    defaultMessage: 'Saved new settings',
    description: '',
  },
  setting_changed_failed: {
    id: 'settings.setting_changed_failed',
    defaultMessage: 'Failed to update settings',
    description: '',
  },
  password_changed_success: {
    id: 'settings.password_changed_success',
    defaultMessage: 'Succesfully changed password',
    description: '',
  },
  password_changed_failed: {
    id: 'settings.password_changed_failed',
    defaultMessage: "Couldn't change password, please try again later",
    description: '',
  },
});

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

interface Country {
  id: number;
  title: string;
  code: string;
  dial_code: string;
};

interface Measurement {
  id: number;
  title: string;
  unit: string;
  symbol: string;
  conversion: string;
};

interface ResultsPerPage {
  id: number;
  title: string;
  unit: string;
  symbol: string;
  conversion: string;
};

interface Props {
  user: UserEntity;
  hasSessionExpired: boolean;
  client_id: number;
  client_name: string;
  setUserSetting: (setting: string, value: any) => Promise<unknown>;
  setUser: (user: UserEntity) => void;
  intl: IntlShape;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  new_password: string;
  current_password: string;
  phone_object: Partial<PhoneObject> | null;
  countries: Country[];
  currencies: Currency[];
  measurements: Measurement[];
  resultsPerPage: ResultsPerPage[];
  isLoading: boolean;
  isLoadingPassword: boolean;
  isLoadingSettings: boolean;
  isDiactivating: boolean;
  isSavingPhone: boolean;
  isSavingLandingPage: boolean;
  showDiactivationModal: boolean;
  showGDPRConditions: boolean;
  firstLogin: boolean;
  landing_page: string;
};

class Settings extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    new_password: '',
    current_password: '',
    phone_object: this.props.user?.phone_object ? _.cloneDeep(this.props.user.phone_object) : fallbackPhoneObject,
    countries: [],
    currencies: [],
    measurements: [],
    resultsPerPage: [],
    isLoading: false,
    isLoadingPassword: false,
    isLoadingSettings: false,
    isSavingPhone: false,
    isDiactivating: false,
    isSavingLandingPage: false,
    showDiactivationModal: false,
    showGDPRConditions: false,
    firstLogin: false,
    landing_page: !!getSetting(this.props.user.settings, 'landing_page') ? getSetting(this.props.user.settings, 'landing_page') : '',
  };

  componentDidMount = async () => {
    const { client_id } = this.props;
    const { isLoading } = this.state;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Settings', path: '/user/settings' },
    ], false);

    if (!isLoading) {
      try {

        await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
        const countries = await API.get(`client/${client_id}/available_countries`);
        const currencies = await API.get(`client/${client_id}/available_currencies`);
        const measurements = await API.get(`client/${client_id}/available_measurements`);

        this.mounted && this.setState({
          countries: countries,
          currencies: currencies,
          measurements: measurements,
        });
      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoading: false
        });
      }

      if (!this.props.hasSessionExpired && !this.props.user?.last_login && !this.state.firstLogin) {
        this.setState({
          isLoading: true
        }, () => {
          setTimeout(() => {
            this.mounted && this.setState({
              isLoading: false,
              firstLogin: true,
            });
          }, 1000);
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  setSettingAttribute = (key: string, value: string) => {
    const { intl: { formatMessage }, setUserSetting } = this.props;

    this.setState({
      isLoadingSettings: true
    }, () => {
      setUserSetting(key, value)
        .then(() => {
          Notification('success', formatMessage(messages.setting_changed_success), formatMessage(messages.notification));
        })
        .catch(() => {
          Notification('error', formatMessage(messages.setting_changed_failed), formatMessage(messages.notification));
        })
        .finally(() => {
          this.mounted && this.setState({
            isLoadingSettings: false
          });
        });
    });
  };

  updatePassword = () => {
    const { intl: { formatMessage } } = this.props;
    const { new_password, current_password } = this.state;

    this.setState({
      isLoadingPassword: true
    }, () => {
      API.updatePassword(current_password, new_password)
        .then(() => {
          Notification('success', formatMessage(messages.password_changed_success));
          this.mounted && this.setState({
            new_password: '',
            current_password: ''
          });
        })
        .catch((error: any) => {
          Notification('error', error.response.data ? error.response.data : formatMessage(messages.password_changed_failed));
        })
        .finally(() => {
          this.mounted && this.setState({
            isLoadingPassword: false
          });
        });
    });

  };

  updatePhone = async () => {
    const { setUser } = this.props;
    const { phone_object } = this.state;

    if (!phone_object) return;

    try {
      await new Promise((resolve) => this.setState({ isSavingPhone: true }, () => resolve(null) ));

      const user = await API.put(`user/update_phone`, {
        code: phone_object.dial_code,
        phone: phone_object.phone_number,
      });

      setUser(user);

      Notification('success', 'Saved phone number');

    } catch (error) {
      Notification('error', 'Failed to save phone number');
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isSavingPhone: false,
      });
    }
  };

  updateLandingPage = async () => {
    const { setUser, client_id } = this.props;
    const { landing_page } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isSavingLandingPage: true }, () => resolve(null) ));

      const user = await API.put(`user/update_landing_page`, {
        landing_page: landing_page,
        client_id: client_id
      });

      setUser(user);

      Notification('success', 'Saved landing page');

      this.mounted && this.setState({
        isSavingLandingPage: false,
        landing_page: getSetting(user.settings, 'landing_page') ? getSetting(user.settings, 'landing_page') : ''
      });
    } catch (error) {
      Notification('error', 'Failed to save landing page');
      console.error('Error: ', error);
    }
  };

  renderDeactivateConfirmationDialog = () => {
    const { user, client_id } = this.props;
    const { isDiactivating } = this.state;
    return (
      <Modal
        title={ 'Deactivate Account' }
        closable={ false }
        maskClosable={ !isDiactivating }
        centered
        visible
        onCancel={ () => this.setState({ showDiactivationModal: false, isDiactivating: false }) }
        okText={ 'Deactivate' }
        onOk={ async () => {
          try {

            await new Promise((resolve) => this.setState({ isDiactivating: true }, () => resolve(null) ));
            await API.delete(`client/${client_id}/user/${user.id}`);

            logout();

          } catch (error) {
            Notification('error', 'Failed to deactivate account');
            console.error('Error: ', error);
          } finally {
            this.setState({
              showDiactivationModal: false,
              isDiactivating: false,
            });
          }
        } }
        okButtonProps={{
          danger: true,
          disabled: !!isDiactivating,
          loading: !!isDiactivating,
        }}
        cancelButtonProps={{
          disabled: !!isDiactivating,
        }}
      >
        <p>Are you sure you want to deactivate your account?</p>
      </Modal>
    );
  };

  renderSettings = () => {
    const { intl: { formatMessage }, user } = this.props;
    const {
      phone_object,
      new_password,
      countries,
      currencies,
      measurements,
      current_password,
      isLoadingPassword,
      isLoadingSettings,
      isSavingPhone,
      isSavingLandingPage,
      landing_page,
    } = this.state;

    const number_format = getSetting(user.settings, 'number_format');
    const date_format = getSetting(user.settings, 'date_format');
    const locale = getSetting(user.settings, 'locale');
    const currency = getSetting(user.settings, 'currency');
    const measurement = getSetting(user.settings, 'measurement');
    const results_per_page = getSetting(user.settings, 'results_per_page');
    const public_email = getSetting(user.settings, 'public_email');
    const public_phone = getSetting(user.settings, 'public_phone');
    const original_landing_page = getSetting(user.settings, 'landing_page') ?  getSetting(user.settings, 'landing_page') : '';

    const number_format_placeholder = `${AVAILABLE_NUMBER_FORMATS[getClientSetting('number_format')]}`;
    const date_format_placeholder = `${getClientSetting('date_format')}`;
    const locale_placeholder = `${getLocaleName(getClientSetting('locale'))}`;

    const fallbackCurrency = currencies.find((_currency: Currency) => _currency.code === getClientSetting('currency'));
    const currency_placeholder = fallbackCurrency ? `Default: ${fallbackCurrency.title} (${fallbackCurrency.code})` : 'Default: ()';

    const fallbackMeasurement = measurements.find((_measurement: Measurement) => _measurement.unit === getClientSetting('measurement'));
    const measurement_placeholder = fallbackMeasurement ? `Default: ${fallbackMeasurement.title} (${fallbackMeasurement.unit})` : 'Default: ()';

    const passwordErrors = !!new_password ? validatePasswordStrength(new_password) : [];
    const validPhoneNumber = !!phone_object?.phone_number ? phone_object.phone_number.length > 5 && validPhone(`+${phone_object.dial_code}${phone_object.phone_number}`) : true;

    return (
      <div className='Layout-box'>
        <div className="pT-50 pB-50" style={{ maxWidth: 700, margin: '0 auto' }}>
          <Row className='mT-50'>
            <Col flex='2'>
              <Title level={2} style={{ fontWeight: 400 }}>{user.first_name} {user.last_name}</Title>
            </Col>
            <Col flex='100px'>
              <Icon style={{ fontSize: '100px' }} component={AvatarIcon} />
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col span={24}>
              <Form
                layout={ 'vertical' }
                autoComplete="off"
                initialValues={{
                  'country_code': phone_object?.country_code
                }}
              >
                <Form.Item label={formatMessage(messages.first_name)}>
                  <Tooltip placement='topLeft' title={formatMessage(messages.tooltip_disabled)}>
                    <div>
                      <Input isDisabled value={user.first_name} isPlain />
                    </div>
                  </Tooltip>
                </Form.Item>
                <Form.Item label={formatMessage(messages.last_name)}>
                  <Tooltip placement='topLeft' title={formatMessage(messages.tooltip_disabled)}>
                    <div>
                      <Input isDisabled value={user.last_name} isPlain />
                    </div>
                  </Tooltip>
                </Form.Item>
                <Form.Item label={formatMessage(messages.email)}>
                  <Tooltip placement='topLeft' title={formatMessage(messages.tooltip_disabled)}>
                    <div>
                      <Input isDisabled value={ user.email } isPlain />
                    </div>
                  </Tooltip>
                </Form.Item>
                <Form.Item
                  label="Phone number"
                >
                  <Input
                    prefix={ (
                      <>
                        <Form.Item
                          name="country_code"
                          noStyle
                        >
                          <Select
                            showSearch
                            bordered={ false }
                            disabled={ isSavingPhone }
                            style={{ width: 120 }}
                            dropdownMatchSelectWidth={ false }
                            optionLabelProp={ 'label' }
                            filterOption={ (input: any, option: any) => {
                              const country = countries.find((country: Country) => country.code === option.value);
                              return _.toLower(country?.title).indexOf(_.toLower(input)) >= 0;
                            } }
                            onChange={ (country_code: string) => {
                              const country = countries.find((country: any) => country.code === country_code);
                              this.setState({
                                phone_object: phone_object && Object.assign(phone_object, { dial_code: `+${country?.dial_code}` })
                              });
                            } }
                            // @ts-ignore
                            autoComplete="none"
                          >
                            { !_.isEmpty(countries) && countries
                              .sort((a: any, b: any) => a.title.localeCompare(b.title))
                              .map((country: any) => (
                                <Option key={ country.id } value={ country.code } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                                  <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                                </Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                        <span className="op-50p">(0)</span>
                      </>
                    ) }
                    isPlain
                    isDisabled={ isSavingPhone }
                    defaultValue={ phone_object?.phone_number }
                    onChange={ (phonenumber: string) => {
                      this.setState({
                        phone_object: {
                          ...phone_object,
                          phone_number: phonenumber
                        }
                      });
                    } }
                    suffix={ validPhoneNumber && !_.isEqual(phone_object, user?.phone_object || fallbackPhoneObject) ? (
                      <Button disabled={ isSavingPhone } onClick={ () => this.updatePhone() } type="link">{ 'SAVE CHANGES' }</Button>
                    ) : (
                      <span style={{ height: 32 }} />
                    ) }
                  />
                </Form.Item>
                <Form.Item label={formatMessage(messages.new_password)}>
                  <Input
                    isPlain
                    type='password'
                    value={ new_password }
                    onChange={ (value: string) => this.setState({ new_password: value }) }
                  />
                  { !_.isEmpty(passwordErrors) &&
                    <ul style={{ paddingTop: 15, paddingLeft: 25 }}>
                      { passwordErrors.map((error: string, index: number) => <li key={ index } className="ant-text-danger">{ error }</li>) }
                    </ul>
                  }
                </Form.Item>
                { !!new_password && _.isEmpty(passwordErrors) &&
                  <div>
                    <Form.Item label={formatMessage(messages.current_password)}>
                      <Input
                        isPlain
                        type='password'
                        value={ current_password }
                        onChange={ (value: string) => this.setState({ current_password: value }) }
                      />
                    </Form.Item>
                    { !!current_password &&
                      <Button loading={isLoadingPassword} danger block shape='round' onClick={ this.updatePassword }>{ !isLoadingPassword ? <FormattedMessage id='settings.save_changes' defaultMessage='SAVE CHANGES' /> : ' '}</Button>
                    }
                  </div>
                }
              </Form>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.landing_page) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['landing_page'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <AntInput
                style={{ width: 400 }}
                value={ landing_page }
                addonBefore={ `${window.location.origin}` }
                suffix={ !_.isEqual(landing_page, original_landing_page) ? (
                  <Button style={{ height: 22, padding: 0 }} disabled={ isSavingLandingPage } onClick={ () => this.updateLandingPage() } type="link">{ 'SAVE' }</Button>
                ) : (
                  <span />
                ) }
                onChange={ (event: BaseSyntheticEvent | null) => this.setState({ landing_page: event?.target.value }) }
              />
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.number_format) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['number_format'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                loading={isLoadingSettings}
                disabled={isLoadingSettings}
                defaultValue={ number_format || undefined }
                placeholder={ `Default: ${number_format_placeholder}` }
                style={{ width: 350 }}
                onChange={value => {
                  this.setSettingAttribute('number_format', value);
                }}>
                { Object.keys(AVAILABLE_NUMBER_FORMATS).map((_numberFormat: string) => {
                  const isDefault = AVAILABLE_NUMBER_FORMATS[_numberFormat] === number_format_placeholder;
                  return <Option key={_numberFormat} value={_numberFormat}>{ `${AVAILABLE_NUMBER_FORMATS[_numberFormat]} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.date_format) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['date_format'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ date_format || undefined }
                placeholder={ `Default: ${date_format_placeholder}` }
                style={{ width: 200 }}
                onChange={value => {
                  this.setSettingAttribute('date_format', value);
                }}>
                { AVAILABLE_DATE_FORMATS.map((_dateFormat: string) => {
                  const isDefault = _dateFormat === date_format_placeholder;
                  return <Option key={ _dateFormat } value={ _dateFormat }>{ `${_dateFormat} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.system_language) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['locale'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ locale || undefined }
                placeholder={ `Default: ${locale_placeholder}` }
                style={{ width: 200 }}
                onChange={value => {
                  this.setSettingAttribute('locale', value);
                }}>
                { AVAILABLE_LOCALES.map((_locale: string) => {
                  const isDefault = getLocaleName(_locale) === locale_placeholder;
                  return <Option key={_locale} value={_locale}>{ `${getLocaleName(_locale)} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.currency) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['currency'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                showSearch
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ currency || undefined }
                placeholder={ currency_placeholder }
                style={{ width: 250 }}
                onChange={value => {
                  this.setSettingAttribute('currency', value);
                }}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { currencies.map((_currency: Currency, index: number) => {
                  return <Option key={ index } value={ _currency.code }>{ `${_currency.title} (${_currency.code})` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.measurement) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['measurement'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ measurement || undefined }
                placeholder={ measurement_placeholder }
                style={{ width: 250 }}
                onChange={value => {
                  this.setSettingAttribute('measurement', value);
                }}>
                { measurements.map((_measurement: Measurement, index: number) => {
                  return <Option key={ index } value={ _measurement.unit }>{ `${_measurement.title} (${_measurement.unit})` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { formatMessage(messages.results_per_page) }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['results_per_page'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ results_per_page || undefined }
                placeholder="Default: 25"
                style={{ width: 250 }}
                onChange={value => {
                  this.setSettingAttribute('results_per_page', value);
                }}>
                 <Option value="10">10</Option>
                 <Option value="25">25</Option>
                 <Option value="50">50</Option>
                 <Option value="100">100</Option>
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                Show email on profile
              </Text>
            </Col>
            <Col flex='3 align-items: right'>
              <Switch
                disabled={ isLoadingSettings }
                checked={ public_email === 'true' }
                onChange={ checked => {
                  this.setSettingAttribute('public_email', checked ? 'true' : 'false');
                }}
              />
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                Show phone number on profile
              </Text>
            </Col>
            <Col flex='3 align-items: right'>
              <Switch
                disabled={ isLoadingSettings }
                checked={ public_phone === 'true' }
                onChange={ checked => {
                  this.setSettingAttribute('public_phone', checked ? 'true' : 'false');
                }}
              />
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Link onClick={ () => this.setState({ showGDPRConditions: true }) }>
                <Text className="primaryColor"strong>
                  GDPR Consent
                </Text>
              </Link>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['gdpr'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col flex='3 align-items: right'>
              <Switch
                disabled={ isLoadingSettings }
                checked
                onChange={ () => this.setState({ showDiactivationModal: true }) }
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render = () => {

    if (process.env.REACT_APP_ENVIRONMENT === 'training') {
      return (
        <div className="d-f jc-c ai-c mT-20 mX-30 h-100p">
          <Result title="Disabled For Training" />
        </div>
      );
    }

    return (
      <BlockingSpinner isLoading={ this.state.isLoading }>
        <Jumbotron
          title={ this.props.intl.formatMessage(messages.settings) }
          tabs={[
            {
              label: '',
              node: this.renderSettings(),
            }
          ]}
        />
        { this.state.firstLogin &&
          <FirstLoginModal
            clientName={ this.props.client_name }
            onClose={ () => {
              this.setState({ firstLogin: false }, async () => {
                const user = await API.get(`user`);
                this.props.setUser(user);
              });
            } }
          />
        }
        { this.state.showDiactivationModal && this.renderDeactivateConfirmationDialog() }
        { this.state.showGDPRConditions && <TermsModal type={ 'gdpr' } onClose={ () => this.setState({ showGDPRConditions: false }) } /> }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    hasSessionExpired: store.UserState.hasSessionExpired,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
    client_name: store.ClientState.name,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserSetting: (setting: string, value: any) => dispatch(setUserSetting(API, setting, value)),
    setUser: (user: UserEntity) => dispatch(receiveMe(user)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Settings));
