// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import AuditActionContainer from 'containers/ehs/action/AuditActionContainer';
import IncidentActionContainer from 'containers/ehs/action/IncidentActionContainer';
import HelpDeskActionContainer from 'containers/ehs/action/HelpDeskActionContainer';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Views
import Actions from 'views/ehs/Actions';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

interface Props {
  permissions: UserPermissions;
};

class ActionContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/ehs/actions', '/ehs/actions/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'action_audit_action_view_list') }>
                <Actions />
              </RestrictionWrapper>
              <Route
                exact
                path='/ehs/actions/create'
                render={ () => (
                  <RestrictionWrapper restricted={ !hasPermission(permissions, 'action_audit_action_create') }>
                    <ListViewCreateModal
                      bundle={ 'action' }
                      type={ 'audit-action' }
                      redirectUrl={ '/ehs/actions' }
                    />
                  </RestrictionWrapper>
                ) }
              />
            </>
          );
        } } />

        <Redirect exact from="/ehs/actions/audit-action" to="/ehs/actions" />

        <Route path="/ehs/actions/audit-action/:record_id" component={ AuditActionContainer } />
        <Route path="/ehs/actions/incident-action/:record_id" component={ IncidentActionContainer } />
        <Route path="/ehs/actions/help-desk-action/:record_id" component={ HelpDeskActionContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions
  };
};

export default connect(mapStateToProps, null)(ActionContainer);
