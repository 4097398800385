// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Select } from 'antd';

// Interfaces
import {
  IField,
  IRating,
} from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';

interface Props {
  field: IField;
  isRequired: boolean;
  isModified: boolean;
  isDisabled: boolean;
  hasErrors: boolean;
  handleChange: (field: IField) => void;
};

export default function RatingType({
  field,
  isModified,
  isDisabled,
  hasErrors,
  handleChange,
}: Props) {

  const ratings: IRating[] = field?.energy_ratings || [];
  const value = !_.isEmpty(field.values) && _.has(field.values[0], 'energy_rating_id') ? field.values[0].energy_rating_id : undefined;

  return (
    <Select
      style={{ minWidth: 80 }}
      allowClear
      className={ classNames('Select-Field', {
        'Select-Field--has-error border-danger': !!hasErrors,
        'Select-Field--has-warning border-warning': isModified && !hasErrors,
      }) }
      dropdownMatchSelectWidth={ false }
      disabled={ isDisabled }
      placeholder={ 'Rating Type' }
      onChange={ (id: string | number) => {
        const values = !_.isEmpty(field.values) ? _.cloneDeep(field.values[0]) : [];
        handleChange(_.set(field, 'values', [{
          ...values,
          energy_rating_id: id
        }]));
      } }
      onClear={ () => {
        const values = !_.isEmpty(field.values) ? _.cloneDeep(field.values[0]) : [];
        handleChange(_.set(field, 'values', [{
          ...values,
          energy_rating_id: null
        }]));
      } }
      value={ value }
    >
      { ratings.map((rating: IRating, index: number) => {
        return (
          <Select.Option
            key={ index }
            value={ rating.id }
          >
            { rating?.title || '-' }
          </Select.Option>
        );
      }) }
    </Select>
  );
};
