// Libs
import React, { BaseSyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Button, Input, Popconfirm, Select, Table, Tooltip, TreeSelect, Typography } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';

// Icons
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Utils
import { isBlank, formatCostToNumber, isNumeric, flattenSet } from 'utils/utils';
import { getFormatedNumber } from 'services/settings';

// Interfaces
import {
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage
} from 'components/form/form-wrapper';
import {
  Field,
  FieldValue,
  ErrorState,
  Modified,
  CoaOption,
  FinanceTemplate,
  PresetValue,
  IColumnType,
  IColumn,
} from 'components/form/field/cost/Cost.interfaces';

// Styles
import './Cost.scss';

const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();
const { Text } = Typography;

interface Props {
  isNew: boolean;
  numberFormat: any;
  originalValues: Field['values']
  field: Field;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onValueChange(state: Field['values']): void;
  border?: boolean;
  height?: number;
};

interface State {
  tableHeight: number;
};

class CostField extends React.Component<Props, State> {

  component: any = null; // This is used to figure out when the component is rendered

  state: State = {
    tableHeight: this.props.height || 0,
  };

  getBlankState = (field: Field, presetValue?: PresetValue): Partial<FieldValue> => {

    const newState: any = {
      key: uuidv4(),
      currency_code: field?.currency?.code || '',
    };

    if (field?.config?.columns && !_.isEmpty(field?.config?.columns)) {
      field.config.columns
        .filter((column: IColumn) => !column?.hidden && !column?.locked)
        .forEach((column: IColumn) => {
          newState[column.reference] = null;
        });
    }

    if (!!field?.currency?.code) {
      newState.currency_code = field.currency.code;
    }

    if (field?.default_vat) {
      newState.vat = field.default_vat.toString();
    }

    if (presetValue) {
      newState.service = `${presetValue.target_bundle}|${presetValue.target_type}|${presetValue.target_id}`;
      newState.unit_cost = formatCostToNumber(presetValue.unit_cost || '', this.props.numberFormat.thousandSeparator, this.props.numberFormat.decimalSeparator).toString();
    }

    return newState;
  };

  componentDidMount = () => {
    const { isNew, originalValues } = this.props;

    //initialise only if it is a new record
    if (isNew || _.isEmpty(originalValues)) {
      this.initialiseWithParentValues();
    }

    if (this.component && !this.props.height) {
      this.heightObserver();
    }

    this.validate(this.props.field.values);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.field, field)) {

      if (this.component && !this.props.height) {
        this.heightObserver();
      }

      this.validate(this.props.field.values);

      this.scrollToBottom();
    }
  };

  heightObserver = () => {
    const height: number = document.getElementById('CostTable')?.offsetHeight || 0;

    if (this.state.tableHeight !== height) {
      this.setState({
        tableHeight: height - 50
      });
    }
  };

  scrollToBottom = () => {
    const table = document.querySelector('.CostField div.ant-table-body') as HTMLElement;
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
  };

  initialiseWithParentValues = () => {
    const { field } = this.props;
    const { preset_values } = field;
    const initValues: any[] = [];

    preset_values?.forEach((presetValue: PresetValue) => {
      if (!this.checkCoaIsPreset(presetValue)) {
        initValues.push(
          this.getBlankState(
            this.props.field,
            presetValue
          )
        );
      }
    });

    if (!_.isEmpty(initValues)) {
      this.handleChange([...initValues]);
    }
  };

  getErrors = (columns: IColumn[] = [], values: FieldValue[]): ErrorState => {
    const requiredColumns = columns.filter((column: IColumn) => !column?.hidden && !column?.locked && !!column?.required);
    const errors: ErrorState = {};

    values.forEach((row: any) => {

      const rowErrors = Object.keys(row)
        .filter((key: string) => {
          return requiredColumns
            .find((requiredColumn: IColumn) => {

              if (requiredColumn.reference === key) {
                switch (requiredColumn.type) {
                  case IColumnType.Currency:
                  case IColumnType.Number:
                    return _.has(row, requiredColumn?.reference) && !isNumeric(parseFloat(row[key]));
                  case IColumnType.Text:
                  case IColumnType.Service:
                    return _.has(row, requiredColumn?.reference) && isBlank(row[key]);
                  default:
                    return !row[key];
                }
              }

              return false;
            });
        });

        if (!_.isEmpty(rowErrors)) {
          errors[row.key] = rowErrors;
        }
    });

    return errors;
  };

  getModified = (columns: IColumn[], values: FieldValue[], originalValues: FieldValue[]): Modified => {
    const modified: Modified = {};

    const compareObjects = (columns: IColumn[], o1: any, o2: any) => {
      return Object.keys(o2)
        .filter((key: string) => {
          const column = columns.find((column: IColumn) => column.reference === key);
          return !column?.hidden;
        })
        .reduce((diff, key) => {
          const column = columns.find((column: IColumn) => column.reference === key);

          let value1 = o1[key];
          let value2 = o2[key];

          if (column) {
            switch (column.type) {
              case IColumnType.Number:
              case IColumnType.Percentage:
              case IColumnType.Currency:
                value1 = parseInt(value1);
                value2 = parseInt(value2);
              break;
            }
          }

          if (value1 === value2) {
            return diff;
          }

          return {
            ...diff,
            [key]: o2[key]
          };
        }, {});
    };

    // check if values have changed
    if (!_.isEqual(values, originalValues)) {

      // Something's been removed
      const newStateKeys: string[] = values.map((value: FieldValue) => value.key);
      if (originalValues.some((_value: FieldValue) => !newStateKeys.includes(_value.key))) {
        modified['removed'] = null;
      }

      values.forEach((row: FieldValue) => {
        const originalRow: FieldValue | undefined = originalValues.find((originalRow: FieldValue) => originalRow.key === row.key);
        const rowKeys: string[] = Object.keys(row);

        if (originalRow) {
          const diff: any = compareObjects(columns, originalRow, row);

          if (!_.isEmpty(diff)) {
            modified[row.key] = Object.keys(diff);
          }
        } else {
          // New row
          modified[row.key] = rowKeys;
        }
      });
    }

    return modified;
  };

  validate = (values: FieldValue[]): void => {
    const { field, config, originalValues, setFieldErrorMessage, setFieldModifiedMessage } = this.props;
    const columns = field?.config?.columns || [];
    const errors: ErrorState = this.getErrors(columns, values);
    const modified: Modified = this.getModified(columns, values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(`${field.id}`, errorMessage);
    setFieldModifiedMessage(`${field.id}`, modifiedMessage);
  };

  hasError = (rowKey: FieldValue['key'], fieldKey: keyof FieldValue): boolean => {
    const { field, fieldErrorMessages } = this.props;
    return (
      _.has(fieldErrorMessages, [field.id, 'errors', rowKey]) &&
      fieldErrorMessages[field.id]['errors'][rowKey].includes(fieldKey)
    );
  };

  isModified = (fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>, rowKey: FieldValue['key'], fieldKey: keyof FieldValue): boolean => {
    const fieldId = this.props.field.id;
    return (
      _.has(fieldModifiedMessages, [fieldId, 'modified', rowKey]) &&
      fieldModifiedMessages[fieldId]['modified'][rowKey].includes(fieldKey)
    );
  };

  checkCoaIsPreset = (presetValue: PresetValue): FieldValue | undefined => {
    return this.props.field.values.find(value => value.service === `${presetValue.target_bundle}|${presetValue.target_type}|${presetValue.target_id}`);
  };

  calculateTotalAvailable = (field: Field): number | null => {
    return field.values?.reduce((total: number, row: FieldValue) => {
      const coa = this.getCoa(field, row?.service);
      const availableValue = !!coa && coa?.available_value ? coa.available_value : 0;
      const formattedAvailableValue = formatCostToNumber(availableValue?.toString() ?? '0', this.props.numberFormat.thousandSeparator, this.props.numberFormat.decimalSeparator);

      return total + (formattedAvailableValue || 0);
    }, 0);
  };

  calculateTotalSpent = (field: Field): number | null => {
    return field.values?.reduce((total: number, row: FieldValue) => {
      const coa = this.getCoa(field, row?.service);
      const spentValue = !!coa && coa?.spent_value ? coa.spent_value : 0;
      const formattedSpentValue = formatCostToNumber(spentValue?.toString() ?? '0', this.props.numberFormat.thousandSeparator, this.props.numberFormat.decimalSeparator);

      return total + (formattedSpentValue || 0);
    }, 0);
  };

  calculateTotalFromKey = (data: any, reference: string, numberFormat: any): number => {
    return data.reduce((acc: number, curr: any) => {
      const currFormatted = _.has(curr, reference) ? formatCostToNumber(`${curr[reference] || 0}`, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : 0;
      return acc += currFormatted;
    }, 0);
  };

  canAddNewRow = (): boolean => {
    const { field, isDisabled } = this.props;

    if (_.isEmpty(field.currency) || isDisabled) {
      return false;
    }

    return field.config.can_create;
  };

  handleChange = (values: FieldValue[]): void => {
    this.props.onValueChange(values);
    this.validate(values);
  };

  modifyValue = (identifier: FieldValue['key'], values: FieldValue[], newValue: {}): FieldValue[] => {
    return values.map((value: FieldValue) => {
      if (value.key === identifier) {
        value = {
          ...value,
          ...newValue
        };
      }

      return {
        ...value,
      };
    });
  };

  delete = (identifier: FieldValue['key'], items: FieldValue[]): FieldValue[] => {
    return items.filter((value: FieldValue) => value.key !== identifier);
  };

  getCoa = (field: Field, service: string | null): CoaOption | null => {
    const serviceColumn = field?.config?.columns && field.config.columns.find((column: IColumn) => column.type === IColumnType.Service);
    const coaParts = this.getServiceCoa(service);

    if (coaParts && serviceColumn && !_.isEmpty(serviceColumn?.options)) {
      return flattenSet(serviceColumn.options).find((coa: CoaOption) => coa.bundle === coaParts.bundle && coa.type === coaParts.type && `${coa.id}` === coaParts.id);
    }
    return null;
  };

  handleColumnChange = (field: Field, newRowValues: FieldValue) => {
    const prevRow = field.values.find((_row: FieldValue) => _row.key === newRowValues.key);
    if (!_.isEqual(prevRow, newRowValues)) {
      this.handleChange(this.modifyValue(newRowValues.key, _.cloneDeep(field.values), newRowValues));
    }
  };

  getServiceCoa = (service: string | null) => {
    if (!service) {
      return null;
    }

    const parts = service.split('|');

    if (_.isEmpty(parts)) {
      return null;
    }

    return {
      bundle: parts[0],
      type: parts[1],
      id: parts[2],
    };
  };

  getFinanceTemplate = (field: Field, financeTemplateId: number | undefined): FinanceTemplate | undefined => {
    if (!financeTemplateId) {
      return undefined;
    }

    const financeTemplateColumn = field?.config?.columns && field.config.columns.find((column: IColumn) => column.type === IColumnType.FinanceTemplate);
    const financeTemplates = financeTemplateColumn && financeTemplateColumn?.options || [];
    return financeTemplates.find(_financeTemplate => _financeTemplate.id === financeTemplateId);
  };


  calculateFormula = (field: Field, reference: string, row: any) => {

    const configColumns = field?.config?.columns || [];
    const column = configColumns.find((column: IColumn) => column.reference === reference);
    const coa = this.getCoa(field, row?.service);

    let formula: string = column?.formula || '';
    const tags = formula.match(/\[(.*?)\]/g);

    if (formula && tags) {
      tags.forEach((tag: string) => {
        const reference = tag.substring(1, tag.length - 1);
        let value = 0;

        switch (reference) {
          case 'coa:spent_value':
            value = coa?.spent_value ? coa.spent_value : 0;
          break;
          case 'coa:available_value':
            value = coa?.available_value ? coa.available_value : 0;
          break;
          default:
            const refColumn = configColumns.find((configColumn: IColumn) => configColumn.reference === reference);

            if (!!refColumn && reference) {
              if (_.has(row, reference) && !refColumn?.formula) {
                value = parseFloat(row[reference]) || 0;
              } else {
                value = this.calculateFormula(field, reference, row);
              }
            }
          break;
        }

        formula = formula && formula.replaceAll(tag, `${value}`);
      });
    }

    const { error, result } = parser.parse(formula);

    if (!error) {
      return result;
    }

    return 0;
  };

  renderColumn = (column: IColumn, reference: any, row: any, type: IColumnType | undefined, placeholder: string, isLocked: boolean, isRequired: boolean) => {
    const { field, numberFormat, isDisabled, fieldModifiedMessages} = this.props;

    const values: FieldValue[] = field.values || [];
    const decimal: number | undefined = _.has(field, 'config.decimal') ? field.config.decimal : 2;
    const hasErrors: boolean = this.hasError(row.key, reference);
    const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, reference);
    const errorMessage: string = field?.preset_values_error ? field.preset_values_error : '';

    let value = _.has(row, reference) ? row[reference] : undefined;

    if (column?.formula) {
      value = this.calculateFormula(field, column.reference, row);
    }

    const isNegative = value < 0;

    switch (type) {
      case IColumnType.Service:

        const financeTemplate = this.getFinanceTemplate(field, row?.finance_template_id);
        const coaServices: any = column?.options;

        const excludeIds = financeTemplate?.coa_map.filter((coaKey: string) => {
          if (row.service === coaKey) {
            return false;
          }
          return true;
        });

        const generateCoaTree = (data: CoaOption[] = [], excludeIds: string[] | undefined = []): CoaOption[] | any[] => {
          return data
            .map((coa: CoaOption) => {
              const isDisabled = excludeIds.includes(`${coa.bundle}|${coa.type}|${coa.id}`);
              return {
                'key': `${coa.bundle}|${coa.type}|${coa.id}`,
                ...coa,
                'id': coa.id,
                'value': `${coa.bundle}|${coa.type}|${coa.id}`,
                'title': coa.title,
                'disabled': isDisabled,
                'children': !!coa?.children ? generateCoaTree(coa?.children, excludeIds) : null,
              };
            });
        };

        const tree = generateCoaTree(coaServices, excludeIds);

        return (
          <TreeSelect
            showSearch
            allowClear
            dropdownMatchSelectWidth={ false }
            style={{ width: '100%' }}
            placeholder={ '-' }
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            value={ value }
            treeData={ tree }
            disabled={ isDisabled || isLocked }
            filterTreeNode={ (input: string, option: any) => {

              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }

              return false;
            } }
            onChange={ (selectedCoaService: string) => {
              // It should not be possible to select the same service twice
              if (values.every((row: FieldValue) => row.service !== selectedCoaService)) {
                const newRowValues = { ...row, service: selectedCoaService };
                this.handleColumnChange(field, newRowValues);
              };
            } }
          />
        );

      case IColumnType.FinanceTemplate:
        const templates = column?.options || [];
        return (
          <Select
            showSearch
            allowClear
            style={ { width: '100%' } }
            disabled={ isDisabled || isLocked }
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            placeholder={ '-' }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            onChange={ (finance_template_id: number | undefined) => {
              const newRowValues = { ...row, finance_template_id: finance_template_id };
              this.handleColumnChange(field, newRowValues);
            } }
            value={ value }
          >
            { templates.map((financeTemplate: FinanceTemplate) => (
              <Select.Option key={ financeTemplate.id } value={ financeTemplate.id }>
                { financeTemplate.title }
              </Select.Option>
            )) }
          </Select>
        );

      case IColumnType.Select:
        return (
          <Select
            showSearch
            allowClear
            style={ { width: '100%' } }
            disabled={ isDisabled || isLocked }
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            placeholder={ '-' }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            onChange={ (value: number | undefined) => {
              this.handleColumnChange(field, { ...row, [reference]: value || null });
            } }
            defaultValue={ value }
          >
            { column?.options || [].map((option: any) => (
              <Select.Option key={ option.id } value={ option.id }>
                { option.title }
              </Select.Option>
            )) }
          </Select>
        );

      case IColumnType.Text:
        return (
          <Input
            className={ classNames('Field', {
              'Field--has-error border-danger': !!hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            placeholder={ placeholder }
            required={ field.config.required }
            defaultValue={ value }
            disabled={ isDisabled || isLocked }
            onBlur={ (event: BaseSyntheticEvent) => {
              this.handleColumnChange(field, { ...row, [reference]: event.target.value || null });
            } }
          />
        );

      case IColumnType.Currency:

        switch (reference) {
          case 'net_total_variance':
            return (
              <div
                className={ classNames('ta-r', {
                  'text-danger': value < 0,
                  'text-success': value > 0,
                }) }
              >
                <span>{ getFormatedNumber(`${Math.abs(value)}`, null) }</span>
              </div>
            );

          case 'available_value':
            return (
              <div className="d-f">
                <NumberFormat
                  { ...numberFormat }
                  fixedDecimalScale={ !!decimal }
                  decimalScale={ decimal }
                  allowNegative
                  customInput={ Input }
                  className={ classNames('Field pR-20 ta-r', {
                    'Field--has-error border-danger': !!hasErrors,
                    'Field--has-error border-problem': !hasErrors && isNegative,
                  }) }
                  disabled={ isDisabled || isLocked }
                  required={ isRequired }
                  value={ value }
                />
              </div>
            );

          case 'spent_value':
            return (
              <div className="d-f">
                <NumberFormat
                  { ...numberFormat }
                  fixedDecimalScale={ !!decimal }
                  decimalScale={ decimal }
                  customInput={ Input }
                  className={ classNames('Field pR-20 ta-r', {
                    'Field--has-error border-danger': !!hasErrors,
                  }) }
                  disabled={ isDisabled || isLocked }
                  required={ isRequired }
                  allowNegative
                  value={ value }
                />
              </div>
            );

          case 'remaining_value':
            return (
              <div className="d-f">
                <NumberFormat
                  { ...numberFormat }
                  fixedDecimalScale={ !!decimal }
                  decimalScale={ decimal }
                  customInput={ Input }
                  className={ classNames('Field pR-20 ta-r', {
                    'Field--has-error border-danger': !!hasErrors,
                    'Field--has-error border-problem': !hasErrors && isNegative,
                  }) }
                  disabled={ isDisabled || isLocked }
                  required={ isRequired }
                  allowNegative
                  value={ value }
                />
                { isNegative &&
                  <Tooltip
                    overlayClassName="text-white"
                    placement="topRight"
                    title={ errorMessage }
                  >
                    <WarningIcon
                      className={ classNames('mL-5 mT-5', {
                        'text-danger': !!hasErrors,
                        'ant-text-danger': !hasErrors && isNegative
                      }) }
                      height={ 20 }
                      width={ 20 }
                    />
                  </Tooltip>
                }
              </div>
            );
        }

        return (
          <NumberFormat
            { ...numberFormat }
            fixedDecimalScale={ !!decimal }
            decimalScale={ decimal }
            customInput={ Input }
            className={ classNames('Field pR-20 ta-r', {
              'Field--has-error border-danger': !!hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            required={ isRequired }
            disabled={ isDisabled || isLocked }
            allowNegative={ false }
            value={ value }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value !== '' ? formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : null;
              this.handleColumnChange(field, { ...row, [reference]: value });
            } }
          />
        );

      case IColumnType.Number:
      case IColumnType.Percentage:
        return (
          <NumberFormat
            className={ classNames('Field pR-20 ta-r', {
              'Field--has-error border-danger': !!hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            customInput={ Input }
            defaultValue={ value }
            suffix={ type === IColumnType.Percentage ? '%' : undefined }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value !== '' ? formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : null;
              this.handleColumnChange(field, { ...row, [reference]: value });
            } }
          />
        );

      default:
        return <></>;
    }
  };

  renderColumns = (columns: IColumn[] = [], currencyCode: string | undefined) => {
    return columns
      .filter((column: IColumn) => {
        return !column?.hidden;
      })
      .map((column: IColumn) => {

        const type: IColumnType = column.type;
        const reference = column.reference;
        const tooltip = column?.description;
        const title = column.title || '-';
        const width = column?.width || 200;
        const isRequired = !!column?.required;
        const isLocked = !!column?.locked;
        let tintColor = '';

        switch (reference) {
          case 'net_total_forecast':
            tintColor = 'bg-tinted-yellow';
          break;
          case 'net_total':
            tintColor = 'bg-tinted-green';
          break;
          case 'net_total_variance':
            tintColor = 'bg-tinted-cyan';
          break;
        }

        return {
          key: reference,
          dataIndex: reference,
          width: width,
          onHeaderCell: () => {
            return { className: tintColor };
          },
          onCell: () => {
            return { className: tintColor };
          },
          title: (
            <div className='d-f ai-c'>
              <div className='d-f'>
                { title }
                { column.type === IColumnType.Currency && currencyCode &&
                  ` (${currencyCode})`
                }
              </div>
              { !!isRequired &&
                <span className="text-required mL-2 lh-1 fsz-md va-t">*</span>
              }
              { !!tooltip &&
                <Tooltip className="mL-5" placement="top" title={ tooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              }
            </div>
          ),
          render: (__: any, row: FieldValue) => this.renderColumn(column, reference, row, type, title, isLocked, isRequired)
        };
      });
  };

  renderSummary = (field: Field, columns: any[], rows: readonly FieldValue[]) => {
    const { numberFormat, fieldErrorMessages } = this.props;

    return (
      <Table.Summary.Row>
        { columns.map((column: { dataIndex: string }, index: number) => {

          const configColumns = field?.config?.columns || [];
          const configColumn = configColumns.find((_column: IColumn) => _column.reference === column.dataIndex);
          const hasErrors = _.has(fieldErrorMessages, 'total_validation');

          switch (configColumn?.type) {
            case IColumnType.Currency:
            case IColumnType.Number:

              const netTotal = this.calculateTotalFromKey(rows, 'net_total', numberFormat);
              const forecastTotal = this.calculateTotalFromKey(rows, 'net_total_forecast', numberFormat);
              const varianceTotal = forecastTotal - netTotal;
              const totalAvailable: number | null = this.calculateTotalAvailable(field);
              const totalSpent: number | null = this.calculateTotalSpent(field);
              const _totalSpent: number = (totalSpent || 0) + netTotal;
              const exceedMaxValue: boolean = totalAvailable !== null && totalAvailable < _totalSpent;
              const remainingTotal: number = (totalAvailable || 0) - _totalSpent;

              let value: number | string | null = null;

              switch (configColumn.reference) {
                case 'unit_cost':
                case 'volume':
                case 'total_value':
                  if (configColumn?.formula) {
                    rows.forEach((row: FieldValue) => {
                      const rowValue = this.calculateFormula(field, configColumn.reference, row);
                      value = value + rowValue;
                    });
                  }
                break;
                case 'net_total_forecast':
                  value = forecastTotal;
                break;
                case 'net_total':
                  value = netTotal;
                break;
                case 'net_total_variance':
                  value = Math.abs(varianceTotal);
                break;
                case 'available_value':
                  value = totalAvailable;
                break;
                case 'remaining_value':
                  value = remainingTotal;
                break;
                case 'spent_value':
                  return (
                    <Table.Summary.Cell key={ index } index={ index }>
                      <div className="ta-r fw-600">
                        <Tooltip
                          overlayClassName={ hasErrors ? 'text-white' : '' }
                          placement="topRight"
                          title={ `Must Equal or Less: ${getFormatedNumber(totalAvailable !== null ? `${totalAvailable}` : '')}` }
                        >
                          <Text
                            className={ classNames({
                              'text-danger': !!hasErrors || !!exceedMaxValue,
                              'text-success': !hasErrors && !exceedMaxValue
                            }) }
                          >
                            { `${getFormatedNumber(`${_totalSpent}`)}` }
                          </Text>
                          { (!!hasErrors || !!exceedMaxValue) &&
                            <WarningIcon className="text-danger mL-5 va-s" height={ 20 } width={ 20 } />
                          }
                        </Tooltip>
                      </div>
                    </Table.Summary.Cell>
                  );
              }

              return (
                <Table.Summary.Cell key={ index } index={ index }>
                  <div className="ta-r fw-600">
                    <Text>
                      { `${getFormatedNumber(`${value}`)}` }
                    </Text>
                  </div>
                </Table.Summary.Cell>
              );
            default:
              return <Table.Summary.Cell key={ index } index={ index } />;
          }
        } ) }
      </Table.Summary.Row>
    );
  };

  renderTable = (field: Field): JSX.Element => {
    const { isDisabled } = this.props;
    const { tableHeight } = this.state;

    const values: FieldValue[] = field.values || [];
    const currencyCode: string = field.currency !== undefined && !_.isEmpty(field.currency) ? field.currency?.code : '';
    const canDelete: boolean | undefined = !!field.config?.can_delete;

    const columns: any[] = this.renderColumns(field?.config?.columns, field.currency?.code);

    columns.push({
      key: 'actions',
      title: !this.canAddNewRow() ? <></> : (
        <Button
          className="ActionButton"
          onClick={ () => this.handleChange([
            ...field.values,
            this.getBlankState(field)
          ]) }
          disabled={ !!field.template?.fixed || !currencyCode }
        >
          <PlusOutlined />
        </Button>
      ),
      render: (row: FieldValue) => {
        return (
          <Popconfirm
            title={ 'Are you sure?' }
            icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
            okButtonProps={ {
              danger: true,
              disabled: isDisabled,
            } }
            disabled={ isDisabled || !canDelete }
            placement="topRight"
            onConfirm={ () => this.handleChange(this.delete(row.key, values)) }
          >
            <Button
              style={ {
                padding: '4px 7px',
                width: '32px',
              } }
              disabled={ isDisabled || !canDelete }
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 60,
    });

    return (
      <div id="CostTable" className="h-100p" ref={ component => { this.component = component; } }>
        <Table
          className={ 'CostField' }
          dataSource={ values }
          columns={ columns }
          rowKey={ 'key' }
          size={ 'small' }
          scroll={ {
            x: 600,
            y: tableHeight || 400
          } }
          pagination={ false }
          sticky
          bordered
          summary={ (data: readonly FieldValue[]) => this.renderSummary(field, columns, data) }
        />
      </div>
    );
  };

  renderCards = (field: Field): JSX.Element => {

    const total = this.calculateTotalFromKey(field.values, 'net_total', this.props.numberFormat);
    const threshold = field?.threshold?.available || 0;
    const spent = field?.threshold?.spent || 0;
    const formattedSpent = spent + total;
    const billable = (threshold - spent) - total;
    const formattedBillable = billable > 0 ? 0 : billable;
    const billableColour = formattedBillable === 0 ? 'bg-tinted-green' : 'bg-tinted-red';
    const thresholdTooltip = field?.threshold?.threshold_tooltip;
    const spentTooltip = field?.threshold?.spent_tooltip;
    const billableTooltip = field?.threshold?.billable_tooltip;
    const currencySymbol = field.currency?.symbol || '';

    return (
      <div className='d-f gp-10 fxw-w'>
        <div className='d-f fx-1 bd fxd-c'>
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Comprehensive Threshold
              { !!thresholdTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ thresholdTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>{ getFormatedNumber(`${threshold}`, null, currencySymbol) }</div>
          </div>
        </div>
        <div className="d-f fx-1 bd fxd-c">
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Spent During Period
              { !!spentTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ spentTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>{ getFormatedNumber(`${formattedSpent}`, null, currencySymbol) }</div>
          </div>
        </div>
        <div className={ `d-f fx-1 bd fxd-c ${billableColour}` }>
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Billable
              { !!billableTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ billableTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>
              { getFormatedNumber(`${Math.abs(formattedBillable)}`, null, currencySymbol) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = (): JSX.Element => {
    const { field, config, border } = this.props;
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        description={ !!field.description && field.description }
        required={ field.config.required }
        border={ border }
      >
        <FieldWrapper
          col={ 12 }
          border
        >
          <div>
            { this.renderTable(field) }
          </div>
          { !!field.threshold &&
            <div className='mT-10'>
              { this.renderCards(field) }
            </div>
          }
        </FieldWrapper>
      </FieldWrapper>
    );
  };
}

export default CostField;
