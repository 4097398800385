// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC, hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown from 'components/dropdown';
import AdvancedList from "components/advanced-list";

// Services
import Notification from 'services/notification';
import { getUserSetting } from 'services/settings';
import { Api } from 'services/api';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { Action as DropdownAction } from 'components/dropdown';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  record: any;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: {
    id?: number;
    bundle: string;
    type: string;
    parent_id?: number;
    parent_type?: string;
    parent_bundle?: string;
  } | null;
  exportingRow: number | null;
};

class Quotes extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: null,
    exportingRow: null,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Finance', path: '/finance' },
      { title: 'Quotes', path: '/finance/quotes' }
    ], false);

    this.props.setSecondarySidebarRoutes([]);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/record/quote/advanced-list`, {
        filters: {},
        currency: getUserSetting('currency'),
        measurement: getUserSetting('measurement'),
      });

      this.mounted && this.setState({
        record: record
      });

    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Failed to get data');
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id } = this.props;
    try {
      const record = await API.get(`client/${client_id}/record/quote/advanced-list`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        record: record
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id } = this.props;
    const { record } = this.state;
    try {
      await API.download(`client/${client_id}/record/quote/advanced-list/export`, `pacs_export_${_.snakeCase(record.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderListView = (record: any) => {
    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ record?.filters || [] }
        columns={ record?.columns || [] }
        items={ record?.data || [] }
        config={ record?.config }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { record, showCreateModal, isFetching, isCreateLoading } = this.state;

    if (isFetching || !record) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'record_quote_create')) {
      actions.push({
        node: 'Create Quote',
        onClick: () => history.push('/finance/quotes/create'),
      });
    }

    return (
      <>
        <Jumbotron
          title={ 'Quotes' }
          tabs={ [
            {
              label: formatMessage(messages.list_view),
              node: this.renderListView(record),
            }
          ] }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Quotes), 'record_quote_view_list'));
