// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Containers
import CostCentresContainer from 'containers/finance/CostCentresContainer';
import MasterCoasContainer from 'containers/finance/MasterCoasContainer';
import ContractPricingContainer from 'containers/finance/ContractPricingContainer';
import ProjectPricingContainer from 'containers/finance/ProjectPricingContainer';
import InvoiceContainer from 'containers/finance/InvoiceContainer';
import QuoteContainer from 'containers/finance/QuoteContainer';
import PurchaseOrderContainer from './finance/PurchaseOrderContainer';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Views
import Budgets from 'views/finance/Budgets';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
};

class FinanceContainer extends React.Component<Props> {

  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/finance/budgets', '/finance/budgets/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_view_list') }>
                <Budgets />
              </RestrictionWrapper>
                <Route
                  exact
                  path='/finance/budgets/create'
                  render={ () => (
                    <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_create') }>
                      <ListViewCreateModal
                        bundle={ 'record' }
                        type={ 'budget' }
                        redirectUrl={ '/finance/budgets' }
                      />
                    </RestrictionWrapper>
                  ) }
                />
            </>
          );
        } } />

        <Redirect exact from="/finance" to="/finance/budgets" />

        <Route exact path="/finance/cost-centres" component={ CostCentresContainer } />
        <Route path="/finance/cost-centres/:cost_centre_id" component={ CostCentresContainer } />

        <Route exact path="/finance/master-coa" component={ MasterCoasContainer } />
        <Route path="/finance/master-coa/:coa_id" component={ MasterCoasContainer } />

        <Route exact path="/finance/invoices" component={ InvoiceContainer } />
        <Route path="/finance/invoices/:invoice_id" component={ InvoiceContainer } />

        <Route exact path="/finance/quotes" component={ QuoteContainer } />
        <Route path="/finance/quotes/:quote_id" component={ QuoteContainer } />

        <Route exact path="/finance/purchase-orders" component={ PurchaseOrderContainer } />
        <Route path="/finance/purchase-orders/:purchase_order_id" component={ PurchaseOrderContainer } />

        <Route path="/finance/contract-pricing/:contract_pricing_id" component={ ContractPricingContainer } />

        <Route path="/finance/project-pricing/:project_pricing_id" component={ ProjectPricingContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(FinanceContainer);
