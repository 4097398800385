// Libs
import * as React from 'react';
import classNames from 'classnames';

// Components
import AntIcon from 'components/ant-icon';

interface Props {
  selectedIcon?: string,
  onSelect: (icon: string) => void;
};

export const icons = ['ApartmentOutlined', 'AppstoreAddOutlined', 'AppstoreOutlined', 'AuditOutlined', 'BankOutlined', 'BarChartOutlined', 'BookOutlined', 'BranchesOutlined', 'BuildOutlined', 'CalendarOutlined', 'CarryOutOutlined', 'CheckCircleOutlined', 'ClockCircleOutlined', 'CloseCircleOutlined', 'CloudOutlined', 'ClusterOutlined', 'CommentOutlined', 'CompassOutlined', 'ContactsOutlined', 'ControlOutlined', 'DatabaseOutlined', 'DeploymentUnitOutlined', 'DesktopOutlined', 'DisconnectOutlined', 'DownCircleOutlined', 'DownloadOutlined', 'EyeOutlined', 'FileOutlined', 'FilterOutlined', 'FireOutlined', 'FlagOutlined', 'FolderOpenOutlined', 'ForkOutlined', 'FundProjectionScreenOutlined', 'GatewayOutlined', 'GlobalOutlined', 'GoldOutlined', 'GroupOutlined', 'HddOutlined', 'HomeOutlined', 'IdcardOutlined', 'InboxOutlined', 'InfoCircleOutlined', 'IssuesCloseOutlined', 'LaptopOutlined', 'LeftCircleOutlined', 'LockOutlined', 'MailOutlined', 'MessageOutlined', 'MobileOutlined', 'NotificationOutlined', 'PaperClipOutlined', 'PartitionOutlined', 'PlusCircleOutlined', 'ProfileOutlined', 'QuestionCircleOutlined', 'RightCircleOutlined', 'RocketOutlined', 'SafetyCertificateOutlined', 'SafetyOutlined', 'SaveOutlined', 'ScheduleOutlined', 'SecurityScanOutlined', 'SettingOutlined', 'ShoppingCartOutlined', 'StarOutlined', 'StopOutlined', 'TableOutlined', 'TagOutlined', 'TeamOutlined', 'ThunderboltOutlined', 'ToolOutlined', 'UnlockOutlined', 'UnorderedListOutlined', 'UpCircleOutlined', 'UserOutlined', 'WalletOutlined', 'WarningOutlined'];

export default function IconPickerComponent({ selectedIcon, onSelect }: Props) {
  return (
    <div className='d-f ov-s fxw-w jc-s' style={{ height: 200 }}>
      { icons.map((icon: string) => (
        <span
          key={ icon }
          style={{ border: '1px solid transparent', borderRadius: 10 }}
          className={ classNames('cur-p fsz-xl pX-10 pY-5', {
            'bd border-primary': icon === selectedIcon
          }) }
          onClick={ () => onSelect(icon) }
        >
          <AntIcon type={ icon } />
        </span>
      ) ) }
    </div>
  );
};
