// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';

// Components
import ResourcesTable, { AvailableResourceRecord, ResourceRecord, ResourceRequest } from 'components/resources-table';
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import ProfileCard from 'components/profile-card';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  entity: string;
  client_id?: number;
  permissions?: UserPermissions;
  pure?: boolean;
  intl: IntlShape;
  record?: RecordFormEntity;
  getRecord(silent?: boolean): void;
}

interface State {
  record: RecordFormEntity | null;
  profile_id: number | null;
  availableRoles: AvailableResourceRecord[];
  availableRegions: any[];
  resources: ResourceRecord[];
  isFetching: boolean;
}

const messages = defineMessages({
  title: {
    id: 'resources.title',
    defaultMessage: 'Resource',
    description: '',
  },
});

class ResourceView extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    record: null,
    profile_id: null,
    availableRoles: [],
    availableRegions: [],
    resources: [],
    isFetching: false,
  };

  componentDidMount = async () => {
    const { type, client_id, record, entity } = this.props;

    this.mounted = true;

    try {
      if (!client_id || !record) throw new Error('Failed');
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const availableRoles = await API.getAvailableEntityRoles(client_id, type, record.id, entity);
      const resources = await API.getEntityResources(client_id, type, record.id, entity);
      const availableRegions = await API.get(`client/${client_id}/available_regions`);

      this.mounted && this.setState({
        availableRoles: availableRoles,
        availableRegions: availableRegions,
        resources: resources,
        record: record,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
        this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getResources = async (): Promise<ResourceRecord[]> => {
    const { type, client_id, record, entity } = this.props;

    if (!client_id || !record) throw new Error('Failed');

    try {
      return await API.getEntityResources(client_id, type, record.id, entity);
    } catch (error) {
      throw error;
    }
  };

  addResource = async (resource: ResourceRequest) => {
    const { type, client_id, record, entity, getRecord } = this.props;

    if (!client_id || !record) throw new Error('Failed');

    try {
      const response = await API.postEntityResource(client_id, type, record.id, resource, entity);
      await getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  editResource = async (resource: ResourceRequest, resourceId: number) => {
    const { type, client_id, record, entity, getRecord } = this.props;

    if (!client_id || !record) throw new Error('Failed');

    try {
      const response = await API.editEntityResource(client_id, type, record.id, resourceId, resource, entity);
      await getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  modifyResource = async (resource: ResourceRequest, resourceId: number) => {
    const { type, client_id, record, entity, getRecord } = this.props;

    if (!client_id || !record) throw new Error('Failed');

    try {
      const response = await API.putEntityResource(client_id, type, record.id, resourceId, resource, entity);
      await getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  removeResource = async (resourceId: number) => {
    const { type, client_id, record, entity, getRecord } = this.props;

    if (!record || !type || !client_id || !resourceId) throw new Error('Failed');

    try {
      const response = await API.deleteEntityResource(client_id, type, record.id, resourceId, entity);
      await getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  approveResource = async (resourceId: number) => {
    const { type, client_id, record, entity, getRecord } = this.props;

    if (!record || !type || !client_id || !resourceId) throw new Error('Failed');

    try {
      const response = await API.approveEntityResource(client_id, type, record.id, resourceId, entity);
      getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  makePrimaryResource = async (resourceId: number) => {
    const { record, type, client_id, entity, getRecord } = this.props;

    if (!record || !type || !client_id || !resourceId) throw new Error('Failed');

    try {
      const response = await API.makeEntityResourcePrimary(client_id, type, record.id, resourceId, entity);
      getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  rejectResource = async (resourceId: number, comment: string) => {
    const { record, type, client_id, entity, getRecord } = this.props;

    if (!record || !type || !client_id || !resourceId) throw new Error('Failed');

    try {
      const response = await API.rejectEntityResource(client_id, type, record.id, resourceId, comment, entity);
      getRecord(true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getCompanies = async (roleId: number) => {
    const { type, client_id, record, entity } = this.props;

    if (!record || !type || !entity || !client_id || !roleId) throw new Error('Failed');

    try {
      const response = await API.getCompaniesAutoCompleteOptions(client_id, type, entity, record.id, roleId);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getUsers = async (roleId: number, companyId: number) => {
    const { record, type, client_id, entity } = this.props;
    const { availableRoles } = this.state;

    if (!record || !type || !entity || !client_id || !roleId || !companyId) throw new Error('Failed');

    const groupId = availableRoles.find(role => role.id === roleId)?.group_id || undefined;

    try {
      const response = await API.getUserAutoCompleteOptions(client_id, type, entity, record.id, roleId, companyId, groupId);
      return response;
    } catch (error) {
      return error;
    }
  };

  renderProfileModal = (permissions: any, client_id: number, profile_user_id: number) => {
    return (
      <ProfileCard
        clientId={ client_id }
        userId={ profile_user_id }
        canMasquerade={ hasPermission(permissions, 'masquerade') }
        onMasquerade={ async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: profile_user_id
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        } }
        onClose={ () => this.setState({ profile_id: null }) }
      />
    );
  };

  renderResourcesTable = () => {
    const { permissions, client_id } = this.props;
    const { availableRoles, availableRegions, record, resources, profile_id } = this.state;
    const type = _.snakeCase(this.props.type);
    const bundle = _.snakeCase(this.props.entity);

    const canCreate = hasPermission(permissions, `${bundle}_${type}_resource_create`) || hasPermission(record, `permissions.${bundle}_${type}_resource_create`);
    const canDelete = hasPermission(permissions, `${bundle}_${type}_resource_delete`) || hasPermission(record, `permissions.${bundle}_${type}_resource_delete`);
    const canEdit = hasPermission(permissions, `${bundle}_${type}_resource_edit`) || hasPermission(record, `permissions.${bundle}_${type}_resource_edit`);
    const canModerate = hasPermission(permissions, `${bundle}_${type}_resource_moderate`) || hasPermission(record, `permissions.${bundle}_${type}_resource_moderate`);

    if (!record) return null;

    return (
      <>
        <ResourcesTable
          record={ record }
          resources={ resources }
          roles={ availableRoles }
          regions={ availableRegions }
          canCreate={ canCreate }
          canDelete={ canDelete }
          canEdit={ canEdit }
          canModerate={ canModerate }
          isRejectCommentRequired={ true }
          title={ record.title }
          onProfileClick={ (profileId: number) => this.setState({ profile_id: profileId }) }
          addResource={ this.addResource }
          editResource={ this.editResource }
          modifyResource={ this.modifyResource }
          removeResource={ this.removeResource }
          approveResource={ this.approveResource }
          makePrimaryResource={ this.makePrimaryResource }
          rejectResource={ this.rejectResource }
          getCompanies={ this.getCompanies }
          getUsers={ this.getUsers }
          getResources={ this.getResources }
        />
        { !!client_id && !!profile_id && this.renderProfileModal(permissions, client_id, profile_id) }
      </>
    );
  };

  render = () => {
    const { intl: { formatMessage }, pure } = this.props;
    const { isFetching } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { pure ? (
          <div>
            { this.renderResourcesTable() }
          </div>
        ) : (
          <Jumbotron
            content={ formatMessage(messages.title) }
            title={ this.props.record?.title }
            tabs={[
              {
                label: '',
                node: this.renderResourcesTable(),
              },
            ]}
          />
        ) }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(injectIntl(ResourceView));
