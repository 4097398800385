// Libs
import React, { useRef, useState, useEffect, MutableRefObject } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Button } from 'antd';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';

// Icons
import { DownloadOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

interface IQRCode {
  filename: string;
  hash: string;
};

interface Props {
  endpoint: string;
  isLoading: (isLoading: boolean) => void;
};

const API: Api = new Api();

export default function QRCodeComponent(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qrCode, setQRcode] = useState<IQRCode | undefined>(undefined);
  const ref = useRef<QRCode>();

  useEffect(() => {
    fetchQR();
  }, [props.endpoint]);

  useEffect(() => {
    props.isLoading(isLoading);
  }, [isLoading]);

  const fetchQR = async () => {
    try {

      setIsLoading(true);

      const QRUrl = await API.get(props.endpoint);
      setQRcode(QRUrl);

    } catch (error) {
      Notification('error', 'Failed to fetch QR-Code', 'Failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (filename: string = 'PACS-QR') => {
		ref.current?.download('png', filename);
	};

  return (
    <BlockingSpinner isLoading={ isLoading }>
      { qrCode ? (
        <div>
          <div>
            <QRCode
              ref={ ref as MutableRefObject<QRCode> }
              size={ 300 }
              value={ `${window.location.origin}/qr/${qrCode.hash}` }
              fgColor={ '#2196f3' }
              logoImage={ '/pacs-symbol.png' }
              removeQrCodeBehindLogo
            />
          </div>
          <div className="d-f jc-c">
            <Button
              type={ 'default' }
              autoFocus={ false }
              loading={ isLoading }
              onClick={ () => handleDownload(qrCode.filename) }
            >
              <DownloadOutlined />
              Download
            </Button>
          </div>
        </div>
      ) : (
        <p>Failed to generate QR Code</p>
      ) }
    </BlockingSpinner>
  );
};
