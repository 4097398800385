// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import { Button, Checkbox, Form, Input, Modal, Popconfirm, Select, Table, Tooltip } from 'antd';
import BadgeComponent, { BadgeType } from 'components/badge/Badge';
import FieldWrapper from 'components/form/field/field-wrapper';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { Workflow } from 'components/workflow/Workflow.interface';
import { RoleEntity, UserEntity } from 'types/entities';

// Utils
import { transformFieldTitle } from 'utils/workflow';
import { orderListByKey } from 'utils/formSetup';

// Interfaces
import {
  NotificationTrigger,
  ConditionType,
  DeliveryMethodType,
  Entity,
  NotificationCondition,
  NotificationRecipient,
  RecipientType,
  Tool,
  NotificationTemplate as INotificationTemplate,
  DeliveryMethod,
} from './Notifications.interfaces';
import { UserPermissions } from 'types/permissions';

// Styles
import './Notifications.scss';

interface Props {
  client_id: number;
  permissions: UserPermissions;
  intl: IntlShape;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  templateRecord: INotificationTemplate | null;
  defaultTemplate: INotificationTemplate | null;
  notificationTriggers: NotificationTrigger[];
  entities: Entity[];
  workflows: Workflow[];
  roles: RoleEntity[];
  users: UserEntity[];
  tools: Tool[];
  condition: Partial<NotificationCondition>;
  recipient: Partial<NotificationRecipient>;
  isLoading: boolean;
  isConditionsInfoLoading: boolean;
  isRecipientsinfoLoading: boolean;
  isEditingDetails: boolean;
  isEditingCondition: boolean;
  isEditingRecipient: boolean;
  isSavingDetails: boolean;
  isSavingCondition: boolean;
  isSavingRecipient: boolean;
  showEditConditionDialog: boolean;
  showEditRecipientDialog: boolean;
};

export const conditionTypes = [
  {
    title: 'Record Type',
    type: ConditionType.ENTITY_TYPE,
    tooltip: 'Limits the notification to specific record types',
  },
  {
    title: 'Workflow Stage',
    type: ConditionType.WORKFLOW_STAGE,
    tooltip: 'Notification is only sent if the record is on specific workflow stage',
  },
  {
    title: 'Workflow Transition',
    type: ConditionType.WORKFLOW_TRANSITION,
    tooltip: 'Notification is only sent if a specific transition was used to trigger the notification',
  },
  // temporarily not displayed FIELD_VALUE
];

const API: Api = new Api();
const { TextArea } = Input;

class NotificationTemplate extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    isLoading: false,
    isConditionsInfoLoading: false,
    isRecipientsinfoLoading: false,
    isEditingDetails: false,
    isEditingCondition: false,
    isEditingRecipient: false,
    isSavingDetails: false,
    isSavingCondition: false,
    isSavingRecipient: false,
    templateRecord: null,
    defaultTemplate: null,
    notificationTriggers: [],
    entities: [],
    workflows: [],
    roles: [],
    users: [],
    tools: [],
    showEditConditionDialog: false,
    showEditRecipientDialog: false,
    condition: {},
    recipient: {},
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const templateId = this.props.match.params.id;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Notifications', path: '/admin/notifications' },
    ], false);

    const loadConditionTabInfo = () => {
      this.mounted && this.setState({ isConditionsInfoLoading: true }, async () => {
        try {
          const entities = await API.get(`client/${client_id}/admin/notifications/template/entities`);
          const workflows = await API.get(`client/${client_id}/admin/workflows`);
          this.setState({ entities: entities, workflows: workflows });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.setState({ isConditionsInfoLoading: false });
        }
      });
    };

    const loadRecipientTabInfo = () => {
      this.mounted && this.setState({ isRecipientsinfoLoading: true }, async () => {
        try {
          const roles = await API.get(`client/${client_id}/admin/roles`);
          const users = await API.get(`client/${client_id}/admin/users`);
          this.setState({ roles: roles, users: users });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.setState({ isRecipientsinfoLoading: false });
        }
      });
    };

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      const template = await API.get(`client/${client_id}/admin/notifications/template/${templateId}`);
      const triggers = await API.get(`client/${client_id}/admin/notifications/trigger`);
      const tools = await API.get(`client/${client_id}/admin/entity/tools`);
      loadConditionTabInfo();
      loadRecipientTabInfo();

      this.mounted && this.setState({
        templateRecord: template,
        notificationTriggers: triggers,
        tools: tools,
      });

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Notifications', path: '/admin/notifications' },
        { title: template.title, path: null },
      ], false);

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getErrors = (template: Partial<INotificationTemplate>) => {
    const schema = {
      title: (value: string = '') => !_.isEmpty(value.trim()),
      subject: (value: string = '') => !_.isEmpty(value.trim()),
      message: (value: string = '') => !_.isEmpty(value.trim()),
      notification_trigger_id: (value: number) => !!value,
    };

    const validate = (template: any, schema: any) =>
      Object.keys(schema)
        .filter((key) => !schema[key](template[key]))
        .map((key) => key);

    return validate(template, schema);
  };

  getModified = (template: INotificationTemplate | null, originalTemplate: INotificationTemplate | null) => {
    const modified: string[] = [];
    if (template && originalTemplate && !_.isEqual(template, originalTemplate)) {
      // check if the template has changed
      (Object.keys(originalTemplate) as Array<keyof INotificationTemplate>).forEach((key) => {
        if (!_.isEqual(template[key], originalTemplate[key])) {
          modified.push(key);
        }
      });
    }
    return modified;
  };

  isModified = (modified: string[], fieldKey: string) => {
    return modified.includes(fieldKey);
  };

  hasError = (errors: string[], fieldKey: string) => {
    return !_.isEmpty(errors) && errors.includes(fieldKey);
  };

  renderDetailsTab = () => {
    const { templateRecord, defaultTemplate, notificationTriggers, tools, isEditingDetails, isSavingDetails } = this.state;
    const { client_id } = this.props;

    const disabledFields = isSavingDetails || !isEditingDetails;
    const errors = this.getErrors(templateRecord || {});
    const modified = this.getModified(templateRecord, defaultTemplate);

    const actions: DropdownAction[] = [];

    if (isEditingDetails) {
      actions.push(
        {
          node: 'Save',
          type: 'primary',
          disabled: !_.isEmpty(errors) || _.isEqual(templateRecord, defaultTemplate),
          isLoading: isSavingDetails,
          onClick: () => this.setState({ isSavingDetails: true }, async () => {
            try {
              const template = await API.put(`client/${client_id}/admin/notifications/template/${templateRecord?.id}`, {
                data: templateRecord
              });
              this.mounted && this.setState({
                templateRecord: template,
              }, () => {
                Notification('success', 'Template was updated', 'Sucessful');
              });
            } catch (err) {
              Notification('error', 'Failed to update template', 'Failed');
            } finally {
              this.mounted && this.setState({
                isSavingDetails: false,
                isEditingDetails: false,
                defaultTemplate: null,
              });
            }
          })
        },
        {
          node: 'Cancel',
          onClick: () => this.setState({ isEditingDetails: false, templateRecord: defaultTemplate, defaultTemplate: null }),
        },
      );
    } else {
      actions.push({
        node: 'Edit',
        onClick: () => this.setState({ isEditingDetails: true, defaultTemplate: _.cloneDeep(templateRecord) }),
      });
    }

    return (
      <div className="Layout-box pB-20">
        <div className="d-f jc-fe">
          <Dropdown actions={ actions } />
        </div>
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ templateRecord?.title || '' }
              disabled={ disabledFields }
              className={ classNames({
                'Field--has-error border-danger': this.hasError(errors, 'title'),
                'Field--has-warning border-warning': !this.hasError(errors, 'title') && this.isModified(modified, 'title'),
              }) }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({ templateRecord: Object.assign(templateRecord, { title: event.target.value }) });
              } }
            />
          </Form.Item>
          <Form.Item label="Subject" required>
            <Input
              value={ templateRecord?.subject || '' }
              disabled={ disabledFields }
              className={ classNames({
                'Field--has-error border-danger': this.hasError(errors, 'subject'),
                'Field--has-warning border-warning': !this.hasError(errors, 'subject') && this.isModified(modified, 'subject'),
              }) }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({ templateRecord: Object.assign(templateRecord, { subject: event.target.value }) });
              } }
            />
          </Form.Item>
          <Form.Item label="Message" required>
            <TextArea
              rows={ 4 }
              disabled={ disabledFields }
              className={ classNames({
                'Field--has-error border-danger': this.hasError(errors, 'message'),
                'Field--has-warning border-warning': !this.hasError(errors, 'message') && this.isModified(modified, 'message'),
              }) }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({ templateRecord: Object.assign(templateRecord, { message: event.target.value }) });
              } }
              value={ templateRecord?.message || '' }
            />
          </Form.Item>
          <Form.Item
            label={
              <div>
                <label>Trigger</label>
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title="The action performed that will trigger the notification to be sent"
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              </div>
            }
            required
          >
            <Select
              showSearch
              disabled={ disabledFields }
              value={ templateRecord?.notification_trigger_id }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': this.hasError(errors, 'notification_trigger_id'),
                'Select-Field--has-warning border-warning': !this.hasError(errors, 'message') && this.isModified(modified, 'notification_trigger_id'),
              }) }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (triggerId: number) => {
                this.setState({ templateRecord: Object.assign(templateRecord, { notification_trigger_id: triggerId }) });
              } }
            >
              { orderListByKey(notificationTriggers, 'title').map((trigger: NotificationTrigger) => (
                <Select.Option key={ trigger.id } value={ trigger.id }>
                  { trigger.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Tool Selection">
            <Select
              showSearch
              disabled={ disabledFields }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': this.hasError(errors, 'tool_id'),
                'Select-Field--has-warning border-warning': !this.hasError(errors, 'message') && this.isModified(modified, 'tool_id'),
              }) }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (toolId: number) => {
                this.setState({ templateRecord: Object.assign(templateRecord, { tool_id: toolId }) });
              } }
              value={ templateRecord?.tool_id || undefined }
            >
             { tools.map((tool: Tool) => (
                <Select.Option key={ tool.id } value={ tool.id }>
                  { tool.label }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Delivery Method" required>
            { (Object.keys(DeliveryMethodType) as Array<keyof typeof DeliveryMethodType>).map((deliveryMethod) => (
              <Checkbox
                key={ deliveryMethod }
                disabled={ disabledFields }
                onChange={ (e) => {
                  let cloneDeliveryMethods = _.cloneDeep(templateRecord?.delivery_methods) || [];
                  const shouldAddMethod = !cloneDeliveryMethods.find(method => method.method === deliveryMethod);
                  if (e.target.checked && shouldAddMethod) {
                    const originalDeliveryMethod = defaultTemplate?.delivery_methods.find(method => method.method === deliveryMethod);
                    // if the initial delivery method exists, the method will be set
                    const payload = !!originalDeliveryMethod ? originalDeliveryMethod : { method: deliveryMethod } as DeliveryMethod;
                    cloneDeliveryMethods.push(payload);
                  } else if (!e.target.checked) {
                    cloneDeliveryMethods = cloneDeliveryMethods.filter(method => method.method !== deliveryMethod);
                  }
                  // if the state is equal but marked in a different order, set the initial state
                  if (cloneDeliveryMethods.length > 1 && defaultTemplate?.delivery_methods.length === cloneDeliveryMethods.length) {
                    cloneDeliveryMethods = defaultTemplate?.delivery_methods;
                  }
                  this.setState({ templateRecord: Object.assign(templateRecord, { delivery_methods: cloneDeliveryMethods }) });
                } }
                checked={ !!templateRecord?.delivery_methods.find(method => method.method === deliveryMethod) }
              >
                { transformFieldTitle(deliveryMethod) }
              </Checkbox>
            )) }
          </Form.Item>
        </Form>
      </div>
    );
  };

  renderConditionsTab = () => {
    const { client_id } = this.props;
    const { templateRecord, isConditionsInfoLoading, workflows, entities } = this.state;

    const getBadges = (type: ConditionType, config: any): string[] => {
      if (type === ConditionType.ENTITY_TYPE && config.type && config.bundle) {
        const entityLabel = entities.find((e) => e.type === config.type)?.label;
        if (entityLabel) {
          return [entityLabel];
        }
      }
      if (type === ConditionType.WORKFLOW_STAGE && config.workflow_id && config.reference) {
        const workflow = workflows.find((w) => w.id === config.workflow_id);
        const stage = workflow?.stages.find((s) => s.reference === config.reference);
        if (workflow && stage) {
          return [workflow.title, stage.title];
        }
      }
      if (type === ConditionType.WORKFLOW_TRANSITION && config.workflow_id && config.reference) {
        const workflow = workflows.find((w) => w.id === config.workflow_id);
        const transition = _.flatMap(
          workflow?.stages,
          (stage) => stage.transitions
        ).find((s) => s.reference === config.reference);
        if (workflow && transition) {
          return [workflow.title, transition.title];
        }
      }
      return [];
    };

    const getConditionTypeTitle = (type: string) => {
      return conditionTypes.find(condition => condition.type === type)?.title || transformFieldTitle(type);
    };

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        render: (type: string) => {
          return <div>{ getConditionTypeTitle(type) }</div>;
        },
        ellipsis: true,
      },
      {
        dataIndex: 'config',
        key: 'config',
        title: 'Additional Config',
        render: (__: any, record: NotificationCondition) => (
          <div className="d-f fxw-w">
            { getBadges(record.type, record.config).map((item: string, i) => {
              return (
                <BadgeComponent
                  key={ item + i }
                  className={ 'mR-5 mB-5' }
                  type={ i === 0 ? BadgeType.Warning : BadgeType.Success }
                  text={ item }
                />
              );
            }) }
          </div>
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => (
          <Button onClick={ () => this.setState({ showEditConditionDialog: true }) }>
            Create New
          </Button>
        ),
        render: (__: any, record: NotificationCondition) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    showEditConditionDialog: true,
                    isEditingCondition: true,
                    condition: _.cloneDeep(record),
                  });
                } }
              />
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={{
                  danger: true,
                }}
                placement="topRight"
                onConfirm={ () => this.setState({ isConditionsInfoLoading: true }, async () => {
                  try {
                    await API.delete(`client/${client_id}/admin/notifications/template/condition/${record?.id}`);
                    const conditions = templateRecord?.conditions.filter((c) => c.id !== record.id) || [];
                    this.setState({ templateRecord: Object.assign(templateRecord, { conditions: conditions }) });
                  } catch (error) {
                    console.error('Error: ', error);
                  } finally {
                    this.setState({ isConditionsInfoLoading: false });
                  }
                }) }
              >
                <DeleteOutlined className="mL-20 link" style={{ fontSize: 18 }} />
              </Popconfirm>
            </>
          );
        },
        width: 120,
        ellipsis: true,
        align: 'center',
      },
    ];
    return (
      <BlockingSpinner isLoading={ isConditionsInfoLoading }>
        <Table
          size={ 'small' }
          rowKey={ 'id' }
          pagination={ false }
          columns={ columns }
          dataSource={ templateRecord?.conditions || [] }
        />
      </BlockingSpinner>
    );
  };

  renderRecipientsTab = () => {
    const { client_id } = this.props;
    const { templateRecord, roles, users, isRecipientsinfoLoading } = this.state;

    const getTarget = (type: RecipientType, id: number) => {
      if (type === RecipientType.ROLE) {
        return roles.find((role) => role.id === id)?.title;
      }
      if (type === RecipientType.USER) {
        return users.find((user) => user.id === id)?.full_name;
      }
    };

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        ellipsis: true,
      },
      {
        dataIndex: 'target_id',
        key: 'target_id',
        title: 'Target',
        render: (__: any, record: { type: RecipientType; target_id: number }) => (
          <div>{ getTarget(record.type, record.target_id) }</div>
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => (
          <Button onClick={ () => this.setState({ showEditRecipientDialog: true }) }>
            Create New
          </Button>
        ),
        render: (__: any, record: any) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    showEditRecipientDialog: true,
                    isEditingRecipient: true,
                    recipient: _.cloneDeep(record),
                  });
                } }
              />
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={{
                  danger: true,
                }}
                placement="topRight"
                onConfirm={ () => this.mounted && this.setState({ isRecipientsinfoLoading: true }, async () => {
                  try {
                    await API.delete(`client/${client_id}/admin/notifications/template/recipient/${record?.id}`);
                    const recipients = templateRecord?.recipients.filter((r) => r.id !== record.id) || [];
                    this.setState({ templateRecord: Object.assign(templateRecord, { recipients: recipients }) });
                  } catch (error) {
                    console.error('Error: ', error);
                  } finally {
                    this.setState({ isRecipientsinfoLoading: false });
                  }
                }) }
              >
                <DeleteOutlined className="mL-20 link" style={{ fontSize: 18 }} />
              </Popconfirm>
            </>
          );
        },
        width: 120,
        ellipsis: true,
        align: 'center',
      },
    ];
    return (
      <BlockingSpinner isLoading={ isRecipientsinfoLoading }>
        <Table
          size={ 'small' }
          rowKey={ 'id' }
          pagination={ false }
          columns={ columns }
          dataSource={ templateRecord?.recipients || [] }
        />
      </BlockingSpinner>
    );
  };

  renderEditConditionDialog = () => {
    const { client_id } = this.props;
    const { templateRecord, condition, entities, workflows, isEditingCondition, isSavingCondition } = this.state;

    const groupedEntities: Array<{ label: string; data: Entity[] }> = _.uniqBy(
      entities,
      'bundle',
    ).map((entity) => ({
      label: entity.bundle,
      data: entities.filter((e) => e.bundle === entity.bundle),
    }));
    const stages = workflows.find((w) => w.id === condition.config?.workflow_id)?.stages;
    const transitions = _.flatMap(stages, stage => stage.transitions);
    // filtered workflows with transitions
    const workflowsWithTransitions = workflows.filter((workflow) => {
      return workflow.stages.some((stage) => !_.isEmpty(stage.transitions));
    });

    const isOkButtonDisabled = () => {
      const { type, config } = condition;
      // if the condition type is not selected, disable the submit button
      if (!type) {
        return true;
      }

      // if the condition type is ENTITY_TYPE and no additional configuration is selected, disable the submit button
      if (type === ConditionType.ENTITY_TYPE && _.isEmpty(config)) {
        return true;
      }

      // if the condition type is WORKFLOW_STAGE and no additional configuration is selected, disable the submit button
      if (type === ConditionType.WORKFLOW_STAGE && _.isEmpty(config?.reference)) {
        return true;
      }

      // if the condition type is WORKFLOW_TRANSITION and no additional configuration is selected, disable the submit button
      if (type === ConditionType.WORKFLOW_TRANSITION && _.isEmpty(config?.reference)) {
        return true;
      }

      // if the condition has not changed, disable the submit button
      if (isEditingCondition && _.isEqual(condition, templateRecord?.conditions.find(item => item.id === condition.id))) {
        return true;
      }
    };

    const submitHandler = () => {
      this.mounted && this.setState({ isSavingCondition: true }, async () => {
        try {
          if (isEditingCondition) {
            const updatedCondition = await API.put(`client/${client_id}/admin/notifications/template/condition/${condition.id}`, {
              data: condition
            });
            const conditions = templateRecord?.conditions.map((c) => c.id === updatedCondition.id ? updatedCondition : c);
            this.setState({ templateRecord: Object.assign(templateRecord, { conditions: conditions }) });
          } else {
            const payload = {
              type: condition.type,
              config: condition.config,
              notification_template_id: templateRecord?.id,
            };
            const response = await API.post(`client/${client_id}/admin/notifications/template/${templateRecord?.id}/condition`, {
              data: payload
            });
            const conditions = [...(templateRecord?.conditions || []), response[0]];
            this.setState({ templateRecord: Object.assign(templateRecord, { conditions: conditions }) });
          }
          Notification('success', 'Template was updated', 'Sucessful');
        } catch (error) {
          console.error('Error: ', error);
          Notification('error', 'Failed to update template', 'Failed');
        } finally {
          this.mounted && this.setState({
            isSavingCondition: false,
            isEditingCondition: false,
            showEditConditionDialog: false,
            condition: {},
          });
        }
      });
    };

    return (
      <Modal
        visible
        centered
        title={ `${isEditingCondition ? 'Edit' : 'Create'} Condition` }
        maskClosable={ !isSavingCondition }
        okText={ isEditingCondition ? 'Save' : 'Create' }
        style={ { minWidth: 800 } }
        onOk={ submitHandler }
        onCancel={ () => {
          this.setState({
            showEditConditionDialog: false,
            isEditingCondition: false,
            condition: {},
          });
        } }
        cancelButtonProps={{
          disabled: isSavingCondition,
        }}
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isSavingCondition,
        }}
      >
        <Form layout="vertical">
          <FieldWrapper
            style={{ minHeight: 50 }}
            label="Type"
            description="What type of condition should this be? Additional options may be presented below based on your selection."
            required
          >
            <Select
              placeholder={ 'Please select the Type of condition' }
              value={ condition.type }
              onChange={ (conditionType: ConditionType) => {
                this.setState({ condition: Object.assign(condition, { type: conditionType, config: {} }) });
              } }
            >
              { conditionTypes.map((condition) => {
                return (
                  <Select.Option key={ condition.type } value={ condition.type }>
                    <Tooltip title={ condition.tooltip } placement="right">
                      <div className="Notifications-Option">
                        <div className="Notifications-OptionText">
                          { condition.title }
                        </div>
                      </div>
                    </Tooltip>
                  </Select.Option>
                );
              }) }
            </Select>
          </FieldWrapper>
          { condition.type === ConditionType.ENTITY_TYPE && (
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Record"
              description="Only send the notification when the record matches this type."
              required
            >
              <Select
                showSearch
                placeholder={ 'Please select the record type' }
                value={ condition.config?.type }
                filterOption={ (input: any, optionOrGroup: any) => {
                  if (_.isArray(optionOrGroup.options)) {
                    return false;
                  }
                  return optionOrGroup.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                } }
                onChange={ (type: string) => {
                  const bundle = entities.find((e) => e.type === type)?.bundle;
                  if (bundle) {
                    this.setState({
                      condition: Object.assign(condition, { config: { bundle, type } }),
                    });
                  }
                } }
              >
                { groupedEntities.map((item, index) => {
                  return (
                    <Select.OptGroup key={ item.label + index } label={ item.label }>
                      { item.data.map((entity, i) => {
                        return (
                          <Select.Option value={ entity.type } key={ entity.type + i }>
                            { entity.label }
                          </Select.Option>
                        );
                      }) }
                    </Select.OptGroup>
                  );
                }) }
              </Select>
            </FieldWrapper>
          ) }
          { condition.type === ConditionType.WORKFLOW_STAGE && (
            <>
              <FieldWrapper
                style={{ minHeight: 50 }}
                label="Workflow"
                description="Only send the notification when the record uses this workflow."
                required
              >
                <Select
                  showSearch
                  placeholder={ 'Please select the workflow that contains the stage which, when reached, should trigger the notification' }
                  value={ condition.config?.workflow_id }
                  filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  onChange={ (workflowId: number) => {
                    this.setState({ condition: Object.assign(condition, { config: { workflow_id: workflowId } }) });
                  } }
                >
                  { workflows.map((workflow) => (
                    <Select.Option value={ workflow.id } key={ workflow.id }>
                      { workflow.title }
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                style={{ minHeight: 50 }}
                label="Stage"
                description="Only send the notification when the record is on this workflow stage."
                required
              >
                <Select
                  placeholder={ 'Please select the stage which when reached will trigger the notification' }
                  value={ condition.config?.reference }
                  disabled={ !stages }
                  onChange={ (reference: string) => {
                    this.setState({ condition: Object.assign(condition, { config: { ...condition.config, reference: reference } }) });
                  } }
                >
                  { stages?.map((stage) => (
                    <Select.Option value={ stage.reference } key={ stage.id }>
                      { stage.title }
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
            </>
          ) }
          { condition.type === ConditionType.WORKFLOW_TRANSITION && (
            <>
              <FieldWrapper
                style={{ minHeight: 50 }}
                label="Workflow"
                description="Only send the notification when the record uses this workflow."
                required
              >
                <Select
                  showSearch
                  placeholder={ 'Please select the workflow that contains the stage which, when reached, should trigger the notification' }
                  value={ condition.config?.workflow_id }
                  filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  onChange={ (workflowId: number) => {
                    this.setState({ condition: Object.assign(condition, { config: { workflow_id: workflowId } }) });
                  } }
                >
                  { workflowsWithTransitions.map((workflow) => (
                    <Select.Option value={ workflow.id } key={ workflow.id }>
                      { workflow.title }
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                style={{ minHeight: 50 }}
                label="Transition"
                description="Only send the notification when the record uses this transition."
                required
              >
                <Select
                  placeholder={ 'Please select the transition' }
                  value={ condition.config?.reference }
                  disabled={ _.isEmpty(transitions) }
                  onChange={ (reference: string) => {
                    this.setState({ condition: Object.assign(condition, { config: { ...condition.config, reference: reference } }) });
                  } }
                >
                  { transitions?.map((transition) => (
                    <Select.Option value={ transition.reference } key={ transition.id }>
                      { transition.title }
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
            </>
          ) }
        </Form>
      </Modal>
    );
  };

  renderEditRecipientDialog = () => {
    const { client_id } = this.props;
    const { templateRecord, isEditingRecipient, isSavingRecipient, recipient, roles, users } = this.state;

    // Return recipients or roles depending on the selected recipient type
    const getRecipientsByType: () => Array<{ id: number, label: string }> = () => {
      // if recipient type is ROLE, return recipients
      if (recipient.type === RecipientType.ROLE) {
        return roles.map((role) => ({ id: role.id, label: role.title }));
      }

      // if recipient type is USER, return users
      if (recipient.type === RecipientType.USER) {
        return users.map((user) => ({ id: user.id, label: user.full_name }));
      }

      return [];
    };

    const isOkButtonDisabled = () => {
      const { type, target_id } = recipient;
      // if type is not selected, disable submit button
      if (!type) {
        return true;
      }

      // if target_id is not selected and recipient type is not ALL_ROLES or ALL_CONTACTS, disable submit button
      if (!target_id && ![RecipientType.ALL_ROLES, RecipientType.ALL_CONTACTS].includes(type)) {
        return true;
      }

      // if the recipient has not changed, disable the submit button
      if (isEditingRecipient && _.isEqual(recipient, templateRecord?.recipients.find(item => item.id === recipient.id))) {
        return true;
      }
    };

    const submitHandler = () => {
      this.mounted && this.setState({ isSavingRecipient: true }, async () => {
        try {
          if (isEditingRecipient) {
            const updatedRecipient = await API.put(`client/${client_id}/admin/notifications/template/recipient/${recipient.id}`, {
              data: recipient
            });
            const recipients = templateRecord?.recipients.map((r) => r.id === updatedRecipient.id ? updatedRecipient : r);
            this.setState({ templateRecord: Object.assign(templateRecord, { recipients: recipients }) });
          } else {
            const response = await API.post(`client/${client_id}/admin/notifications/template/${templateRecord?.id}/recipient`, {
              data: recipient
            });
            const recipients = [...(templateRecord?.recipients || []), response[0]];
            this.setState({ templateRecord: Object.assign(templateRecord, { recipients: recipients }) });
          }
          Notification('success', 'Template was updated', 'Sucessful');
        } catch (error) {
          Notification('error', 'Failed to update template', 'Failed');
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isSavingRecipient: false,
            isEditingRecipient: false,
            showEditRecipientDialog: false,
            recipient: {},
          });
        }
      });
    };

    return (
      <Modal
        visible
        centered
        title={ `${isEditingRecipient ? 'Edit' : 'Create'} Recipient` }
        maskClosable={ !isSavingRecipient }
        okText={ isEditingRecipient ? 'Save' : 'Create' }
        style={ { minWidth: 800 } }
        onOk={ submitHandler }
        onCancel={ () => {
          this.setState({
            showEditRecipientDialog: false,
            isEditingRecipient: false,
            recipient: {},
          });
        } }
        cancelButtonProps={{
          disabled: isSavingRecipient,
        }}
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isSavingRecipient,
        }}
      >
        <Form layout="vertical">
          <FieldWrapper
            style={{ minHeight: 50 }}
            label="Recipient Type"
            description="What type of recipient should this be? Additional options may be presented below based on your selection."
            required
          >
            <Select
              placeholder={ 'Please select the Type of recipient' }
              value={ recipient.type }
              onChange={ (recipientType: RecipientType) => {
                this.setState({
                  recipient: Object.assign(recipient, {
                    type: recipientType,
                    target_id: recipientType === RecipientType.ALL_ROLES ? null : undefined,
                  }),
                });
              } }
            >
            { Object.keys(RecipientType).map((key) => {
              return (
                <Select.Option key={ key } value={ key }>
                  { transformFieldTitle(key) }
                </Select.Option>
              );
            }) }
            </Select>
          </FieldWrapper>
          { !!recipient.type && recipient.type !== RecipientType.ALL_ROLES && recipient.type !== RecipientType.ALL_CONTACTS && (
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Target"
              description={
                recipient.type === RecipientType.ROLE
                  ? 'All users holding this role against the record will be notified.'
                  : 'The targetted user will be notified.'
              }
              required
            >
              <Select
                showSearch
                disabled={ !recipient.type }
                placeholder={ 'Please select the target' }
                filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                onChange={ (targetId: number) => {
                  this.setState({
                    recipient: Object.assign(recipient, { target_id: targetId }),
                  });
                } }
                value={ recipient.target_id }
              >
                { orderListByKey(getRecipientsByType(), 'label').map((item: { label: string, id: number }, i: number) => {
                  return (
                    <Select.Option key={ `${item.id}_${i}` } value={ item.id }>
                      { item.label }
                    </Select.Option>
                  );
                }) }
              </Select>
            </FieldWrapper>
          ) }
        </Form>
      </Modal>
    );
  };

  render = () => {
    const { templateRecord, isLoading, showEditConditionDialog, showEditRecipientDialog } = this.state;
    const tabs = [
      {
        label: 'Details',
        node: this.renderDetailsTab(),
      },
      {
        label: 'Conditions',
        node: this.renderConditionsTab(),
      },
      {
        label: 'Recipients',
        node: this.renderRecipientsTab(),
      },
    ];

    return (
      <BlockingSpinner isLoading={ isLoading }>
        { !!templateRecord && (
          <Jumbotron
            tabs={ tabs }
          />
        ) }
        { showEditConditionDialog && this.renderEditConditionDialog() }
        { showEditRecipientDialog && this.renderEditRecipientDialog() }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(NotificationTemplate), 'access_admin_notifications'));
