// Libs
import * as React from 'react';
import moment from "moment/moment";

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { OnClone, OnLoading } from 'components/form/form-wrapper/FormWrapper.interface';

// Views
import MaintenancePlanCopyModal from 'views/workplace-service/maintenance-plan/MaintenancePlanCopyModal';

interface Props {
  client_id: number;
  record: RecordFormEntity;
};

class MaintenancePlanRecord extends React.Component<Props> {

  renderCloneDialog = (isCopying: boolean, onLoading: OnLoading, onClone: OnClone, onClose: () => void) => {
    return (
      <MaintenancePlanCopyModal
        record={ this.props.record }
        isCopying={ isCopying }
        onClose={ onClose }
        onCopy={ onClone }
        onLoading={ onLoading }
      />
    );
  };

  render = () => {
    return (
      <RecordView
        id={ this.props.record.id }
        entity={ 'category' }
        type={ 'asset_maintenance_plan' }
        record={ this.props.record }
        importProps={{
          filePrefix: `pacs_export_asset_maintenance_plan_${moment().format('YYYY-MM-DD')}.xlsx`,
          verifyEndpoint: `category/asset-maintenance-plan/${this.props.record.id}/import/verify`,
          importEndpoint: `category/asset-maintenance-plan/${this.props.record.id}/import/run`,
          downloadEndpoint: `category/asset-maintenance-plan/${this.props.record.id}/import/download`,
        }}
        renderCloneDialog={ this.renderCloneDialog }
      />
    );
  };
};

export default MaintenancePlanRecord;
