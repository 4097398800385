// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';

// Containers
import LocationContainer from 'containers/portfolio/LocationContainer';
import PropertyContainer from 'containers/portfolio/PropertyContainer';
import OwnedContainer from 'containers/portfolio/OwnedContainer';
import LeaseContainer from 'containers/portfolio/LeaseContainer';
import SubleaseContainer from 'containers/portfolio/SubleaseContainer';
import TenantContainer from 'containers/portfolio/TenantContainer';
import LandlordContainer from 'containers/portfolio/LandlordContainer';
import TransactionContainer from 'containers/portfolio/TransactionContainer';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

class PortfolioContainer extends React.Component<Props> {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/portfolio" to="/portfolio/locations" />

        <Route exact path="/portfolio/locations" component={ LocationContainer } />
        <Route path="/portfolio/locations/:record_id" component={ LocationContainer } />

        <Route exact path="/portfolio/properties" component={ PropertyContainer } />
        <Route path="/portfolio/properties/:record_id" component={ PropertyContainer } />

        <Route exact path="/portfolio/owned" component={ OwnedContainer } />
        <Route path="/portfolio/owned/:record_id" component={ OwnedContainer } />

        <Route exact path="/portfolio/leases" component={ LeaseContainer } />
        <Route path="/portfolio/leases/:lease_id" component={ LeaseContainer } />

        <Route exact path="/portfolio/subleases" component={ SubleaseContainer } />
        <Route path="/portfolio/subleases/:sublease_id" component={ SubleaseContainer } />

        <Route exact path="/portfolio/tenants" component={ TenantContainer } />
        <Route path="/portfolio/tenants/:record_id" component={ TenantContainer } />

        <Route exact path="/portfolio/landlords" component={ LandlordContainer } />
        <Route path="/portfolio/landlords/:record_id" component={ LandlordContainer } />

        <Route path="/portfolio/transactions" component={ TransactionContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioContainer);
