import { defineMessages } from "react-intl";

const messages = defineMessages({
  filter_by: {
    id: 'basic_list_view.filter_by',
    defaultMessage: 'Filter by',
    description: '',
  },
  show: {
    id: 'basic_list_view.show',
    defaultMessage: 'Show',
    description: '',
  },
  entries_of: {
    id: 'basic_list_view.entries_of',
    defaultMessage: 'Entries of',
    description: '',
  },
  filter: {
    id: 'basic_list_view.filter',
    defaultMessage: 'Filter',
    description: '',
  },
  columnsRole: {
    id: 'resources.table.columns.role',
    defaultMessage: 'Role',
    description: '',
  },
  columnsInnerCompany: {
    id: 'resources.table.columns.inner.company',
    defaultMessage: 'Company',
    description: '',
  },
  columnsInnerEmail: {
    id: 'resources.table.columns.inner.email',
    defaultMessage: 'Email',
    description: '',
  },
  columnsInnerFirstName: {
    id: 'resources.table.columns.inner.first_name',
    defaultMessage: 'First Name',
    description: '',
  },
  columnsInnerMobile: {
    id: 'resources.table.columns.inner.mobile',
    defaultMessage: 'Mobile',
    description: '',
  },
  columnsInnerStatus: {
    id: 'resources.table.columns.inner.status',
    defaultMessage: 'Status',
    description: '',
  },
  columnsInnerSurname: {
    id: 'resources.table.columns.inner.surname',
    defaultMessage: 'Surname',
    description: '',
  },
  columnsInnerType: {
    id: 'resources.table.columns.inner.type',
    defaultMessage: 'Type',
    description: '',
  },
  addResourcesButtonLabel: {
    id: 'resources.table.add_resources_label',
    defaultMessage: 'Add Resources',
    description: '',
  },
  addResourceTitle: {
    id: 'resources.table.add_resource_modal_title',
    defaultMessage: 'Add Resource',
    description: '',
  },
  editResourceTitle: {
    id: 'resources.table.edit_resource_modal_title',
    defaultMessage: 'Edit Resource',
    description: '',
  },
  changeResourceTitle: {
    id: 'resources.table.change_resource_modal_title',
    defaultMessage: 'Change role of resource "{resource}"',
    description: '',
  },
  changeResourceWarningMessage: {
    id: 'resources.table.change_resource_modal_warning_message',
    defaultMessage: 'Warning',
    description: '',
  },
  replaceResourceTitle: {
    id: 'resources.table.replace_resource_modal_title',
    defaultMessage: 'Replace "{role}" of "{entity}"',
    description: '',
  },
  replaceResourceWarningMessage: {
    id: 'resources.table.replace_resource_modal_warning_message',
    defaultMessage: 'Warning',
    description: '',
  },
  changePrimaryResourceTitle: {
    id: 'resources.table.change_primary_resource_modal_title',
    defaultMessage: 'Change primary resource of "{role}"',
    description: '',
  },
  changePrimaryResourceWarningMessage: {
    id: 'resources.table.change_primary_resource_modal_warning_message',
    defaultMessage: 'Warning',
    description: '',
  },
  removeResourceTitle: {
    id: 'resources.table.remove_resource_modal_title',
    defaultMessage: 'Remove resource from "{entity}"',
    description: '',
  },
  removeResourceWarningMessage: {
    id: 'resources.table.remove_resource_modal_warning_message',
    defaultMessage: 'Warning',
    description: '',
  },
  rejectResourceTitle: {
    id: 'resources.table.reject_resource_modal_title',
    defaultMessage: 'Reject resource "{role}, {user}"',
    description: '',
  },
  rejectResourceCommentMessage: {
    id: 'resources.table.reject_resource_modal_comment_message',
    defaultMessage: 'Please explain why this resource is being removed.',
    description: '',
  },
  addLabel: {
    id: 'resources.table.add_label',
    defaultMessage: 'Add',
    description: '',
  },
  editLabel: {
    id: 'resources.table.edit_label',
    defaultMessage: 'Edit',
    description: '',
  },
  changeLabel: {
    id: 'resource_s.able_change_label',
    defaultMessage: 'Change',
    description: '',
  },
  commentLabel: {
    id: 'resources.table.comment_label',
    defaultMessage: 'Comment',
    description: '',
  },
  companyLabel: {
    id: 'resources.table.company_label',
    defaultMessage: 'Company',
    description: '',
  },
  rejectLabel: {
    id: 'resources.table.reject_label',
    defaultMessage: 'Reject',
    description: '',
  },
  removeLabel: {
    id: 'resources.table.remove_label',
    defaultMessage: 'Remove',
    description: '',
  },
  replaceLabel: {
    id: 'resources.table.replace_label',
    defaultMessage: 'Replace',
    description: '',
  },
  roleLabel: {
    id: 'resources.table.role_label',
    defaultMessage: 'Role',
    description: '',
  },
  updateLabel: {
    id: 'resources.table.update_label',
    defaultMessage: 'Update',
    description: '',
  },
  fromLabel: {
    id: 'resources.table.from_label',
    defaultMessage: 'From',
    description: '',
  },
  toLabel: {
    id: 'resources.table.to_label',
    defaultMessage: 'To',
    description: '',
  },
  userLabel: {
    id: 'resources.table.user_label',
    defaultMessage: 'User',
    description: '',
  },
  approvedStatusLabel: {
    id: 'general.entity.status_approved',
    defaultMessage: 'Approved',
    description: '',
  },
  pendingStatusLabel: {
    id: 'general.entity.status_pending',
    defaultMessage: 'Pending',
    description: '',
  },
  rejectedStatusLabel: {
    id: 'general.entity.status_rejected',
    defaultMessage: 'Rejected',
    description: '',
  },
  requestedStatusLabel: {
    id: 'general.entity.status_requested',
    defaultMessage: 'Requested',
    description: '',
  },
  pendingBadgeStatusLabel: {
    id: 'resources.table.pending_badge_label',
    defaultMessage: 'Total number of resources that are pending for this role',
    description: '',
  },
  rejectedBadgeStatusLabel: {
    id: 'resources.table.rejected_badge_label',
    defaultMessage: 'Total number of resources that are rejected for this role',
    description: '',
  },
  errorsAddingResource: {
    id: 'resources.table.errors.adding_resource',
    defaultMessage: 'Error adding resource',
    description: '',
  },
  errorsRemovingResource: {
    id: 'resources.table.errors.removing_resource',
    defaultMessage: 'Error replacing resource',
    description: '',
  },
  errorsReplacingResource: {
    id: 'resources.table.errors.replacing_resource',
    defaultMessage: 'Error replacing resource',
    description: '',
  },
  errorsUsers: {
    id: 'resources.table.errors.users_missing',
    defaultMessage: 'User is missing',
    description: '',
  },
  menuChangeResource: {
    id: 'resources.table.menu.change_resource',
    defaultMessage: 'Change Resource',
    description: '',
  },
  menuEditUser: {
    id: 'resources.table.menu.edit_user',
    defaultMessage: 'Edit User',
    description: '',
  },
  menuRemoveResource: {
    id: 'resources.table.menu.remove_resource',
    defaultMessage: 'Remove Resource',
    description: '',
  },
  menuReplaceResource: {
    id: 'resources.table.menu.replace_resource',
    defaultMessage: 'Replace Resource',
    description: '',
  },
  menuReplacePrimaryResource: {
    id: 'resources.table.menu.replace_primary_resource',
    defaultMessage: 'Make Primary',
    description: '',
  },
  menuEditResource: {
    id: 'resources.table.menu.edit_resource',
    defaultMessage: 'Edit Resource',
    description: '',
  },
  menuApproveResource: {
    id: 'resources.table.menu.approve_resource',
    defaultMessage: 'Approve Resource',
    description: '',
  },
  menuRejectResource: {
    id: 'resources.table.menu.reject_resource',
    defaultMessage: 'Reject Resource',
    description: '',
  },
});

export default messages;
