// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Table, Tooltip, Pagination, Spin } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import PropertySelectionModal from 'components/form/field/property-relationship/PropertySelectionModal';

// Services
import Notification from 'services/notification';

// Icons
import { EditOutlined, ArrowRightOutlined, InfoCircleOutlined, AimOutlined, LoadingOutlined } from '@ant-design/icons';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  currentPage: number;
  itemsPerPage: number;
  coordinates: {
    latitude: number;
    longitude: number;
  } | undefined;
  isLoadingGeoLocation: boolean;
  showPropertySelectionModal: boolean;
  showCurrentLocationTooltip: boolean;
};

class PropertyRelationshipField extends Component<Props, State> {

  state: State = {
    currentPage: 1,
    itemsPerPage: 5,
    coordinates: undefined,
    isLoadingGeoLocation: false,
    showPropertySelectionModal: false,
    showCurrentLocationTooltip: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;
    this.generateModifiedState(originalState, state, 'property_relationship');
    this.generateErrorState(state, 'property_relationship');
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any[], columnKey: string) => {
    const { setFieldErrorMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: any = [];

    if (!!field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  paginageItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  getPosition = (options?: PositionOptions): Promise<any> => {
    return new Promise((resolve, reject) => {
      return navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  };

  handleGeolocation = async () => {
    try {

      await new Promise((resolve) => this.setState({ isLoadingGeoLocation: true }, () => resolve(null)));
      const position = await this.getPosition();

      this.setState({
        coordinates: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        },
        showPropertySelectionModal: true
      });
    } catch (error) {
      Notification('error', 'Failed to get location');
      console.error(error);
    } finally {
      this.setState({
        isLoadingGeoLocation: false
      });
    }
  };

  renderTableSummary = (itemsPerPage: number, currentPage: number, total: number) => {
    return (
      <span>{ `Showing ${((itemsPerPage * currentPage) - itemsPerPage) + 1}-${currentPage * itemsPerPage} of ${total} item` }{ `${ total > 1 ? 's' : '' }` }</span>
    );
  };

  render = () => {
    const { record, field, config, clientId, border, state, isDisabled, onChange, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { showPropertySelectionModal, currentPage, itemsPerPage, isLoadingGeoLocation, showCurrentLocationTooltip } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_property_relationship`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const properties = state && state.map((value: any, index: number) => {
      return {
        key: index,
        ...value,
      };
    });
    const paginatedData = this.paginageItems(properties || [], currentPage, itemsPerPage);
    const columns = [
      {
        title: <span>Property</span>,
        key: 'title',
        width: 180,
        render: (row: any) => {
          return (
            <Link className='primaryColor' to={ `${row.target_path}` }>
              { row.target_title }
            </Link>
          );
        }
      },
      {
        title: <span>Ownership</span>,
        key: 'ownership',
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'ownership') || !row.ownership) return <>-</>;
          return (
            <span>{ row.ownership }</span>
          );
        }
      },
      {
        title: <span>Location</span>,
        key: 'location',
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'location') || !row.location) return <>-</>;
          return (
            <Link className='primaryColor' to={ `${row.location.path}` }>
              { row.location.title }
            </Link>
          );
        }
      },
      {
        title: <span>Regions</span>,
        key: 'regions',
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'region_trees')) return <>-</>;
          return (
            <>
              { row.region_trees.map((regions: any, index: number) => (
                <div key={ index }>
                  { regions.map((region: any, _index: number) => (
                    <span key={ _index }>
                      { regions.length === _index + 1 ? (
                          <span>{ region }</span>
                        ) : (
                          <span><span>{ region }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                        )
                      }
                    </span>
                  ) ) }
                </div>
              ) ) }
            </>
          );
        }
      }
    ]
    .filter((column) => {
      if (!field.config?.showOwnership) {
        return column.key !== 'ownership';
      }
      return column;
    });

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 }
        label={ field.label }
        errors={ errors }
        hideErrorInfo
        required={ field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        isModified={ _.isEmpty(errors) && isModified }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        rightActions={[
          {
            node: (
              <>
                { !isDisabled &&
                  <span className="d-f ai-c">
                    <Tooltip
                      placement="topRight"
                      title={ 'Use Current Location' }
                      visible={ showCurrentLocationTooltip }
                      onVisibleChange={ (visible: boolean) => {
                        this.setState({ showCurrentLocationTooltip: visible });
                      } }
                    >
                      { isLoadingGeoLocation ? (
                        <Spin size={ 'small' } indicator={ <LoadingOutlined /> } />
                      ) : (
                        <AimOutlined
                          className="link fsz-md"
                          onClick={ () => this.setState({
                            isLoadingGeoLocation: true,
                            showCurrentLocationTooltip: false,
                          }, () => {
                            this.handleGeolocation();
                          }) }
                        />
                      ) }
                    </Tooltip>
                    <EditOutlined
                      className="link fsz-md"
                      style={{ padding: '5px 10px'}}
                      onClick={ () => this.setState({ showPropertySelectionModal: true }) }
                    />
                  </span>
                }
                { !_.isEmpty(errors) &&
                  <Tooltip
                    overlayClassName="text-white Field-Error"
                    placement="topRight"
                    title={ errors.join(', ') }
                  >
                    <InfoCircleOutlined className="Field-Error-Icon" />
                  </Tooltip>
                }
              </>
            )
          }
        ]}
      >
        <>
          <Table
            size={ 'small' }
            columns={ columns }
            dataSource={ paginatedData || [] }
            pagination={ false }
          />
          { this.canSelectMultiple() &&
            <div className='d-f jc-sb mT-10'>
              <div>
                { this.renderTableSummary(itemsPerPage, currentPage, properties.length) }
              </div>
              <div>
                <Pagination
                  size={ 'small' }
                  disabled={ _.isEmpty(paginatedData) }
                  showSizeChanger={ false }
                  current={ currentPage }
                  total={ properties.length }
                  pageSize={ itemsPerPage }
                  onChange={ page => {
                    this.setState({
                      currentPage: page
                    });
                  } }
                />
              </div>
            </div>
          }
        </>
        { showPropertySelectionModal &&
          <PropertySelectionModal
            clientId={ clientId }
            record={ record }
            field={ field }
            coordinates={ this.state.coordinates }
            onSave={ (_field: FormField) => {
              this.setState({ showPropertySelectionModal: false }, () => {
                onChange(field, _field.values, config);
              });
            } }
            onClose={ () => this.setState({ showPropertySelectionModal: false }) }
          />
        }
      </FieldWrapper>
    );
  };
};

export default PropertyRelationshipField;
