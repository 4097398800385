// Interfaces
import { FieldConfig, FormField } from 'components/form/form-wrapper';

export interface FieldValue {
  key: string;
  service: string | null;
  total_value: string | null;
  total_invoiced_value: string | null;
  available_value: string | null;
  spent_value: string | null;
  remaining_value: string | null;
  currency_code: string | null;
  description: string | null;
  unit_cost: string | null;
  volume: string | null;
  vat: string | null;
  net_total: string | null;
  net_total_forecast: string | null;
  finance_template_id: number | null;
};

export interface CoaOption {
  id: number;
  title: string;
  type: string;
  bundle: string;
  reference: string;
  spent_value: number;
  available_value: number;
  children: CoaOption[];
};

export interface CostFieldColumnConfig {
  [key: string]: {
    title: string | null;
    description: string | null;
    hide: boolean;
    lock: boolean;
  };
};

export interface CostFieldConfig extends FieldConfig {
  columns?: IColumn[];
  can_delete?: boolean;
};

export interface Field extends FormField {
  /**
   * property is of type FieldValue[]
   */
  values: any[];
  coa_options?: CoaOption[];
  preset_values?: PresetValue[];
  finance_templates?: FinanceTemplate[];
  config: CostFieldConfig;
  preset_values_error?: string;
  default_vat?: number;
  threshold?: {
    available: number;
    spent: number;
    threshold_tooltip: string;
    spent_tooltip: string;
    billable_tooltip: string;
  };
};

export interface PresetValue {
  target_id: number | null;
  target_type: string;
  target_bundle: string;
  currency_code: string;
  total_value: string;
  description: string;
  finance_template_id: number | null;
  unit_cost: string;
  volume: string;
  vat: string;
  net_total: string;
  available_value: string | null;
  spent_value: string | null;
};

export interface FinanceTemplate {
  id: number;
  title: string;
  reference: string;
  coa_map: string[];
};

export interface Modified {
  [key: string]: string[];
  removed?: any;
};

export interface ErrorState {
  [key: string]: string[];
};

export enum IColumnType {
  Service = 'service',
  FinanceTemplate = 'finance_template',
  Select = 'select',
  Text = 'text',
  Number = 'number',
  Percentage = 'percentage',
  Currency = 'currency',
};

export interface IColumn {
  type: IColumnType;
  key: string;
  reference: string;
  formula: string | null;
  title: string;
  description: string;
  options?: any[];
  width?: number;
  required: boolean;
  hidden: boolean;
  locked: boolean;
};