// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from "components/basic-list";
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import { ProcessImport } from 'components/import/ProcessImport';
import { Form, Input, Modal } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  columns: any[];
  items: any[];
  isFetching: boolean;
  isImporting: boolean;
  isExporting: boolean;
  importOptions: any;
  showImportOptionModal: boolean;
  showImportModal: boolean;
};

class MaintenancePlans extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    isFetching: false,
    isImporting: false,
    isExporting: false,
    importOptions: null,
    showImportOptionModal: false,
    showImportModal: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Workplace Services', path: '/workplace-services' },
      { title: 'Maintenance Plan', path: '/workplace-services/maintenance-plane' }
    ], false);

    this.props.setSecondarySidebarRoutes([]);

    try {
      this.loadEntities();
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  loadEntities = async () => {
    const { client_id } = this.props;

    await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
    const response = await API.get(`client/${client_id}/asset-maintenance-plan`);

    if (this.mounted && response?.columns && response?.data) {
      this.setState({
        columns: response.columns,
        items: response.data,
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList columns={ this.state.columns } items={ this.state.items } exportTitle={ 'Maintenance Plan' } />;
  };

  renderImportModal = () => {
    const { client_id } = this.props;
    const { importOptions } = this.state;

    return (
      <ProcessImport
        title={ `Import - ${importOptions.title}` }
        clientId={ client_id }
        options={ importOptions }
        filePrefix={ `pacs_export_new_asset_maintenance_plan_${moment().format('YYYY-MM-DD')}.xlsx` }
        verifyEndpoint={ `category/asset-maintenance-plan/import/verify` }
        importEndpoint={ `category/asset-maintenance-plan/import/run` }
        downloadEndpoint={ `category/asset-maintenance-plan/import/download` }
        onClose={ (shouldReload: boolean) => {
          this.setState({
            importOptions: null,
            showImportModal: false,
            isImporting: false,
          }, () => {
            if (shouldReload) {
              this.loadEntities();
            }
          });
        } }
      />
    );
  };

  renderImportOptionModal = () => {
    const { importOptions } = this.state;

    return (
      <Modal
        title={ 'New Maintenance Plan' }
        maskClosable
        closable
        centered
        visible
        onCancel={ () => this.setState({
          showImportOptionModal: false
        }) }
        onOk={ () => this.setState({
          showImportOptionModal: false,
          showImportModal: true
        }) }
        okText={ 'Proceed' }
        okButtonProps={ {
          disabled: !importOptions?.title,
        } }
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ importOptions?.title }
              onChange={ (e) => {
                this.setState({
                  importOptions: Object.assign({}, { title: e.target.value })
                });
              } }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  onExport = async () => {
    const { client_id } = this.props;
    try {
      this.setState({
        isExporting: true
      });

      await API.download(`client/${client_id}/category/asset-maintenance-plan/export`, `pacs_export_asset_maintenance_plan_${moment().format('YYYY-MM-DD')}.xlsx`);
    } catch (error) {
      Notification('error', 'Export Failed');
    } finally {
      this.setState({
        isExporting: false
      });
    }
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { isFetching, isImporting, isExporting, showImportModal, showImportOptionModal } = this.state;

    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      }
    ];

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'category_asset_maintenance_plan_create')) {
      actions.push({
        node: 'Create Maintenance Plan',
        onClick: () => history.push('/workplace-services/maintenance-plan/create'),
      });
    }

    if (hasPermission(permissions, 'category_asset_maintenance_plan_import')) {
      actions.push({
        node: 'Import Maintenance Plan',
        onClick: () => this.mounted && this.setState({ showImportOptionModal: true }),
        isLoading: isImporting
      });
    }

    if (hasPermission(permissions, 'category_asset_maintenance_plan_export')) {
      actions.push({
        node: 'Export Template',
        onClick: () => this.mounted && this.onExport(),
        isLoading: isExporting
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Maintenance Plan' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { showImportOptionModal && this.renderImportOptionModal() }
        { showImportModal && this.renderImportModal() }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MaintenancePlans));
