// Libs
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Tooltip, Select, Button, Popconfirm, Table, Input, Divider, Menu } from 'antd';
import Flag from 'components/flag';

// View
import CreateRecordView from 'views/common/CreateRecordView';

// Icons
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Utilities
import { isBlank } from 'utils/utils';
import { validEmail } from 'utils/utils';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

// Styles
import './Contact.scss';

const { Option } = Select;
const { SubMenu } = Menu;
const API: Api = new Api();

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

const getBlankState: any = () => {
  return {
    key: uuidv4(),
    id: null,
    contact_id: null,
    company_id: '',
    name: '',
    email: '',
    phone_country_code: null,
    phone_number: '',
  };
};

interface Props {
  originalValues: any;
  errors?: Record<string, string[]>;
  numberFormat: any;
  field: FormField;
  clientId: number;
  entity: string;
  record: RecordFormEntity;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onValueChange(state: any): void;
  onFieldChange(field: FormField): void;
};

interface State {
  deleteConfirmId: any;
  createCompanyType: string | null;
  activeRow: number | null;
  isCreateLoading: boolean;
};

class ContactField extends React.Component<Props, State> {

  companySelector: any = React.createRef();

  state: State = {
    deleteConfirmId: null,
    createCompanyType: null,
    activeRow: null,
    isCreateLoading: false,
  };

  componentDidMount = () => {
    const { field } = this.props;

    // Sets required contacts
    this.handleChange(this.getContacts(field));
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues);
    }
  };

  getContacts = (field: any) => {
    const contacts = _.has(field, 'contacts') ? field.contacts : [];
    let values = _.has(field, 'values') ? field.values : [];

    const missingContacts = contacts
      .filter((contact: any) => {
        if (contact.required) {
          const exists = values.find((value: any) => value.contact_id === contact.id);
          if (!exists) {
            return true;
          }
        }
        return false;
      })
      .map((contact: any) => {
        return {
          ...getBlankState(),
          contact_id: contact.id
        };
      });

    return values.concat(missingContacts);
  };

  canModify = (field: any, contact_id: number) => {

    if (!contact_id) return true;

    const contacts = _.has(field, 'contacts') ? field.contacts : [];
    const values = _.has(field, 'values') ? field.values : [];

    const isRequired = contacts.some((contact: any) => contact.id === contact_id && contact.required);
    if (isRequired) {
      const _contacts = values.filter((contact: any) => contact.contact_id === contact_id);
      return _contacts.length > 1;
    }

    return true;
  };

  getErrors = (values: any) => {
    const errors: any = {};

    // Check for empty fields
    values.forEach((value: any) => {
      const schema = {
        contact_id: (_value: any) => !isBlank(_value),
        company_id: (_value: any) => !isBlank(_value),
        name: (_value: any) => !isBlank(_value),
        email: (_value: any) => !isBlank(_value) && validEmail(_value),
      };

      const _validate = (_value: any, schema: any) => Object
        .keys(schema)
        .filter(key => !schema[key](_value[key]))
        .map(key => key);

      if (!_.isEmpty(_validate(value, schema))) {
        errors[value.key] = _validate(value, schema);
      }
    });

    return errors;
  };

  getModified = (values: any, originalValues: any) => {

    const modified: any = {};

    if (!_.isEqual(values, originalValues)) {
      values.forEach((newValue: any) => {
        if (originalValues.some((_value: any) => _value.key === newValue.key)) {
          const oldValue = originalValues.find((_oldValue: any) => _oldValue.key === newValue.key);
          if (!_.isEqual(newValue, oldValue)) {
            Object.keys(newValue)
              .filter((key: string) => ['contact_id', 'company_id', 'name', 'email', 'phone_country_code', 'phone_number'].includes(key))
              .forEach((key: string) => {
                if (!oldValue) {
                  modified[newValue.key] = ['contact_id', 'company_id', 'name', 'email', 'phone_country_code', 'phone_number'];
                } else if (newValue[key] !== oldValue[key]) {
                  if (!!modified[newValue.key] && !modified[newValue.key].includes(key)) {
                    modified[newValue.key] = modified[newValue.key].concat(key);
                  } else {
                    modified[newValue.key] = [key];
                  }
                }
              });
          } else {
            // Removed something
            modified['removed'] = null;
          }
        } else {
          modified[newValue.key] = ['contact_id', 'company_id', 'name', 'email', 'phone_country_code', 'phone_number'];
        }
      });
    }

    return modified;
  };

  validate = (values: any, originalValues: any) => {
    const { field, config, setFieldErrorMessage, setFieldModifiedMessage } = this.props;

    const errors = this.getErrors(values);
    const modified = this.getModified(values, originalValues);

    setFieldErrorMessage(`${field.id}`, _.isEmpty(errors) ? undefined : {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: []
      },
      errors: errors
    });

    setFieldModifiedMessage(`${field.id}`, _.isEmpty(modified) ? undefined : {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: []
      },
      modified: modified
    });
  };

  hasError = (fieldErrorMessages: any, fieldKey: string | number, fieldType: string) => {
    return _.has(fieldErrorMessages, ['contacts', 'errors', fieldKey]) && fieldErrorMessages['contacts']['errors'][fieldKey].includes(fieldType);
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string | number, fieldType: string) => {
    return _.has(fieldModifiedMessages, ['contacts', 'modified', fieldKey]) && fieldModifiedMessages['contacts']['modified'][fieldKey].includes(fieldType);
  };

  handleChange = (values: any) => {
    this.props.onValueChange(values);
  };

  delete = (identifier: number, values: any) => {
    return values.filter((value: any) => value.key !== identifier);
  };

  modifyValues = (identifier: number, values: any, newValue: any, key: any) => {
    return values.map((value: any) => {

      if (value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value
      };
    });
  };

  createCompany = (type: string, rowIndex: number) => {
    // Hide dropdown menu
    this.companySelector.blur();

    this.setState({
      createCompanyType: type,
      isCreateLoading: true,
      activeRow: rowIndex,
    });
  };

  renderTable = (field: any) => {
    const { isDisabled, fieldErrorMessages, fieldModifiedMessages, clientId, record, onFieldChange } = this.props;
    const { createCompanyType, isCreateLoading, activeRow } = this.state;
    const contacts = field.values || [];
    const columns = [
      {
        key: 'contact_id',
        title: <span>Contact</span>,
        width: 180,
        render: (row: any) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'contact_id');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'contact_id');
          const contactSelectField = (
            <Select
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              dropdownMatchSelectWidth={ false }
              disabled={ isDisabled || !this.canModify(field, row.contact_id) }
              placeholder={ 'Contact' }
              value={ row.contact_id }
              onChange={ (contact_id: number) => {

                const contact: any = !!contact_id && !_.isEmpty(field.contacts) && field.contacts.find((contact: any) => contact.id === contact_id);
                const companies = !!contact && _.has(contact, 'companies') ? contact.companies : [];

                if (companies.length === 1) {
                  // Set contact and default company
                  this.handleChange(this.modifyValues(row.key, this.modifyValues(row.key, _.cloneDeep(field.values), contact_id, 'contact_id'), companies[0].id, 'company_id'));
                } else {
                  // Set contact and clear company
                  this.handleChange(this.modifyValues(row.key, this.modifyValues(row.key, _.cloneDeep(field.values), contact_id, 'contact_id'), null, 'company_id'));
                }
              } }
            >
              { !!field.contacts && field.contacts.map((type: any) => (
                <Option key={ type.id } value={ type.id }>
                  { type.title }
                </Option>
              )) }
            </Select>
          );

          if (!this.canModify(field, row.contact_id)) {
            return (
              <Tooltip placement={ 'topLeft' } title={ 'This is a required contact and cannot be changed.' }>
                { contactSelectField }
              </Tooltip>
            );
          }

          return contactSelectField;
        }
      },
      {
        key: 'company_id',
        title: <span>Company</span>,
        width: 180,
        render: (row: any, __: any, rowIndex: any) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'company_id');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'company_id');
          const contact: any = !!row.contact_id && !_.isEmpty(field.contacts) && field.contacts.find((contact: any) => contact.id === row.contact_id);
          const companies = !!contact && _.has(contact, 'companies') ? contact.companies : [];
          const supportedCompanyTypes = _.has(contact, 'supported_company_types') && !_.isEmpty(contact.supported_company_types) ? contact.supported_company_types : [];
          const canCreateCompany = _.has(contact, 'config') && !!contact.config.can_create;
          const customMenu = (
            <Menu
              mode="vertical"
              triggerSubMenuAction={ 'click' }
              expandIcon={ <></> }
            >
              <SubMenu
                key={ 'create' }
                disabled={ !canCreateCompany }
                icon={ <PlusOutlined /> }
                title={ 'Create Company' }
                onTitleClick={ () => supportedCompanyTypes.length === 1 && this.createCompany(supportedCompanyTypes[0].type, rowIndex) }
              >
                { supportedCompanyTypes.length > 1 && supportedCompanyTypes.map((company_type: any) => (
                  <Menu.Item key={ company_type.type } onClick={ () => this.createCompany(company_type.type, rowIndex) }>
                    { company_type.label }
                  </Menu.Item>
                ) ) }
              </SubMenu>
            </Menu>
          );
          return (
            <Select
              ref={ companySelector => this.companySelector = companySelector }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled || !contact }
              loading={ isCreateLoading }
              placeholder={ 'Company' }
              onChange={ (value: number) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), value, 'company_id')) }
              value={ row.company_id || undefined }
              dropdownMatchSelectWidth={ false }
              dropdownRender={ _menu => (
                <>
                  { _menu }
                  { !isDisabled && (
                    <>
                      <Divider style={{ margin: '4px 0' }} />
                      <>
                        { canCreateCompany ? (
                          customMenu
                        ) : (
                          <Tooltip
                            placement="top"
                            title={ 'Inline Creation is not available for this Contact Type' }
                          >
                            { customMenu }
                          </Tooltip>
                        ) }
                      </>
                    </>
                  ) }
                </>
              ) }
            >
              { companies.map((company: any) => (
                <Option key={ company.id } value={ company.id }>
                  { company.title }
                </Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'name',
        title: <span>Name</span>,
        render: (row: any) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'name');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'name');
          return (
            <Input
              className={ classNames('ContactField', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled }
              onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), e.target.value, 'name')) }
              placeholder={ 'Name' }
              defaultValue={ row.name }
            />
          );
        }
      },
      {
        key: 'email',
        title: <span>Email</span>,
        render: (row: any) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'email');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'email');
          return (
            <Input
              className={ classNames('ContactField', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled }
              onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), e.target.value, 'email')) }
              placeholder={ 'Email' }
              defaultValue={ row.email }
            />
          );
        }
      },
      {
        key: 'phone',
        title: 'Phone',
        render: (row: any) => {
          return (
            <Input.Group compact className="d-f">
              <Select
                style={{ minWidth: 80 }}
                showSearch
                allowClear
                className={ classNames('Select-Field d-if', {
                  'Select-Field--has-warning border-warning': this.isModified(fieldModifiedMessages, row.key, 'phone_country_code')
                }) }
                dropdownMatchSelectWidth={ false }
                optionLabelProp={ 'label' }
                disabled={ isDisabled }
                placeholder={ 'Code' }
                filterOption={(input: any, option: any) => {
                  return field.countries.find((country: any) => country.code.toLowerCase() === option.value.toLowerCase() && country.title.toLowerCase().includes(input.toLowerCase()) );
                } }
                onChange={ (value: number) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), value, 'phone_country_code')) }
                onClear={ () => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), null, 'phone_country_code')) }
                value={ row.phone_country_code }
              >
                { !!field.countries && field.countries
                  .sort((a: any, b: any) => a.title.localeCompare(b.title))
                  .map((country: any) => (
                    <Option key={ country.id } value={ country.code } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                      <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                    </Option>
                  ))
                }
              </Select>
              <Input
                style={{ minWidth: 100 }}
                className={ classNames('ContactField d-if fx-1', {
                  'Field--has-warning border-warning': this.isModified(fieldModifiedMessages, row.key, 'phone_number')
                }) }
                disabled={ isDisabled }
                onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), e.target.value, 'phone_number')) }
                placeholder={ 'Phone Number' }
                defaultValue={ row.phone_number }
              />
            </Input.Group>
          );
        }
      },
      {
        key: 'actions',
        title: !isDisabled ? (
          <Button
            style={{
              marginLeft: 5,
              padding: '4px 7px',
              width: '32px',
            }}
            onClick={ () => this.handleChange([].concat(_.cloneDeep(field.values), getBlankState())) }
          >
            <PlusOutlined />
          </Button>
        ): (
          <></>
        ),
        dataIndex: '',
        align: Alignment.Right,
        width: '50px',
        render: (row: any) => {

          if (!this.canModify(field, row.contact_id)) {
            return (
              <Tooltip placement={ 'topRight' } title={ 'This is a required contact and cannot be removed.' }>
                <Button
                  disabled
                  style={{
                    padding: '4px 7px',
                    width: '32px',
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            );
          }

          return (
            <Popconfirm
              title={ 'Are you sure?' }
              icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
              visible={ this.state.deleteConfirmId === row.key }
              okButtonProps={{
                danger: true
              }}
              onConfirm={ () => {
                this.setState({ deleteConfirmId: null }, () => this.handleChange(this.delete(row.key, _.cloneDeep(field.values))) );
              }}
              onCancel={ () => this.setState({ deleteConfirmId: null }) }
            >
              <Button
                disabled={ isDisabled }
                style={{
                  padding: '4px 7px',
                  width: '32px',
                }}
                onClick={ () => this.setState({ deleteConfirmId: row.key }) }
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          );
        },
      }
    ];

    return (
      <>
        <Table
          size={ 'small' }
          className="ContactField"
          columns={ columns }
          dataSource={ contacts || [] }
          pagination={ false }
        />
        { createCompanyType && activeRow !== null &&
          <CreateRecordView
            type={ _.kebabCase(createCompanyType) }
            entity={ 'company' }
            onReady={ () => this.setState({ isCreateLoading: false }) }
            onClose={ () => this.setState({ createCompanyType: null }) }
            onCreated={ async (_record: any) => {
              try {

                const contacts = await API.get(`client/${clientId}/${record.bundle}/${record.type}/${record.id}/field/contact/${field.id}/refresh-companies`);
                onFieldChange({
                  ...field,
                  contacts: contacts,
                  values: _.set(_.cloneDeep(field.values), [activeRow], { ...field.values[activeRow], company_id: _record.id })
                });

              } catch (error) {
                Notification('error', 'Failed to create Company', 'Failed');
              } finally {
                this.setState({
                  createCompanyType: null,
                });
              }
            } }
          />
        }
      </>
    );
  };

  render = () => {
    const { field, config } = this.props;
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        border
        col={ config.fieldColSpan }
        label={ field.label }
        versionChanged={ !!field.config.version_changed }
      >
        { this.renderTable(field) }
      </FieldWrapper>
    );
  };
};

export default ContactField;
