// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import { Action as DropdownAction } from 'components/dropdown';
import Dropdown from 'components/dropdown';
import BasicList, { Config } from "components/basic-list";

// Services
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  error: {
    id: 'general.error',
    defaultMessage: 'Error',
    description: '',
  },
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

export const BREADCRUMBS = [
  { title: 'Home', path: '/' },
  { title: 'Customers', path: '/customer' },
  { title: 'Landlords', path: '/customer/landlords' },
];

interface Props {
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
};

class Landlords extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    const { intl: { formatMessage }, user, setBreadcrumbs } = this.props;

    this.mounted = true;
    setBreadcrumbs(BREADCRUMBS, false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${user.active_client}/company/landlord`);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error(`${formatMessage(messages.error)}:`, error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } />;
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { isFetching } = this.state;

    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      }
    ];

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'company_landlord_create')) {
      actions.push({
        node: 'Create Landlord',
        onClick: () => history.push('/portfolio/landlords/create'),
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Landlords' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Landlords));
