// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import CreateRecordView from 'views/common/CreateRecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

// Utils
import history from 'utils/history';

interface Props {
  bundle: string;
  type: string;
  redirectUrl: string;
};

class ListViewCreateModal extends React.Component<Props> {
  render = () => {
    const { bundle, type, redirectUrl } = this.props;
    return (
      <CreateRecordView
        entity={ bundle }
        type={ type }
        onCreated={ (record: RecordFormEntity) => {
          history.push(record.path);
        } }
        onClose={ () => {
          history.push(redirectUrl);
        } }
      />
    );
  };
};

export default ListViewCreateModal;
