// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Views
import Contracts from 'views/supplier/Contracts';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  isFetching: boolean;
  record: RecordFormEntity | null;
};

class ContactContainer extends React.Component<Props> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    record: null
  };

  componentDidMount = () => {
    this.mounted = true;
    this.instantiate();
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  instantiate = async () => {
    try {
      // Set default breadcrumbs
      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Suppliers', path: '/suppliers' },
        { title: 'Contracts', path: '/suppliers/contracts' },
      ], false);

      // Set default routes
      this.props.setSecondarySidebarRoutes([]);

    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  render = () => {
    const { permissions } = this.props;
    const { isFetching } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Route exact path={ ['/suppliers/contracts', '/suppliers/contracts/create' ] } render={ () => {
            return (
              <>
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_contract_view_list') }>
                  <Contracts />
                </RestrictionWrapper>
                <Route
                  exact
                  path='/suppliers/contracts/create'
                  render={ () => (
                    <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_contract_create') }>
                      <ListViewCreateModal
                        bundle={ 'record' }
                        type={ 'contract' }
                        redirectUrl={ '/suppliers/contracts' }
                      />
                    </RestrictionWrapper>
                  ) }
                />
              </>
            );
          } } />

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
