// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Containers
import IncidentContainer from 'containers/ehs/incident/IncidentContainer';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Views
import Incidents from 'views/ehs/Incidents';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

interface Props {
  permissions: UserPermissions;
};

class IncidentsContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/ehs/incidents', '/ehs/incidents/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_incident_view_list') }>
                <Incidents />
              </RestrictionWrapper>
              <Route
                exact
                path='/ehs/incidents/create'
                render={ () => (
                  <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_incident_create') }>
                    <ListViewCreateModal
                      bundle={ 'record' }
                      type={ 'incident' }
                      redirectUrl={ '/ehs/incidents' }
                    />
                  </RestrictionWrapper>
                ) }
              />
            </>
          );
        } } />

        <Route path="/ehs/incidents/:record_id" component={ IncidentContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(IncidentsContainer);