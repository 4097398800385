// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Input } from 'antd';
import NumberFormat from 'react-number-format';

// Interfaces
import { IField } from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';
import { Currency } from 'components/form/form-wrapper';

interface Props {
  field: IField;
  numberFormat: any;
  decimal: number;
  currency: Currency | undefined;
  isRequired: boolean;
  isModified: boolean;
  isDisabled: boolean;
  hasErrors: boolean;
  handleChange: (field: IField) => void;
};

export default function RatingValue({
  field,
  numberFormat,
  currency,
  isRequired,
  isModified,
  isDisabled,
  hasErrors,
  handleChange,
}: Props) {

  const value = !_.isEmpty(field.values) && _.has(field.values[0], 'energy_rating_value') ? field.values[0].energy_rating_value : undefined;

  return (
    <NumberFormat
      key={ JSON.stringify(value) }
      { ...numberFormat }
      fixedDecimalScale
      decimalScale={ 3 }
      prefix={ currency?.symbol }
      customInput={ Input }
      className={ classNames('Field pR-20 ta-r', {
        'Field--has-error border-danger': !!hasErrors,
        'Field--has-warning border-warning': isModified && !hasErrors,
      }) }
      required={ isRequired }
      disabled={ isDisabled }
      allowNegative={ false }
      value={ value }
      placeholder={ `${currency?.code} Per Unit` }
      onBlur={ (event: React.BaseSyntheticEvent) => {
        const values = !_.isEmpty(field.values) ? _.cloneDeep(field.values[0]) : [];
        handleChange(_.set(field, 'values', [{
          ...values,
          energy_rating_value: event.target.value !== '' ? parseFloat(event.target.value.replace(currency?.symbol || '', '').replaceAll(',', '')) : null
        }]));
      } }
    />
  );
};
