import { RoleEntity } from 'types/entities';

export enum Type {
  Range = 'range',
  Select = 'select',
}

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}

export enum ResourceStatus {
  Approved = 'Approved',
  Pending = 'Pending Approval',
  Rejected = 'Rejected',
  Requested = 'Requested'
}

export enum ResourceStatusKeys {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export enum ResourceType {
  Direct = 'DIRECT',
  Inherited = 'INHERITED',
}

export enum ResourceTypeValues {
  Direct = 'Direct',
  Inherited = 'Inherited',
}

export interface Column {
  title: string;
  dataIndex: string;
  key: string;
  type?: Type;
  align?: Alignment;
  render?: any;
  width?: number | string;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  active_client?: number;
  created_at: string;
  updated_at: string;
  full_name: string;
  phone: string;
  company: {
    id: number;
    title: string;
  };
}

export interface Resource {
  id: number;
  role?: RoleEntity;
  role_id?: number;
  user_id: number;
  regions: RegionRecord[];
  type: ResourceType | string;
  primary?: boolean;
  status: ResourceStatus | string;
  user: User;
  entity_preview: ResourcePreview;
  version_changed: boolean;
}

export interface ResourcePreview {
  id: number;
  bundle: string;
  path: string;
  title: string;
  type: string;
}

export interface AvailableResourceRecord {
  id: number;
  reference: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  required?: boolean;
  group_id: number | null;
}

export interface ResourceRecord extends AvailableResourceRecord {
  resources: Resource[];
}

export interface RoleRecord {
  regions: any,
  roles: ResourceRecord,
}

export interface RegionRecord {
  id: number,
  title: string,
}

export interface ResourceListing extends Resource {
  key: string;
};

export interface ResourceRecordListing extends ResourceRecord {
  key: string;
  resources: ResourceListing[];
};

export interface ResourceRequest {
  id?: number;
  role_id: number;
  user_id: number;
  regions: number[];
};

export interface NestedColumn {
  id: string;
  title: string;
  key: string;
  dataIndex: string;
  type: Type;
  items?: Array<string | number>;
  itemLabels?: string[];
  width?: number | string;
};
