// Libs
import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import { Modal, Button, Tooltip, Tabs, Empty } from 'antd';
import TemplateTable from 'components/template-modal/TemplateTable';

// Icons
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Interfaces
import { FormField } from 'components/form/form-wrapper';
import { ITemplate, ITemplateColumn } from 'components/template-modal/TemplateModal.interface';
import { RecordFormEntity } from 'types/entities';

// Styles
import 'components/antd-modal/AntdModal.scss';
import './TemplateModal.scss';

const { TabPane } = Tabs;

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface Coa {
  id: number;
  title: string;
  description: string | null;
  key: string;
  reference: string;
  compatible_template_ids: number[];
  children: Coa[];
};

interface FinancialYear {
  start_date: string;
  end_date: string;
};

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

interface Props {
  record?: RecordFormEntity,
  coa?: Coa,
  contractPricingId?: number | null,
  financialYear?: FinancialYear | null,
  defaultCurrency?: Currency | null,
  templates: ITemplate[],
  values: any;
  numberFormat: any;
  isDisabled?: boolean;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  activeTemplateId: number | null;
  values: any;
  errors: any[];
};

class TemplateModal extends React.Component<Props, State> {

  state: State = {
    activeTemplateId: !_.isEmpty(this.props.templates) ? this.props.templates[0].id : null,
    values: _.cloneDeep(this.props.values),
    errors: [],
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.values, this.props.values)) {
      this.setState({
        values: this.props.values
      });
    }
  };

  calculateTotals = (templates: ITemplate[], rows: any) => {
    return rows
      .reduce((_acc: any, row: any) => {

        const template = templates.find((template: ITemplate) => template.id === row.template_id);
        const totalColumn = template && template.columns.find((column: ITemplateColumn) => column.context === 'TOTAL');

        if (totalColumn && _.has(row, `values.${totalColumn.reference}`)) {
          const exchangeRate: string = _.has(row, `values.exchange_rate`) && !!row.values.exchange_rate ? row.values.exchange_rate : 1;
          return parseFloat(_acc) + (parseFloat(row.values[totalColumn.reference]) * parseFloat(exchangeRate));
        }

        return parseFloat(_acc);
      }, 0);
  };

  calculateTemplateTotals = (template: ITemplate, rows: any) => {
    const totalColumn = template.columns.find((column: ITemplateColumn) => column.context === 'TOTAL');

    if (!totalColumn) return 0;

    return rows
      .filter((row: any) => row.template_id === template.id)
      .reduce((_acc: any, row: any) => {
        if (_.has(row, `values.${totalColumn.reference}`)) {
          const exchangeRate: string = _.has(row, `values.exchange_rate`) && !!row.values.exchange_rate ? row.values.exchange_rate : 1;
          return parseFloat(_acc) + (parseFloat(row.values[totalColumn.reference]) * parseFloat(exchangeRate));
        }
        return _acc;
      }, 0);
  };

  renderTabTitle = (template: ITemplate, values: any, errors: any) => {
    const rows = values.filter((value: any) => value.template_id === template.id);

    // Check if we got any errors
    const hasError = Object.keys(errors).find((errorKey: any) => {
      const value = values.find((value: any) => value.key === errorKey);
      return !!value && value.template_id === template.id;
    });

    return (
      <>
        <div>
          <span
            className={ classNames({
              'text-danger': !!hasError
            }) }
          >
            { `${template.title} (${!_.isEmpty(rows) ? rows.length : 0 })` }
          </span>
          { !!hasError &&
            <span>
              <Tooltip
                overlayClassName="text-white"
                placement="top"
                title={ "Please resolve all errors" }
              >
                <WarningIcon className="text-danger mL-5 va-s" height={ 20 } width={ 20 } />
              </Tooltip>
            </span>
          }
        </div>
        <div>
          <NumberFormat
            className='default-grey'
            { ...this.props.numberFormat }
            fixedDecimalScale
            decimalScale={ 2 }
            value={ this.calculateTemplateTotals(template, values) }
            prefix={ this.props.defaultCurrency ? this.props.defaultCurrency.symbol : undefined }
            displayType={ 'text' }
          />
        </div>
      </>
    );
  };

  render = () => {
    const { record, coa, templates, isDisabled } = this.props;
    const { values, errors, activeTemplateId } = this.state;
    const hasErrors = !_.isEmpty(Object.values(errors));
    const template = templates.find((template: ITemplate) => template.id === activeTemplateId);

    return (
      <Modal
        wrapClassName='TemplateModal AntdModal--fullscreen'
        visible
        closable
        title={ (
          <div>
            { record && <div>{ record.title }</div> }
            <div className="fw-200">{ coa ? coa.title : 'Templates' }</div>
          </div>
        ) }
        footer={ (
          <>
            <Button
              key={ 'cancel' }
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            { !isDisabled &&
              <Tooltip key={ 'ok' } placement={ 'top' } title={ hasErrors ? 'Please resolve all errors' : 'To save your changes, be sure to save the record before leaving' }>
                <Button
                  className="mL-5"
                  type="primary"
                  disabled={ !template || hasErrors || _.isEqual(this.props.values, this.state.values) }
                  onClick={ () => {
                    this.props.onSave(values);
                    this.props.onClose();
                  } }
                >
                  Ok
                </Button>
              </Tooltip>
            }
          </>
        ) }
        onCancel={ () => this.props.onClose() }
      >
        { !template ? (
          <div className="d-f jc-c ai-c h-100p">
            <Empty description={ 'No available templates' } />
          </div>
        ) : (
          <Tabs
            className="TemplateModal--tabs"
            type="card"
            activeKey={ `${activeTemplateId}` }
            onChange={ (templateId: string) => {
              this.setState({
                activeTemplateId: Number(templateId)
              });
            } }
          >
            { templates.map((_template: ITemplate) => (
              <TabPane
                key={ _template.id }
                tab={ this.renderTabTitle(_template, values, errors) }
              >
                <TemplateTable
                  isDisabled={ isDisabled }
                  numberFormat={ this.props.numberFormat }
                  template={ template }
                  record={ this.props.record }
                  coa={ this.props.coa }
                  contractPricingId={ this.props.contractPricingId }
                  financialYear={ this.props.financialYear }
                  defaultCurrency={ this.props.defaultCurrency }
                  templates={ this.props.templates }
                  values={ this.state.values }
                  errors={ this.state.errors }
                  setValues={ (values: any, callback: () => void) => {
                    this.setState({
                      values: values
                    }, () => {
                      callback && callback();
                    });
                  } }
                  setErrors={ (errors: any) => {
                    this.setState({
                      errors: errors
                    });
                  } }
                />
              </TabPane>
            ) ) }
              <TabPane
                key={ 'total' }
                disabled
                tab={ (
                  <>
                    <div>
                      <span>
                        Total
                      </span>
                    </div>
                    <div>
                      <NumberFormat
                        className='default-grey'
                        { ...this.props.numberFormat }
                        fixedDecimalScale
                        decimalScale={ 2 }
                        value={ this.calculateTotals(templates, values) }
                        prefix={ this.props.defaultCurrency ? this.props.defaultCurrency.symbol : undefined }
                        displayType={ 'text' }
                      />
                    </div>
                  </>
                ) }
              />
          </Tabs>
        ) }
      </Modal>
    );
  };
};

export default TemplateModal;
