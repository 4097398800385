// Libs
import * as React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { Button } from 'antd';

// Styles
import './CoverModal.scss';

interface Action {
  label: string;
  onClick?: () => void;
  type?: 'link' | 'text' | 'primary' | 'dashed' | 'ghost' | 'default';
  isLoading?: boolean;
  isDisabled?: boolean;
};

interface Props {
  cover?: boolean;
  transparent?: boolean;
  lightBackdrop?: boolean;
  closeOnTop?: boolean;
  showCloseIcon?: boolean;
  isLoading?: boolean;
  disableBodyScroll?: boolean;
  enableCloseOutside?: boolean;
  smallHeader?: boolean;
  children: React.ReactNode;
  leftContent?: React.ReactNode;
  middleContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  actions?: Action[];
  style?: React.CSSProperties;
  onClose?(): void;
};

const portal: any = document.getElementById('portal');

export default class CoverModal extends React.Component<Props> {

  modal: any;

  constructor(props: Props) {
    super(props);
    this.modal = document.createElement('div');

    // Fixes problem with scrolling background content on touch devices.
    // Does not work on iOS 11. Only confirmed to work on Android 8.
    this.props.disableBodyScroll && document.body.classList.add('ovY-h');
  }

  componentDidMount() {
    portal.appendChild(this.modal);
  };

  componentWillUnmount = () => {
    portal.removeChild(this.modal);

    // Fixes problem with scrolling background content on touch devices.
    // Does not work on iOS 11. Only confirmed to work on Android 8.
    this.props.disableBodyScroll && document.body.classList.remove('ovY-h');
  };

  handleModalClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleModalClick = () => {
    this.props.enableCloseOutside && this.props.onClose && this.props.onClose();
  };

  handleContainerClick = (event: any) => {
    event.stopPropagation();
  };

  render = () => {
    const {
      cover,
      transparent,
      style,
      children,
      lightBackdrop,
      closeOnTop,
      leftContent,
      middleContent,
      rightContent,
      isLoading,
      showCloseIcon = true,
      smallHeader = false,
      actions = [],
    } = this.props;

    const modalClasses = classNames('Modal', {
      'Modal--cover': cover,
      'Modal--dialog': !cover,
      'Modal--lightBackdrop': lightBackdrop,
    });

    const containerClasses = classNames('Modal-container', {
      'u-animationBounceIn': !cover,
      'u-animationSlideIn': cover && !transparent,
      'bg-grey-100': !transparent,
    });

    return createPortal((
      <div
        className={ modalClasses }
        onClick={ this.handleModalClick }
      >
        <div
          className={ containerClasses }
          onClick={ this.handleContainerClick }
          style={ style }
        >
          <div className="h-100p bg-white">
            <div className="d-f fxd-c h-100p">
              <div className="fx-n ov-h">
                <div
                  className="Modal-navigation bg-white"
                  style={{ lineHeight: smallHeader ? '80px' : '102px' }}
                >
                  <div className="Modal-navigation-side">
                    { leftContent }
                  </div>
                  <div className="Modal-navigation-middle">
                    { middleContent }
                  </div>
                  <div className="Modal-navigation-side">
                    { showCloseIcon && !rightContent &&
                      <div className="fl-r">
                        <div
                          className={ classNames('Modal-navigation-close', {
                            'Modal-close-align-top': closeOnTop
                          }) }
                          onClick={ this.handleModalClose }
                        />
                      </div>
                    }
                    { !!rightContent && rightContent }
                  </div>
                </div>
              </div>
              <div className="fx-a ovY-a h-100p">
                <BlockingSpinner isLoading={ isLoading || false }>
                  { children }
                </BlockingSpinner>
              </div>
              { !_.isEmpty(actions) &&
                <div
                  className="d-f fx-a ai-fe jc-fe bg-white pT-10 pB-10 pR-20"
                >
                  { actions.map((action: Action, index: number) => (
                    <span key={ index } className="d-if ai-c mL-5">
                      <Button
                        autoFocus={ false }
                        type={ action?.type }
                        loading={ !!action?.isLoading }
                        onClick={ action?.onClick ? action.onClick : () => {} }
                        disabled={ !!action.isDisabled }
                      >
                        { action.label }
                      </Button>
                    </span>
                  ) ) }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    ), portal);
  };
};