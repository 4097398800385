// Interfaces
import { FieldConfig, FormField } from 'components/form/form-wrapper';
import { PhoneCountry } from 'components/form/field/phone';

export interface ITableField extends FormField {
  values: ITableFieldValue[];
  config: ITableFieldConfig;
};

export interface ITableFieldValue {
  [key: string]: any;
};

export interface ITableFieldConfig extends FieldConfig {
  columns?: IColumn[];
  can_delete?: boolean;
};

export interface ITableFieldColumnConfig {
  [key: string]: {
    title: string | null;
    description: string | null;
    hide: boolean;
    lock: boolean;
  };
};

export interface ModifiedState {
  [key: string]: string[];
  removed?: any;
};

export interface ErrorState {
  [key: string]: string[];
};

export enum IColumnType {
  Text = 'text',
  Email = 'email',
  Number = 'number',
  Percentage = 'percentage',
  Currency = 'currency',
  Phone = 'phone',
  Select = 'select',
  Category = 'category',
};

export interface IColumn {
  type: IColumnType;
  countries?: PhoneCountry[];
  key: string;
  reference: string;
  formula: string | null;
  title: string;
  description: string;
  options?: any[];
  config: {
    select_list: string;
    multiselect: boolean;
    width?: number;
    required: boolean;
    hidden: boolean;
    locked: boolean;
    tint?: string;
    decimal?: number;
  };
};