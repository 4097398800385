// Libs
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import { Tooltip, Button, Popconfirm, Table as AntTable } from 'antd';
import Column from 'components/form/field/table/Column';
import Summary from 'components/form/field/table/Summary';

// Icons
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import { nestedSet } from 'utils/utils';

// Interfaces
import {
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import {
  ITableFieldValue,
  ITableField,
  IColumnType,
  IColumn,
} from 'components/form/field/table/Table.interfaces';

interface Props {
  field: ITableField;
  numberFormat: any;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  handleChange: (values: ITableFieldValue[]) => void;
};

const getBlankState = (field: ITableField): Partial<ITableFieldValue> => {

  const newState: any = {
    key: uuidv4()
  };

  if (field?.config?.columns && !_.isEmpty(field?.config?.columns)) {
    field.config.columns
      .filter((column: IColumn) => !column?.config?.hidden && !column?.config?.locked)
      .forEach((column: IColumn) => {
        switch (column.type) {
          case IColumnType.Phone:
            newState[column.reference] = {
              dial_code: null,
              number: null,
            };
          break;
          default:
            newState[column.reference] = null;
          break;
        }
      });
  }

  return newState;
};

export default function Table(props: Props) {
  const { field, isDisabled, numberFormat, fieldErrorMessages, fieldModifiedMessages, handleChange } = props;
  const configColumns = field?.config?.columns || [];
  const values: ITableFieldValue[] = nestedSet(field?.values || []);
  const currencyCode: string = field.currency !== undefined && !_.isEmpty(field.currency) ? field.currency?.code : '';
  const canModify: boolean | undefined = !!field.config?.can_create && !isDisabled;

  const hasError = (rowKey: ITableFieldValue['key'], fieldKey: keyof ITableFieldValue): boolean => {
    return (
      _.has(fieldErrorMessages, [field.id, 'errors', rowKey]) &&
      fieldErrorMessages[field.id]['errors'][rowKey].includes(fieldKey)
    );
  };

  const isModified = (fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>, rowKey: ITableFieldValue['key'], fieldKey: keyof ITableFieldValue): boolean => {
    const fieldId = props.field.id;
    return (
      _.has(fieldModifiedMessages, [fieldId, 'modified', rowKey]) &&
      fieldModifiedMessages[fieldId]['modified'][rowKey].includes(fieldKey)
    );
  };

  const deleteRow = (identifier: ITableFieldValue['key'], rows: ITableFieldValue[]): ITableFieldValue[] => {
    return rows.filter((row: ITableFieldValue) => row.key !== identifier);
  };

  const columns: any[] = configColumns
    .filter((column: IColumn) => {
      return !column?.config?.hidden;
    })
    .map((column: IColumn) => {

      const type: IColumnType = column.type;
      const reference = column.reference;
      const tooltip = column?.description;
      const title = column.title || '-';
      const width = column?.config?.width || 200;
      const isRequired = !!column?.config?.required;
      const isLocked = !!column?.config?.locked;
      const tint = !!column?.config?.tint;

      return {
        key: reference,
        dataIndex: reference,
        width: width,
        onHeaderCell: () => {
          return { className: tint };
        },
        onCell: () => {
          return { className: tint };
        },
        title: (
          <div className='d-f ai-c'>
            <div className='d-f'>
              { title }
              { column.type === IColumnType.Currency && currencyCode && ` (${currencyCode})`}
            </div>
            { !!isRequired &&
              <span className="text-required mL-2 lh-1 fsz-md va-t">*</span>
            }
            { !!tooltip &&
              <Tooltip className="mL-5" placement="top" title={ tooltip }>
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            }
          </div>
        ),
        render: (__: any, row: ITableFieldValue) => {
          return (
            <Column
              field={ field }
              column={ column }
              row={ row }
              type={ type }
              numberFormat={ numberFormat }
              placeholder={ title }
              isLocked={ isLocked }
              isRequired={ isRequired }
              isModified={ isModified(fieldModifiedMessages, row.key, reference) }
              isDisabled={ !!isDisabled }
              hasErrors={ hasError(row.key, reference) }
              handleChange={ handleChange }
            />
          );
        }
      };
    });

  if (canModify) {
    columns.push({
      key: 'actions',
      title: (
        <Button
          className="ActionButton"
          onClick={ () => {
            handleChange([
              ...values,
              getBlankState(field)
            ]);
          } }
        >
          <PlusOutlined />
        </Button>
      ),
      render: (row: ITableFieldValue) => {
        return (
          <Popconfirm
            placement="topRight"
            title={ 'Are you sure?' }
            icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
            okButtonProps={ {
              danger: true,
              disabled: isDisabled,
            } }
            onConfirm={ () => {
              handleChange(deleteRow(row.key, values));
            } }
          >
            <Button
              style={ {
                padding: '4px 7px',
                width: '32px',
              } }
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
      align: 'center' as 'center',
      fixed: 'right' as 'right',
      width: 50,
    });
  }

  return (
    <AntTable
      className={ 'TableFieldTable' }
      dataSource={ nestedSet(values) }
      columns={ columns }
      rowKey={ 'key' }
      size={ 'small' }
      scroll={ {
        x: columns.reduce((acc: any, curr: any) => acc += curr.width, 0),
      } }
      pagination={ false }
      sticky
      bordered
      summary={ (rows: readonly ITableFieldValue[]) => {
        const showSummary = false;
        if (showSummary) {
          return (
            <Summary
              field={ field }
              columns={ columns }
              rows={ rows }
            />
          );
        }
        return undefined;
      } }
    />
  );
};
