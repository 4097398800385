// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Input } from 'antd';
import NumberFormat from 'react-number-format';

// Interfaces
import { IField } from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';

interface Props {
  field: IField;
  numberFormat: any;
  decimal: number;
  isRequired: boolean;
  isModified: boolean;
  isDisabled: boolean;
  hasErrors: boolean;
  handleChange: (field: IField) => void;
};

export default function OperatingHours({
  field,
  isRequired,
  isModified,
  isDisabled,
  hasErrors,
  handleChange,
}: Props) {

  const value = !_.isEmpty(field.values) && _.has(field.values[0], 'operating_hours') ? field.values[0].operating_hours : undefined;

  return (
    <NumberFormat
      key={ JSON.stringify(value) }
      fixedDecimalScale={ false }
      decimalScale={ 0 }
      customInput={ Input }
      className={ classNames('Field pR-20 ta-r', {
        'Field--has-error border-danger': !!hasErrors,
        'Field--has-warning border-warning': isModified && !hasErrors,
      }) }
      required={ isRequired }
      disabled={ isDisabled }
      allowNegative={ false }
      value={ value }
      placeholder={ `Operating Hours` }
      onBlur={ (event: React.BaseSyntheticEvent) => {
        const values = !_.isEmpty(field.values) ? _.cloneDeep(field.values[0]) : [];
        handleChange(_.set(field, 'values', [{
          ...values,
          operating_hours: event?.target?.value !== '' ? parseFloat(event.target.value) : null
        }]));
      } }
    />
  );
};
