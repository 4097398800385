// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps, Link as ReactLink } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicList from 'components/basic-list';
import Badge, { BadgeType, getBadgeType } from 'components/badge';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import CoverModal from 'components/cover-modal';
import { regionMap } from 'components/resources-table';
import { Input, Form, Select, Typography, Tree, Menu, Dropdown as AntDropdown, Button, Modal, Tooltip } from 'antd';
import Flag from 'components/flag';

// Icons
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// Views
import CreateResourceDialog from 'views/admin/users/CreateResourceDialog';

// Services
import { validEmail } from 'utils/utils';
import Notification from 'services/notification';
import { getFormatedDate } from 'services/settings';
import { validPhone } from 'utils/utils';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity, UserStatus } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Api
import { Api } from 'services/api';

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

const { Link } = Typography;

interface ActivityRecord {
  id: number;
  action: string;
  created_at: string;
  type: string;
};

interface Props {
  user: UserEntity;
  permissions: UserPermissions;
  client_id: number;
  history: Record<string, any>;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  user: Partial<UserEntity> | null;
  defaultUser: UserEntity | null;
  roles: any | null;
  regions: any[];
  activityLogs: ActivityRecord[];
  activeResourceRow: any;
  availableCompanies: any[] | null;
  availableCountries: any[];
  isFetchingCompanies: boolean;
  errors: any[];
  isSaving: boolean;
  isEditing: boolean;
  isLoading: boolean;
  isSendingInvite: boolean;
  showCreateResourceModal: boolean;
  showRegionsModal: boolean;
  showReactivateDialog: boolean;
  showDeleteDialog: boolean;
  showResetDialog: boolean;
  removeResourceId: number | null;
  isRemovingResourceLoading: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class User extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    user: null,
    defaultUser: null,
    roles: null,
    regions: [],
    activityLogs: [],
    activeResourceRow: null,
    errors: [],
    availableCompanies: [],
    availableCountries: [],
    isFetchingCompanies: false,
    isSaving: false,
    isEditing: false,
    isLoading: false,
    isSendingInvite: false,
    showCreateResourceModal: false,
    showRegionsModal: false,
    showReactivateDialog: false,
    showDeleteDialog: false,
    showResetDialog: false,
    removeResourceId: null,
    isRemovingResourceLoading: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const user_id = this.props.match.params.id;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Users', path: '/admin/users' },
    ], false);

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const user = await API.get(`client/${client_id}/admin/users/${user_id}`);
      const roles = await API.get(`client/${client_id}/admin/users/${user_id}/roles`);
      const activityLogs = await API.get(`client/${client_id}/admin/users/${user_id}/activity`);
      const availableRegions = await API.get(`client/${client_id}/available_regions`);
      const availableCountries = await API.get(`client/${client_id}/available_countries`);

      this.mounted && this.setState({
        user: user,
        roles: roles,
        activityLogs: activityLogs,
        regions: availableRegions,
        availableCountries: availableCountries,
      });

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Users', path: '/admin/users' },
        { title: user.full_name, path: null },
      ], false);

    } catch (error) {
        Notification('error', `Failed to load user`);
        console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }

  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { user: { active_client } } = this.props;

    if ((prevState.isEditing !== this.state.isEditing) && _.isEmpty(this.state.availableCompanies)) {
      this.mounted && this.setState({
        isFetchingCompanies: true,
      }, () => {
        API.getAvailableUserCompanies(active_client)
          .then((availableCompanies) => {
            this.mounted && this.setState({
              availableCompanies: availableCompanies,
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => this.mounted && this.setState({
            isFetchingCompanies: false
          }));
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleUserInvite = async (userID: number) => {
    try {
      const { client_id } = this.props;

      await new Promise((resolve) => this.setState({ isSendingInvite: true }, () => resolve(null)));

      await API.sendUserInviteEmail(client_id, userID);

      Notification('success', 'User invite sent', 'Successful');
    } catch (e) {
      Notification('error', 'Failed to send invite user', 'Failed');
    } finally {
      this.mounted && this.setState({ isSendingInvite: false });
    }
  };

  handleCreateResource = async (bundle: string, entityType: string, recordId: number, roleId: number) => {
    const user_id = this.props.match.params.id;
    const { client_id } = this.props;

    try {

      const roles = await API.put(`client/${client_id}/admin/users/${user_id}/assign_role`, { data: {
        'bundle': bundle,
        'entity_type': entityType,
        'record_id': recordId,
        'role_id': roleId,
      }});

      this.mounted && this.setState({
        roles: roles
      });

      Notification('success', 'Added Resource');

    } catch (error) {
        console.error('Error: ', error);
        Notification('error', 'Failed to add Resource');
    } finally {
      this.mounted && this.setState({
        showCreateResourceModal: false
      });
    }
  };

  getErrors = () => {
    const schema = {
      first_name: (value: any) => !!value,
      last_name: (value: any) => !!value,
      email: (value: any) => !!value && validEmail(value),
      phone_object: (phone_object: any) => {
        if (!!phone_object?.phone_number) {
          return phone_object?.phone_number.length > 5 && validPhone(`${phone_object?.dial_code}${phone_object?.phone_number}`);
        }
        return true;
      },
      company: (value: any) => !_.isEmpty(value),
    };

    const validate = (user: any, schema: any) => Object
      .keys(schema)
      .filter(key => !schema[key](user[key]))
      .map(key => key);

    return validate(this.state.user, schema);
  };

  renderCreateResourceDialog = () => {
    const { client_id } = this.props;
    return (
      <CreateResourceDialog
        clientId={ client_id }
        onClose={ () => this.setState({ showCreateResourceModal: false }) }
        onCreate={ (bundle: string, entityType: string, recordId: number, roleId: number) => this.handleCreateResource(bundle, entityType, recordId, roleId) }
      />
    );
  };

  renderRegionModal = (resourceRow: any) => {
    const { regions } = this.state;
    const activeRegions = resourceRow.regions && resourceRow.regions.map((region: any) => region.id);
    return (
      <CoverModal
        closeOnTop
        style={ { minWidth: 500, minHeight: 600, maxHeight: '70vh' } }
        middleContent={ 'Regions' }
        onClose={ () => this.setState({ showRegionsModal: false }) }
      >
        <Tree
          className="m-20 bg-grey-100"
          disabled
          checkable
          selectable={ false }
          defaultExpandedKeys={ activeRegions || [] }
          defaultCheckedKeys={ activeRegions || [] }
          treeData={ regions ? regionMap(regions) : [] }
        />
      </CoverModal>
    );
  };

  renderRemoveResourceModal = (resourceId: number) => {
    const { client_id } = this.props;
    const { roles, isRemovingResourceLoading } = this.state;
    const user_id = this.props.match.params.id;

    return (
      <Modal
        title={ 'Remove Resource' }
        closable={ false }
        maskClosable={ !isRemovingResourceLoading }
        centered
        visible
        onCancel={ () => this.setState({ removeResourceId: null, isRemovingResourceLoading: false }) }
        okText={ 'Remove' }
        onOk={ async () => {

          const role = roles.find((role: any) => role.resource_id === resourceId);

          try {

            if (!role) throw new Error("Couldn't find role");

            await new Promise((resolve) => this.setState({ isRemovingResourceLoading: true }, () => resolve(null) ));
            await API.delete(`client/${client_id}/${role.bundle}/${role.type.replaceAll('_', '-')}/${role.id}/resource/${resourceId}`);
            const roles = await API.get(`client/${client_id}/admin/users/${user_id}/roles`);

            this.mounted && this.setState({
              roles: roles
            });

            Notification('success', 'Removed resource');
          } catch (error) {
            console.error('Error: ', error);
            Notification('error', 'Failed to remove Resource');
          } finally {
            this.mounted && this.setState({
              removeResourceId: null,
              isRemovingResourceLoading: false,
            });
          }
        } }
        okButtonProps={ {
          danger: true,
          disabled: !!isRemovingResourceLoading,
          loading: !!isRemovingResourceLoading,
        } }
        cancelButtonProps={ {
          disabled: !!isRemovingResourceLoading,
        } }
      >
        <p>Are you sure you want to remove this resource?</p>
      </Modal>
    );
  };

  addUserActions = (actions: DropdownAction[], user: any): void => {
    const { client_id } = this.props;
    const isMe: boolean = user.id === this.props.user.id;

    actions.push(
      {
        node: 'Masquerade',
        disabled: isMe ? [''] : false,
        onClick: async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: user.id
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        }
      },
      {
        node: 'Reset Password',
        onClick: () => this.setState({ showResetDialog: true })
      }
    );

    if (user.status === UserStatus.Pending) {
      actions.push(
        {
          node: 'Resend Invite',
          onClick: () => this.handleUserInvite(user.id)
        }
      );
    }

    if (user.status === UserStatus.Deactivated) {
      actions.push(
        {
          node: 'Reactivate',
          onClick: () => this.setState({ showReactivateDialog: true })
        }
      );
    }

    if (user.status === UserStatus.Active) {
      actions.push(
        {
          node: 'Deactivate',
          disabled: isMe ? [''] : false,
          isDangerous: true,
          onClick: () => !isMe && this.setState({ showDeleteDialog: true })
        }
      );
    }

  };

  renderDetails = (user: any) => {
    const { client_id } = this.props;
    const {
      defaultUser,
      availableCompanies,
      availableCountries,
      isEditing,
      isSaving,
      errors,
      isFetchingCompanies,
    } = this.state;

    const actions: DropdownAction[] = [];

    if (isEditing) {
      actions.push(
        {
          node: 'Save',
          type: 'primary',
          disabled: !_.isEmpty(errors) ? ['Please resolve errors'] : false,
          isLoading: isSaving,
          onClick: () => {
            this.setState({
              isSaving: true
            }, () => {
              API.updateUser(client_id, user.id, user)
                .then((user: UserEntity) => {
                  this.mounted && this.setState({
                    user: user
                  }, () => {
                    Notification('success', 'User was updated', 'Sucessful');
                  });
                })
                .catch((err: any) => {
                  Notification('error', 'Failed to update user', 'Failed');
                })
                .finally(() => this.mounted && this.setState({
                  isSaving: false,
                  isEditing: false,
                }));
            });
          }
        },
        {
          node: 'Cancel',
          onClick: () => this.setState({ isEditing: false, user: defaultUser, defaultUser: null, errors: [] })
        }
      );
    } else {
      actions.push(
        {
          node: 'Edit',
          onClick: () => this.setState({ isEditing: true, defaultUser: {...user}, errors: this.getErrors() })
        }
      );

      this.addUserActions(actions, user);
    }

    const options = [
      {
        key: 'first_name',
        label: 'First Name',
        value: user.first_name,
        editable: true,
      },
      {
        key: 'last_name',
        label: 'Last Name',
        value: user.last_name,
        editable: true,
      },
      {
        key: 'email',
        label: 'Email',
        value: user.email,
        editable: true,
      },
    ];

    let company: any = '';

    if (_.has(user, 'company.id')) {
      if (_.isEmpty(availableCompanies)) {
        company = user.company.title;
      } else {
        company = user.company.id;
      }
    }

    const onChangePhoneNumber = _.debounce((value: string) => {
      this.setState({
        user: _.set(user, 'phone_object.phone_number', value)
      }, () => {
        this.setState({
          errors: this.getErrors()
        });
      });
    }, 300);

    return (
      <div className="Layout-box pB-20">
        <div className="d-f jc-sb">
          <span />
          <Dropdown
            actions={ actions }
          />
        </div>
        <div className="Form-Grid-Item--twelve-col">
          <Form
            layout="vertical"
            autoComplete="off"
            preserve={ false }
            initialValues={{
              'country_code': !!user?.phone_object?.dial_code ? `${parseInt(user.phone_object.dial_code)}` : '-',
              'phone_number': user?.phone_object?.phone_number,
            }}
          >
            { options.map((option: any) => (
              <Form.Item
                key={ option.key }
                label={ option.label }
                required
              >
                <Input
                  status={ isEditing && !_.isEmpty(errors) && errors.includes(option.key) ? 'error' : undefined }
                  disabled={ !option.editable || !isEditing }
                  value={ option.value }
                  onChange={ (event: BaseSyntheticEvent) => {
                    this.setState({
                      user: Object.assign(user, { [option.key]: event.target.value }),
                    }, () => {
                      this.setState({
                        errors: this.getErrors()
                      });
                    });
                  } }
                />
              </Form.Item>
            )) }
            <Form.Item
              name="phone_number"
              label="Phone Number"
              style={ { width: '100%' } }
            >
              <Input
                status={ isEditing && !_.isEmpty(errors) && errors.includes('phone_object') ? 'error' : undefined }
                addonBefore={ (
                  <Form.Item
                    name="country_code"
                    noStyle
                  >
                    <Select
                      disabled={ !isEditing }
                      style={ { minWidth: 120 } }
                      className="Select-Field"
                      dropdownMatchSelectWidth={ false }
                      optionLabelProp={ 'label' }
                      onChange={ (dial_code: string) => {
                        if (dial_code) {
                          this.setState({
                            user: _.set(user, 'phone_object.dial_code', `+${dial_code}`)
                          }, () => {
                            this.setState({
                              errors: this.getErrors()
                            });
                          });
                        }
                      } }
                      // @ts-ignore
                      autoComplete="none"
                    >
                      { availableCountries
                        .sort((a: any, b: any) => a.title.localeCompare(b.title))
                        .map((country: any) => (
                          <Option key={ country.id } value={ country.dial_code } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                            <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                ) }
                onChange={ (event: BaseSyntheticEvent) => onChangePhoneNumber(event.target.value) }
                prefix={ <span className="op-50p">(0)</span> }
                disabled={ !isEditing }
                // @ts-ignore
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>Company</span>
                  <Tooltip placement={ 'top' } title={ 'This selection is for display purposes and provides no access to company data. To provide access, a role must also be assigned on the company record.' }>
                    <QuestionCircleOutlined className="fsz-def text-ant-default mL-5" />
                  </Tooltip>
                </>
              }
              required
            >
              <Select
                showSearch
                className={ classNames('Select-Field', {
                  'Select-Field--has-error': !_.isEmpty(errors) && _.includes(errors, 'company'),
                }) }
                disabled={ !isEditing || isFetchingCompanies }
                loading={ isFetchingCompanies }
                filterOption={ (input: any, option: any) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                } }
                onSelect={ (company_id: any) => {
                  const company = availableCompanies?.find((company: any) => company.id === company_id);
                  this.setState({
                    user: Object.assign(user, { company: company }),
                    errors: this.getErrors(),
                  });
                } }
                value={ company }
              >
                { availableCompanies && availableCompanies.map((company: any) => (
                  <Option key={ company.id } value={ company.id }>{ company.title }</Option>
                )) }
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  renderResources = () => {
    const { roles = [] } = this.state;

    const renderActionList = (row: any) => {

      const menu = () => {
        return (
          <Menu>
            <Menu.Item
              key='remove_resource'
              danger
              icon={ <DeleteOutlined /> }
              onClick={ () => this.setState({
                removeResourceId: row.resource_id
              }) }
            >
              Remove
            </Menu.Item>
          </Menu>
        );
      };

      return (
        <div onClick={ (e: any) => e.stopPropagation() }>
          <AntDropdown overlay={ menu } trigger={ ['click'] } placement="bottomRight">
            <Button style={ { padding: '4px 7px', width: 32 } }>...</Button>
          </AntDropdown>
        </div>
      );
    };

    const mapColumns = () => {
      return [
        {
          key: 'role_type',
          dataIndex: 'role_type',
          title: 'Role Type',
          render: (role_type: string) => {
            switch (role_type) {
              case 'GLOBAL':
                return <Badge type={ BadgeType.Success } text={ 'Global' } />;
              case 'LOCAL':
                return <Badge type={ BadgeType.Default } text={ 'Local' } />;
            }
            return '-';
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'role',
          dataIndex: 'role',
          title: 'Role',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Record',
          render: (title: string, role: any) => {
            if (role?.path) {
              return <ReactLink className='primaryColor' to={ role.path }>{ title }</ReactLink>;
            }
            return title || '-';
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'type',
          dataIndex: 'type',
          title: 'Record Type',
          render: (type: string) => {
            return type || '-';
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          title: 'Regions',
          key: 'regions',
          dataIndex: 'regions',
          render: (__: any, resource: any) => {

            if (!_.isEmpty(resource.regions)) {
              return (
                <Link onClick={ () => this.setState({ showRegionsModal: true, activeResourceRow: resource }) }>
                  Regions
                </Link>
              );
            }

            return <>-</>;

          },
          width: 150,
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: 'Role Status',
          render: (status: string) => {
            if (status) {
              return <Badge type={ getBadgeType(status) } text={ _.startCase(_.toLower(status)).split('_').join(' ') } />;
            }
            return '-';
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created At',
          filterable: false,
          sorter: true,
          ellipsis: true,
        },
        {
          title: '',
          key: 'actions',
          dataIndex: 'actions',
          align: Alignment.Right,
          render: (_: any, row: any) => renderActionList(row)
        },
      ];
    };

    const mapData = (roles: any[]): any[] => {
      if (!_.isEmpty(roles)) {
        return roles
        .map((role: any, index: number) => {
          return {
            'key': index,
            'id': role.id,
            'role_type': role.role_type,
            'resource_id': role.resource_id,
            'title': role.title,
            'type': _.startCase(_.toLower(role.type)).split('_').join(' '),
            'regions': role.regions || [],
            'path': role.path,
            'role': role.role,
            'status': role.status,
            'created_at': getFormatedDate(role.created_at),
          };
        });
      }

      return [];
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(roles) }
      />
    );
  };

  renderActivityLogs = () => {
    const { activityLogs = [] } = this.state;

    const mapColumns = () => {
      return [
        {
          key: 'action',
          dataIndex: 'action',
          title: 'Action',
          filterable: false,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'type',
          dataIndex: 'type',
          title: 'Type',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Date/Time',
          defaultSortOrder: 'descend',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          filterable: true,
          ellipsis: true,
        },
      ];
    };

    const mapData = (logs: ActivityRecord[]) => {
      return logs.map((log: ActivityRecord, index: number) => {
        return {
          'key': index,
          'id': log.id,
          'action': log.action,
          'type': _.startCase(_.toLower(log.type)),
          'created_at': log?.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(activityLogs) }
        dateRangeFilterFields={ ['created_at'] }
        defaultSortOrder={ { field: 'created_at', order: 'descend' } }
      />
    );
  };

  renderReactivateDialog = (user: any) => {
    const { active_client } = this.props.user;

    return (
      <Modal
        visible
        title={ 'Reactivate User' }
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {
          try {

            const users = await API.put(`client/${active_client}/admin/users/${user.id}/reactivate`);
            const reactivatedUser = users.find((_user: any) => user.id === _user.id);

            reactivatedUser && this.mounted && this.setState({
              user: reactivatedUser,
              showReactivateDialog: false
            });

            Notification('success', `Successfully reactivated user`);
          } catch (error) {
            Notification('error', `Failed to reactivate user`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isLoading: false,
              showReactivateDialog: false
            });
          }
        }) }
        onCancel={ () => this.setState({ showReactivateDialog: false }) }
      >
        <p>Are you sure you want to reactivate <b>{ user.full_name }</b>?</p>
      </Modal>
    );
  };

  renderDeleteDialog = (user: any) => {
    const { active_client } = this.props.user;

    return (
      <Modal
        visible
        title={ 'Deactivate User' }
        okButtonProps={ {
          danger: true,
        } }
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {
          try {

            const users = await API.delete(`client/${active_client}/admin/users/${user.id}`);
            const deactivatedUser = users.find((_user: any) => user.id === _user.id);

            deactivatedUser && this.mounted && this.setState({
              user: deactivatedUser,
              showDeleteDialog: false
            });

            Notification('success', `Successfully deactivated user`);
          } catch (error) {
            Notification('error', `Failed to deactivate user`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isLoading: false,
              showDeleteDialog: false
            });
          }
        } ) }
        onCancel={ () => this.setState({ showDeleteDialog: false}) }
      >
        <p>Are you sure you want to deactivate <b>{ user.full_name }</b>?</p>
      </Modal>
    );
  };

  renderResetDialog = (user: any) => {
    const { active_client } = this.props.user;
    return (
      <Modal
        visible
        title={ 'Reset Password' }
        onOk={ () => this.mounted && this.setState({
            showResetDialog: false,
          }, () => {
            API.requestPasswordToken(user.email, active_client)
              .then(() => {
                Notification('success', 'A reset link has been sent.', 'Successful');
              })
              .catch((err) => {
                Notification('error', 'Failed so send reset password link.', 'Failed');
              });
          }
        ) }
        onCancel={ () => this.setState({ showResetDialog: false }) }
      >
        <p>Are you sure you want to send a reset link to <b>{ user.email }</b>?</p>
      </Modal>
    );
  };

  render = () => {
    const {
      user,
      isLoading,
      showCreateResourceModal,
      showRegionsModal,
      showReactivateDialog,
      showDeleteDialog,
      showResetDialog,
      activeResourceRow,
      removeResourceId,
    } = this.state;
    return (
      <BlockingSpinner isLoading={ isLoading }>
        { !!user &&
          <>
            <Jumbotron
              content={
                <>
                  <span className="mB-0">{ user.full_name }</span> <Badge type={ getBadgeType(user.status ? user.status : '') } text={ _.startCase(_.toLower(user.status)).split('_').join(' ') } />
                </>
              }
              tabs={ [
                {
                  label: 'Overview',
                  node: this.renderDetails(user),
                },
                {
                  label: 'Roles',
                  node: this.renderResources(),
                },
                {
                  label: 'Activity',
                  node: this.renderActivityLogs(),
                },
              ] }
            />
            { showCreateResourceModal && this.renderCreateResourceDialog() }
            { !!removeResourceId && this.renderRemoveResourceModal(removeResourceId) }
            { showRegionsModal && activeResourceRow && this.renderRegionModal(activeResourceRow) }
            { showReactivateDialog && this.renderReactivateDialog(user) }
            { showDeleteDialog && this.renderDeleteDialog(user) }
            { showResetDialog && this.renderResetDialog(user) }
          </>
        }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(User)), 'access_admin_users'));
