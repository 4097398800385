// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Modal, Tabs, Table, Select, Input, Tooltip, Empty } from 'antd';
import { regionMap } from 'components/resources-table';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';

// Services
import { Api } from 'services/api';

// Utils
import { findFirst } from 'utils/utils';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { SpaceCategory, Space } from './SpaceRelationship.interfaces';
import { FormField } from 'components/form/form-wrapper';

interface ICoordinates {
  latitude: number;
  longitude: number;
};

interface Props {
  record: RecordFormEntity;
  coordinates?: ICoordinates;
  field: FormField;
  clientId: number;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  activeTab: string;
  field: any;
  filter: string | null,
  search: string | null,
  spaceSearch: string | null,
  selectedRegions: any,
  selectedProperty: any | null,
  selectedSpaceCategoryRef: string | null,
  selectedSpace: string | null,
  availableRegions: any[],
  availableProperties: any[],
  availableSpaceCategories: SpaceCategory[],
  isFetchingRegions: boolean,
  isFetchingProperties: boolean,
  isFetchingSpaces: boolean,
  shouldUseCoordinates: boolean,
};

const API: Api = new Api();
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const getSelectedRegions = (field: any) => {
  const selectedRegions: any[] = [];

  field.values.forEach((serviceSpecification: any) => {
    serviceSpecification.selected_regions.forEach((region_id: number) => {
      selectedRegions.push(region_id);
    });
  });

  return _.union(selectedRegions);
};

const getSelectedProperty = (field: any) => {
  return _.has(field, 'values') && !_.isEmpty(field.values) ? field.values[0] : null;
};

const getSelectedSpace = (field: any) => {
  if (_.has(field, 'values') && !_.isEmpty(field.values)) {
    return field.values[0].space_item_id;
  }
  return null;
};

const getSelectedSpaceCategoryRef = (field: any) => {
  if (_.has(field, 'values') && !_.isEmpty(field.values)) {
    return field.values[0].space_category_reference || 'built_area';
  }
  return 'built_area';
};

class SpaceSelectionModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    activeTab: 'regions',
    field: _.cloneDeep(this.props.field),
    filter: null,
    search: null,
    spaceSearch: null,
    selectedRegions: getSelectedRegions(this.props.field),
    selectedProperty: getSelectedProperty(this.props.field),
    selectedSpaceCategoryRef: getSelectedSpaceCategoryRef(this.props.field),
    selectedSpace: getSelectedSpace(this.props.field),
    availableRegions: [],
    availableProperties: [],
    availableSpaceCategories: [],
    isFetchingRegions: false,
    isFetchingProperties: false,
    isFetchingSpaces: false,
    shouldUseCoordinates: !!this.props.coordinates,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getRegions(true);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { coordinates } = this.props;
    const { activeTab, selectedRegions, selectedProperty, selectedSpace, availableSpaceCategories, selectedSpaceCategoryRef, shouldUseCoordinates } = this.state;

    if (prevState.activeTab !== activeTab) {
      switch (activeTab) {
        case 'regions':
          this.getRegions();
        break;
        case 'properties':
          this.getProperties(selectedRegions, shouldUseCoordinates ? coordinates : undefined);
        break;
        case 'spaces':
          this.getSpaces(selectedProperty);
        break;
      }
    }

    if (prevState.selectedProperty !== selectedProperty) {
      if (!selectedProperty) {
        this.setState({
          field: _.set(this.state.field, ['values'], []),
        });
      } else {
        this.setState({
          field: _.set(this.state.field, ['values'], [{
            ...selectedProperty,
            space_tree: null,
            space_item_id: null
          }]),
        });
      }
    }

    if (prevState.selectedSpace !== selectedSpace) {

      const spaceCategory = availableSpaceCategories.find((availableSpaceCategory: any) => availableSpaceCategory.reference === selectedSpaceCategoryRef);

      if (spaceCategory && _.has(spaceCategory, 'spaces')) {

        const space = findFirst({ children: spaceCategory.spaces }, 'children', { id: selectedSpace });

        this.setState({
          field: _.set(this.state.field, ['values'], [{
            ...selectedProperty,
            space_tree: space ? space.tree : null,
            space_item_id: space ? space.id : null,
            space_category_reference: space ? space.space_category_reference : null
          }]),
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  filter = (properties: any[], value: any) => {
    return properties.filter((_property: any) => _.has(_property, 'location') && !!_property.location && _property.location.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  search = (properties: any[], value: any) => {
    return properties.filter((_property: any) => _property.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  spaceSearch = (spaces: Space[], value: string): Space[] => {
    const searchTree = (element: Space, value: string): Space | null => {
      let obj: Space | null = null;

      if (element.title.toLowerCase().includes(value.toLocaleLowerCase())) {
        obj = { ...element, children: [] };
      }

      if (!_.isEmpty(element.children)) {
        const processedChildren: Space[] = element.children.map(child => searchTree(child, value) as Space).filter(child => !!child);
        if (!_.isEmpty(processedChildren)) {
          obj = { ...element, children: processedChildren };
        }
      }

      return obj;
    };
    return spaces.map(space => searchTree(space, value) as Space).filter(s => !!s);
  };

  getRegions = async (initial: boolean = false) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingRegions: true }, () => resolve(null)));

      const availableRegions = await API.get(`client/${this.props.clientId}/field/space_relationship/${this.props.field.id}/regions`);

      this.mounted && this.setState({
        availableRegions: availableRegions,
        activeTab: initial && this.state.shouldUseCoordinates ? 'properties' : this.state.activeTab
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingRegions: false,
      });
    }
  };

  getProperties = async (selectedRegions: number[], coordinates?: ICoordinates) => {
    const { record } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetchingProperties: true }, () => resolve(null)));

      const payload: any = {
        entity_id: record.id,
        entity_type: record.type.replaceAll('-', '_'),
        entity_bundle: record.bundle.replaceAll('-', '_'),
        regions: selectedRegions,
      };

      if (coordinates) {
        payload.longitude = coordinates.longitude;
        payload.latitude = coordinates.latitude;
      }

      const availableProperties = await API.get(`client/${this.props.clientId}/field/space_relationship/${this.props.field.id}/properties`, payload);

      this.mounted && this.setState({
        availableProperties: availableProperties
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingProperties: false,
      });
    }
  };

  getSpaces = async (selectedProperty: any | null) => {
    try {

      await new Promise((resolve, reject) => {
        if (!selectedProperty) {
          return reject('No property found');
        }
        return resolve(null);
      });

      await new Promise((resolve) => this.setState({ isFetchingSpaces: true }, () => resolve(null)));

      const availableSpaceCategories = await API.get(`client/${this.props.clientId}/field/space_relationship/${this.props.field.id}/spaces`, {
        property_id: selectedProperty.target_id,
        property_type: selectedProperty.target_type
      });

      this.mounted && this.setState({
        availableSpaceCategories: availableSpaceCategories
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingSpaces: false,
      });
    }
  };

  renderRegionTab = () => {
    const { availableRegions, isFetchingRegions, selectedRegions } = this.state;

    if (isFetchingRegions || _.isEmpty(availableRegions)) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <Table
        className="ov-s"
        size={ 'small' }
        style={{ height: 450 }}
        bordered
        showHeader={ false }
        columns={ [
          {
            render: (region: any) => <span>{ region.title }</span>
          }
        ] }
        dataSource={ !_.isEmpty(availableRegions) ? regionMap(availableRegions) : [] }
        pagination={ false }
        expandable={{
          defaultExpandAllRows: true
        }}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedRegions,
          onChange: (selectedRowKeys: any, _selectedRegions: any) => {
            this.setState({ selectedRegions: selectedRowKeys });
          }
        }}
      />
    );
  };

  renderPropertyTab = () => {
    const { availableProperties, isFetchingProperties, selectedProperty, filter, search, shouldUseCoordinates } = this.state;

    if (isFetchingProperties) {
      return (
        <div className="d-f jc-c ai-c mH-450">
          <BlockingSpinner isLoading />
        </div>
      );
    };

    if (shouldUseCoordinates && _.isEmpty(availableProperties)) {
      return (
        <div className="d-f jc-c ai-c mH-450">
          <p>No properties in a 5km radius, please select manually</p>
        </div>
      );
    }

    let properties = availableProperties.map((property: any) => {
      return {
        key: `${property.target_bundle}-${property.target_type}-${property.target_id}`,
        title: property.target_title,
        ownership: property.ownership,
        locationTitle: property.location?.title || '',
        ...property
      };
    });

    const locations: any[] = availableProperties
      .filter((property: any) => _.has(property, 'location') && !!property.location)
      .map((property: any) => property.location)
      .reduce((acc, cur) => [
        ...acc.filter((location: any) => location.id !== cur.id), cur
      ], []);

    if (!!filter) {
      properties = this.filter(properties, filter);
    }

    if (!!search) {
      properties = this.search(properties, search);
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      if (sorter.order) {
        properties = _.orderBy(properties, [sorter.field], [sorter.order === 'ascend' ? 'asc' : 'desc']);
      }
      this.setState({ availableProperties: properties });
    };

    return (
      <>
        <div className="d-f">
          <Search
            className="d-if"
            style={{ width: 350 }}
            placeholder="Search for Property"
            onSearch={(value: string) => this.setState({ search: value })}
          />
          <Select
            className="d-if mL-10"
            style={{ width: 350 }}
            allowClear
            showSearch
            onSelect={(title: string) => {
              this.setState({ filter: title });
            }}
            onClear={() => {
              this.setState({ filter: null });
            }}
            placeholder={'Filter by Location'}
            disabled={_.isEmpty(locations)}
            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            // @ts-ignore
            autoComplete="none"
          >
            {locations.map((location: any) => {
              return (
                <Option
                  key={location.id}
                  value={location.title}
                >
                  {location.title}
                </Option>
              );
            })}
          </Select>
        </div>
        <Table
          className="ov-s mT-10"
          size={'small'}
          style={{ height: 408 }}
          bordered
          columns={[
            {
              title: 'Property',
              dataIndex: 'title',
              key: 'title',
              sorter: true,
              sortDirections: ['ascend', 'descend'],
              width: 300,
            },
            {
              title: 'Ownership',
              dataIndex: 'ownership',
              key: 'ownership',
              sorter: true,
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Location',
              dataIndex: 'locationTitle',
              key: 'locationTitle',
              sorter: true,
              sortDirections: ['ascend', 'descend'],
            },
          ]}
          dataSource={ properties || [] }
          pagination={ false }
          onChange={ handleTableChange }
          rowClassName={ () => {
            return 'us-n cur-p';
          }}
          onRow={(property: any) => {
            return {
              onClick: () => {
                if (selectedProperty && `${selectedProperty.target_bundle}-${selectedProperty.target_type}-${selectedProperty.target_id}` === property.key) {
                  this.setState({ selectedProperty: null });
                } else {
                  this.setState({ selectedProperty: property });
                }
              },
            };
          }}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedProperty ? [`${selectedProperty.target_bundle}-${selectedProperty.target_type}-${selectedProperty.target_id}`] : [],
            renderCell: (checked: boolean, record: any, index: number, originNode: any) => {
              if (selectedProperty && `${selectedProperty.target_bundle}-${selectedProperty.target_type}-${selectedProperty.target_id}` !== record.key) {
                return (
                  <Tooltip key={index} title={'Can only select one Property'} placement={'top'}>
                    { React.cloneElement((originNode), { disabled: true }) }
                  </Tooltip>
                );
              }
              return originNode;
            },
            onSelect: (property: any) => {
              if (selectedProperty && `${selectedProperty.target_bundle}-${selectedProperty.target_type}-${selectedProperty.target_id}` === property.key) {
                this.setState({ selectedProperty: null });
              } else {
                this.setState({ selectedProperty: property });
              }
            }
          }}
        />
      </>
    );
  };

  renderSpaceTab = () => {
    const { availableSpaceCategories, isFetchingSpaces, selectedSpace, selectedSpaceCategoryRef, spaceSearch } = this.state;

    if (isFetchingSpaces) {
      return (
        <div className="d-f jc-c ai-c mH-450">
          <BlockingSpinner isLoading />
        </div>
      );
    };

    let spaceCategory = null;

    if (!_.isEmpty(availableSpaceCategories)) {
      if (selectedSpaceCategoryRef) {
        spaceCategory = availableSpaceCategories.find((availableSpaceCategory) => availableSpaceCategory.reference === selectedSpaceCategoryRef);
      } else {
        spaceCategory = availableSpaceCategories[0]; // fallback
      }
    }

    let spaces: Space[] = spaceCategory?.spaces || [];

    if (!!spaceSearch && !_.isEmpty(spaces)) {
      spaces = this.spaceSearch(spaces, spaceSearch);
    }

    const rows = spaces.map((entity: Space) => {
      const appendChildrenKeys = (children: any) => {
        return children
          .map((childEntity: any) => {
            return {
              ...childEntity,
              'key': childEntity.id,
              'label': childEntity.title,
              'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
            };
          });
      };

      return {
        ...entity,
        'key': entity.id,
        'label': entity.title,
        'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
      };
    });

    const columns: any = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Name',
      },
      {
        key: 'space_type',
        dataIndex: 'space_type',
        title: 'Space Type',
      },
      {
        key: 'space_status',
        dataIndex: 'space_status',
        title: 'Space Status',
      },
    ];

    return (
      <>
        <div className="d-f">
          <Search
            className="d-if"
            style={{ width: 350 }}
            placeholder="Search for Space"
            defaultValue={ spaceSearch || '' }
            onSearch={ (value: string) => this.setState({ spaceSearch: value }) }
          />
          <Select
            className="d-if mL-10"
            style={{ width: 200 }}
            onSelect={ (reference: string) => {
              this.setState({
                selectedSpaceCategoryRef: reference,
              });
            } }
            value={ selectedSpaceCategoryRef || undefined }
          >
            { availableSpaceCategories.map((spaceCategory: any) => {
              return (
                <Option
                  key={ spaceCategory.reference }
                  value={ spaceCategory.reference }
                >
                  { spaceCategory.title }
                </Option>
              );
            }) }
          </Select>
        </div>
        <Table
          key={ JSON.stringify(selectedSpaceCategoryRef) }
          className="ov-s mT-10"
          size={ 'small' }
          style={{ height: 408 }}
          bordered
          columns={ columns }
          dataSource={ rows || [] }
          pagination={ false }
          expandable={{
            defaultExpandAllRows: true
          }}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedSpace ? [selectedSpace] : [],
            onSelect: (space: any) => {
              if (selectedSpace === space.id) {
                this.setState({ selectedSpace: null });
              } else {
                this.setState({ selectedSpace: space.id });
              }
            }
          }}
        />
      </>
    );
  };

  render = () => {
    const { field, activeTab, selectedProperty } = this.state;
    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ field?.label }
        onCancel={ () => this.props.onClose() }
        okButtonProps={{
          disabled: _.isEqual(field.values, this.props.field.values)
        }}
        okText={ 'Ok' }
        onOk={ () => this.props.onSave(field) }
        style={{ minWidth: 1000 }}
      >
        <Tabs
          centered
          activeKey={ activeTab }
          onTabClick={ (tab: string) => this.setState({ activeTab: tab, shouldUseCoordinates: false }) }
        >
          <TabPane tab="Regions" key="regions">
            { this.renderRegionTab() }
          </TabPane>
          <TabPane tab="Properties" key="properties">
            { this.renderPropertyTab() }
          </TabPane>
          <TabPane tab="Spaces" key="spaces" disabled={ !selectedProperty }>
            { this.renderSpaceTab() }
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
};

export default SpaceSelectionModal;