// Libs
import * as React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Widget from 'components/widget';
import { Modal, Input, Select, Button, Skeleton, Tooltip } from 'antd';
import CoverModal from 'components/cover-modal';
import Chart from 'components/insight/Chart';
import ChartTable from 'components/insight/ChartTable';
import InsightFilter from 'components/insight/InsightFilter';
import ManageFilterDialog from 'components/dashboard/ManageFilterDialog';
import IconPicker from 'components/icon-picker';

// Interfaces
import { IWidget } from 'components/widget/Widget.interface';
import { IDashboard } from 'components/dashboard/Dashboard.interface';
import { IPreview, IFilter } from 'components/insight/Insight.interfaces';
import { RecordFormEntity } from 'types/entities';

// Services
import { nestedSet, modifyNestedSetItem } from 'utils/utils';
import Notification from 'services/notification';
import { Api } from 'services/api';

// Icons
import Icon, { QuestionCircleOutlined, PlusOutlined, LinkOutlined, SnippetsOutlined } from '@ant-design/icons';

// Styles
import './Dashboard.scss';

const API: Api = new Api();
const ResponsiveGridLayout = WidthProvider(Responsive);
const getEmptyWidget = (parantState: any, cols: number = 12): IWidget => {
  return {
    key: uuidv4(),
    id: null,
    title: '',
    type: 'REPORT',
    config: !_.isEmpty(parantState.availableReports) && parantState.availableReports.length === 1 ? { report_id: parantState.availableReports[0].id, report_mode: parantState.availableReports[0].modes[0] } : null,
    w: 6,
    h: 3,
    x: (parantState.dashboard.widgets.length * 3) % cols,
    y: 0,
  };
};

interface Props {
  record?: RecordFormEntity;
  clientId: number;
  dashboard: IDashboard;
  state?: any;
  readOnly?: boolean;
  isPrimarySidebarCollapsed: boolean;
  onLayoutChange?: (dashboard: IDashboard) => void;
  onChangeState?: (state: any) => void;
};

interface State {
  fullscreenInsight: IPreview | null;
  showDialog: boolean;
  showManageFilterDialog: boolean;
  fullscreenWidgetKey: string | number | null;
  isFullscreenInsightLoading: boolean;
  placeHolderWidget: IWidget | null;
  isLoadingFilters: boolean;
  availableFilters: IFilter[];
  selectedFilters: { [key: string]: any } | null;
  selectedFullscreenFilters: { [key: string]: any } | null;
  activeFilters: { [key: string]: any } | null;
  activeFullScreenFilters: { [key: string]: any } | null;
};

export class Dashboard extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    fullscreenWidgetKey: null,
    fullscreenInsight: null,
    isFullscreenInsightLoading: false,
    placeHolderWidget: null,
    showDialog: false,
    showManageFilterDialog: false,
    isLoadingFilters: false,
    availableFilters: [],
    selectedFilters: null,
    selectedFullscreenFilters: null,
    activeFilters: null,
    activeFullScreenFilters: null,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getAvailibleFilters(this.props.clientId, this.props.dashboard);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {

    if (this.props?.state?.showDialog !== prevProps.state?.showDialog && prevState.showDialog === this.state.showDialog) {
      this.setState({
        showDialog: this.props.state.showDialog,
        placeHolderWidget: getEmptyWidget(this.props.state)
      });
    }

    // Get new list of availible filters if widgets change
    if (!_.isEqual(prevProps?.state?.dashboard.widgets.map((widget: IWidget) => widget.config?.report_id), this.props?.state?.dashboard.widgets.map((widget: IWidget) => widget.config?.report_id))) {
      this.getAvailibleFilters(this.props.clientId, this.props.state.dashboard);
    }

    if (
      this.state.fullscreenWidgetKey &&
      (!_.isEqual(prevState.fullscreenWidgetKey, this.state.fullscreenWidgetKey) ||
      (!_.isEqual(prevState.activeFullScreenFilters, this.state.activeFullScreenFilters))
    )) {
      this.fetchFullscreenInsight(this.props.clientId, this.state.fullscreenWidgetKey, this.state.activeFullScreenFilters);
    }
  };

  getAvailibleFilters = async (clientId: number, dashboard: IDashboard) => {
    try {
      await new Promise((resolve) => this.setState({ isLoadingFilters: true }, () => resolve(null)));

      const reportIds = dashboard.widgets.filter((widget: IWidget) => !!widget.config?.report_id).map((widget: IWidget) => widget.config?.report_id);

      if (!_.isEmpty(reportIds)) {
        const availableFilters = await API.get(`client/${clientId}/insight/dashboard/${dashboard.id}/available-filters`, {
          report_ids: _.uniq(reportIds)
        });

        this.mounted && this.setState({
          availableFilters: availableFilters
        });
      }

    } catch (error) {
      Notification('error', 'Failed to fetch filters', 'Failed');
    } finally {
      this.mounted && this.setState({
        isLoadingFilters: false
      });
    }
  };

  fetchFullscreenInsight = async (clientId: number, widgetKey: string | number, activeFullScreenFilters: { [key: string]: any } | null) => {
    const widget: IWidget | undefined = nestedSet(this.props.dashboard.widgets).find((widget: IWidget) => widget.key === widgetKey);

    if (!widget) return;
    try {

      let insight = null;

      await new Promise((resolve) => this.setState({ isFullscreenInsightLoading: true }, () => resolve(null)));

      if (this.props?.record) {
        insight = await API.post(`client/${clientId}/${_.snakeCase(this.props.record.bundle)}/${_.snakeCase(this.props.record.type)}/${this.props.record.id}/dashboard/${this.props.dashboard.id}/report/${widget.config?.report_id}`, {
          filters: activeFullScreenFilters
        });
      } else if (widget.type === 'REPORT' && widget.config?.report_id) {
        insight = await API.post(`client/${clientId}/insight/report/${widget.config?.report_id}`, {
          filters: activeFullScreenFilters
        });
      }

      this.mounted && this.setState({
        fullscreenInsight: insight
      });

    } catch (error) {
      Notification('error', 'Failed to fetch insight', 'Failed');
    } finally {
      this.mounted && this.setState({
        isFullscreenInsightLoading: false
      });
    }
  };

  onLayoutChange = (currentLayout: ReactGridLayout.Layout[]) => {
    const dashboard = _.cloneDeep(this.props.dashboard);
    this.props.onLayoutChange && this.props.onLayoutChange(_.set(dashboard, 'widgets', currentLayout.map((layout: ReactGridLayout.Layout) => {
      return {
        ...dashboard.widgets.find((_widget: IWidget) => `${_widget.key}` === layout.i),
        w: layout.w,
        h: layout.h,
        x: layout.x,
        y: layout.y
      };
    })));
  };

  handleEditWidget = (widgets: IWidget[], widgetKey: string | number) => {
    const widget = widgets.find((_widget: IWidget) => _widget.key === widgetKey);
    if (widget) {
      this.setState({
        placeHolderWidget: _.cloneDeep(widget),
        showDialog: true,
      });
    }
  };

  handleDeleteWidget = (widgetKey: string | number) => {
    this.props.onChangeState && this.props.onChangeState({
      ...this.props.state,
      dashboard: _.set(this.props.dashboard, 'widgets', _.cloneDeep(this.props.dashboard.widgets.filter((_widget: IWidget) => `${_widget?.id || _widget.key}` !== `${widgetKey}`)))
    });
  };

  handleDuplicateWidget = (widgets: IWidget[], widgetKey: string | number) => {
    const widget = widgets.find((_widget: IWidget) => _widget.key === widgetKey);
    if (widget) {
      this.props.onChangeState && this.props.onChangeState({
        ...this.props.state,
        dashboard: _.set(this.props.dashboard, 'widgets', _.cloneDeep(this.props.dashboard.widgets.concat({
          ...widget,
          title: `${widget.title} (Copy)`,
          id: null,
          key: uuidv4(),
          x: (widgets.length * 3) % 12,
          y: Infinity,
        })))
      });
    }
  };

  handleToggleFullscreen = (widgetKey: string | number) => {
    this.setState({
      fullscreenWidgetKey: !this.state.fullscreenWidgetKey ? widgetKey : null
    });
  };

  getIconComponent = (key: string) => {
    const icons: { [key: string]: React.ReactNode } = {
      'REPORT': SnippetsOutlined,
      'LINK': LinkOutlined,
    };
    return icons[key] || false;
  };

  renderDialog = (dashboard: IDashboard, widget: IWidget) => {
    const selectedReport = this.props.state.availableReports.find((report: any) => report.id === widget.config?.report_id);
    const isEditing = nestedSet(dashboard.widgets).some((_widget: IWidget) => _widget.key === widget.key);
    const isDisabled = (widget: IWidget) => {
      if (!widget?.type || !widget.title) {
        return true;
      }

      if (widget.type === 'REPORT') {
        return !widget.config?.report_id || !widget.config?.report_mode;
      }

      if (widget.type === 'LINK') {
        return !widget.config?.icon || !widget.config?.url;
      }

      return false;
    };

    return (
      <CoverModal
        style={{ width: 600, height: 'auto' }}
        middleContent={ `${isEditing ? 'Edit' : 'Add' } Widget` }
        closeOnTop
        actions={ [
          {
            label: 'Ok',
            type: 'primary',
            onClick: () => {
              if (isEditing) {
                dashboard = _.set(dashboard, 'widgets', modifyNestedSetItem(widget?.id || widget.key, widget, dashboard.widgets));
              } else {
                dashboard = _.set(dashboard, 'widgets', _.cloneDeep(dashboard.widgets).concat(widget));
              }

              this.setState({
                showDialog: false
              }, () => {
                this.props.onChangeState && this.props.onChangeState({
                  ...this.props.state,
                  dashboard: dashboard,
                  showDialog: false,
                });
              });
            },
            isDisabled: isDisabled(widget),
          },
          {
            label: 'Close',
            onClick: () => {
              this.setState({
                showDialog: false
              }, () => {
                this.props.onChangeState && this.props.onChangeState({
                  ...this.props.state,
                  showDialog: false,
                });
              });
            }
          }
        ] }
        onClose={ () => {
          this.setState({
            showDialog: false
          }, () => {
            this.props.onChangeState && this.props.onChangeState({
              ...this.props.state,
              showDialog: false,
            });
          });
        }}
      >
        <div className="d-f fxd-c bg-white pX-20 pB-30">
          <div className='d-f fxd-c'>
            <p>Widget Type</p>
            <div className="d-f mT-10">
              { ['REPORT', 'LINK'].map((type: string) => (
                <div
                  key={ type }
                  className={ classNames('d-if fxd-c p-10 bd mR-10 cur-p', {
                    'border-primary': widget.type === type
                  }) }
                  style={{ width: 110, borderRadius: 10 }}
                  onClick={ () => {

                    if (type === 'REPORT') {
                      this.setState({
                        placeHolderWidget: {
                          ..._.set(widget, 'type', type),
                          ..._.set(widget, 'config.url', null),
                          ..._.set(widget, 'config.icon', null),
                        },
                      });
                    } else {
                      this.setState({
                        placeHolderWidget: {
                          ..._.set(widget, 'type', type),
                          ..._.set(widget, 'config.report_mode', null),
                          ..._.set(widget, 'config.report_id', null),
                        },
                      });
                    }
                  } }
                >
                  <Icon
                    style={{ fontSize: 30 }}
                    component={ this.getIconComponent(type) as any }
                  />
                  <div className="ta-c pT-5 fsz-xs">{ type === 'REPORT' ? 'Insight' : 'Quick Link' }</div>
                </div>
              )) }
            </div>
          </div>
          <div>
            <div className="mT-20">
              <label>Title<span className="text-required mL-2 fsz-md lh-1 va-t">*</span></label>
              <Input
                autoFocus
                value={ widget?.title || undefined }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({
                    placeHolderWidget: _.set(widget, 'title', event.target.value)
                  });
                } }
              />
            </div>
            { widget.type === 'REPORT' ? (
              <div className="mT-20">
                <div>
                  <label>Report<span className="text-required mL-2 fsz-md lh-1 va-t">*</span></label>
                  <Select
                    style={{ width: '100%' }}
                    disabled={ _.isEmpty(this.props.state.availableReports) }
                    placeholder={ 'Please Select' }
                    value={ widget?.config?.report_id || undefined }
                    onChange={ (report_id: number) => {
                      const report_mode = this.props.state.availableReports.find((report: any) => report.id === report_id).modes[0];
                      this.setState({
                        placeHolderWidget: {
                          ..._.set(widget, 'config.report_id', report_id),
                          ..._.set(widget, 'config.report_mode', report_mode),
                        }
                      });
                    } }
                  >
                    { this.props.state.availableReports.map((report: any, index: number) => (
                      <Select.Option
                        key={ index }
                        value={ report.id }
                      >
                        { report.title }
                      </Select.Option>
                    ) ) }
                  </Select>
                </div>
                { widget.config?.report_id &&
                  <div className="mT-10">
                    <label>Type<span className="text-required mL-2 fsz-md lh-1 va-t">*</span></label>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={ 'Please Select' }
                      value={ widget?.config?.report_mode || undefined }
                      onChange={ (report_mode: string) => {
                        this.setState({
                          placeHolderWidget: _.set(widget, 'config.report_mode', report_mode)
                        });
                      } }
                    >
                      { selectedReport && selectedReport.modes.map((mode: string, index: number) => (
                        <Select.Option
                          key={ index }
                          value={ mode }
                        >
                          { _.upperFirst(_.toLower(mode)) }
                        </Select.Option>
                      ) ) }
                    </Select>
                  </div>
                }
              </div>
            ) : (
              <div className="mT-20">
                <div>
                  <label>URL<span className="text-required mL-2 fsz-md lh-1 va-t">*</span></label>
                  <Input
                    value={ widget?.config?.url }
                    addonBefore={ `${window.location.origin}` }
                    onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        placeHolderWidget: _.set(widget, 'config.url', event.target.value)
                      });
                    } }
                  />
                </div>
                <div className='mT-20'>
                  <label>Icon<span className="text-required mL-2 fsz-md lh-1 va-t">*</span></label>
                  <IconPicker
                    selectedIcon={ widget?.config?.icon }
                    onSelect={ (icon: string) => {
                      this.setState({
                        placeHolderWidget: _.set(widget, 'config.icon', icon)
                      });
                    } }
                  />
                </div>
              </div>
            ) }
          </div>
        </div>
      </CoverModal>
    );
  };

  renderFullscreen = (type: string | undefined, insight: IPreview, dashboard: IDashboard, showFilters: boolean, selectedFullscreenFilters: { [key: string]: any } | null, isFullscreenInsightLoading: boolean) => {
    return (
      <CoverModal
        style={{ width: '90%', height: '90%' }}
        middleContent={ insight?.title }
        onClose={ () => this.setState({
          fullscreenWidgetKey: null,
          fullscreenInsight: null,
          selectedFullscreenFilters: null,
          activeFullScreenFilters: null,
        }) }
      >
        <div className="d-f fxd-c mT-100 mX-50">
          <div className="d-f jc-sb ai-c">
            <div id="Insight-filters" className="d-f fxw-w ai-fe">
              { showFilters && this.renderFilters(dashboard.filters, selectedFullscreenFilters, (filters: { [key: string]: any } | null) => this.setState({
                selectedFullscreenFilters: filters
              })) }
            </div>
            <div className="d-f">
              { showFilters &&
                <Button
                  className="mL-5"
                  type='primary'
                  loading={ isFullscreenInsightLoading }
                  onClick={ () => {
                    this.setState({
                      activeFullScreenFilters: _.cloneDeep(selectedFullscreenFilters)
                    });
                  } }
                >
                  { 'Apply Filter' }
                </Button>
              }
            </div>
          </div>
          { type === 'table' ? (
            <ChartTable
              mode={ 'DASHBOARD' }
              insight={ insight }
              isLoading={ isFullscreenInsightLoading }
            />
          ) : (
            <Chart
              mode={ 'DASHBOARD' }
              insight={ insight }
            />
          ) }
        </div>
      </CoverModal>
    );
  };

  renderWidgets = (widgets: IWidget[], activeFilters: any, readOnly: boolean) => {
    return widgets.map((widget: IWidget) => {
      return (
        <Widget
          key={ widget?.key }
          dashboard={ this.props.dashboard }
          isDashboardModified={ !_.isEqual(this.props.state.originalDashboard, this.props.dashboard) }
          record={ this.props.record }
          clientId={ this.props.clientId }
          data-grid={ widget }
          widget={ widget }
          filters={ activeFilters }
          isFullScreenInsightLoading={ this.state.isFullscreenInsightLoading && this.state.fullscreenWidgetKey === widget.key }
          readOnly={ readOnly }
          onEdit={ () => this.handleEditWidget(widgets, widget.key) }
          onDelete={ () => this.handleDeleteWidget(widget.key) }
          onDuplicate={ () => this.handleDuplicateWidget(widgets, widget.key) }
          onToggleFullscreen={ () => this.handleToggleFullscreen(widget.key) }
        />
      );
    });
  };

  renderFilters = (filters: IFilter[], selectedFilters: { [key: string]: any } | null, onSetFilters: (filters: { [key: string]: any } | null) => void) => {
    return filters.filter(filter => !filter.settings?.hidden).map((filter: IFilter, index: number) => {
      return (
        <div key={ index }
          className={ classNames('mB-10 mR-10', {
            'd-f ai-c': filter.type === 'exclude_empty'
          }) }
        >
          { filter.type !== 'exclude_empty' &&
            <div className="d-f ai-c text-ant-grey">
              <span>
                { filter?.settings?.custom_title || filter.title }
              </span>
              { !!filter?.settings?.tooltip &&
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title={ filter?.settings?.tooltip }
                >
                  <QuestionCircleOutlined className="cur-p fsz-def" />
                </Tooltip>
              }
              { !!filter?.settings?.required &&
                <span className="text-required mL-2 lh-1 fsz-md va-t">*</span>
              }
              { filter?.type === 'datetime' &&
                <span className="mL-5">
                  {`(${ _.startCase(_.toLower(filter.settings.date_mode)).replaceAll('_', ' ') })`}
                </span>
              }
            </div>
          }
          <div className="d-f">
            <InsightFilter
              filters={ selectedFilters }
              filter={ filter }
              onSetFilters={ onSetFilters }
            />
          </div>
        </div>
      );
    });
  };

  render = () => {
    const { dashboard, readOnly } = this.props;
    const {
      isLoadingFilters,
      selectedFilters,
      selectedFullscreenFilters,
      activeFilters,
      activeFullScreenFilters,
      availableFilters,
      fullscreenInsight,
      showDialog,
      showManageFilterDialog,
      placeHolderWidget,
      isFullscreenInsightLoading,
    } = this.state;

    const fullscreenWidget: IWidget | undefined = nestedSet(dashboard.widgets).find((widget: IWidget) => widget.key === this.state.fullscreenWidgetKey);
    const showFilters = !_.isEmpty(dashboard?.filters || [].filter((filter: IFilter) => !filter.settings?.hidden));
    const isFullscreen = !!fullscreenInsight && !!fullscreenInsight;

    return (
      <div className='d-f fxd-c'>
        <Skeleton
          className="fx-1 pX-10"
          paragraph={ false }
          title={{
            style: {
              margin: 0,
              marginBottom: 20
            }
          }}
          loading={ isLoadingFilters }
          active={ isLoadingFilters }
        >
          <div>
            <div className="d-f jc-sb ai-c mX-10">
              <div id="Insight-filters" className="d-f fxw-w ai-fe">
                { showFilters && this.renderFilters(dashboard.filters, selectedFilters, (filters: { [key: string]: any } | null) => this.setState({
                  selectedFilters: filters
                })) }
              </div>
              <div className="d-f" style={{ marginTop: 11 }}>
                { !readOnly &&
                  <div>
                    <Button
                      type='default'
                      loading={ isLoadingFilters }
                      disabled={ isLoadingFilters }
                      onClick={ () => this.setState({ showManageFilterDialog: true }) }
                    >
                      { 'Manage Filters' }
                    </Button>
                  </div>
                }
                <div>
                  { showFilters &&
                    <Button
                      className="mL-5"
                      type='primary'
                      loading={ isLoadingFilters }
                      disabled={ isLoadingFilters }
                      onClick={ () => {
                        this.setState({
                          activeFilters: _.cloneDeep(selectedFilters)
                        });
                      } }
                    >
                      { 'Apply Filter' }
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </Skeleton>
        <div>
          { _.isEmpty(dashboard.widgets) ? (
            <div
              className="d-f jc-c ai-c fxd-c"
              style={{ height: 200, width: 300, border: '1px dashed lightgrey', borderRadius: 5 }}
            >
              <p>No widgets on this dashboard.</p>
              { !readOnly &&
                <Button
                  className="mT-10"
                  onClick={ () => {
                    this.setState({
                      showDialog: true,
                      placeHolderWidget: getEmptyWidget(this.props.state)
                    });
                  } }
                >
                  <PlusOutlined /> Add Widget
                </Button>
              }
            </div>
          ) : (
            <ResponsiveGridLayout
              draggableHandle='.Widget-header'
              resizeHandles={ ['se'] }
              autoSize
              isResizable={ !readOnly }
              isDraggable={ !readOnly }
              onLayoutChange={ this.onLayoutChange }
              rowHeight={ 100 }
            >
              { this.renderWidgets(nestedSet(dashboard.widgets), isFullscreen ? activeFullScreenFilters : activeFilters, !!readOnly) }
            </ResponsiveGridLayout>
          ) }
        </div>
        { showDialog && placeHolderWidget && this.renderDialog(dashboard, placeHolderWidget) }
        { showManageFilterDialog &&
          <ManageFilterDialog
            dashboard={ dashboard }
            availableFilters={ availableFilters }
            onSave={ (_dashboard: IDashboard) => {
              this.setState({
                showManageFilterDialog: false
              }, () => {
                this.props.onChangeState && this.props.onChangeState({
                  ...this.props.state,
                  dashboard: _dashboard
                });
              });
            } }
            onClose={ () => this.setState({ showManageFilterDialog: false }) }
          />
        }
        { isFullscreen && fullscreenWidget && fullscreenInsight && this.renderFullscreen(fullscreenWidget?.config?.report_mode, fullscreenInsight, dashboard, showFilters, selectedFullscreenFilters, isFullscreenInsightLoading) }
      </div>
    );
  };
};