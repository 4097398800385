enum UIActionTypes {
  APP_INITIALISATION_START = 'APP_INITIALISATION_START',
  APP_INITIALISATION_SUCCESS = 'APP_INITIALISATION_SUCCESS',
  APP_INITIALISATION_FAILED = 'APP_INITIALISATION_FAILED',
  SET_PRIMARY_SIDEBAR_COLLAPSED = 'SET_PRIMARY_SIDEBAR_COLLAPSED',
  SET_SECONDARY_SIDEBAR_COLLAPSED = 'SET_SECONDARY_SIDEBAR_COLLAPSED',
  SET_BREADCRUMBS_LOADING = 'SET_BREADCRUMBS_LOADING',
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SET_SECONDARY_SIDEBAR_LOADING = 'SET_SECONDARY_SIDEBAR_LOADING',
  SET_SECONDARY_SIDEBAR_ROUTES = 'SET_SECONDARY_SIDEBAR_ROUTES',
  SET_IS_BLOCKED = 'SET_IS_BLOCKED',
  SET_PING = 'SET_PING',
  SET_QR = 'SET_QR',
};

export default UIActionTypes;
