// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import AdvancedList from "components/advanced-list";
import CalendarView from 'components/calendar-view';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

const API: Api = new Api();

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  record: any;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: {
    id?: number;
    bundle: string;
    type: string;
    parent_id?: number;
    parent_type?: string;
    parent_bundle?: string;
  } | null;
  activeTabKey: string;
};

class Actions extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: null,
    activeTabKey: 'list_view'
  };

  componentDidMount = async () => {
    const { user, setBreadcrumbs } = this.props;

    this.mounted = true;
    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'EH&S', path: '/ehs' },
      { title: 'Actions', path: null },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${user.active_client}/action/advanced-list`);

      this.mounted && this.setState({
        record: record,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleTabChange = (tabKey: string) => {
    this.mounted && this.setState({ activeTabKey: tabKey });
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { user } = this.props;
    try {
      const record = await API.get(`client/${user.active_client}/action/advanced-list`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        record: record
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { user } = this.props;
    const { record } = this.state;
    try {
      await API.download(`client/${user.active_client}/action/advanced-list/export`, `pacs_report_${_.snakeCase(record.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderListView = (record: any) => {
    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ record?.filters || [] }
        columns={ record?.columns || [] }
        items={ record?.data || [] }
        config={ record?.config }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  renderCalendarView = () => {
    return (
      <CalendarView
        client_id={ this.props.user.active_client }
        customEndpoint={ 'action' }
      />
    );
  };

  render = () => {
    const { permissions } = this.props;
    const { showCreateModal, isFetching, isCreateLoading, record, activeTabKey } = this.state;
    const actions: DropdownAction[] = [{ node: '', onClick: () => {} }];
    const tabs = [
      {
        label: 'List View',
        node: this.renderListView(record)
      },
      {
        label: 'Calendar View',
        // The condition is necessary for the Calendar View tab to reload on every click.
        node: activeTabKey === 'calendar_view' ? this.renderCalendarView() : null,
      }
    ];

    if (hasPermission(permissions, 'action_audit_action_create')) {
      actions.push({
        node: 'Create Audit Action',
        onClick: () => history.push('/ehs/actions/create'),
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Actions' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
          onChange={ (tab: string) => this.handleTabChange(tab) }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
