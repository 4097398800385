// Libs
import React, { BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Select, Input, TreeSelect, Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import Flag from 'components/flag';

// Icons
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Utils
import { formatCostToNumber, nestedSet } from 'utils/utils';

// Interfaces
import {
  ITableFieldValue,
  ITableField,
  IColumnType,
  IColumn,
} from 'components/form/field/table/Table.interfaces';
import { PhoneCountry } from 'components/form/field/phone';

const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();

interface Props {
  field: ITableField;
  column: IColumn;
  row: any;
  type: IColumnType | undefined;
  numberFormat: any;
  placeholder: string;
  isLocked: boolean;
  isRequired: boolean;
  isModified: boolean;
  isDisabled: boolean;
  hasErrors: boolean;
  handleChange: (values: ITableFieldValue[]) => void;
};

export default function Column({
  field,
  column,
  row,
  type,
  numberFormat,
  placeholder,
  isLocked,
  isRequired,
  isModified,
  isDisabled,
  hasErrors,
  handleChange,
}: Props) {

  const calculateFormula = (field: ITableField, reference: string, row: any) => {
    const configColumns = field?.config?.columns || [];
    const column = configColumns.find((column: IColumn) => column.reference === reference);

    let formula: string = column?.formula || '';
    const tags = formula.match(/\[(.*?)\]/g);

    if (formula && tags) {
      tags.forEach((tag: string) => {
        const reference = tag.substring(1, tag.length - 1);
        let value = 0;

        switch (reference) {
          default:
            const refColumn = configColumns.find((configColumn: IColumn) => configColumn.reference === reference);

            if (!!refColumn && reference) {
              if (_.has(row, reference) && !refColumn?.formula) {
                value = parseFloat(row[reference]) || 0;
              } else {
                value = calculateFormula(field, reference, row);
              }
            }
          break;
        }

        formula = formula && formula.replaceAll(tag, `${value}`);
      });
    }

    const { error, result } = parser.parse(formula);

    if (!error) {
      return result;
    }

    return 0;
  };

  const modifyValue = (identifier: ITableFieldValue['key'], values: ITableFieldValue[], newValue: {}): ITableFieldValue[] => {
    return values.map((value: ITableFieldValue) => {
      if (value.key === identifier) {
        value = {
          ...value,
          ...newValue
        };
      }

      return {
        ...value,
      };
    });
  };

  const handleColumnChange = (field: ITableField, newRowValues: ITableFieldValue) => {
    const prevRow = nestedSet(field.values).find((row: ITableFieldValue) => row.key === newRowValues.key);
    if (!_.isEqual(prevRow, newRowValues)) {
      handleChange(modifyValue(newRowValues.key, _.cloneDeep(nestedSet(field.values)), newRowValues));
    }
  };

  let value = _.has(row, column.reference) ? row[column.reference] : undefined;

  if (column?.formula) {
    value = calculateFormula(field, column.reference, row);
  }

  switch (type) {
    case IColumnType.Select:
      const options = column?.options || [];
      return (
        <Select
          showSearch
          allowClear
          style={ { width: '100%' } }
          disabled={ isDisabled || isLocked }
          className={ classNames('Select-Field', {
            'Select-Field--has-error border-danger': !!hasErrors,
            'Select-Field--has-warning border-warning': isModified && !hasErrors,
          }) }
          placeholder={ placeholder || '-' }
          filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
          onChange={ (value: number | undefined) => {
            handleColumnChange(field, { ...row, [column.reference]: value || null });
          } }
          defaultValue={ value }
        >
          { options.map((option: any, index: number) => (
            <Select.Option key={ index } value={ option.id }>
              { option?.title || '-' }
            </Select.Option>
          )) }
        </Select>
      );

    case IColumnType.Category:
      const categories = !!column?.options && !_.isEmpty(column.options) ? nestedSet(column.options) : [];
      const canSelectMultiple = !!column?.config?.multiselect;

      const formatSelectValues = (value: string | number | string[] | number[], isMultiSelect: boolean) => {
        if (isMultiSelect) {

          if (_.isEmpty(value)) {
            return [];
          }

          if (_.isArray(value)) {
            return value;
          }

          return [value];
        }

        if (_.isArray(value)) {
          return value[0];
        }

        return value || undefined;
      };

      value = formatSelectValues(value, canSelectMultiple);

      return (
        <TreeSelect
          className={ classNames('Select-Field', {
            'Select-Field--has-error border-danger': !!hasErrors,
            'Select-Field--has-warning border-warning': isModified && !hasErrors,
          }) }
          showSearch
          allowClear
          dropdownMatchSelectWidth={ false }
          placeholder={ placeholder || '-' }
          treeCheckable={ canSelectMultiple }
          treeData={ categories }
          defaultValue={ value }
          maxTagCount={ 3 }
          multiple={ canSelectMultiple }
          showCheckedStrategy={ TreeSelect.SHOW_PARENT }
          filterTreeNode={ (input: string, option: any) => {
            if (option) {
              const filteredInput = input.toLocaleLowerCase();
              const title = option.title && option.title.toLowerCase();

              if (title.includes(filteredInput)) {
                return true;
              }
            }

            return false;
          } }
          disabled={ isDisabled }
          onChange={ (changedValues: any) => {
            if (!changedValues) {
              changedValues = [];
            }

            // If single value, convert to array for consistent handling
            if(!Array.isArray(changedValues)) {
              changedValues = [changedValues];
            }

            handleColumnChange(field, { ...row, [column.reference]: changedValues });
          } }
        />
      );

    case IColumnType.Email:
      return (
        <div className="d-f">
          <Input
            className={ classNames('Field', {
              'Field--has-error border-danger': !!hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            placeholder={ placeholder || '-' }
            required={ field.config.required }
            defaultValue={ value }
            disabled={ isDisabled || isLocked }
            onBlur={ (event: BaseSyntheticEvent) => {
              handleColumnChange(field, { ...row, [column.reference]: event.target.value || null });
            } }
          />
          { !!hasErrors &&
            <Tooltip
              overlayClassName="text-white"
              placement="topRight"
              title={ `Email validation failed` }
            >
              <WarningIcon className="text-danger mL-5 mT-5" height={ 20 } width={ 20 } />
            </Tooltip>
          }
        </div>
      );
    case IColumnType.Text:
      return (
        <Input
          className={ classNames('Field', {
            'Field--has-error border-danger': !!hasErrors,
            'Field--has-warning border-warning': isModified && !hasErrors,
          }) }
          placeholder={ placeholder || '-' }
          required={ field.config.required }
          defaultValue={ value }
          disabled={ isDisabled || isLocked }
          onBlur={ (event: BaseSyntheticEvent) => {
            handleColumnChange(field, { ...row, [column.reference]: event.target.value || null });
          } }
        />
      );

    case IColumnType.Phone:
      const countries = column?.countries || [];
      const dialCode = _.has(row, column.reference) ? row[column.reference]?.dial_code : undefined;
      const selectedCountry = countries.find((_country: PhoneCountry) => `${_country.dial_code}` === `${dialCode}`);

      return (
        <Input.Group compact className="d-f">
          <Select
            style={{ minWidth: 80 }}
            showSearch
            allowClear
            className={ classNames('Select-Field d-if', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            dropdownMatchSelectWidth={ false }
            optionLabelProp={ 'label' }
            disabled={ isDisabled }
            placeholder={ 'Code' }
            filterOption={(input: string, option: any): any => {
              const country = countries.find((_country: PhoneCountry) => _country.id === option.value);
              return country && _.toLower(`${country.dial_code}-${country.title}`).includes(_.toLower(input));
            } }
            onChange={ (countryId: number) => {
              const country = countries.find((_country: PhoneCountry) => _country.id === countryId);
              if (country) {
                handleColumnChange(field, { ...row, [column.reference]: {
                  ...row[column.reference],
                  dial_code: country?.dial_code || null,
                } });
              }
            } }
            onClear={ () => {
              handleColumnChange(field, { ...row, [column.reference]: {
                ...row[column.reference],
                dial_code: null,
              } });
            } }
            value={ selectedCountry ? selectedCountry.id : undefined }
          >
            { countries
              .sort((a: any, b: any) => a.title.localeCompare(b.title))
              .map((country: PhoneCountry, index: number) => (
                <Select.Option key={ index } value={ country.id } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                  <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                </Select.Option>
              ))
            }
          </Select>
          <Input
            type='number'
            className={ classNames('Field no-spinners d-if fx-1', {
              'Field--has-error border-danger': !!hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            prefix={ <span className="op-50p">(0)</span> }
            disabled={ isDisabled }
            onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => {
              handleColumnChange(field, { ...row, [column.reference]: {
                ...row[column.reference],
                number: e?.target?.value || null
              } });
            } }
            placeholder={ 'Phone Number' }
            defaultValue={ _.has(row, column.reference) ? row[column.reference]?.number : undefined }
          />
        </Input.Group>
      );

    case IColumnType.Currency:
    case IColumnType.Number:
      const decimal: number | undefined = _.has(column, 'config.decimal') ? column.config.decimal : 2;
      return (
        <NumberFormat
          { ...numberFormat }
          fixedDecimalScale={ !!decimal }
          decimalScale={ decimal }
          customInput={ Input }
          className={ classNames('Field pR-20 ta-r', {
            'Field--has-error border-danger': !!hasErrors,
            'Field--has-warning border-warning': isModified && !hasErrors,
          }) }
          required={ isRequired }
          disabled={ isDisabled || isLocked }
          allowNegative={ false }
          value={ value }
          placeholder={ placeholder || '-' }
          onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value !== '' ? formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : null;
            handleColumnChange(field, { ...row, [column.reference]: value });
          } }
        />
      );
    case IColumnType.Percentage:
      return (
        <NumberFormat
          className={ classNames('Field pR-20 ta-r', {
            'Field--has-error border-danger': !!hasErrors,
            'Field--has-warning border-warning': isModified && !hasErrors,
          }) }
          customInput={ Input }
          defaultValue={ value }
          disabled={ isDisabled || isLocked }
          required={ isRequired }
          placeholder={ placeholder || '-' }
          suffix={ '%' }
          onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value !== '' ? formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : null;
            handleColumnChange(field, { ...row, [column.reference]: value });
          } }
        />
      );

    default:
      return <></>;
  }
};
