// Libs
import React from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Source from 'components/form/field/energy-consumption/Source';
import RatingType from 'components/form/field/energy-consumption/RatingType';
import Consumption from 'components/form/field/energy-consumption/Consumption';
import RatingValue from 'components/form/field/energy-consumption/RatingValue';
import OperatingHours from 'components/form/field/energy-consumption/OperatingHours';

// Services
import { getFormatedNumber } from 'services/settings';

// Interfaces
import {
  FormField,
  Currency,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import {
  IField,
  IFieldValue,
  IRating,
  IModifiedState
} from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  field: IField;
  originalValues: any[];
  errors?: Record<string, string[]>;
  numberFormat: any;
  clientId: number;
  entity: string;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onFieldChange(field: FormField): void;
};

export default function EnergyConsumptionField(props: Props) {
  const {
    field,
    originalValues,
    numberFormat,
    config,
    isDisabled,
    fieldErrorMessages,
    fieldModifiedMessages,
    setFieldModifiedMessage,
    onFieldChange,
  } = props;

  const getFieldKey = (props: Props): string => {
    return `${props.field.id}_${props.config.fieldIndex || 0}_${props.field.type}`;
  };

  const isModified = (key: keyof IModifiedState): boolean => {
    return _.has(fieldModifiedMessages, [fieldKey, 'modified', key]) && fieldModifiedMessages[fieldKey]['modified'][key];
  };

  const hasError = (key: keyof IModifiedState): boolean => {
    return _.has(fieldErrorMessages, [fieldKey, 'errors', key]) && fieldErrorMessages[fieldKey]['errors'][key];
  };

  const fieldKey = getFieldKey(props);
  const errors = _.has(fieldErrorMessages, fieldKey) ? ['Cannot be empty'] : [];
  const isRequired = field.config.required;

  const decimal: number = field?.config?.decimal || 2;
  const currency: Currency | undefined = field?.currency;

  const energyRating = !!field?.energy_ratings && !_.isEmpty(field?.energy_ratings) && _.has(field.values[0], 'energy_rating_id') ? field?.energy_ratings.find((rating: IRating) => rating.id === field.values[0].energy_rating_id) : undefined;
  const energyConsumption = field?.values[0]?.energy_consumption || 0;
  const energyRatingValue = field?.values[0]?.energy_rating_value || 0;
  const operatingHoursPerYear = field?.values[0]?.operating_hours || 0;
  const estimatedCostPerYear = (energyRatingValue * energyConsumption) * operatingHoursPerYear;

  React.useEffect(() => {
    validate(field.values, originalValues);
  }, [field.values]);

  const validate = (values: IFieldValue[], originalValues: IFieldValue[]): void => {

    const getModified = (values: IFieldValue[], originalValues: IFieldValue[]): IModifiedState => {

      const modified: IModifiedState = {};

      if (!_.isEqual(values, originalValues)) {

        if (!_.isEqual(values[0]?.energy_source_combined, originalValues[0]?.energy_source_combined)) {
          modified.energy_source = true;
        }

        if (!_.isEqual(parseFloat(`${values[0]?.energy_rating_value}`), parseFloat(`${originalValues[0]?.energy_rating_value}`))) {
          modified.energy_rating_value = true;
        }

        if (!_.isEqual(parseFloat(`${values[0]?.energy_consumption}`), parseFloat(`${originalValues[0]?.energy_consumption}`))) {
          modified.energy_consumption = true;
        }

        if (!_.isEqual(parseFloat(`${values[0]?.operating_hours}`), parseFloat(`${originalValues[0]?.operating_hours}`))) {
          modified.operating_hours = true;
        }

        if (!_.isEqual(values[0]?.energy_rating_id, originalValues[0]?.energy_rating_id)) {
          modified.energy_rating_type = true;
        }
      }

      return modified;
    };

    const modified = getModified(values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldModifiedMessage(fieldKey, modifiedMessage);
  };

  return (
    <FieldWrapper
      id={ `${config.tabID}|${config.groupID}|${field.id}` }
      col={ 12 }
      label={ field.label }
      description={ field.description }
      required={ isRequired }
      border
      errors={ errors }
      hideErrorInfo
    >
      <div className="Form-Grid">
        <FieldWrapper
          col={ 3 }
          label={ <p className="fsz-xs fw-500">Energy Source</p> }
          description={ `Please select an 'Energy Source' using the dropdown list.` }
          border
        >
          <Source
            field={ field }
            numberFormat={ numberFormat }
            isRequired={ false }
            isDisabled={ !!isDisabled }
            isModified={ isModified('energy_source') }
            hasErrors={ hasError('energy_source') }
            handleChange={ onFieldChange }
          />
        </FieldWrapper>
        <FieldWrapper
          col={ 3 }
          label={ <p className="fsz-xs fw-500">{ `Energy Rating Value (${currency?.code} Per Unit)` }</p> }
          description={ `${currency?.code} Per Unit` }
          border
        >
          <RatingValue
            field={ field }
            numberFormat={ numberFormat }
            decimal={ decimal }
            currency={ currency }
            isRequired={ false }
            isDisabled={ !!isDisabled }
            isModified={ isModified('energy_rating_value') }
            hasErrors={ hasError('energy_rating_value') }
            handleChange={ onFieldChange }
          />
        </FieldWrapper>
        <FieldWrapper
          col={ 3 }
          label={ <p className="fsz-xs fw-500">Consumption (Units Per Hour)</p> }
          description={ `Consumption (Units Per Hour)` }
          border
        >
          <Consumption
            field={ field }
            isRequired={ false }
            isDisabled={ !!isDisabled }
            isModified={ isModified('energy_consumption') }
            hasErrors={ hasError('energy_consumption') }
            handleChange={ onFieldChange }
          />
        </FieldWrapper>
        <FieldWrapper
          col={ 3 }
          label={ <p className="fsz-xs fw-500">Operating Hours (Per Year)</p> }
          description={ `Please input a 'Operating Hours'` }
          border
        >
          <OperatingHours
            field={ field }
            numberFormat={ numberFormat }
            decimal={ decimal }
            isRequired={ false }
            isDisabled={ !!isDisabled }
            isModified={ isModified('operating_hours') }
            hasErrors={ hasError('operating_hours') }
            handleChange={ onFieldChange }
          />
        </FieldWrapper>
        <FieldWrapper
          col={ 12 }
        >
          <div className="Form-Grid">
            <FieldWrapper
              col={ 6 }
            >
              <FieldWrapper
                col={ 6 }
                label={ <p className="fsz-xs fw-500">Energy Rating</p> }
                description={ `Please input an 'Energy Rating` }
                border
              >
                <RatingType
                  field={ field }
                  isRequired={ false }
                  isDisabled={ !!isDisabled }
                  isModified={ isModified('energy_rating_type') }
                  hasErrors={ hasError('energy_rating_type') }
                  handleChange={ onFieldChange }
                />
              </FieldWrapper>
            </FieldWrapper>
            <FieldWrapper
              col={ 6 }
            >
              <FieldWrapper
                col={ 12 }
                label={ <p className="fsz-xs fw-500">Rating</p> }
                border
              >
                { energyRating ? (
                  <div className="d-f ai-c jc-c" style={{ height: 155 }}>
                    <div className="d-f ai-c jc-c fxd-c" style={{ width: '60%' }}>
                      <div className="d-f ai-c jc-c w-100p" style={{ backgroundColor: energyRating.color }}>
                        <span style={{ fontSize: 100, lineHeight: '100px' }}>{ energyRating?.title || '-' }</span>
                      </div>
                      <div className="d-f ai-c jc-c w-100p" style={{ backgroundColor: energyRating.color, filter: 'brightness(85%)' }}>
                        <span style={{ fontSize: 20 }}>{ `${getFormatedNumber(`${estimatedCostPerYear}`, null, currency?.symbol)}/year` }</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>-</div>
                ) }
              </FieldWrapper>
            </FieldWrapper>
          </div>
        </FieldWrapper>
      </div>
    </FieldWrapper>
  );
};
