// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Containers
import PlannedContainer from 'containers/workplace-service/help-desk/PlannedContainer';
import StatutoryContainer from 'containers/workplace-service/help-desk/StatutoryContainer';
import ReactiveContainer from 'containers/workplace-service/help-desk/ReactiveContainer';
import RemedialContainer from 'containers/workplace-service/help-desk/RemedialContainer';
import ScheduledContainer from 'containers/workplace-service/help-desk/ScheduledContainer';

// Views
import HelpdeskTickets from 'views/workplace-service/HelpdeskTickets';
import NotFound from 'views/NotFound';


// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

interface Props {
  permissions: UserPermissions;
};

class HelpDeskContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    const ticketTypes = ['planned', 'statutory', 'reactive', 'remedial', 'scheduled'];
    const createPaths = ticketTypes.map((type: string) => `/workplace-services/help-desk-tickets/${type}-ticket/create`);
    return (
      <Switch>

        <Route
          exact
          path={ ['/workplace-services/help-desk-tickets'].concat(createPaths) }
          render={ () => {
            return (
              <>
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'ticket_help_desk_ticket_view_list') }>
                  <HelpdeskTickets />
                </RestrictionWrapper>
                { ticketTypes.map((type: string) => (
                  <Route
                    key={ type }
                    exact
                    path={ `/workplace-services/help-desk-tickets/${type}-ticket/create` }
                    render={ () => (
                      <RestrictionWrapper key={ type } restricted={ !hasPermission(permissions, `help_desk_ticket_${type}_ticket_create`) }>
                        <ListViewCreateModal
                          bundle={ 'help-desk-ticket' }
                          type={ `${type}-ticket` }
                          redirectUrl={ '/workplace-services/help-desk-tickets' }
                        />
                      </RestrictionWrapper>
                    ) }
                  />
                )) }
              </>
            );
          } }
        />

        <Redirect exact from="/workplace-services/help-desk-tickets/planned-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/statutory-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/reactive-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/remedial-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/scheduled-ticket" to="/workplace-services/helpdesk-tickets" />

        <Route path="/workplace-services/help-desk-tickets/planned-ticket/:record_id" component={ PlannedContainer } />

        <Route path="/workplace-services/help-desk-tickets/statutory-ticket/:record_id" component={ StatutoryContainer } />

        <Route path="/workplace-services/help-desk-tickets/reactive-ticket/:record_id" component={ ReactiveContainer } />

        <Route path="/workplace-services/help-desk-tickets/remedial-ticket/:record_id" component={ RemedialContainer } />

        <Route path="/workplace-services/help-desk-tickets/scheduled-ticket/:record_id" component={ ScheduledContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes,
  };
};

export default connect(mapStateToProps, null)(HelpDeskContainer);