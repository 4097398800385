  // Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Components
// import { GoogleOutlined } from '@ant-design/icons';

// Views
import ClientPortal from 'views/auth/ClientPortal';

// Interfaces
import AppState from 'store/AppState.interface';

// Services
import { validEmail } from 'utils/utils';
import Notification from 'services/notification';
import Console from 'utils/console';
import history from 'utils/history';
import { useEmailMFA } from 'services/settings';

// Actions
import { authenticateUser, logoutUser } from 'store/User/Actions';
import { setActiveClient } from 'store/Client/Actions';

// Interfaces
import { UserEntity, UserAccessType } from 'types/entities';

interface Props {
  clients: any[];
  user: UserEntity;
  clientSet?: boolean;
  authenticateUser: (email: string, password: string) => Promise<void>;
  logoutUser: () => Promise<void>;
  setActiveClient: (client_id: number) => void;
};

interface State {
  isLoading: boolean;
  showPortal: boolean;
};

class Login extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    isLoading: false,
    showPortal: false,
  };

  componentDidMount = () => {
    const { clients, user } = this.props;
    this.mounted = true;

    if (!isEmpty(clients) && !user.active_client) {
      this.handleLogout();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleLogout = () => {
    this.setState({
      isLoading: false,
      showPortal: false
    }, () => {
      this.props.logoutUser();
    });
  };

  handleLogin = (values: { email: string, password: string }) => {
    const { authenticateUser } = this.props;
    const { email, password } = values;

    this.setState(
      {
        isLoading: true,
      }, async () => {
        try {
          await authenticateUser(email, password);
          const { user } = this.props;
          if (user.access_type === UserAccessType.Contact) {
            Notification('error', 'Your account is marked as a contact only');
            this.handleLogout();
          } else if (isEmpty(user.clients)) {
            Notification('error', 'Your access has been revoked', 'Account Deactivated');
          } else if (useEmailMFA()) {
            history.push('/multi-factor-authentication');
          } else if (user.clients.length === 1) {
            this.props.setActiveClient(user.clients[0].id);
          } else {
            this.mounted && this.setState({
              showPortal: true
            });
          }
        } catch (e) {
          Notification('error', `Email or Password Incorrect`, 'Login Failed');
          Console.info(e);
        } finally {
          this.mounted && this.setState({
            isLoading: false
          });
        }
      }
    );
  };

  render = () => {
    const { isLoading, showPortal } = this.state;
    return (
      <>
        <Form
          className="auth-form"
          layout="vertical"
          onFinish={ this.handleLogin }
          initialValues={process.env.REACT_APP_ENVIRONMENT === 'development' ? {
            'email': 'superadmin@pacs.org.uk',
            'password': 'password',
          } : {}}
        >
          <Form.Item
            name="email"
            label="Email"
            required
            rules={[
              { validator: (_, value) => validEmail(value) ? Promise.resolve() : Promise.reject('Not a valid email address') }
            ]}
          >
            <Input
              placeholder="e.g. user@email.com"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input.Password placeholder="*******" />
          </Form.Item>
          <Form.Item>
            <div className="ta-r">
              <Link to="/request-token">Forgot Password?</Link>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="ta-c mT-30">
              <Button
                type="primary"
                style={{ width: 200 }}
                htmlType="submit"
                disabled={isLoading || showPortal}
                loading={isLoading && !showPortal}
              >
                Sign in
              </Button>
            </div>
          </Form.Item>
        </Form>
        {
          /*
            We don't support Google Logins at this time 21/05/21 so this will be commented out
            <div className="ta-c mB-100">
              <div className="mT-50 mB-50">
                <div className="divider"><span>or</span></div>
              </div>
              <div>
                <Button
                  type="default"
                  icon={<GoogleOutlined />}
                  disabled
                >
                  Continue with Google
                </Button>
              </div>
            </div>
          */
        }

        { showPortal &&
          <ClientPortal
            initial
            onCancel={ () => this.handleLogout() }
          />
        }
      </>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    clients: store.UserState.user.clients || [],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authenticateUser: (email: string, password: string) => dispatch(authenticateUser(email, password)),
    logoutUser: () => dispatch(logoutUser()),
    setActiveClient: (client_id: number) => dispatch(setActiveClient(client_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);