// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Containers
import MasterCoaContainer from 'containers/finance/master-coa/MasterCoaContainer';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Views
import MasterCoas from 'views/finance/MasterCoas';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
};

class MasterCoasContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route
          exact
          path={ [
            '/finance/master-coa',
            '/finance/master-coa/opex-coa/create',
            '/finance/master-coa/service/create',
          ] }
          render={ () => {
            return (
              <>
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_view_list') }>
                  <MasterCoas />
                </RestrictionWrapper>
                <Route
                  exact
                  path='/finance/master-coa/opex-coa/create'
                  render={ () => (
                    <RestrictionWrapper restricted={ !hasPermission(permissions, 'category_opex_coa_create') }>
                      <ListViewCreateModal
                        bundle={ 'category' }
                        type={ 'opex-coa' }
                        redirectUrl={ '/finance/master-coa' }
                      />
                    </RestrictionWrapper>
                  ) }
                />
                <Route
                  exact
                  path='/finance/master-coa/service/create'
                  render={ () => (
                    <RestrictionWrapper restricted={ !hasPermission(permissions, 'category_service_create') }>
                      <ListViewCreateModal
                        bundle={ 'category' }
                        type={ 'service' }
                        redirectUrl={ '/finance/master-coa' }
                      />
                    </RestrictionWrapper>
                  ) }
                />
              </>
            );
          } }
        />

        <Route path="/finance/master-coa/:coa_type/:coa_id" component={ MasterCoaContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes,
  };
};

export default connect(mapStateToProps, null)(MasterCoasContainer);
