// Libs
import store from 'store/Store';

// Actions
import { setQR } from 'store/UI/ActionCreators';

// Utils
import history from 'utils/history';

export function redirect(path: string) {
  store.dispatch(setQR(null));
  history.push(path);
};
