// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import { Action as DropdownAction } from 'components/dropdown';
import Dropdown from 'components/dropdown';
import BasicList, { Config } from "components/basic-list";

// Services
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

const API: Api = new Api();

export const BREADCRUMBS = [
  { title: 'Home', path: '/' },
  { title: 'EH&S', path: '/ehs' },
  { title: 'Incidents', path: null },
];

interface Props {
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
};

class Incidents extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    const { user, setBreadcrumbs } = this.props;

    this.mounted = true;
    setBreadcrumbs(BREADCRUMBS, false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${user.active_client}/record/incident`);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error(`Error:`, error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return (
      <BasicList
        exportTitle={ 'Incidents' }
        config={ this.state.config }
        columns={ this.state.columns }
        items={ this.state.items }
      />
    );
  };

  render = () => {
    const { permissions } = this.props;
    const { isFetching } = this.state;

    const tabs = [
      {
        label: 'List View',
        node: this.renderListView(),
      }
    ];

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'record_incident_create')) {
      actions.push({
        node: 'Create Incident',
        onClick: () => history.push('/ehs/incidents/create')
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Incidents' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
