// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import AuditContainer from 'containers/ehs/AuditContainer';
import ActionContainer from 'containers/ehs/ActionContainer';
import AuditEventsContainer from 'containers/ehs/AuditEventsContainer';
import IncidentContainer from 'containers/ehs/IncidentsContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class EHSContainer extends React.Component {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/ehs" to="/ehs/audits" />

        <Route path="/ehs/audits" component={ AuditContainer } />

        <Route path="/ehs/actions" component={ ActionContainer } />

        <Route path="/ehs/audit-events" component={ AuditEventsContainer } />

        <Route path="/ehs/incidents" component={ IncidentContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default EHSContainer;
