// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Containers
import CostCentreContainer from 'containers/finance/cost-centre/CostCentreContainer';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import ListViewCreateModal from 'views/common/ListViewCreateModal';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Views
import CostCentres from 'views/finance/CostCentres';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  permissions: UserPermissions;
};

class CostCentresContainer extends React.Component<Props> {
  render = () => {
    const permissions = this.props.permissions;
    return (
      <Switch>

        <Route exact path={ ['/finance/cost-centres', '/finance/cost-centres/create' ] } render={ () => {
          return (
            <>
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_view_list') }>
                <CostCentres />
              </RestrictionWrapper>
              <Route
                exact
                path='/finance/cost-centres/create'
                render={ () => (
                  <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_create') }>
                    <ListViewCreateModal
                      bundle={ 'record' }
                      type={ 'cost-centre' }
                      redirectUrl={ '/finance/cost-centres' }
                    />
                  </RestrictionWrapper>
                ) }
              />
            </>
          );
        } } />

        <Route path="/finance/cost-centres/:cost_centre_id" component={ CostCentreContainer } />

        <Route render={() => <NotFound />} />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes,
  };
};

export default connect(mapStateToProps, null)(CostCentresContainer);
