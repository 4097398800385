// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Compoents
import { Menu, Dropdown, Typography, Modal } from 'antd';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';
import ChartTable from 'components/insight/ChartTable';
import Chart from 'components/insight/Chart';
import AntIcon from 'components/ant-icon';

// Icons
import { MoreOutlined, ReloadOutlined, FullscreenOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { IPreview } from 'components/insight/Insight.interfaces';

// Utils
import history from 'utils/history';

// Styles
import './Widget.scss';

const API: Api = new Api();

const Widget = React.forwardRef(({style, className, key, children, ...restOfProps}: any, ref: any) => {

  const [insight, setInsight] = useState<IPreview | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (restOfProps?.widget?.type === 'REPORT' && restOfProps?.widget?.config?.report_id) {
      fetchInsight(restOfProps.widget.config.report_id, restOfProps.filters);
    }
  }, [restOfProps.widget.config.report_id, restOfProps.filters]);

  const fetchInsight = async (report_id: number, filters: any) => {
    try {

      let insight = null;

      setIsLoading(true);
      if (restOfProps?.record) {
        insight = await API.post(`client/${restOfProps.clientId}/${_.kebabCase(restOfProps.record.bundle)}/${_.kebabCase(restOfProps.record.type)}/${restOfProps.record.id}/dashboard/${restOfProps.dashboard.id}/report/${report_id}`, {
          filters: filters
        });
      } else {
        insight = await API.post(`client/${restOfProps.clientId}/insight/report/${report_id}`, {
          filters: filters
        });
      }
      setInsight(insight);

    } catch (error) {
      Notification('error', 'Failed to fetch widget', 'Failed');
    } finally {
      setIsLoading(false);
    }
  };

  const renderWarningPrompt = (redirectUrl: string) => {
    return (
      <Modal
        centered
        closable
        visible
        title={ 'Unsaved Changed' }
        onOk={ () => {
          history.push(redirectUrl);
        } }
        okButtonProps={{
          danger: true
        }}
        okText={ 'Yes' }
        onCancel={ () => setRedirectUrl(null) }
        cancelText={ 'No' }
      >
        You have got unsaved changed. Are you sure you want to discard these?
      </Modal>
    );
  };

  const renderType = (type: 'REPORT' | 'LINK') => {
    if (type === 'REPORT' && !!insight) {
      switch (restOfProps?.widget?.config?.report_mode) {
        case 'chart':
          return (
            <div className="Widget-chart">
              { isLoading ? (
                <BlockingSpinner isLoading />
              ) : (
                <Chart
                  mode={ 'WIDGET' }
                  insight={ insight }
                />
              ) }
            </div>
          );
        case 'table':
          return (
            <ChartTable
              mode={ 'WIDGET' }
              insight={ insight }
              widget={ restOfProps.widget }
              isLoading={ isLoading }
            />
          );
      }
    }

    if (type === 'LINK') {
      return (
        <div className="d-f fxd-c h-100p">
          <div className="d-f fxd-c jc-c ai-c h-100p">
            <Typography.Link
              className="d-if ai-c jc-c fxd-c"
              onClick={ () => {
                if (!!restOfProps?.isDashboardModified) {
                  setRedirectUrl(restOfProps.widget?.config?.url || null);
                } else {
                  history.push(`${restOfProps.widget?.config?.url || '' }`);
                }
              } }
            >
              <div className="d-if ai-c jc-c hov-op-80p bg-primary p-30" style={{ borderRadius: 100, fontSize: '40px', lineHeight: '40px', color: 'white' }}>
                <AntIcon type={ restOfProps.widget.config.icon } />
              </div>
              <div className="fsz-lg ai-c mT-10">
                <Typography.Text className="d-f jc-c">
                  { restOfProps.widget.title }
                </Typography.Text>
              </div>
            </Typography.Link>
          </div>
        </div>
      );
    }

    return '-';
  };

  return (
    <div style={{ ...style }} className={["Widget-container", className].join(' ')} key={ key } { ..._.omit(restOfProps, 'isDashboardModified', 'onEdit', 'onDelete', 'onDuplicate', 'clientId', 'onToggleFullscreen', 'isFullScreenInsightLoading') } ref={ ref }>
      <div
        className={ classNames('Widget-header d-f jc-sb ai-c', {
          'cur-g': !restOfProps.readOnly
        } ) }
        onClick={ (event: React.BaseSyntheticEvent) => event.stopPropagation() }
      >
        <div className="d-if">
          { restOfProps?.widget?.type === 'REPORT' &&
            <div className="d-if">
              <span>
                { restOfProps.widget.title }
              </span>
              { !restOfProps.readOnly &&
                <span className='Widget-hidden-action mL-5'>
                  <EditOutlined onClick={ () => restOfProps.onEdit(restOfProps.widget.id) } />
                </span>
              }
            </div>
          }
        </div>
        <div className="d-if">
          { restOfProps?.widget?.type === 'REPORT' &&
            <>
              <span className='Widget-hidden-action mR-5'>
                <ReloadOutlined onClick={ () => fetchInsight(restOfProps.widget.config.report_id, restOfProps.filters) }/>
              </span>
              <span className='Widget-hidden-action mR-5'>
                { restOfProps.isFullScreenInsightLoading ? (
                  <LoadingOutlined className="fsz-def text-ant-default" />
                ) : (
                  <FullscreenOutlined onClick={ () => restOfProps.onToggleFullscreen(restOfProps.widget.id) }/>
                ) }
              </span>
            </>
          }
          { !restOfProps.readOnly &&
            <span>
              <Dropdown
                placement={ 'bottomRight' }
                overlay={ () => (
                  <Menu>
                    { restOfProps?.widget?.type === 'REPORT' &&
                      <Menu.Item key={ 'edit-report' } onClick={ () => history.push(`/insights/${restOfProps.widget.config.report_id}/edit`) }>
                        { 'Edit Report' }
                      </Menu.Item>
                    }
                    <Menu.Item key={ 'edit-widget' } onClick={ () => restOfProps.onEdit(restOfProps.widget.id) }>
                      { 'Edit Widget' }
                    </Menu.Item>
                    <Menu.Item key={ 'duplice' } onClick={ () => restOfProps.onDuplicate(restOfProps.widget.id) }>
                      { 'Duplicate' }
                    </Menu.Item>
                    <Menu.Item key={ 'delete' } onClick={ () => restOfProps.onDelete(restOfProps.widget.id) }>
                      { 'Delete' }
                    </Menu.Item>
                  </Menu>
                ) }
                trigger={ ['click'] }
              >
                <MoreOutlined className="fsz-def text-ant-default" />
              </Dropdown>
            </span>
          }
        </div>
      </div>
      <div id={ `widget_key_${restOfProps.widget?.key}` } className="Widget-body">
        { renderType(restOfProps?.widget?.type) }
      </div>
      { children }
      { !!redirectUrl && renderWarningPrompt(redirectUrl) }
    </div>
  );
});

export default Widget;
