// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { Form, Modal, Select } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';

// Actions
import { authenticateUser, logoutUser } from 'store/User/Actions';
import { setActiveClient } from 'store/Client/Actions';

// Services
import { redirect } from 'services/store';

// Interfaces
import AppState from 'store/AppState.interface';;
import { UserEntity } from 'types/entities';
import { IQR } from 'store/UI/State.interface';

const { Option } = Select;

interface IClient {
  id: number;
  name: string;
};

interface Props {
  user: UserEntity;
  clients: IClient[];
  initial?: boolean;
  QR: IQR;
  redirect(path: string): void;
  authenticateUser: (email: string, password: string) => Promise<void>;
  logoutUser: () => Promise<void>;
  setActiveClient: (client_id: number, callback: () => void) => void;
  onClientSet?: () => void;
  onCancel: () => void;
};

interface State {
  isLoading: boolean;
  client_id: number | null;
};

class ClientPortal extends React.Component<Props, State> {

  state: State = {
    isLoading: false,
    client_id: this.props.clients[0].id,
  };

  componentDidMount = () => {
    if (this.shouldRedirect()) {
      this.props.setActiveClient(this.props.QR?.client_id, () => {
        this.props.redirect(this.props.QR.value);
      });
    }
  };

  handleClientChange = (client_id: number) => {
    this.setState(
      {
        isLoading: true,
      }, () => {
        this.props.setActiveClient(client_id, () => {
          this.props.onClientSet && this.props.onClientSet();
        });
      }
    );
  };

  shouldRedirect = () => {
    return !!this.props.initial && !!this.props.clients.find((client: IClient) => client.id === this.props.QR?.client_id);
  };

  renderModalContent = () => {
    const { user, clients, initial, QR } = this.props;
    const { client_id } = this.state;

    if (this.shouldRedirect()) {
      return (
        <BlockingSpinner />
      );
    }

    return (
      <div className="ta-c">
        <p>Choose the client you would like to sign into.</p>
        <Form
          className="auth-form"
          layout="vertical"
          initialValues={{
            "client": client_id
          }}
        >
          <Form.Item name="client">
            <Select
              style={{ width: 300 }}
              onSelect={ (client_id: number ) => this.setState({ client_id: client_id }) }
            >
              { clients.map((client: IClient) => {
                const disabled = !initial && _.has(user, 'active_client') && user.active_client === client.id;
                return (
                  <Option key={ client.id } disabled={ disabled } value={ client.id }>
                    { client.name }
                  </Option>
                );
              } ) }
            </Select>
          </Form.Item>
        </Form>
      </div>
    );
  };

  render = () => {
    const { user, initial, onCancel } = this.props;
    const { client_id, isLoading } = this.state;
    const disabled = !initial && _.has(user, 'active_client') && user.active_client === client_id;

    return (
      <Modal
        title="Client Portal"
        centered
        visible
        okButtonProps={{
          loading: isLoading || this.shouldRedirect(),
          disabled: !client_id || disabled || this.shouldRedirect(),
        }}
        onOk={ client_id ? () => this.handleClientChange(client_id) : () => {} }
        onCancel={ () => onCancel() }
      >
        { this.renderModalContent() }
      </Modal>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    clients: store.UserState.user.clients || [],
    QR: store.UIState.QR,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    redirect: (path: string) => redirect(path),
    authenticateUser: (email: string, password: string) => dispatch(authenticateUser(email, password)),
    logoutUser: () => dispatch(logoutUser()),
    setActiveClient: (client_id: number, callback: () => void) => dispatch(setActiveClient(client_id, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPortal);