// Libs
import React from 'react';
import classNames from 'classnames';
import _, { forEach } from 'lodash';

// Components
import { Select } from 'antd';

// Interfaces
import {
  IField,
  IEnergySource,
  IEnergyUnit,
} from 'components/form/field/energy-consumption/EnergyConsumption.interfaces';

interface Props {
  field: IField;
  numberFormat: any;
  isRequired: boolean;
  isModified: boolean;
  isDisabled: boolean;
  hasErrors: boolean;
  handleChange: (field: IField) => void;
};

export default function Source({
  field,
  isModified,
  isDisabled,
  hasErrors,
  handleChange,
}: Props) {

  const energySources: IEnergySource[] = field?.energy_sources || [];
  const value = !_.isEmpty(field.values) && _.has(field.values[0], 'energy_source_combined') ? field.values[0].energy_source_combined : undefined;
  let label = '';

  if (value) {
    const parts = value.split('|');
    if (parts[0] && parts[1]) {
      energySources.forEach((energySource: IEnergySource) => {
        if (`${energySource.id}` === parts[0]) {
          label = energySource?.title || '-';
          energySource.units.forEach((unit: IEnergyUnit) => {
            if (unit.unit === parts[1]) {
              label = `${label} (${unit.symbol})`;
            }
          });
        }
      });
    }
  }

  const options: any = energySources.map((energySource: IEnergySource) => {
    return {
      label: energySource.title,
      options: energySource.units.map((unit: IEnergyUnit) => {
        return {
          label: `${unit.title} (${unit.symbol})`,
          value: `${energySource.id}|${unit.unit}`,
        };
      })
    };
  });

  return (
    <Select
      style={{ minWidth: 80 }}
      className={ classNames('Select-Field', {
        'Select-Field--has-error border-danger': !!hasErrors,
        'Select-Field--has-warning border-warning': isModified && !hasErrors,
      }) }
      dropdownMatchSelectWidth={ false }
      disabled={ isDisabled }
      placeholder={ '-' }
      onChange={ (id: string) => {
        const values = !_.isEmpty(field.values) ? _.cloneDeep(field.values[0]) : [];
        handleChange(_.set(field, 'values', [{
          ...values,
          energy_source_combined: id
        }]));
      } }
      value={ label }
      options={ options }
    />
  );
};
